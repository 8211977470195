import React, { ReactElement } from 'react';
import classes from './BookingStickyMenuDatePicker.module.scss';

interface IProps {
  locale: 'en' | 'da';
}

const DatePickerWeekDays: React.FC<IProps> = ({ locale }): ReactElement => {
  const dayNames = {
    en: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    da: ['Man', 'Tir', 'Ons', 'Tor', 'Fre', 'Lør', 'Søn'],
  };
  return (
    <div className={classes.DatePickerWeekDays}>
      {dayNames[locale].map((day) => (
        <p key={day}>{day}</p>
      ))}
    </div>
  );
};

export default DatePickerWeekDays;
