import { useEffect, useMemo, useRef, useState } from 'react';
import { useMultiStyleConfig, Box, Button, useBreakpointValue, useDimensions, useToken } from '@chakra-ui/react';
import { withDatasourceCheck, Field, Text, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { useI18n } from 'next-localization';
import { Navigation, Scrollbar } from 'swiper';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';
import 'swiper/css';

import { ComponentProps } from 'lib/component-props';
import { IDroptreeColor } from '../../Component-models/data-items/IDroptreeColor';
import EventCalendarItem from './EventCalendarItem';
import { hexToRgbA, rgbToHex } from 'lib/helpers';
import axios from 'axios';
import { hostelEventsWeek } from 'components/HostelEventCalendar/helpers';
import { addDays } from 'date-fns';
import { shortISODateFormat } from 'lib/booking-box-helpers';
import useOnScreen from 'components/Util-Components/useOnScreen';

type EventCalendarProps = ComponentProps & {
  fields: {
    EventCalendarHeading: Field<string>;
    Today: Field<string>;
    color: IDroptreeColor;
  };
};

const genDateObj = (date: Date, locale = 'en') => {
  return {
    date,
    day: date.getDate(),
    weekdayEn: date.toLocaleString('en', { weekday: 'long' }),
    weekday: date.toLocaleString(locale, { weekday: 'long' }),
    month: date.toLocaleString(locale, { month: 'short' }),
    year: date.toLocaleString(locale, { year: 'numeric' }),
  };
};

const genWeekDays = (locale = 'en') => {
  const todaysDate = new Date();

  const today = { ...genDateObj(todaysDate, locale) };

  const weekArray = new Array(6).fill(null).map((_, i) => {
    const date = new Date(todaysDate.getFullYear(), todaysDate.getMonth(), today.day + i + 1);

    return { ...genDateObj(date, locale) };
  });

  return [today, ...weekArray];
};

const EventCalendar = (props: EventCalendarProps) => {
  const { sitecoreContext } = useSitecoreContext();
  const isEditing = sitecoreContext && sitecoreContext.pageEditing;
  // We are artificially rerendering the swiper. We have to do this because we want to
  // change the slidesPerView prop when the viewport width changes, but the swiper is not
  // re-rendering when the dimensions value changes, so we are unmounting and remounting it
  const [loaded, setLoaded] = useState(false);
  const [hostelContainerBgColor, setHostelContainerBgColor] = useState('#000000');
  const [eventsArray, setEventsArray] = useState<hostelEventsWeek>();

  const containerRef = useRef<HTMLDivElement>(null);
  const eventScrolledIntoView = useOnScreen(containerRef);
  const [hasScrolledInto, setHasScrolledInto] = useState(false);

  const { EventCalendarHeading } = props.fields;
  const color = props.fields.color ?? ({ fields: { colorcode: { value: '#000000' } } } as IDroptreeColor);

  const hexEventColor = useToken('colors', color.fields.colorcode.value);
  const rgbaEventColor = hexToRgbA(hexEventColor, 0.3);

  const styles = useMultiStyleConfig('EventCalendar', {});

  const swiperRef = useRef<SwiperRef>(null);
  const dimensions = useDimensions(containerRef, true); // deprecated but alternative not available yet in chakra. change to useSize in next release

  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  });

  const I18n = useI18n();
  const t = I18n.t;
  const locale = I18n.locale() as 'en';

  //get events from new HostelEvents folder
  const reqObject = {
    HostelName: sitecoreContext.site?.name,
    startDate: shortISODateFormat(new Date()),
    endDate: addDays(new Date(), 6),
  };

  const weekArray = useMemo(() => {
    const weekDaySlots = genWeekDays(locale);

    return weekDaySlots;
  }, [locale]);

  const [selectedDay, setSelectedDay] = useState(weekArray[0].weekdayEn);

  const changeSelectedDay = (day: string) => () => {
    swiperRef.current?.swiper.slideTo(0);
    setSelectedDay(day);
  };

  useEffect(() => {
    if (hasScrolledInto) return;
    setLoaded(true);
    //if window is not undefined
    if (typeof window !== 'undefined' && loaded && !isEditing && eventScrolledIntoView) {
      if (!eventsArray) {
        axios.post<hostelEventsWeek>(`/api/hostelevent/getallevents`, reqObject).then((res) => {
          setEventsArray(res.data);
        });
      }
      setTimeout(() => {
        //wait for the swiper to load the swiper-scrollbar-eventCalendar class
        const scrollBarDrag = document.getElementsByClassName('swiper-scrollbar-drag') as HTMLCollectionOf<HTMLElement>;
        const scrollBar = document.getElementsByClassName('swiper-scrollbar') as HTMLCollectionOf<HTMLElement>;
        //find which element in scrollBarr has parent with class name swiper-scrollbar-eventCalendar
        const scrollBarDragEventCalendar = Array.from(scrollBarDrag).find((el) => {
          return el.parentElement?.className.includes('swiper-scrollbar-eventCalendar');
        });
        const scrollBarEventCalendar = Array.from(scrollBar).find((el) => {
          return el.className.includes('swiper-scrollbar-eventCalendar');
        });
        const hostelContainer = scrollBarDragEventCalendar?.closest('.hostel-container');

        if (!hostelContainer) return;
        //get background color of hostel-container
        const hostelContainerBgColor = window.getComputedStyle(hostelContainer).backgroundColor;
        //remove rgb from hostelContainerBgColor and split it into array
        const hostelContainerBgColorArray = hostelContainerBgColor.replace(/[^\d,]/g, '').split(',');
        const hexHostelContainerBgColor = rgbToHex(
          hostelContainerBgColorArray[0],
          hostelContainerBgColorArray[1],
          hostelContainerBgColorArray[2]
        );
        setHostelContainerBgColor(hexHostelContainerBgColor);

        //if scrollBarHorizontal is not undefined
        if (scrollBarDragEventCalendar && scrollBarEventCalendar) {
          //add background color to the scrollbar
          scrollBarEventCalendar.style.backgroundColor = rgbaEventColor ?? 'rgba(0, 0, 0, 0.3)';
          scrollBarDragEventCalendar.style.backgroundColor = hexEventColor ?? 'black';
        }
      }, 200);
      setHasScrolledInto(eventScrolledIntoView);
    }
  }, [
    loaded,
    hexEventColor,
    rgbaEventColor,
    isEditing,
    eventsArray,
    reqObject,
    eventScrolledIntoView,
    hasScrolledInto,
  ]);

  return (
    <Box ref={containerRef} __css={styles.container}>
      {hasScrolledInto && !isEditing ? (
        <>
          <Box
            __css={{ ...styles.divider, borderColor: color?.fields?.colorcode?.value ?? styles.divider.borderColor }}
          />
          <Box __css={styles.header}>
            <Box
              textStyle="h2"
              __css={{ ...styles.headingText, color: color?.fields?.colorcode?.value ?? styles.headingText.color }}
            >
              <Text tag="h2" field={EventCalendarHeading} />
            </Box>
            {isWideVersion ? (
              <Box __css={styles.buttonsContainer}>
                {weekArray.map((weekItem, i) => (
                  <Button
                    key={i}
                    onClick={changeSelectedDay(weekItem.weekdayEn)}
                    __css={{
                      ...styles.dayButton,
                      borderColor: color?.fields?.colorcode?.value ?? styles.dayButton.color,
                      color: color?.fields?.colorcode?.value ?? styles.dayButton.color,
                      opacity: selectedDay === weekItem.weekdayEn ? '1' : '0.65',
                    }}
                  >
                    {i === 0 ? props.fields.Today.value : weekItem.weekday}
                  </Button>
                ))}
              </Box>
            ) : null}
            <Box
              textStyle="p"
              __css={{ ...styles.datesText, color: color?.fields?.colorcode?.value ?? 'darkGrey.700' }}
            >
              {weekArray[0].day} {weekArray[0].month} - {weekArray[6].day} {weekArray[6].month}
            </Box>
          </Box>
          {!isWideVersion ? (
            <Box __css={{ ...styles.buttonsContainer, marginTop: '1rem' }}>
              {weekArray.map((weekItem, i) => (
                <Button
                  key={i}
                  onClick={changeSelectedDay(weekItem.weekdayEn)}
                  __css={{
                    ...styles.dayButton,
                    borderColor: color?.fields?.colorcode?.value ?? styles.dayButton.color,
                    color: color?.fields?.colorcode?.value ?? styles.dayButton.color,
                    opacity: selectedDay === weekItem.weekdayEn ? '1' : '0.65',
                  }}
                >
                  {i === 0 ? props.fields.Today.value : weekItem.weekday}
                </Button>
              ))}
            </Box>
          ) : null}
          {dimensions ? (
            <div
              className={
                eventsArray &&
                eventsArray[selectedDay as keyof hostelEventsWeek].length <= dimensions.contentBox.width / (196 + 40)
                  ? 'hiddenControls'
                  : 'arrowContainer'
              }
            >
              <Box
                __css={styles.arrowNext}
                className="swiper-button image-eventCalendar-button-next"
                backgroundColor={color?.fields?.colorcode?.value ?? 'white'}
              >
                <svg
                  width="31"
                  height="21"
                  viewBox="0 0 31 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  stroke={hostelContainerBgColor}
                >
                  <line x1="28" y1="10.4178" x2="6.11959e-08" y2="10.4178" stroke="inherit" strokeWidth="1.4" />
                  <path d="M21 20.418C21 14.8951 25.4772 10.418 31 10.418" stroke="inherit" strokeWidth="1.4" />
                  <path d="M31 10.418C25.4772 10.418 21 5.94082 21 0.417969" stroke="inherit" strokeWidth="1.4" />
                </svg>
              </Box>
              <Box
                __css={styles.arrowPrev}
                className="swiper-button image-eventCalendar-button-prev"
                backgroundColor={color?.fields?.colorcode?.value ?? 'white'}
              >
                <svg
                  width="31"
                  height="21"
                  viewBox="0 0 31 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  stroke={hostelContainerBgColor}
                >
                  <line x1="3" y1="10.4182" x2="31" y2="10.4182" stroke="inherit" strokeWidth="1.4" />
                  <path d="M10 0.417966C10 5.94082 5.52285 10.418 0 10.418" stroke="inherit" strokeWidth="1.4" />
                  <path
                    d="M-2.38419e-06 10.418C5.52285 10.418 10 14.8951 10 20.418"
                    stroke="inherit"
                    strokeWidth="1.4"
                  />
                </svg>
              </Box>

              <Swiper
                className="sliderContainer"
                modules={[Scrollbar, Navigation]}
                navigation={{
                  nextEl: '.image-eventCalendar-button-next',
                  prevEl: '.image-eventCalendar-button-prev',
                  disabledClass: 'eventCalendar-button-disabled',
                }}
                scrollbar={{ draggable: true, horizontalClass: 'swiper-scrollbar-eventCalendar' }}
                grabCursor
                ref={swiperRef}
                longSwipesRatio={0.1}
                breakpoints={{
                  0: { slidesPerView: dimensions.contentBox.width / (140 + 10) },
                  768: { slidesPerView: dimensions.contentBox.width / (196 + 40) },
                }}
              >
                {eventsArray && eventsArray[selectedDay as keyof hostelEventsWeek].length > 0 ? (
                  <>
                    {eventsArray[selectedDay as keyof hostelEventsWeek]
                      .sort((event1, event2) => event1.Event.en.StartTime.localeCompare(event2.Event.en.StartTime))
                      .map((card) => (
                        <SwiperSlide key={card.ItemId}>
                          <EventCalendarItem
                            cardColor={card.Event.en.CardColor}
                            cardColorCode={card.Event.en.CardColorCode}
                            date={card.Event.startDate}
                            id={card.ItemId}
                            image={card.Event.en.Image}
                            link={card.Event[locale].Link}
                            location={card.Event[locale].Location}
                            startTime={card.Event.en.StartTime}
                            endTime={card.Event.en.EndTime}
                            title={card.Event[locale].Title}
                            description={card.Event[locale].Description}
                          />
                        </SwiperSlide>
                      ))}
                  </>
                ) : (
                  <Box
                    textStyle="h3"
                    __css={{ ...styles.noEvents, color: color?.fields?.colorcode?.value ?? styles.noEvents.color }}
                  >
                    {t('event_calendar_no_events')}
                  </Box>
                )}
              </Swiper>
            </div>
          ) : null}
        </>
      ) : (
        <>Loading...</>
      )}
    </Box>
  );
};

export default withDatasourceCheck()<EventCalendarProps>(EventCalendar);
