// HR-27
import { Box, useMultiStyleConfig, Link, useDimensions, useBreakpointValue } from '@chakra-ui/react';
import { NextImage, Text, withDatasourceCheck, Field, RichText } from '@sitecore-jss/sitecore-jss-nextjs';
import { I_GeneralLink_Link } from '../../Component-models/base-items/IbaseLink';
import { IDroptreeColor } from '../../Component-models/data-items/IDroptreeColor';
import { ComponentProps } from 'lib/component-props';
import { ITag } from '../../Component-models/base-items/Itext';
import CLink from '../buttons/CLink';
import { useRef } from 'react';
import {
  GetImageUrlFromString,
  GetMetaData,
  generateBreakpointValues,
  orginalDigizuiteImage,
  transformCurrency,
} from 'lib/helpers';
import { useCurrencyStore, useGeneralStore } from 'src/store';
import { useI18n } from 'next-localization';

import imageSizeData from 'public/image-sizes.json';

// https://youtrack.arp-hansen.dk/issue/HR-27/Module-Large-image-and-text
type LargeImageAndTextProps = ComponentProps & {
  fields: {
    hTag: ITag;
    heading: Field<string>;
    text: Field<string>;
    fullImageBg: Field<boolean>;
    darkOverlay: Field<boolean>; // 0 = fully dark | 1 = no overlay
    color: IDroptreeColor;
    image: orginalDigizuiteImage;
    link: I_GeneralLink_Link;
    sticker: orginalDigizuiteImage;
    stickerLink: I_GeneralLink_Link;
    // INTERFACE FIELDS - reused across components
  };
};

const LargeImageAndText = (props: LargeImageAndTextProps) => {
  const { hTag, heading, text, image, fullImageBg, darkOverlay, color, link, sticker, stickerLink } = props.fields;
  const _image = GetMetaData(image);
  const _sticker = GetMetaData(sticker);

  const subContainerRef = useRef<HTMLDivElement>(null);
  const dimensions = useDimensions(subContainerRef, true);
  const { currentCurrency } = useCurrencyStore((state) => state);
  const { loaded } = useGeneralStore((state) => state);
  const { locale } = useI18n();

  const styles = useMultiStyleConfig('LargeImageAndText', {});

  const textContent = text.value;
  const updatedTextContent = transformCurrency(textContent, currentCurrency, locale());

  const largeimageandtextBP = generateBreakpointValues(imageSizeData.largeimageandtext);
  const largeimageandtextimagenobgparamsBP = generateBreakpointValues(imageSizeData.largeimageandtextimagenobgparams);
  const largeimageandtextBPValue = useBreakpointValue(
    fullImageBg.value ? largeimageandtextBP : largeimageandtextimagenobgparamsBP,
    {
      fallback: 'lg',
    }
  );

  return loaded ? (
    <Box
      className={`ahhg-large-image-and-text ${props.params.GridParameters}`}
      __css={{
        ...styles.largeContainer,
        backgroundColor: darkOverlay ? 'black' : 'transparent',
        maxHeight: fullImageBg.value
          ? { base: '635px', lg: '850px' }
          : {
              base: dimensions ? `calc(${dimensions.contentBox.height}px + 635px)` : '100px',
              lg: '850px',
            },
      }}
    >
      {/* <pre style={{ color: 'white' }}>{JSON.stringify(props, null, 2)}</pre> */}
      <Box
        ref={subContainerRef}
        // ref={(newRef) => setSubContainerRef(newRef)}
        __css={{
          ...styles.subContainer,
          backgroundColor: fullImageBg.value ?? 'transparent',
        }}
      >
        <Box __css={{ ...styles.divider, borderColor: color?.fields.colorcode.value ?? styles.divider.borderColor }} />
        <Box
          __css={{
            ...styles.contentContainer,
            color: color?.fields.colorcode.value ?? styles.contentContainer.color,
          }}
        >
          <Box textStyle={hTag?.fields?.Tag?.value ?? 'h2'} __css={{ width: '50%', paddingRight: '2rem' }}>
            <Text tag={hTag?.fields?.Tag?.value} field={heading} />
          </Box>
          <Box __css={styles.textAndLinkContainer} className="ahhg-text-and-link-container">
            <Box textStyle="p" __css={styles.textContainer}>
              <RichText className="sc-rich-text" field={{ value: updatedTextContent }} />
            </Box>
            <CLink
              variant="outline"
              text={link.value.text}
              href={link.value.href}
              target={link.value.target}
              backgroundColor="transparent"
              color={color?.fields?.colorcode?.value ?? 'black'}
              // marginTop={{ base: '1.125rem', lg: '1.5rem' }}
            />
          </Box>
        </Box>
      </Box>
      <Box
        __css={{
          ...styles.imageContainer,
          position: fullImageBg.value ? 'static' : 'relative',
          maxHeight: fullImageBg.value ? undefined : { base: '635px', lg: '850px' },
        }}
      >
        <NextImage
          field={{
            src: GetImageUrlFromString(_image.Src, largeimageandtextBPValue),
            alt: _image.Alt,
            title: _image.Title,
          }}
          style={{ opacity: darkOverlay.value ? 0.7 : 1, objectFit: 'cover', objectPosition: 'center' }}
          placeholder={'empty'}
          unoptimized={true}
          fill={true}
        />
        {sticker && sticker.value?.src ? (
          <Box __css={styles.sticker}>
            {stickerLink ? (
              <Link href={stickerLink.value.href} target={stickerLink.value.target}>
                <NextImage
                  field={{
                    src: GetImageUrlFromString(_sticker.Src),
                    alt: _sticker.Alt,
                    title: _sticker.Title,
                  }}
                  fill={true}
                  unoptimized={true}
                />
              </Link>
            ) : (
              <NextImage
                field={{
                  src: GetImageUrlFromString(_sticker.Src),
                  alt: _sticker.Alt,
                  title: _sticker.Title,
                }}
                fill={true}
                unoptimized={true}
              />
            )}
          </Box>
        ) : null}
      </Box>
    </Box>
  ) : (
    <></>
  );
};

export default withDatasourceCheck()(LargeImageAndText);
