import { Box, Icon, useBreakpointValue, useMultiStyleConfig } from '@chakra-ui/react';
import { withDatasourceCheck, Field, RichText, NextImage } from '@sitecore-jss/sitecore-jss-nextjs';
import Link from 'next/link';
import { ComponentProps } from 'lib/component-props';
import { IDroptreeColor } from '../../../Component-models/data-items/IDroptreeColor';
import { LinkArrowRightIcon } from 'assets/Icons/ui/LinkArrowRightIcon';
import { GetImageUrlFromObject, GetMetaData, generateBreakpointValues, orginalDigizuiteImage } from 'lib/helpers';
import imageSizeData from 'public/image-sizes.json';
export type NavigationCardItemProps = ComponentProps & {
  id: string;
  fields: {
    color: IDroptreeColor;
    title: Field<string>;
    text: Field<string>;
    link: Field<{ href: string; description: string; target: string }>;
    image: orginalDigizuiteImage;
  };
};
const NavigationCardItem = (props: NavigationCardItemProps): JSX.Element => {
  const { title, text, link, image, color } = props.fields;

  const styles = useMultiStyleConfig('NavigationCardList', { props });
  // const imageParams = useBreakpointValue(
  //   {
  //     base: { cw: 120, ch: 150, renderingId: DamRenderings['3:4'] },
  //   },
  //   {
  //     fallback: 'base', // Important
  //   }
  // );
  const generatedbreakpoitns = generateBreakpointValues(imageSizeData.navigationcarditem);
  const breakpointImageParams = useBreakpointValue(generatedbreakpoitns, { fallback: 'base' });
  const _image = GetMetaData(image);

  return (
    <Box className="ahhg-navigation-card-item" __css={styles.cardContainer}>
      {link && link.value.href ? (
        <Link href={link.value.href} target={link.value.target} passHref>
          <Box flexGrow={1} display="flex" cursor="pointer" __css={styles.imageAndContentContainer}>
            <Box __css={styles.imageContainer}>
              <NextImage
                field={{
                  src: GetImageUrlFromObject(image, breakpointImageParams),
                  alt: _image?.Alt ?? '',
                  title: _image?.Title ?? '',
                }}
                fill={true}
                placeholder={'empty'}
                style={{ objectFit: 'cover', objectPosition: 'center' }}
                unoptimized={true}
              />
            </Box>
            <Box __css={styles.contentContainer}>
              <Box __css={styles.titleAndLinkContainer}>
                <Box __css={{ ...styles.title, color: color?.fields.colorcode.value ?? styles.title.color }}>
                  <RichText tag="h3" className="sc-rich-text" field={title} />
                </Box>
                <Icon
                  as={LinkArrowRightIcon}
                  boxSize={{ base: '30px', md: '40px' }}
                  strokeWidth={1}
                  color={color?.fields.colorcode.value ?? styles.title.color}
                  __css={styles.icon}
                />
              </Box>
              <Box __css={{ ...styles.text, color: color?.fields.colorcode.value ?? styles.text.color }}>
                <RichText tag="p" className="sc-rich-text" field={text} />
              </Box>
            </Box>
          </Box>
        </Link>
      ) : (
        <div>navigation card missing data for LINK</div>
      )}
    </Box>
  );
};

export default withDatasourceCheck()<NavigationCardItemProps>(NavigationCardItem);
