import config from 'temp/config';
import {
  Box,
  Button,
  chakra,
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Link,
  Text,
  useMultiStyleConfig,
} from '@chakra-ui/react';

import {
  constants,
  GetServerSideComponentProps,
  GraphQLRequestClient,
  Link as SitecoreLink,
  LinkField,
  TextField,
  useComponentProps,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { use100vh } from 'react-div-100vh';
import { useI18n } from 'next-localization';
import HostelSubMenu from './HostelSubMenu';
import { Search, X } from 'tabler-icons-react';
import { ComponentProps } from 'lib/component-props';
import { LinkArrowRightIcon } from 'assets/Icons/ui';
import { HostelMenuQueryDocument } from './HostelMenuQuery.graphql';
import { companyDetailsConst } from 'src/store/booking-store-modules';
import React, { ChangeEvent, FormEvent, useMemo, useRef, useState } from 'react';
import CompanyLoginModal from 'components/CompanyLogin/CompanyLoginModal';
import LanguageAndCurrencyPicker, { DanishFlag, UKFlag } from './LanguageAndCurrencyPicker';
import { useCartStore, useCompanyStore, useCurrencyStore, useMenuStore } from '../../store';
import ChangeReservationModal from 'components/booking/ChangeReservation/ChangeReservationModal';
import { useRouter } from 'next/router';
import { GetMetaData, orginalDigizuiteImage } from 'lib/helpers';

// Same interface as in Navigation
export interface Fields {
  Id: string;
  DisplayName: string;
  Title: TextField;
  NavigationTitle: TextField;
  NavigationAltTitle: string;
  Href: string;
  Querystring: string;
  Children: Array<Fields>;
  Styles: string[];
}

export const getLinkTitle = (fields: Fields): string => {
  let text = '';

  if (fields.NavigationTitle) {
    text = fields.NavigationTitle.value as string;
  } else if (fields.Title) {
    text = fields.Title.value as string;
  } else {
    text = fields.DisplayName;
  }

  return text;
};

export const getLinkField = (fields: Fields): LinkField => ({
  value: {
    href: fields.Href,
    title: getLinkTitle(fields),
    querystring: fields.Querystring,
  },
});

type NavigationProps = ComponentProps & {
  params?: { [key: string]: string };
  fields: Fields;
  handleClick: (event?: React.MouseEvent<HTMLElement>) => void;
  relativeLevel: number;
};

type colorType = {
  jsonValue: {
    fields: {
      colorcode: {
        value?: string;
      };
    };
  };
};

type linkToSearchType = {
  jsonValue: {
    value: {
      href: string;
    };
  };
};

export type HostelMenuProps = {
  props: {
    item: {
      sticker: orginalDigizuiteImage;
      textColor: colorType;
      backgroundColor: colorType;
      linkToSearch: linkToSearchType;
    };
  };
};

export const getServerSideProps: GetServerSideComponentProps = async (_rendering, _layoutData, context) => {
  if (process.env.JSS_MODE == constants.JSS_MODE.DISCONNECTED) {
    return null;
  }
  const itemId =
    config.jssAppName === 'nex' ? '{7314CB77-9FFF-4C08-9A0E-3F729C897E6B}' : '{1ADE3D5D-D218-4422-B871-994198CB1831}';
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  if (process.env.JSS_MODE == constants.JSS_MODE.DISCONNECTED) {
    return null;
  }

  const graphQLClient = new GraphQLRequestClient(config.graphQLEndpoint, {
    apiKey: config.sitecoreApiKey,
  });
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  const result = await graphQLClient.request<HostelMenuProps>(HostelMenuQueryDocument as any, {
    itemId: itemId,
    language: context.locale,
  });
  context.res.setHeader('Cache-Control', 'public, s-maxage=600, stale-while-revalidate=650');
  return {
    props: result,
    revalidate: 600,
  };
};

const HostelMenu = (props: NavigationProps): JSX.Element => {
  const serverProps = useComponentProps<HostelMenuProps>(props.rendering.uid);
  const data = serverProps?.props;
  const _sticker = GetMetaData(data?.item?.sticker);
  const textColor = data?.item?.textColor.jsonValue.fields.colorcode.value ?? 'pink';
  const linkToSearch = data?.item?.linkToSearch.jsonValue.value.href ?? '';
  const backgroundColor = data?.item?.backgroundColor.jsonValue.fields.colorcode.value ?? 'black';
  const { menuIsOpen, setMenuIsOpen } = useMenuStore();
  const { currentCurrency } = useCurrencyStore();
  const { loggedIn, setLoggedIn, setCompanyActive, companyDetails, setCompanyDetails } = useCompanyStore(
    (state) => state
  );
  const { setCartItems, setCartItemIds } = useCartStore((state) => state);

  const i18n = useI18n();
  const { t } = useI18n();
  const height = use100vh();
  const locale = i18n.locale();
  const links = useMemo(() => Object.values(props.fields), [props.fields]);
  const router = useRouter();

  const [searchOpen, setSearchOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [openLoginModal, setOpenLoginModal] = useState(false);
  const [openChangeModal, setOpenChangeModal] = useState(false);
  const [filteredLinks, setFilteredLinks] = useState(links);
  const [selectedLinkId, setSelectedLinkId] = useState<string | null>(null);

  const selectedLink = useMemo(() => {
    if (!selectedLinkId) return null;
    if (selectedLinkId) {
      const childrenLink = links.find((link) => selectedLinkId === link.Id);

      if (childrenLink) return childrenLink;
    }

    return null;
  }, [selectedLinkId, links]);

  const inputRef = useRef<HTMLInputElement>(null);

  const handleFocusSearch = () => {
    setSearchOpen(true);

    setTimeout(() => {
      if (inputRef.current) inputRef.current.focus();
    }, 100);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setSearch(inputValue);

    // if (selectedLinkId === null) {
    //   // if it is on level 1
    //   const filtered = links.filter((link) => getLinkTitle(link).toLowerCase().includes(inputValue.toLowerCase()));

    //   setFilteredLinks(filtered);
    // }
  };

  const handleClearSearch = () => {
    setSearch('');
    setSearchOpen(false);
    setFilteredLinks(links);
  };

  const handleSubmitSearch = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    router.push({
      pathname: linkToSearch,
      query: { search: search },
    });
    setMenuIsOpen(false);
  };

  const openLoginModalHandler = () => {
    if (loggedIn) {
      setLoggedIn(false);
      setCompanyDetails(companyDetailsConst);
      setCompanyActive(false);
      setCartItems([]);
      setCartItemIds([]);
      return;
    }
    setOpenLoginModal(true);
  };

  const styles = useMultiStyleConfig('Menu', { props });
  const SearchIcon = chakra(Search);

  return (
    <Drawer
      isOpen={menuIsOpen}
      onClose={() => {
        setMenuIsOpen(false);
        setSelectedLinkId(null);
      }}
      placement="left"
      size={{ base: 'full', sm: 'sm' }}
      closeOnOverlayClick
      blockScrollOnMount={true}
      preserveScrollBarGap={true}
      autoFocus={false}
    >
      <DrawerOverlay />
      <DrawerContent shadow="none" overflow="hidden">
        <Box
          __css={{
            ...styles.container,
            height: height ?? '100vh',
            color: textColor ?? styles.container.color,
            backgroundColor: backgroundColor ?? styles.container.backgroundColor,
          }}
        >
          <Box className="hostel-menu-close-button" __css={styles.closeButtonWrapper}>
            <DrawerCloseButton __css={styles.closeButton} height="55px" width="55px" />
          </Box>
          <Box
            className="hostel-menu-header-container"
            __css={styles.headerContainer}
            opacity={selectedLinkId === null ? '1' : '0'}
          >
            <Box __css={styles.headerText}>
              <Text>Menu</Text>
            </Box>
            <SearchIcon
              size={32}
              strokeWidth={1}
              color={textColor ?? 'black'}
              onClick={handleFocusSearch}
              cursor="pointer"
            />

            <Box
              className="hostel-menu-search-container"
              __css={{
                ...styles.searchContainer,
                display: searchOpen ? 'flex' : 'none',
                backgroundColor: backgroundColor ?? styles.searchContainer.backgroundColor,
              }}
            >
              <InputGroup>
                <InputLeftElement>
                  <SearchIcon size={26} strokeWidth={1} color={textColor ?? 'black'} />
                </InputLeftElement>
                <form style={{ marginLeft: '1.5rem' }} onSubmit={handleSubmitSearch}>
                  <Input
                    placeholder="Search..."
                    ref={inputRef}
                    sx={{
                      ...styles.input,
                      '::placeholder': { color: textColor ?? 'black' },
                    }}
                    onChange={handleInputChange}
                    value={search}
                  />
                </form>
                <InputRightElement cursor="pointer" onClick={handleClearSearch} color={textColor ?? 'black'}>
                  <X size={26} strokeWidth={1} />
                </InputRightElement>
              </InputGroup>
            </Box>
            <Box
              __css={{
                ...styles.headerBorder,
                borderColor: textColor ?? styles.headerBorder.borderColor,
                backgroundColor: 'transparent',
              }}
            />
            <Box
              __css={{
                ...styles.headerSeparator,
                backgroundColor: textColor ?? styles.headerSeparator.color,
              }}
            />
          </Box>
          <Box className="hostel-menu-body-container" __css={styles.bodyContainer} bgImage={_sticker?.Src ?? ''}>
            <Box className="hostel-menu-body-links" __css={styles.bodyLinkContainer}>
              {filteredLinks.map((link, key) => {
                return (
                  !link.IncludeInSecondaryMenu && (
                    <div key={key + link.Id}>
                      {!link.Children || link.Children?.length === 0 ? (
                        <Link
                          key={key + link.Id}
                          as={SitecoreLink}
                          field={getLinkField(link)}
                          href={link.Href}
                          sx={styles.linkContainer}
                        >
                          <Text>{getLinkTitle(link)}</Text>
                          <Box
                            __css={{
                              ...styles.linkArrow,
                              borderColor: textColor ?? styles.linkArrow.borderColor,
                            }}
                          >
                            <LinkArrowRightIcon />
                          </Box>
                        </Link>
                      ) : (
                        <Button
                          key={link.Id}
                          __css={styles.linkContainer}
                          onClick={() => {
                            setSelectedLinkId(link.Id);
                            setTimeout(() => handleClearSearch(), 200);
                          }}
                          cursor="pointer"
                        >
                          <Text>{getLinkTitle(link)}</Text>
                          <Box
                            __css={{
                              ...styles.linkArrow,
                              borderColor: textColor ?? styles.linkArrow.borderColor,
                            }}
                          >
                            <LinkArrowRightIcon />
                          </Box>
                        </Button>
                      )}
                    </div>
                  )
                );
              })}
            </Box>
            <Box className="hostel-menu-bottomlinks" __css={{ ...styles.bottomLinkContainer }}>
              {filteredLinks.map((link, key) => {
                return (
                  link.IncludeInSecondaryMenu && (
                    <Button
                      key={key + link.Id}
                      __css={{
                        ...styles.bottomLink,
                        borderColor: textColor ?? styles.bottomLink.borderColor,
                      }}
                    >
                      <Link key={key + link.Id} as={SitecoreLink} field={getLinkField(link)} href={link.Href}>
                        <Text padding="0.3rem 1rem">{getLinkTitle(link)}</Text>
                      </Link>
                    </Button>
                  )
                );
              })}
              {process.env.NEXT_PUBLIC_MEWS_ENABLED === 'false' && (
                <>
                  <Button
                    __css={{
                      ...styles.bottomLink,
                      borderColor: textColor ?? styles.bottomLink.borderColor,
                    }}
                    onClick={() => setOpenChangeModal(true)}
                  >
                    <Text padding="0.3rem 1rem">{t('booking_change_menu_text')}</Text>
                  </Button>
                  <Button
                    __css={{
                      ...styles.bottomLink,
                      borderColor: textColor ?? styles.bottomLink.borderColor,
                    }}
                    onClick={openLoginModalHandler}
                  >
                    <Box padding="0.3rem 1rem">
                      {loggedIn ? (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: '100%',
                            gap: '10px',
                          }}
                        >
                          {companyDetails.GuestName}
                          <div style={{ backgroundColor: '#000', borderRadius: '50%', padding: '5px' }}>
                            <X size={'14'} color="#fff" />
                          </div>
                        </div>
                      ) : (
                        t('booking_menu_login')
                      )}
                    </Box>
                  </Button>
                </>
              )}
              <Button
                __css={{
                  ...styles.bottomLink,
                  borderColor: textColor ?? styles.bottomLink.borderColor,
                }}
                onClick={() => setMenuIsOpen(true, true)}
              >
                {locale === 'da' ? <DanishFlag styles={styles.langFlag} /> : <UKFlag styles={styles.langFlag} />}
                <Text padding="0.3rem 1rem">
                  {locale === 'da' ? 'DAN' : 'ENG'} / {currentCurrency.Code}
                </Text>
              </Button>
            </Box>
            {/*</Box>*/}
            {selectedLinkId && (
              <HostelSubMenu
                selectedLinkId={selectedLinkId}
                setSelectedLinkId={setSelectedLinkId}
                link={selectedLink}
                search={search}
                handleFocusSearch={handleFocusSearch}
                handleClearSearch={handleClearSearch}
                backgroundcolor={backgroundColor}
                textcolor={textColor}
              />
            )}
            <LanguageAndCurrencyPicker backgroundcolor={backgroundColor} textcolor={textColor} />
            {/*</Box>*/}
          </Box>
        </Box>
      </DrawerContent>
      <CompanyLoginModal
        isOpen={openLoginModal}
        message={t('booking_company_login_message')}
        title={t('booking_company_login_title')}
        onClose={() => setOpenLoginModal(false)}
        buttonText={t('booking_company_login_button')}
      />
      <ChangeReservationModal
        isOpen={openChangeModal}
        onClose={() => setOpenChangeModal(false)}
        message={t('booking_change_message')}
        title={t('booking_change_title')}
        buttonText={t('booking_change_button')}
      />
    </Drawer>
  );
};

export default HostelMenu;
