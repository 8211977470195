import React, { ReactElement, useEffect, useState } from 'react';
import { useDormStore, useCartStore, useBookingMenuDatesStore, useGeneralStore } from '../../../../store';
import classes from '../../ParentBox.module.scss';
import customClasses from './Flexibility.module.scss';
import { Checkbox } from '@chakra-ui/react';
import { CircleIcon } from '../../../../assets/Icons/CircleIcon';
import { useI18n } from 'next-localization';
import { updateCartItemPrices } from 'lib/booking-helpers';
import { useGenerateDataLayerFromCartItems, useGenerateViewItemListInverse } from 'lib/customHooks/hooks';
import { dataLayerObject, dataLayerHostelNames, fireEcomDataLayerEvent, dataLayerItemNames } from 'lib/helpers';
import { CartItem } from 'src/store/booking-store-modules';
import { shortDateFormatWithYear, shortISODateFormat } from 'lib/booking-box-helpers';
import { differenceInDays, startOfDay, subDays } from 'date-fns';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';

const FlexibilityBox: React.FC = (): ReactElement => {
  const { t, locale } = useI18n();

  const { DormsData, setDormRooms } = useDormStore((state) => state);
  const { cartItems, setCartItems, cartFetchingDates, setFlexCart, companyRatesActive } = useCartStore(
    (state) => state
  );
  const { loaded } = useGeneralStore((state) => state);
  const { sitecoreContext } = useSitecoreContext();
  const { menuFetchingDates, isFlexDisabled, isRestrictedDisabled, setIsFlexDisabled, setFlex, flex } =
    useBookingMenuDatesStore((state) => state);
  const [datesTooClose, setDatesTooClose] = useState(false);

  const removeDataLayerCartItems = useGenerateDataLayerFromCartItems();
  const generateViewItemList = useGenerateViewItemListInverse();

  const cancellationDate = shortDateFormatWithYear(subDays(new Date(menuFetchingDates.checkIn!), 3), locale());
  const flexDescriptionWithDate = t('booking_flexibility_FLEX_description').replace('##cancelDate##', cancellationDate);

  const generateDataLayerFromCartItems = (cartItems: CartItem[]) => {
    const dataLayerCartItems: dataLayerObject[] = [];
    cartItems.forEach((cartItem) => {
      const dataLayerCartItem: dataLayerObject = {
        item_id: cartItem.UniqueQualifier,
        item_name:
          dataLayerItemNames[cartItem.UniqueQualifier as keyof typeof dataLayerItemNames] ??
          t(`booking_${cartItem.UniqueQualifier}_headline`),
        item_variant: cartItem.RateCode,
        item_brand: sitecoreContext?.site?.name
          ? dataLayerHostelNames[sitecoreContext.site.name as keyof typeof dataLayerHostelNames]
          : '',
        item_category: shortISODateFormat(menuFetchingDates.checkIn!) ?? '',
        item_category2: cartItem.NumberOfPersons,
        item_category3: differenceInDays(new Date(menuFetchingDates.checkOut!), new Date(menuFetchingDates.checkIn!)),
        item_category4: cartItem.NumberOfPersons,
        item_category5: cartItem.RoomType,
        item_list_id: 'STEP-1',
        item_list_name: 'Product list',
        affiliation: locale(),
        currency: 'DKK',
        price: cartItem.Price,
        quantity: 1,
      };
      dataLayerCartItems.push(dataLayerCartItem);
    });
    return dataLayerCartItems;
  };

  const changeFlexibleBooking = (event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    if (DormsData) {
      cartItems.length > 0 && fireEcomDataLayerEvent('remove_from_cart', removeDataLayerCartItems);
      if (event.target.value === 'flexible') {
        const updatedCartItems = updateCartItemPrices(cartItems, DormsData.Flex, menuFetchingDates, cartFetchingDates);
        if (updatedCartItems) {
          //only used here for updating creditCards as mobilePay should not show on flex
          //otherwise use useState(false) by default to not run into problems
          setFlex(true);
          setFlexCart(true);
          setDormRooms(DormsData.Flex);
          setCartItems(updatedCartItems);
          const dataLayerCartItems = generateDataLayerFromCartItems(updatedCartItems);
          cartItems.length > 0 && fireEcomDataLayerEvent('add_to_cart', dataLayerCartItems);
          fireEcomDataLayerEvent('view_item_list', generateViewItemList);
        }

        return;
      }

      const updatedCartItems = updateCartItemPrices(
        cartItems,
        DormsData.Restricted,
        menuFetchingDates,
        cartFetchingDates
      );
      if (updatedCartItems) {
        setFlex(false);
        setFlexCart(false);
        setDormRooms(DormsData.Restricted);
        setCartItems(updatedCartItems);
        const dataLayerCartItems = generateDataLayerFromCartItems(updatedCartItems);
        cartItems.length > 0 && fireEcomDataLayerEvent('add_to_cart', dataLayerCartItems);
        fireEcomDataLayerEvent('view_item_list', generateViewItemList);
      }
    }
  };

  useEffect(() => {
    //on first load, we update prices to rest or flex depending on what is selected
    const pricing = flex ? 'Flex' : 'Restricted';
    //only change rates and prices when companyRates not active
    if (DormsData && !companyRatesActive) {
      const updatedCartItems = updateCartItemPrices(
        cartItems,
        DormsData[pricing],
        menuFetchingDates,
        cartFetchingDates
      );
      updatedCartItems && setCartItems(updatedCartItems);
    }
  }, []);

  useEffect(() => {
    //check if menuFetchingDates.checkIn is less than 2 days from today
    if (menuFetchingDates.checkIn) {
      const today = startOfDay(new Date());
      const checkIn = startOfDay(new Date(menuFetchingDates.checkIn));
      const difference = checkIn.getTime() - today.getTime();
      const days = difference / (1000 * 3600 * 24);
      if (days <= 2) {
        setDatesTooClose(true);
        setIsFlexDisabled(true);
        setFlex(false);
      }
      if (cartItems.length === 0 && days > 2) {
        setIsFlexDisabled(false);
      }
    }
  }, [isFlexDisabled, cartItems.length, menuFetchingDates.checkIn]);

  return loaded ? (
    <div className={classes.roomBoxContainer}>
      <div className={`${classes.roomType} ${customClasses.FlexibilityBox}`}>
        <div className={`${classes.FormControl} ${customClasses.FlexibilityFormControl}`}>
          <div className={customClasses.FlexibilityCheckboxGroup}>
            <Checkbox
              value="restricted"
              colorScheme="darkGrey"
              name="flexibleBooking"
              disabled={isRestrictedDisabled}
              size="lg"
              isChecked={!flex}
              onChange={changeFlexibleBooking}
              variant="custom-control"
              icon={<CircleIcon checked={!flex} />}
            >
              {t('booking_flexibility_RESTRICTED_headline')}
              <p>{t('booking_flexibility_RESTRICTED_description')}</p>
            </Checkbox>
          </div>
          <p className={customClasses.disabledLabel}>
            {isRestrictedDisabled && t('booking_restricted_not_corresponding')}
          </p>
          <div className={`${customClasses.FlexibilityCheckboxGroup} ${customClasses.FlexiblePrice}`}>
            <Checkbox
              variant="custom-control"
              value="flexible"
              colorScheme="darkGrey"
              name="flexibleBooking"
              disabled={isFlexDisabled}
              size="lg"
              isChecked={flex}
              icon={<CircleIcon checked={flex} />}
              onChange={changeFlexibleBooking}
            >
              {t('booking_flexibility_FLEX_headline')}
              {isFlexDisabled ? <></> : <p>{flexDescriptionWithDate}</p>}
            </Checkbox>
          </div>
          <p className={customClasses.disabledLabel}>
            {datesTooClose ? t('booking_flexibility_dates') : isFlexDisabled && t('booking_flexible_not_corresponding')}
          </p>
        </div>
      </div>
    </div>
  ) : (
    <> </>
  );
};
export default FlexibilityBox;
