import { Link, Box, useMultiStyleConfig, Button, useBreakpointValue, Text } from '@chakra-ui/react';
import { NextImage, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { eventCalendarItemTextColor, transformCurrency } from 'lib/helpers';
import { useI18n } from 'next-localization';
import { useRef, useState } from 'react';
import { useCurrencyStore } from 'src/store';
import ArrowRight from '../Content-Components/icons/ArrowRight';

interface Props {
  id: string;
  date: string;
  location: string;
  startTime: string;
  endTime: string; // optional
  title: string;
  description: string;
  image: string;
  link: string;
  cardColor: string;
  cardColorCode: string;
}

const EventCalendarItem = (props: Props): JSX.Element => {
  const [focused, setFocused] = useState(false);

  const { location, startTime, endTime, image, link, cardColor, cardColorCode } = props;
  const description = { value: props.description };
  const title = { value: props.title };
  const styles = useMultiStyleConfig('EventCalendarItem', {});
  const { sitecoreContext } = useSitecoreContext();
  const { currentCurrency } = useCurrencyStore((state) => state);
  const isEditing = sitecoreContext && sitecoreContext.pageEditing;

  const textColor = eventCalendarItemTextColor[cardColor ?? 'White'] ?? '#000';

  const { t, locale } = useI18n();

  const buttonRef = useRef<HTMLButtonElement>(null);

  let txt: string | Document = description?.value;

  if (!isEditing) {
    // HR-780/BUG-Event-calendar-unicode-vises-i-stedet-for-tegn
    // decode html entities
    const domParser = new DOMParser();
    txt = domParser.parseFromString(description.value, 'text/html');
    description.value = txt.documentElement.textContent ?? description.value;
    description.value = description.value.substring(0, 70);
    description.value = transformCurrency(description.value, currentCurrency, locale());
  }

  const breakpointImageParams = useBreakpointValue(
    {
      base: { cw: 240, ch: 210, cx: 0.5, cy: 0.5 },
      md: { cw: 196, ch: 154, cx: 0.5, cy: 0.5 },
    },
    {
      fallback: 'md', // Important
    }
  );

  const breakpointTitleDelimiter = useBreakpointValue(
    {
      base: 23,
      lg: 22,
    },
    {
      fallback: '22', // Important
    }
  );

  title.value = title.value.substring(0, breakpointTitleDelimiter);

  return (
    <Box __css={styles.container}>
      <Button
        ref={buttonRef}
        className="flip-card"
        __css={{ padding: 0 }}
        _focus={{ outline: 'none' }}
        onClick={() => {
          if (focused) {
            setFocused(false);
            buttonRef.current?.blur();
          } else {
            setFocused(true);
            buttonRef.current?.focus();
          }
        }}
        onBlur={() => setTimeout(() => setFocused(false), 200)}
      >
        <Box className="flip-card-inner">
          <Box
            className="flip-card-front"
            __css={{
              ...styles.cardFront,
              backgroundColor: cardColorCode ?? 'blue',
              color: textColor,
            }}
          >
            <Box __css={styles.imageContainer}>
              <NextImage
                className="zoom-image"
                field={{ value: { src: image } }}
                fill={true}
                placeholder="empty"
                unoptimized={true}
                imageParams={breakpointImageParams}
              />
            </Box>
            <Box __css={styles.contentContainer}>
              <Box __css={styles.location}>
                <Text> {location}</Text>
              </Box>
              <Box __css={{ display: 'flex' }}>
                <Box __css={styles.time}>
                  <Text>{startTime}</Text>
                </Box>
                {endTime ? (
                  <Box __css={{ ...styles.time, marginLeft: '0.3rem' }}>
                    <Text>- {endTime}</Text>
                  </Box>
                ) : null}
              </Box>
              <Box __css={styles.title}>
                <Text>
                  {title.value}
                  {title.value.length >= breakpointTitleDelimiter! ? '...' : ''}
                </Text>
              </Box>
            </Box>
          </Box>
          <Box
            className="flip-card-back"
            __css={{
              ...styles.cardBack,
              backgroundColor: cardColorCode ?? 'blue',
              color: textColor,
            }}
          >
            <Box __css={styles.location}>
              <Text> {location}</Text>
            </Box>
            <Box __css={{ display: 'flex' }}>
              <Box __css={styles.time}>
                <Text> {startTime}</Text>
              </Box>
              {endTime ? (
                <Box __css={{ ...styles.time, marginLeft: '0.3rem' }}>
                  <Text>- {endTime}</Text>
                </Box>
              ) : null}
            </Box>
            <Box __css={styles.description}>
              <Text>{description.value} </Text>
              {description.value.length >= 70 && '...'}
            </Box>
            {link && (
              <Link href={link} target={'_blank'} sx={styles.link}>
                {t('event_calendar_see_more')}
                <ArrowRight size={16} color={textColor} style={{ marginLeft: '0.4rem' }} />
              </Link>
            )}
          </Box>
        </Box>
      </Button>
    </Box>
  );
};

export default EventCalendarItem;
