import { Box, Button } from '@chakra-ui/react';
import { ReactElement, useEffect, useState } from 'react';
import { useCompanyStore } from 'src/store';
import { useI18n } from 'next-localization';
import CompanyLoginModal from './CompanyLoginModal';

const CompanyLogin: React.FC = (): ReactElement => {
  const { t } = useI18n();

  const { loggedIn, setLoggedIn, setCompanyActive } = useCompanyStore((state) => state);
  const [displayCompany, setDisplayCompany] = useState(false);
  const [openLoginModal, setOpenLoginModal] = useState(false);

  useEffect(() => {
    useCompanyStore.persist.hasHydrated() && setDisplayCompany(true);
  }, []);

  const openLoginModalHandler = () => {
    if (loggedIn) {
      setLoggedIn(false);
      setCompanyActive(false);
      return;
    }
    setOpenLoginModal(true);
  };

  return displayCompany ? (
    <Box w={40}>
      <Button type="button" bgColor={loggedIn ? 'green.900' : 'green.500'} onClick={openLoginModalHandler}>
        {loggedIn ? 'Log out' : 'Login'}
      </Button>
      <CompanyLoginModal
        isOpen={openLoginModal}
        message={t('booking_company_login_message')}
        title={t('booking_company_login_title')}
        onClose={() => setOpenLoginModal(false)}
        buttonText={t('booking_company_login_button')}
      />
    </Box>
  ) : (
    <></>
  );
};

export default CompanyLogin;
