import {
  Box,
  Checkbox,
  Modal as ChakraModal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Spinner,
  Text,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import CButton from 'components/buttons/CButton';
import classes from './CancelBox.module.scss';
import { useI18n } from 'next-localization';
import { useState } from 'react';
interface Props {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  submitHandler: () => void;
  loading: boolean;
  cancelComplete: boolean;
  buttonText?: string;
  errorText?: string;
  successText?: string;
}

const CancelBookingModal = (props: Props): JSX.Element => {
  const { t } = useI18n();
  const {
    isOpen,
    onClose,
    title,
    buttonText = t('booking_alert_close_text'),
    submitHandler,
    errorText,
    successText,
    loading,
    cancelComplete,
  } = props;

  const [cancelChecked, setCancelChecked] = useState(false);
  const [buttonTouched, setButtonTouched] = useState(false);

  const checkBoxHandler = () => {
    !cancelChecked ? setButtonTouched(false) : setButtonTouched(true);
    setCancelChecked(!cancelChecked);
  };
  const cancelHandler = () => {
    cancelChecked ? submitHandler() : setButtonTouched(true);
  };

  const styles = useMultiStyleConfig('Modal', {});

  return (
    <ChakraModal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent sx={styles.container}>
        <ModalCloseButton __css={styles.closeButton} />
        <Box __css={styles.title}>
          <Text>{title}</Text>
        </Box>
        <Box __css={styles.divider} />
        <Box __css={styles.title}>
          <h3 style={{ color: 'red', fontSize: '16px', marginTop: '-12px' }}>{errorText}</h3>
          <h3 style={{ color: 'green', fontSize: '16px', marginTop: '-12px' }}>{successText}</h3>
        </Box>
        {!cancelComplete && (
          <>
            <Box __css={{ ...styles.message, margin: '0 auto' }}>
              <Checkbox
                value={'cancelBooking'}
                colorScheme="darkGrey"
                name="cancelBooking"
                size="lg"
                isChecked={cancelChecked}
                onChange={checkBoxHandler}
              >
                <p className={`${buttonTouched && classes.modalButtonTouched}`}>{title}</p>
              </Checkbox>
            </Box>
            {loading && <Spinner margin="0 auto" />}
            <CButton
              onClick={cancelHandler}
              text={buttonText}
              backgroundColor={styles.button.backgroundColor as string}
              color={styles.button.color as string}
              mt="1rem"
            />
          </>
        )}
      </ModalContent>
    </ChakraModal>
  );
};

export default CancelBookingModal;
