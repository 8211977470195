import { Box, useBreakpointValue, useMultiStyleConfig } from '@chakra-ui/react';
import React, { ReactElement, useState } from 'react';
import Image from 'next/image';
import { Breakpoints, DigizuiteImage, GetImageUrlFromString, generateBreakpointValues } from 'lib/helpers';
// import DamRenderings from 'lib/damRenderings';
import imageSizeData from 'public/image-sizes.json';

const NaturalHeightImage: React.FC<{
  item: DigizuiteImage;
  customSizeAndRenderingIds?: { [key: string]: Breakpoints } | null;
}> = ({ item, customSizeAndRenderingIds = null }): ReactElement => {
  const [portraitHeight, setPortraitHeight] = useState(0);
  const styles = useMultiStyleConfig('ProductCardGallery', {});

  const _modalImageBP = generateBreakpointValues(imageSizeData.naturalheightimagedefault);
  const modalImageParams = useBreakpointValue(customSizeAndRenderingIds || _modalImageBP, {
    fallback: 'md',
  });

  return (
    <Box __css={{ ...styles.portraitImageContainer }} height={portraitHeight} width="100%">
      <Image
        className="media"
        // src={imageFromBreakpoints(customSizesAndRenderingIds || modalImageParams, item.imageUrl, true)}
        src={GetImageUrlFromString(item.Src, modalImageParams)}
        fill={true}
        placeholder={'empty'}
        priority={false}
        unoptimized={true}
        style={{ objectFit: 'cover' }}
        // imageParams={modalImageParams}
        onLoad={({ target }) => {
          const { naturalHeight } = target as HTMLImageElement;
          setPortraitHeight(naturalHeight);
        }}
        alt={item?.Alt}
        title={item?.Title}
      />
    </Box>
  );
};

export default NaturalHeightImage;
