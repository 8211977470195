import { useI18n } from 'next-localization';
import React, { ReactElement, useEffect, useState } from 'react';
import { useCartStore } from 'src/store';
import classes from '../../ParentBox.module.scss';
import customClasses from './ExtraBox.module.scss';

type IProps = {
  numberOfPackageOptions: ReactElement[];
  roomType: string;
  cartIdentifier: number;
  packageCode1: string;
  packageCode2: string;
};

const ExtraPackage2Select: React.FC<IProps> = ({
  numberOfPackageOptions,
  roomType,
  cartIdentifier,
  packageCode1,
  packageCode2,
}): ReactElement => {
  const { t } = useI18n();

  const {
    cartItems,
    updateCartItemPackage2,
    setExtraPackageChildrenSelectActive,
    extraPackage2Details,
    clearExtraPackageChildrenSelect,
  } = useCartStore((state) => state);
  const [previousSelectValue, setPreviousSelectValue] = useState<{
    val1?: number;
    val2?: number;
  }>();

  useEffect(() => {
    cartItems.forEach((cartItem) =>
      cartIdentifier === cartItem.identifier
        ? cartItem.packages.map((pck) =>
            pck.PackageCode === packageCode1
              ? setPreviousSelectValue((prevState) => ({ ...prevState, val1: pck.Count }))
              : pck.PackageCode === packageCode2
              ? setPreviousSelectValue((prevState) => ({ ...prevState, val2: pck.Count }))
              : null
          )
        : null
    );
  }, []);

  const changePackageDetails = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const [adults, children] = event.target.value.split(',');
    const numAdults = parseInt(adults);
    const numChildren = parseInt(children);
    //if on select change, the number of children is > 0, then set the child package active
    //else clear the child package

    if (numChildren > 0 && (previousSelectValue?.val2 == null || previousSelectValue.val2 === 0)) {
      setExtraPackageChildrenSelectActive(cartIdentifier, numChildren, extraPackage2Details['child']);
    } else if (numChildren === 0) {
      clearExtraPackageChildrenSelect(cartIdentifier, extraPackage2Details['child'].PackageCode);
    }

    //if childPackage is already active, and the selection is not null, we can just update it together with the adult package
    updateCartItemPackage2(cartIdentifier, packageCode1, numAdults, packageCode2, numChildren);

    setPreviousSelectValue({ val1: numAdults, val2: numChildren });
  };

  return (
    <div className={`${classes.selectWrapper} ${customClasses.selectWrapper}`}>
      <select
        onChange={changePackageDetails}
        className={customClasses.selectText}
        name={'numberOfPackageOptions'}
        disabled={roomType.includes('B-')}
        value={`${previousSelectValue?.val1},${previousSelectValue?.val2}`}
      >
        {roomType.includes('B-') ? numberOfPackageOptions[0] : numberOfPackageOptions}
      </select>
      <span className={`${classes.floatingLabel} ${classes.floatingLabelMoved}`}>{t('booking_selector_hint')}</span>
    </div>
  );
};

export default ExtraPackage2Select;
