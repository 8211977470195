import React, { ReactElement, useEffect } from 'react';
import { useDormStore, useCompanyStore, useSitecoreFieldsStore } from '../../../../store';
import classes from '../../ParentBox.module.scss';
import customClasses from './CompanyRatesBox.module.scss';
import { useI18n } from 'next-localization';
import BrandButton from 'components/booking/BrandButton/BrandButton';
import { RichText } from '@sitecore-jss/sitecore-jss-nextjs';

const CompanyRatesBox: React.FC = (): ReactElement => {
  const { t } = useI18n();

  const { DormsData, setDormRooms } = useDormStore((state) => state);
  const { companyActive, setCompanyActive, companyDetails } = useCompanyStore((state) => state);
  const { sitecoreFields } = useSitecoreFieldsStore((state) => state);

  const switchRates = () => {
    if (DormsData) {
      setCompanyActive(!companyActive);
      if (companyActive) {
        setDormRooms(DormsData.Restricted);
        return;
      }
      setDormRooms(DormsData.Corporate);
    }
  };

  useEffect(() => {
    if (DormsData) {
      setCompanyActive(true);
      setDormRooms(DormsData.Corporate);
      //not needed as company and public carts dont mix
      // const updatedCartItems = updateCartItemPrices(
      //   cartItems,
      //   DormsData.Corporate,
      //   menuFetchingDates,
      //   cartFetchingDates
      // );
      // updatedCartItems && setCartItems(updatedCartItems);
    }
  }, []);

  return (
    <div className={`${classes.roomBoxContainer} ${customClasses.CompanyContainer}`}>
      <div className={`${classes.roomType} ${customClasses.CompanyRatesBox}`}>
        <h3>{companyDetails.GuestName}</h3>
        <div className={customClasses.CompanyAdvantages}>
          {companyActive ? (
            <RichText field={{ value: sitecoreFields.companyRates }} />
          ) : (
            <RichText field={{ value: sitecoreFields.companyRatesPublic }} />
          )}
        </div>
        <BrandButton
          clickHandler={switchRates}
          buttonText={companyActive ? t('company_switch_to_public') : t('company_switch_to_agreement')}
        />
      </div>
    </div>
  );
};
export default CompanyRatesBox;
