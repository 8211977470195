import { Icon, IconProps } from '@chakra-ui/react';
import { ReactElement } from 'react';

export const YogaIcon = (props: IconProps): ReactElement => {
  return (
    <Icon {...props} viewBox="0 0 35 35">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.2409 10.7914H15.1234L1.94771 30.96L21.0429 30.9229C25.1293 30.9229 28.1081 27.1343 27.8026 23.3086C27.4971 19.6686 24.3273 16.8457 20.5464 17.1057C17.3766 17.3286 14.4741 19.9657 14.7415 23.1971C14.9706 26.02 17.4912 28.2486 20.3937 28.1C22.8378 27.9886 24.5182 26.4286 24.5182 24.0514C24.5182 22.1943 23.3343 20.56 21.3484 20.3743C19.7826 20.2257 18.1404 21.3029 18.2168 22.9371C18.255 24.3114 19.4389 25.1286 20.8138 25.0914L20.8519 26.1314C18.866 26.2057 17.2239 24.9057 17.1475 22.9743C17.0711 20.7086 19.2479 19.1486 21.463 19.3343C24.0217 19.5571 25.6257 21.6371 25.6257 24.0514C25.6257 26.9857 23.4871 29.0286 20.47 29.1771C16.9947 29.3629 13.9395 26.6886 13.6722 23.3086C13.5576 21.8971 13.9395 20.4857 14.8561 19.1857L20.2409 10.7914ZM17.6439 16.8086L22.4559 9.30571C22.9142 8.6 23.4489 8.08 24.2127 7.67143C24.9001 7.3 25.7021 7.07714 26.5805 7.07714C27.9172 7.07714 29.1011 7.59714 29.9794 8.45143C31.2397 9.67714 31.6598 11.3857 31.2015 13.0571L28.681 21.9343C27.7644 18.3314 24.3273 15.8429 20.47 16.1029C19.5153 16.14 18.5223 16.4 17.6439 16.8086ZM28.7573 25.5371C28.0317 29.0657 24.8619 31.9629 21.0429 31.9629L0.99295 32H0L0.534665 31.1829L14.3596 9.97429L14.5123 9.71429H14.8179H20.9283L21.5394 8.74857C22.1504 7.85714 22.7233 7.26286 23.678 6.74286C24.5182 6.26 25.5112 6 26.5805 6C28.2227 6 29.6739 6.63143 30.7432 7.67143C32.2327 9.12 32.8437 11.3114 32.2327 13.28L28.7573 25.5371Z"
        fill="#212121"
      />
    </Icon>
  );
};
