import { ReactElement } from 'react';

export const DaIcon = (): ReactElement => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15 30c8.285 0 15-6.715 15-15S23.285 0 15 0 0 6.715 0 15s6.715 15 15 15Z" fill="#fff" />
      <path
        d="M11.74 13.044h18.133C28.914 5.683 22.621 0 15 0c-1.12 0-2.21.124-3.26.356v12.688Zm-3.913 0V1.824a15.01 15.01 0 0 0-7.699 11.22h7.699Zm0 3.912h-7.7a15.01 15.01 0 0 0 7.699 11.22v-11.22Zm3.913 0v12.688A15.048 15.048 0 0 0 15 30c7.621 0 13.914-5.683 14.873-13.044H11.74Z"
        fill="#D80027"
      />
    </svg>
  );
};
