import { differenceInDays } from 'date-fns';
import { useI18n } from 'next-localization';
import { dataLayerObject, dataLayerHostelNames, dataLayerItemNames } from '../helpers';
import { useBookingMenuDatesStore, useCartStore, useDormStore } from '../../store/index';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { shortISODateFormat } from '../booking-box-helpers';

export function useGenerateDataLayerFromCartItems(): Array<dataLayerObject> {
  const { cartItems, cartFetchingDates, extraPackage2Details, extraPackage2Checked, package1DetailsList } =
    useCartStore((state) => state);
  const { t, locale } = useI18n();
  const { sitecoreContext } = useSitecoreContext();

  const dataLayerObjects: Array<dataLayerObject> = [];
  let numAdults = 0;
  let numChildren = 0;
  let totalGuests = 0;
  const numberOfNights = differenceInDays(new Date(cartFetchingDates.checkOut!), new Date(cartFetchingDates.checkIn!));
  //one dataLayer object for each cartItem
  cartItems.forEach((item) => {
    dataLayerObjects.push({
      item_id: item.RoomType,
      item_name:
        dataLayerItemNames[item.UniqueQualifier as keyof typeof dataLayerItemNames] ??
        t(`booking_${item.UniqueQualifier}_headline`),
      item_variant: item.RateCode,
      item_brand: sitecoreContext?.site?.name
        ? dataLayerHostelNames[sitecoreContext.site.name as keyof typeof dataLayerHostelNames]
        : '',
      item_category: shortISODateFormat(cartFetchingDates.checkIn!) ?? '',
      item_category2: item.numberOfGuests,
      item_category3: numberOfNights,
      item_category4: item.numberOfGuests,
      item_category5: item.RoomType,
      item_list_id: 'STEP-1',
      item_list_name: 'Product list',
      affiliation: locale(),
      currency: 'DKK',
      price: item.Price,
      quantity: 1,
    });
    if (extraPackage2Checked) {
      item.packages.map((pck) => {
        numAdults += pck.PackageCode === extraPackage2Details.adult.PackageCode ? pck.Count! : 0;
        numChildren += pck.PackageCode === extraPackage2Details.child.PackageCode ? pck.Count! : 0;
      });
    }
    totalGuests += item.numberOfGuests;
  });
  //dataLayer object for extraPackage2Details
  if (extraPackage2Checked) {
    dataLayerObjects.push({
      item_id: extraPackage2Details.adult.PackageCode,
      item_name:
        dataLayerItemNames[extraPackage2Details.adult.PackageCode as keyof typeof dataLayerItemNames] ??
        t(`booking_extra_package_2_label`),
      item_variant: extraPackage2Details.adult.PackageCode,
      item_brand: sitecoreContext?.site?.name
        ? dataLayerHostelNames[sitecoreContext.site.name as keyof typeof dataLayerHostelNames]
        : '',
      item_category: shortISODateFormat(cartFetchingDates.checkIn!) ?? '',
      item_category2: numAdults,
      item_category3: numberOfNights,
      item_category4: numAdults,
      item_category5: extraPackage2Details.adult.PackageCode,
      item_list_id: 'STEP-1',
      item_list_name: 'Product list',
      affiliation: locale(),
      currency: 'DKK',
      price: extraPackage2Details.adult.PackagePrice * numAdults * numberOfNights,
      quantity: numAdults,
    });
    if (numChildren > 0) {
      dataLayerObjects.push({
        item_id: extraPackage2Details.child.PackageCode,
        item_name:
          dataLayerItemNames[extraPackage2Details.adult.PackageCode as keyof typeof dataLayerItemNames] ??
          t(`booking_extra_package_2_label`),
        item_variant: extraPackage2Details.child.PackageCode,
        item_brand: sitecoreContext?.site?.name
          ? dataLayerHostelNames[sitecoreContext.site.name as keyof typeof dataLayerHostelNames]
          : '',
        item_category: shortISODateFormat(cartFetchingDates.checkIn!) ?? '',
        item_category2: numChildren,
        item_category3: numberOfNights,
        item_category4: numChildren,
        item_category5: extraPackage2Details.child.PackageCode,
        item_list_id: 'STEP-1',
        item_list_name: 'Product list',
        affiliation: locale(),
        currency: 'DKK',
        price: extraPackage2Details.child.PackagePrice * numChildren * numberOfNights,
        quantity: numChildren,
      });
    }
  }
  //dataLayer for extraPackages1
  //for each item in package1DetailsList where Active = true, add to dataLayerObjects
  package1DetailsList.forEach((item) => {
    if (item.Active) {
      dataLayerObjects.push({
        item_id: item.PackageCode,
        item_name:
          dataLayerItemNames[item.PackageCode as keyof typeof dataLayerItemNames] ??
          t(`booking_extra_package_1_${item.PackageCode}_label`),
        item_variant: item.PackageCode,
        item_brand: sitecoreContext?.site?.name
          ? dataLayerHostelNames[sitecoreContext.site.name as keyof typeof dataLayerHostelNames]
          : '',
        item_category: shortISODateFormat(cartFetchingDates.checkIn!) ?? '',
        item_category2: totalGuests,
        item_category3: numberOfNights,
        item_category4: totalGuests,
        item_category5: item.PackageCode,
        item_list_id: 'STEP-1',
        item_list_name: 'Product list',
        affiliation: locale(),
        currency: 'DKK',
        price: item.PackagePrice * totalGuests,
        quantity: numberOfNights,
      });
    }
  });

  return dataLayerObjects;
}

export function useGenerateViewItemList(): Array<dataLayerObject> {
  const { menuFetchingDates } = useBookingMenuDatesStore((state) => state);
  const { DormRooms } = useDormStore((state) => state);
  const { t, locale } = useI18n();
  const { sitecoreContext } = useSitecoreContext();

  const dataLayerObjects: Array<dataLayerObject> = [];
  const numberOfNights = differenceInDays(new Date(menuFetchingDates.checkOut!), new Date(menuFetchingDates.checkIn!));
  //one dataLayer object for each item in DormRooms
  if (DormRooms) {
    Object.values(DormRooms['Dorms']['B4']).forEach((item) => {
      dataLayerObjects.push({
        item_id: item[0].UniqueQualifier,
        item_name: t(`booking_${item[0].UniqueQualifier}_headline`),
        item_variant: item[0].RateCode,
        item_brand: sitecoreContext?.site?.name
          ? dataLayerHostelNames[sitecoreContext.site.name as keyof typeof dataLayerHostelNames]
          : '',
        item_category: shortISODateFormat(menuFetchingDates.checkIn!) ?? '',
        item_category2: 0,
        item_category3: numberOfNights,
        item_category4: 0,
        item_category5: item[0].RoomType,
        item_list_id: 'STEP-1',
        item_list_name: 'Product list',
        affiliation: locale(),
        currency: 'DKK',
        price: item[0].Price,
        quantity: 1,
      });
    });

    Object.values(DormRooms['Dorms']['B6']).forEach((item) => {
      dataLayerObjects.push({
        item_id: item[0].UniqueQualifier,
        item_name: t(`booking_${item[0].UniqueQualifier}_headline`),
        item_variant: item[0].RateCode,
        item_brand: sitecoreContext?.site?.name
          ? dataLayerHostelNames[sitecoreContext.site.name as keyof typeof dataLayerHostelNames]
          : '',
        item_category: shortISODateFormat(menuFetchingDates.checkIn!) ?? '',
        item_category2: 0,
        item_category3: numberOfNights,
        item_category4: 0,
        item_category5: item[0].RoomType,
        item_list_id: 'STEP-1',
        item_list_name: 'Product list',
        affiliation: locale(),
        currency: 'DKK',
        price: item[0].Price,
        quantity: 1,
      });
    });

    Object.values(DormRooms['Rooms']).forEach((item) => {
      dataLayerObjects.push({
        item_id: item[0].UniqueQualifier,
        item_name: t(`booking_${item[0].UniqueQualifier}_headline`),
        item_variant: item[0].RateCode,
        item_brand: sitecoreContext?.site?.name
          ? dataLayerHostelNames[sitecoreContext.site.name as keyof typeof dataLayerHostelNames]
          : '',
        item_category: shortISODateFormat(menuFetchingDates.checkIn!) ?? '',
        item_category2: item[0].NumberOfPersons,
        item_category3: numberOfNights,
        item_category4: item[0].NumberOfPersons,
        item_category5: item[0].RoomType,
        item_list_id: 'STEP-1',
        item_list_name: 'Product list',
        affiliation: locale(),
        currency: 'DKK',
        price: item[0].Price,
        quantity: 1,
      });
    });

    if (DormRooms['Offers']) {
      Object.values(DormRooms['Offers']).forEach((item) => {
        dataLayerObjects.push({
          item_id: item[0].UniqueQualifier,
          item_name: t(`booking_${item[0].UniqueQualifier}_headline`),
          item_variant: item[0].RateCode,
          item_brand: sitecoreContext?.site?.name
            ? dataLayerHostelNames[sitecoreContext.site.name as keyof typeof dataLayerHostelNames]
            : '',
          item_category: shortISODateFormat(menuFetchingDates.checkIn!) ?? '',
          item_category2: item[0].NumberOfPersons,
          item_category3: numberOfNights,
          item_category4: item[0].NumberOfPersons,
          item_category5: item[0].RoomType,
          item_list_id: 'STEP-1',
          item_list_name: 'Product list',
          affiliation: locale(),
          currency: 'DKK',
          price: item[0].Price,
          quantity: 1,
        });
      });
    }
  }

  return dataLayerObjects;
}

export function useGenerateViewItemListInverse(): Array<dataLayerObject> {
  const { menuFetchingDates, flex } = useBookingMenuDatesStore((state) => state);
  const { DormsData } = useDormStore((state) => state);
  //here we return what the dormRooms will be after flex change
  //if it is currently flex, we return restricted and vice versa
  const DormRooms = flex ? DormsData?.Restricted : DormsData?.Flex;

  const { t, locale } = useI18n();
  const { sitecoreContext } = useSitecoreContext();

  const dataLayerObjects: Array<dataLayerObject> = [];
  const numberOfNights = differenceInDays(new Date(menuFetchingDates.checkOut!), new Date(menuFetchingDates.checkIn!));
  //one dataLayer object for each item in DormRooms
  if (DormRooms) {
    Object.values(DormRooms['Dorms']['B4']).forEach((item) => {
      dataLayerObjects.push({
        item_id: item[0].UniqueQualifier,
        item_name: t(`booking_${item[0].UniqueQualifier}_headline`),
        item_variant: item[0].RateCode,
        item_brand: sitecoreContext?.site?.name
          ? dataLayerHostelNames[sitecoreContext.site.name as keyof typeof dataLayerHostelNames]
          : '',
        item_category: shortISODateFormat(menuFetchingDates.checkIn!) ?? '',
        item_category2: 0,
        item_category3: numberOfNights,
        item_category4: 0,
        item_category5: item[0].RoomType,
        item_list_id: 'STEP-1',
        item_list_name: 'Product list',
        affiliation: locale(),
        currency: 'DKK',
        price: item[0].Price,
        quantity: 1,
      });
    });

    Object.values(DormRooms['Dorms']['B6']).forEach((item) => {
      dataLayerObjects.push({
        item_id: item[0].UniqueQualifier,
        item_name: t(`booking_${item[0].UniqueQualifier}_headline`),
        item_variant: item[0].RateCode,
        item_brand: sitecoreContext?.site?.name
          ? dataLayerHostelNames[sitecoreContext.site.name as keyof typeof dataLayerHostelNames]
          : '',
        item_category: shortISODateFormat(menuFetchingDates.checkIn!) ?? '',
        item_category2: 0,
        item_category3: numberOfNights,
        item_category4: 0,
        item_category5: item[0].RoomType,
        item_list_id: 'STEP-1',
        item_list_name: 'Product list',
        affiliation: locale(),
        currency: 'DKK',
        price: item[0].Price,
        quantity: 1,
      });
    });

    Object.values(DormRooms['Rooms']).forEach((item) => {
      dataLayerObjects.push({
        item_id: item[0].UniqueQualifier,
        item_name: t(`booking_${item[0].UniqueQualifier}_headline`),
        item_variant: item[0].RateCode,
        item_brand: sitecoreContext?.site?.name
          ? dataLayerHostelNames[sitecoreContext.site.name as keyof typeof dataLayerHostelNames]
          : '',
        item_category: shortISODateFormat(menuFetchingDates.checkIn!) ?? '',
        item_category2: item[0].NumberOfPersons,
        item_category3: numberOfNights,
        item_category4: item[0].NumberOfPersons,
        item_category5: item[0].RoomType,
        item_list_id: 'STEP-1',
        item_list_name: 'Product list',
        affiliation: locale(),
        currency: 'DKK',
        price: item[0].Price,
        quantity: 1,
      });
    });

    if (DormRooms['Offers']) {
      Object.values(DormRooms['Offers']).forEach((item) => {
        dataLayerObjects.push({
          item_id: item[0].UniqueQualifier,
          item_name: t(`booking_${item[0].UniqueQualifier}_headline`),
          item_variant: item[0].RateCode,
          item_brand: sitecoreContext?.site?.name
            ? dataLayerHostelNames[sitecoreContext.site.name as keyof typeof dataLayerHostelNames]
            : '',
          item_category: shortISODateFormat(menuFetchingDates.checkIn!) ?? '',
          item_category2: item[0].NumberOfPersons,
          item_category3: numberOfNights,
          item_category4: item[0].NumberOfPersons,
          item_category5: item[0].RoomType,
          item_list_id: 'STEP-1',
          item_list_name: 'Product list',
          affiliation: locale(),
          currency: 'DKK',
          price: item[0].Price,
          quantity: 1,
        });
      });
    }
  }

  return dataLayerObjects;
}

type dataLayerItemTypes = 'cartItem' | 'extraPackage' | 'dormBox';

export function useGenerateDataLayerObject(
  itemType: dataLayerItemTypes,
  itemCode = '',
  itemPrice = 0,
  numberOfGuests = 0,
  roomType = ''
): dataLayerObject | undefined {
  const { cartItems, cartFetchingDates } = useCartStore((state) => state);
  const { t, locale } = useI18n();
  const { sitecoreContext } = useSitecoreContext();
  const { menuFetchingDates } = useBookingMenuDatesStore((state) => state);

  let numGuests = 0;
  cartItems.forEach((cartItem) => {
    numGuests += cartItem.numberOfGuests;
  });

  let dataLayerObject: dataLayerObject;

  if (itemType === 'extraPackage') {
    dataLayerObject = {
      item_id: itemCode,
      item_name: t(`booking_extra_package_1_${itemCode}_label`),
      item_variant: itemCode,
      item_brand: sitecoreContext?.site?.name
        ? dataLayerHostelNames[sitecoreContext.site.name as keyof typeof dataLayerHostelNames]
        : '',
      item_category: shortISODateFormat(cartFetchingDates.checkIn!) ?? '',
      item_category2: numGuests,
      item_category3: differenceInDays(new Date(cartFetchingDates.checkOut!), new Date(cartFetchingDates.checkIn!)),
      item_category4: numGuests,
      item_category5: itemCode,
      item_list_id: 'STEP-1',
      item_list_name: 'Product list',
      affiliation: locale(),
      currency: 'DKK',
      price: itemPrice * numGuests,
      quantity: 1,
    };

    return dataLayerObject;
  } else if (itemType === 'cartItem') {
    dataLayerObject = {
      item_id: itemCode,
      item_name: dataLayerItemNames[itemCode as keyof typeof dataLayerItemNames] ?? t(`booking_${itemCode}_headline`),
      item_variant: itemCode,
      item_brand: sitecoreContext?.site?.name
        ? dataLayerHostelNames[sitecoreContext.site.name as keyof typeof dataLayerHostelNames]
        : '',
      item_category: shortISODateFormat(cartFetchingDates.checkIn!) ?? '',
      item_category2: numberOfGuests,
      item_category3: differenceInDays(new Date(cartFetchingDates.checkOut!), new Date(cartFetchingDates.checkIn!)),
      item_category4: numberOfGuests,
      item_category5: roomType,
      item_list_id: 'STEP-1',
      item_list_name: 'Product list',
      affiliation: locale(),
      currency: 'DKK',
      price: itemCode.includes('B-') ? itemPrice * numGuests : itemPrice,
      quantity: 1,
    };

    return dataLayerObject;
  } else if (itemType === 'dormBox') {
    if (itemCode === '4BED' || itemCode === '6BED') {
      dataLayerObject = {
        item_id: itemCode,
        item_name: dataLayerItemNames[itemCode as keyof typeof dataLayerItemNames] ?? t(`booking_${itemCode}_headline`),
        item_variant: itemCode,
        item_brand: sitecoreContext?.site?.name
          ? dataLayerHostelNames[sitecoreContext.site.name as keyof typeof dataLayerHostelNames]
          : '',
        item_category: shortISODateFormat(menuFetchingDates.checkIn!) ?? '',
        item_category2: numberOfGuests,
        item_category3: differenceInDays(new Date(menuFetchingDates.checkOut!), new Date(menuFetchingDates.checkIn!)),
        item_category4: itemCode === '4BED' ? 4 : itemCode === '6BED' ? 6 : numberOfGuests,
        item_category5: roomType,
        item_list_id: 'STEP-1',
        item_list_name: 'Product list',
        affiliation: locale(),
        currency: 'DKK',
        price: itemPrice,
        quantity: 1,
      };

      return dataLayerObject;
    }
  }

  return;
}

//separate function for ExtraPackage2 due to complexity
export function useGenerateDataLayerObjectExtra2(
  itemPriceAdult = 0,
  itemPriceChild = 0,
  addOrRemove: 'add' | 'remove' = 'add',
  numGuests = 0,
  removingRoomOrPackage = 'room'
): Array<dataLayerObject> | undefined {
  const { cartItems, cartFetchingDates } = useCartStore((state) => state);
  const { t, locale } = useI18n();
  const { sitecoreContext } = useSitecoreContext();
  const { menuFetchingDates } = useBookingMenuDatesStore((state) => state);

  const removeDates = differenceInDays(new Date(cartFetchingDates.checkOut!), new Date(cartFetchingDates.checkIn!));
  const addDates = differenceInDays(new Date(menuFetchingDates.checkOut!), new Date(menuFetchingDates.checkIn!));

  let numAdults = 0;
  let numKids = 0;

  //when removing cartItem and adding room, we know the number of guests from param, otherwise we loop through cartItems
  if (numGuests !== 0) {
    cartItems.forEach((cartItem) => {
      cartItem.packages.map((pck) => {
        numAdults += pck.PackageCode === 'BUAD' ? pck.Count! : 0;
        numKids += pck.PackageCode === 'BUCH' ? pck.Count! : 0;
      });
    });
  } else {
    cartItems.forEach((cartItem) => {
      numGuests += cartItem.numberOfGuests;
      cartItem.packages.map((pck) => {
        numAdults += pck.PackageCode === 'BUAD' ? pck.Count! : 0;
        numKids += pck.PackageCode === 'BUCH' ? pck.Count! : 0;
      });
    });
  }

  const dataLayerArray: Array<dataLayerObject> = [];

  const dataLayerObjectAdult: dataLayerObject = {
    item_id: 'BUAD',
    item_name: t(`booking_extra_package_2_label`),
    item_variant: 'BUAD',
    item_brand: sitecoreContext?.site?.name
      ? dataLayerHostelNames[sitecoreContext.site.name as keyof typeof dataLayerHostelNames]
      : '',
    item_category: shortISODateFormat(cartFetchingDates.checkIn!) ?? '',
    item_category2: removingRoomOrPackage === 'room' ? numGuests : numAdults,
    item_category3: addOrRemove === 'add' ? addDates : removeDates,
    item_category4: removingRoomOrPackage === 'room' ? numGuests : numAdults,
    item_category5: 'BUAD',
    item_list_id: 'STEP-1',
    item_list_name: 'Product list',
    affiliation: locale(),
    currency: 'DKK',
    price: addOrRemove === 'add' ? itemPriceAdult * numGuests : itemPriceAdult * numAdults,
    quantity: 1,
  };

  dataLayerArray.push(dataLayerObjectAdult);

  let dataLayerObjectChild: dataLayerObject;
  if (numKids > 0) {
    dataLayerObjectChild = {
      item_id: 'BUCH',
      item_name: t(`booking_extra_package_2_label`),
      item_variant: 'BUCH',
      item_brand: sitecoreContext?.site?.name
        ? dataLayerHostelNames[sitecoreContext.site.name as keyof typeof dataLayerHostelNames]
        : '',
      item_category: shortISODateFormat(cartFetchingDates.checkIn!) ?? '',
      item_category2: numKids,
      //numKids is always 0 when adding
      item_category3: removeDates,
      item_category4: numKids,
      item_category5: 'BUCH',
      item_list_id: 'STEP-1',
      item_list_name: 'Product list',
      affiliation: locale(),
      currency: 'DKK',
      price: itemPriceChild * numKids,
      quantity: 1,
    };

    dataLayerArray.push(dataLayerObjectChild);
  }

  return dataLayerArray;
}

//when extraPackage1 is already active, we need to loop through all of em and add them to the dataLayer
//when extraPackage1 is not active, dataLayer runs when checkbox is clicked
export function useGenerateDataLayerWhenExtraPackage1AlreadyActive(numGuests: number): Array<dataLayerObject> {
  const dataLayerArray: Array<dataLayerObject> = [];

  const { t, locale } = useI18n();
  const { sitecoreContext } = useSitecoreContext();
  const { package1DetailsList } = useCartStore((state) => state);
  const { menuFetchingDates } = useBookingMenuDatesStore((state) => state);

  package1DetailsList.forEach((item) => {
    if (item.Active) {
      dataLayerArray.push({
        item_id: item.PackageCode,
        item_name:
          dataLayerItemNames[item.PackageCode as keyof typeof dataLayerItemNames] ??
          t(`booking_extra_package_1_${item.PackageCode}_label`),
        item_variant: item.PackageCode,
        item_brand: sitecoreContext?.site?.name
          ? dataLayerHostelNames[sitecoreContext.site.name as keyof typeof dataLayerHostelNames]
          : '',
        item_category: shortISODateFormat(menuFetchingDates.checkIn!) ?? '',
        item_category2: numGuests,
        item_category3: differenceInDays(new Date(menuFetchingDates.checkOut!), new Date(menuFetchingDates.checkIn!)),
        item_category4: numGuests,
        item_category5: item.PackageCode,
        item_list_id: 'STEP-1',
        item_list_name: 'Product list',
        affiliation: locale(),
        currency: 'DKK',
        price: item.PackagePrice * numGuests,
        quantity: 1,
      });
    }
  });

  return dataLayerArray;
}
