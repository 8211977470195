import CurrencySymbol from 'components/Currency/CurrencyFormatter/CurrencySymbol';
import CurrencyValue from 'components/Currency/CurrencyFormatter/CurrencyValue';
import { differenceInDays } from 'date-fns';
import { useGenerateDataLayerObject } from 'lib/customHooks/hooks';
import { fireEcomDataLayerEvent } from 'lib/helpers';
import { useI18n } from 'next-localization';
import React, { ReactElement, useEffect, useState } from 'react';
import { X } from 'tabler-icons-react';
import { useCartStore } from '../../../../store';

import classes from '../../CartItem/CartItem.module.scss';

export interface IProps {
  packageCode: string;
  packagePrice: number;
}

const ExtraPackage1CartItem: React.FC<IProps> = ({ packageCode, packagePrice }): ReactElement => {
  const { t } = useI18n();

  const { cartItems, cartFetchingDates, clearExtraPackage, setExtraPackage1DetailsActive } = useCartStore(
    (state) => state
  );
  const dataLayerObj = useGenerateDataLayerObject('extraPackage', packageCode, packagePrice);

  const [totalNumGuests, setTotalNumGuests] = useState(0);

  const clearPackages = () => {
    clearExtraPackage(packageCode);
    setExtraPackage1DetailsActive(packageCode, false);
    dataLayerObj && fireEcomDataLayerEvent('remove_from_cart', [dataLayerObj]);
  };

  //Possible Refactor
  //we can loop through packages as well to get the number of guests
  useEffect(() => {
    let numGuests = 0;
    cartItems.forEach((cartItem) => {
      numGuests += cartItem.numberOfGuests;
    });
    setTotalNumGuests(numGuests);
  }, [cartItems]);

  const numberOfNights = differenceInDays(new Date(cartFetchingDates.checkOut!), new Date(cartFetchingDates.checkIn!));

  return packageCode && packagePrice ? (
    <div className={classes.cartItem}>
      <div className={classes.cartSubtitle}>
        {/* reach for each extraPackage1 that is active */}
        <p>{t(`booking_extra_package_1_${packageCode}_label`)}</p>
        <div className={classes.removeIcon} onClick={clearPackages}>
          <X size={19} />
        </div>
      </div>
      <div className={classes.cartPrice}>
        <p>
          {totalNumGuests} {t('booking_persons_label')}
        </p>
        <h3>
          <CurrencySymbol />
          <CurrencyValue value={packagePrice * totalNumGuests * numberOfNights} />
        </h3>
      </div>
    </div>
  ) : (
    <></>
  );
};
export default ExtraPackage1CartItem;
