import { Icon } from '@chakra-ui/react';
import { AmexIcon } from './AmexIcon';
import { DinersIcon } from './DinersIcon';
import { DiscoverIcon } from './DiscoverIcon';
import { KlarnaIcon } from './KlarnaIcon';
import { MaestroIcon } from './MaestroIcon';
import { MastercardIcon } from './MastercardIcon';
import { PaypalIcon } from './PaypalIcon';
import { VisaIcon } from './VisaIcon';

const PaymentIcons = new Map<string, typeof Icon>([
  ['amex', AmexIcon],
  ['diners', DinersIcon],
  ['discover', DiscoverIcon],
  ['klarna', KlarnaIcon],
  ['maestro', MaestroIcon],
  ['mastercard', MastercardIcon],
  ['paypal', PaypalIcon],
  ['visa', VisaIcon],
]);

export default PaymentIcons;

export * from './AmexIcon';
export * from './DinersIcon';
export * from './DiscoverIcon';
export * from './KlarnaIcon';
export * from './MaestroIcon';
export * from './MastercardIcon';
export * from './PaypalIcon';
export * from './VisaIcon';
