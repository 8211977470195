// HR-109
import { Box, useMultiStyleConfig } from '@chakra-ui/react';
import { NextImage, Text, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { CardType } from '../../../Component-models/data-items/SliderOneCard';
import Link from 'next/link';

import { ComponentProps } from 'lib/component-props';
import VideoComponent from 'components/Video/VideoComponent';

import imageSizeData from 'public/image-sizes.json';
import { GetMetaData, generateBreakpointValues } from 'lib/helpers';

type slider1card = ComponentProps & CardType;

const Slider1Item = (props: slider1card): JSX.Element => {
  const { title, image, link, icon, fill, videoURL } = props.fields;
  const { iconContainerBackground, iconColor } = props;
  const styles = useMultiStyleConfig('Slider1Item', {});

  if (!link.value.href) {
    link.value.href = '#';
  }

  const _image = GetMetaData(image);
  const slider1BP = generateBreakpointValues(imageSizeData.slider1);

  return (
    <Box __css={styles.container}>
      <Link target={link.value.target} href={link.value.href}>
        <Box className="Slider1Container" w="100%" h="100%">
          {icon?.value?.src ? (
            <Box
              __css={{
                ...styles.iconContainer,
                backgroundColor: iconContainerBackground,
              }}
            >
              {fill.value ? (
                <Box
                  // CSS mask used instead of image to permit color filling the svg icon
                  __css={{
                    ...styles.icon,
                    backgroundColor: iconColor,
                    WebkitMask: `url(${icon.value.src}) no-repeat center / contain`,
                    mask: `url(${icon.value.src}) no-repeat center / contain`,
                  }}
                />
              ) : (
                <Box __css={{ position: 'relative', height: '2rem', width: '2rem' }}>
                  <NextImage field={icon} objectFit="cover" objectPosition="center" unoptimized={true} />
                </Box>
              )}
            </Box>
          ) : null}
          <Box __css={styles.title}>
            <Text field={title} />
          </Box>
          <Box __css={styles.imageContainer} className="Slider1ImageContainer">
            <VideoComponent
              videoPoster={_image?.Src ?? ''}
              videoSrc={videoURL?.value ? videoURL.value : ''}
              isHero={false}
              customSizesAndRenderingIds={slider1BP}
            />
          </Box>
          <Box __css={styles.bottomOverlay} />
        </Box>
      </Link>
    </Box>
  );
};

export default withDatasourceCheck()(Slider1Item);
