import React, { ReactElement } from 'react';
import { PricingStructure } from '../../../../store/booking-store-modules';
import PrivateBox from './PrivateBox';

interface IProps {
  PrivateData: PricingStructure['Rooms'];
}

const PrivateBoxes: React.FC<IProps> = ({ PrivateData }): ReactElement => {
  return (
    <>
      {PrivateData
        ? Object.keys(PrivateData).map((PrivateRoom, index) => {
            return PrivateData[PrivateRoom][0].Availability <= 0 &&
              PrivateData[PrivateRoom][0].RoomType.includes('BED') ? null : (
              <PrivateBox key={PrivateRoom + index} PrivateBoxData={PrivateData[PrivateRoom][0]} />
            );
          })
        : null}
    </>
  );
};
export default PrivateBoxes;
