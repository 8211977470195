import { Icon, IconProps } from '@chakra-ui/react';
import { ReactElement } from 'react';

export const RooftopIcon = (props: IconProps): ReactElement => {
  return (
    <Icon {...props} viewBox="0 0 35 35">
      <path
        d="M34 32.0467V19.7618H1V32.0467"
        fill="transparent"
        stroke="#212121"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.67871 12.026L5.34754 15.4504V19.5436"
        fill="transparent"
        stroke="#212121"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.34766 16.1859H8.69918V19.5367"
        fill="transparent"
        stroke="#212121"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.0585 12.0259L18.3896 15.4503V19.5435"
        fill="transparent"
        stroke="#212121"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3867 16.1896H15.0352V19.5404"
        fill="transparent"
        stroke="#212121"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.02637 12.6947L11.7017 12.6947L14.377 12.6947"
        fill="transparent"
        stroke="#212121"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M11.7803 19.544V12.8223"
        fill="transparent"
        stroke="#212121"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M24.8834 16.172C24.5546 16.3535 24.1768 16.459 23.7744 16.459C22.5255 16.459 21.5098 15.4555 21.5098 14.214C21.5098 13.2989 22.0667 12.5064 22.8617 12.158C22.0422 11.5029 21.5785 10.4552 21.7134 9.34134C21.9121 7.73918 23.2959 6.55903 24.8907 6.57866C24.8686 6.47071 24.8613 6.3603 24.8613 6.24498C24.8613 5.00595 25.8746 4 27.1259 4C28.3747 4 29.3905 5.00595 29.3905 6.24498C29.3905 6.37747 29.3807 6.50996 29.3537 6.64C29.6751 6.57621 30.0162 6.56149 30.3597 6.6032C32.0992 6.81175 33.3333 8.38201 33.1223 10.1044C32.9874 11.1717 32.3323 12.0476 31.4417 12.5039C31.9299 12.9161 32.2391 13.5271 32.2391 14.214C32.2391 15.458 31.2257 16.459 29.9744 16.459C29.7291 16.459 29.4936 16.4198 29.2727 16.3486"
        fill="transparent"
        stroke="#212121"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.8936 19.7609V14.7851"
        fill="transparent"
        stroke="#212121"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 23.5325H34"
        fill="transparent"
        stroke="#212121"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 21.1429V16.4591"
        fill="transparent"
        stroke="#212121"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.8037 32.0908V20.1224"
        fill="transparent"
        stroke="#212121"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.5498 32.0908V20.1225"
        fill="transparent"
        stroke="#212121"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34 21.1428V16.459"
        fill="transparent"
        stroke="#212121"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};
