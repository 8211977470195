import {
  Box,
  Modal as ChakraModal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import CButton from './buttons/CButton';
import { useCartStore } from '../store';
import { useI18n } from 'next-localization';
interface Props {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  message: string;
  buttonText?: string;
  errorType?: string;
  hideCloseButton?: boolean;
}

const Modal = (props: Props): JSX.Element => {
  const { t } = useI18n();
  const {
    isOpen,
    onClose,
    title,
    message,
    buttonText = t('booking_alert_close_text'),
    errorType,
    hideCloseButton = false,
  } = props;
  const { setCartItemIds, setCartItems } = useCartStore((state) => state);

  const styles = useMultiStyleConfig('Modal', {});
  const onClearCart = () => {
    setCartItemIds([]);
    setCartItems([]);
    onClose();
  };

  return (
    <ChakraModal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent sx={styles.container}>
        <ModalCloseButton __css={styles.closeButton} />
        <Box __css={styles.title}>
          <Text>{title}</Text>
        </Box>
        <Box __css={styles.divider} />
        <Box __css={styles.message}>
          <Text whiteSpace="pre-wrap">{message}</Text>
        </Box>
        {hideCloseButton ? (
          <></>
        ) : (
          <CButton
            onClick={onClose}
            text={buttonText}
            backgroundColor={styles.button.backgroundColor as string}
            color={styles.button.color as string}
            mt="1rem"
          />
        )}
        {errorType === 'companyRates' && (
          <CButton
            onClick={onClearCart}
            text={t('button_company_rates_clear')}
            sx={{ marginTop: '10px' }}
            backgroundColor={styles.button.backgroundColor as string}
            color={styles.button.color as string}
          />
        )}
      </ModalContent>
    </ChakraModal>
  );
};

export default Modal;
