import { Box, useBreakpointValue, useMultiStyleConfig } from '@chakra-ui/react';
import { GetImageUrlFromString, generateBreakpointValues, Breakpoints } from 'lib/helpers';
import React, { ReactElement } from 'react';

import imageSizeData from 'public/image-sizes.json';

interface IProps {
  videoSrc: string;
  videoPoster: string | null;
  className?: string;
  isHero?: boolean;
}

const VideoComponent: React.FC<IProps & { customSizesAndRenderingIds?: { [key: string]: Breakpoints } | null }> = ({
  videoSrc,
  videoPoster,
  className,
  isHero,
  customSizesAndRenderingIds = null,
}): ReactElement => {
  const styles = useMultiStyleConfig('VideoComponent', {});

  // imageSizeData name must be tolower. MUST match with SITECORE TEMPLATE.NAME
  const videoComponentBrekapoints = isHero
    ? generateBreakpointValues(imageSizeData.videocomponentwithhero)
    : generateBreakpointValues(imageSizeData.videocomponentwithouthero);

  const breakpointImageParams = useBreakpointValue(
    customSizesAndRenderingIds === null ? videoComponentBrekapoints : customSizesAndRenderingIds
  );
  return (
    <Box __css={styles.container}>
      <video
        src={videoSrc}
        controls={false}
        autoPlay
        className={className}
        loop
        muted
        playsInline
        poster={videoPoster && breakpointImageParams ? GetImageUrlFromString(videoPoster, breakpointImageParams) : ''}
      />
    </Box>
  );
};

export default VideoComponent;
