import CurrencySymbol from 'components/Currency/CurrencyFormatter/CurrencySymbol';
import CurrencyValue from 'components/Currency/CurrencyFormatter/CurrencyValue';
import { differenceInDays } from 'date-fns';
import { useGenerateDataLayerObjectExtra2 } from 'lib/customHooks/hooks';
import { fireEcomDataLayerEvent } from 'lib/helpers';
import { useI18n } from 'next-localization';
import React, { ReactElement, useEffect, useState } from 'react';
import { X } from 'tabler-icons-react';
import { useCartStore } from '../../../../store';

import classes from '../../CartItem/CartItem.module.scss';

const ExtraPackage2CartItem: React.FC = (): ReactElement => {
  const { t } = useI18n();

  const {
    cartFetchingDates,
    extraPackage2Details,
    extraPackage2Checked,
    setExtraPackage2Checked,
    clearExtraPackage,
    cartItems,
  } = useCartStore((state) => state);

  const [totalNumAdults, setTotalNumAdults] = useState(0);
  const [totalNumKids, setTotalNumKids] = useState(0);

  const numberOfNights = differenceInDays(new Date(cartFetchingDates.checkOut!), new Date(cartFetchingDates.checkIn!));

  //guests come from cartItems loop
  const dataLayerArrRemove = useGenerateDataLayerObjectExtra2(
    extraPackage2Details['adult'].PackagePrice,
    extraPackage2Details['child'].PackagePrice,
    'remove',
    0,
    'package'
  );

  const clearPackages = () => {
    setExtraPackage2Checked(false);
    clearExtraPackage(extraPackage2Details['adult'].PackageCode);
    clearExtraPackage(extraPackage2Details['child'].PackageCode);
    dataLayerArrRemove && fireEcomDataLayerEvent('remove_from_cart', dataLayerArrRemove);
  };

  useEffect(() => {
    let numAdults = 0;
    let numKids = 0;
    cartItems.forEach((cartItem) => {
      cartItem.packages.map((pck) => {
        numAdults += pck.PackageCode === extraPackage2Details.adult.PackageCode ? pck.Count! : 0;
        numKids += pck.PackageCode === extraPackage2Details.child.PackageCode ? pck.Count! : 0;
      });
    });
    setTotalNumAdults(numAdults);
    setTotalNumKids(numKids);
  }, [cartItems]);

  return extraPackage2Checked && extraPackage2Details?.adult && extraPackage2Details?.child ? (
    <div className={classes.cartItem}>
      <>
        <div className={classes.cartSubtitle}>
          <p>{t('booking_extra_package_2_label')}</p>
          <div className={classes.removeIcon} onClick={clearPackages}>
            <X size={19} />
          </div>
        </div>
        <div className={classes.cartPrice}>
          <p>
            {totalNumAdults} {t('booking_adults_label')} + {totalNumKids} {t('booking_children_label')}
          </p>
          <h3>
            <CurrencySymbol />
            <CurrencyValue
              value={
                (extraPackage2Details.adult.PackagePrice * totalNumAdults +
                  extraPackage2Details.child.PackagePrice * totalNumKids) *
                numberOfNights
              }
            />
          </h3>
        </div>
      </>
    </div>
  ) : (
    <></>
  );
};
export default ExtraPackage2CartItem;
