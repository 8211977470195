import {
  Box,
  Modal as ChakraModal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import CButton from '../../../buttons/CButton';
import { useI18n } from 'next-localization';
import { CartItem, Reservations } from 'src/store/booking-store-modules';
import { useBookingMenuDatesStore, useCartStore, useDormStore } from 'src/store';
import { dataLayerObject, dataLayerHostelNames, fireEcomDataLayerEvent, dataLayerItemNames } from 'lib/helpers';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { shortISODateFormat } from 'lib/booking-box-helpers';
import { differenceInDays } from 'date-fns';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  message: string;
  reservations: Reservations | undefined;
  numberOfRooms: number;
  buttonText?: string;
  pizzaDataLayer?: dataLayerObject[];
  extraPackage1DataLayer?: dataLayerObject[];
}

const PrivateChildModal = (props: Props): JSX.Element => {
  const { sitecoreContext } = useSitecoreContext();
  const { menuFetchingDates, flex, setIsFlexDisabled, setIsRestrictedDisabled } = useBookingMenuDatesStore(
    (state) => state
  );
  const { DormsData } = useDormStore((state) => state);

  const { addCartItem, identifier, extraPackage2Checked } = useCartStore((state) => state);

  const { t, locale } = useI18n();
  const {
    isOpen,
    onClose,
    title,
    message,
    reservations,
    buttonText = t('booking_alert_close_text'),
    numberOfRooms,
  } = props;

  const styles = useMultiStyleConfig('Modal', {});

  const onAccept = () => {
    if (reservations && DormsData) {
      for (let index = 0; index < numberOfRooms; index++) {
        const reservation: CartItem = {
          ...reservations,
          identifier: identifier + index,
        };
        const dataLayerObj: dataLayerObject = {
          item_id: reservation.RoomType,
          item_name:
            dataLayerItemNames[reservation.UniqueQualifier as keyof typeof dataLayerItemNames] ??
            t(`booking_${reservation.UniqueQualifier}_headline`),
          item_variant: reservation.RateCode,
          item_brand: sitecoreContext?.site?.name
            ? dataLayerHostelNames[sitecoreContext.site.name as keyof typeof dataLayerHostelNames]
            : '',
          item_category: shortISODateFormat(menuFetchingDates.checkIn!) ?? '',
          item_category2: reservation.numberOfGuests,
          item_category3: differenceInDays(new Date(menuFetchingDates.checkOut!), new Date(menuFetchingDates.checkIn!)),
          item_category4: reservation.numberOfGuests,
          item_category5: reservation.RoomType,
          item_list_id: 'STEP-1',
          item_list_name: 'Product list',
          affiliation: locale(),
          currency: 'DKK',
          price: reservation.Price,
          quantity: 1,
        };

        const isFlex = flex ? 'Restricted' : 'Flex';
        const correspondingRoom = DormsData[isFlex][reservation.category as 'Rooms'][reservation.UniqueQualifier];

        if (!correspondingRoom || correspondingRoom[0].Availability < reservations.Availability - index) {
          flex ? setIsRestrictedDisabled(true) : setIsFlexDisabled(true);
        }

        addCartItem(reservation);

        fireEcomDataLayerEvent('add_to_cart', [dataLayerObj]);
        props.pizzaDataLayer && extraPackage2Checked && fireEcomDataLayerEvent('add_to_cart', props.pizzaDataLayer);
        props.extraPackage1DataLayer &&
          props.extraPackage1DataLayer.length > 0 &&
          fireEcomDataLayerEvent('add_to_cart', props.extraPackage1DataLayer);
      }
    }
    onClose();
  };

  return (
    <ChakraModal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent sx={styles.container}>
        <ModalCloseButton __css={styles.closeButton} />
        <Box __css={styles.title}>
          <Text>{title}</Text>
        </Box>
        <Box __css={styles.divider} />
        <Box __css={styles.message}>
          <Text whiteSpace="pre-wrap">{message}</Text>
        </Box>
        <CButton
          onClick={onAccept}
          text={buttonText}
          backgroundColor={styles.button.backgroundColor as string}
          color={styles.button.color as string}
          mt="1rem"
        />
      </ModalContent>
    </ChakraModal>
  );
};

export default PrivateChildModal;
