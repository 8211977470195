import axios from 'axios';
import { CancelReservationData, CartItem, Dorm, PricingStructure } from 'src/store/booking-store-modules';

export interface CompanyLoginResponse {
  City: string;
  CountryIso2: string;
  Email: string;
  GuestFirstname: string;
  GuestName: string;
  NationalityIso2: string;
  ProfileCategory: string;
  ProfileId: string;
  ProfileType: string;
  Street1: string;
  Telephone: string;
  Title: string;
  ZipCode: string;
  ErrorText: string;
  Status: number;
}

export interface ContactInfo {
  agentReference: string;
  address: string;
  city: string;
  country: string;
  email: string;
  firstName: string;
  language: string;
  lastName: string;
  zipCode: string;
  telephone: string;
}

type reqObject = {
  contactInfo: ContactInfo;
  cartItems: CartItem[];
  arrivalDate: Date | string | null;
  departureDate: Date | string | null;
  profileId: number;
  hostelCode: string;
};

type fetchingDates = {
  checkIn: Date | null;
  checkOut: Date | null;
};

export const updateCartItemPrices = (
  cartItems: CartItem[],
  DormRooms: PricingStructure,
  menuFetchingDates: fetchingDates,
  cartFetchingDates: fetchingDates
): CartItem[] | undefined => {
  if (
    menuFetchingDates.checkIn === cartFetchingDates.checkIn &&
    menuFetchingDates.checkOut === cartFetchingDates.checkOut
  ) {
    const newCartItems = cartItems.map((cartItem) => {
      return {
        ...cartItem,
        RateCode:
          cartItem.UniqueQualifier === 'B-4' ||
          cartItem.UniqueQualifier === 'B-4F' ||
          cartItem.UniqueQualifier === '4BED'
            ? DormRooms['Dorms']['B4'][cartItem.UniqueQualifier][0].RateCode
            : cartItem.UniqueQualifier === 'B-6' ||
              cartItem.UniqueQualifier === 'B-6F' ||
              cartItem.UniqueQualifier === '6BED'
            ? DormRooms['Dorms']['B6'][cartItem.UniqueQualifier][0].RateCode
            : cartItem.RoomType.includes('DBL')
            ? DormRooms['Rooms'][cartItem.UniqueQualifier][0].RateCode
            : DormRooms['Offers'][cartItem.UniqueQualifier]
            ? DormRooms['Offers'][cartItem.UniqueQualifier][0].RateCode
            : 'Rate Code fejl',
        Price:
          cartItem.UniqueQualifier === 'B-4' ||
          cartItem.UniqueQualifier === 'B-4F' ||
          cartItem.UniqueQualifier === '4BED'
            ? DormRooms['Dorms']['B4'][cartItem.UniqueQualifier][0].Price
            : cartItem.UniqueQualifier === 'B-6' ||
              cartItem.UniqueQualifier === 'B-6F' ||
              cartItem.UniqueQualifier === '6BED'
            ? DormRooms['Dorms']['B6'][cartItem.UniqueQualifier][0].Price
            : cartItem.RoomType.includes('DBL')
            ? DormRooms['Rooms'][cartItem.UniqueQualifier][0].Price
            : DormRooms['Offers'][cartItem.UniqueQualifier]
            ? DormRooms['Offers'][cartItem.UniqueQualifier][0].Price
            : 0,
      };
    });
    return newCartItems;
  }
  return undefined;
};

export const fetchAvailability = async (
  fetchCheckIn: string,
  fetchCheckOut: string,
  hostelCode: string,
  language: string,
  profileId?: number
): Promise<Dorm> => {
  if (fetchCheckIn == fetchCheckOut) {
    throw new Error();
  }

  const response = await axios.post<Dorm>(
    // `${config.sitecoreApiHost}/api/ahhg/suite8/avail`,
    `/api/ahhg/suite8/avail`,
    {
      ArrivalDate: fetchCheckIn,
      DepartureDate: fetchCheckOut,
      language: language,
      hostelCode: hostelCode,
      profileId: profileId ?? 0,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  //build fix
  // console.log(hostelCode, language, profileId);
  // const response = await axios.get<Dorm>('/mockAvail.json');
  return response.data;
};

export type ChangeReservationResponse = {
  code: number;
  message: string;
  status: string;
  data: CancelReservationData;
};
export const fetchChangeReservation = async (
  bookingNumber: number,
  Lastname: string,
  hostelCode: string | undefined,
  language: string
): Promise<ChangeReservationResponse> => {
  const response = await axios.post<ChangeReservationResponse>(
    // `${config.sitecoreApiHost}/api/ahhg/suite8/GetReservation`,
    `/api/ahhg/suite8/GetReservation`,
    {
      bookingNumber,
      Lastname,
      language: language,
      hostelCode: hostelCode,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
  // console.log(bookingNumber, Lastname, hostelCode, language)
  // const response = await axios.get<ChangeReservation>('/mockChange.json');
  return response.data;
};

export const getCartItem = (cartItems: Array<CartItem>, roomType: string): CartItem | undefined => {
  return cartItems.find((cartItem) => cartItem.RoomType === roomType);
};

export type statusObject = {
  data: {
    Status: string;
    Guid: string;
    ErrorMessage: undefined | string;
    Url: null | string;
    Data?: string;
  };
};

enum Statuses {
  PendingBooking = 1,
  PendingPayment = 2,
  PendingConfirm = 4,
  Complete = 8,
  Failed = 16,
  Cancelled = 32,
  Working = 64,
}

const poll = async (
  fn: () => Promise<statusObject>,
  validate: (status: statusObject) => boolean,
  interval: number,
  maxAttempts: number
) => {
  let attempts = 0;

  const executePoll = async (resolve: (value?: statusObject) => void, reject: (reason?: unknown) => void) => {
    const result = await fn();
    attempts++;

    if (validate(result)) {
      return resolve(result);
    } else if (maxAttempts && attempts === maxAttempts) {
      return reject(new Error('Exceeded max attempts'));
    } else {
      setTimeout(executePoll, interval, resolve, reject);
    }
  };

  return new Promise(executePoll);
};

export const submitPayment = async (reqObject: reqObject) => {
  if (reqObject.arrivalDate && reqObject.departureDate) {
    const suite8Response = await axios.post(`/api/ahhg/suite8/book`, reqObject, {
      headers: {
        // 'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      },
    });
    const guid = { guid: suite8Response.data.Guid };
    window.sessionStorage.setItem('transcationid', suite8Response.data.Guid);

    const caller = async function () {
      return await axios.post(`/api/ahhg/owsresv/getbookstatus`, guid);
    };
    const validate = function (status: statusObject) {
      if (
        [Statuses.PendingPayment, Statuses.PendingConfirm, Statuses.Complete, Statuses.Failed].indexOf(
          Statuses[status.data.Status as keyof typeof Statuses]
        ) > -1
      ) {
        return true;
      }
      return false;
    };

    await poll(caller, validate, 1000, 100)
      .then((status) => {
        if (
          [Statuses.PendingPayment, Statuses.PendingConfirm, Statuses.Complete].indexOf(
            Statuses[status!.data.Status as keyof typeof Statuses]
          ) > -1
        ) {
          if (status!.data.Url) {
            window.location.href = status!.data.Url;
          }
        }
        if ([Statuses.Failed].indexOf(Statuses[status!.data.Status as keyof typeof Statuses]) > -1) {
          throw new Error();
        }
      })
      .catch(() => {
        throw new Error();
      });
  }
};

export const confirmPayment = async (transactionId: string, responseCode: string): Promise<statusObject> => {
  // await axios.post('/api/ahhg/OwsDibs/Log', {
  //   Error: 'confirmBooking called',
  //   Place: 'confirmBooking',
  //   HasError: false,
  // });
  let finalStatus: statusObject | undefined;
  try {
    const netaxeptReturnUrl = `/api/ahhg/netaxept/return?transactionId=${transactionId}&responseCode=${responseCode}`;

    const suite8Response: statusObject = await axios.get(netaxeptReturnUrl);
    if (suite8Response.data.Status === 'Failed') {
      throw new Error(suite8Response.data.ErrorMessage);
    }
    const status = suite8Response;
    if (status.data.Status !== 'PendingConfirm') {
      return status;
    }

    const guid = { Guid: window.sessionStorage.getItem('transcationid') };

    const caller = async function () {
      return await axios.post(`/api/ahhg/owsresv/getbookstatus`, guid);
    };
    const validate = function (status: statusObject) {
      if ([Statuses.Complete, Statuses.Failed].indexOf(Statuses[status.data.Status as keyof typeof Statuses]) > -1) {
        return true;
      }
      return false;
    };

    await poll(caller, validate, 1000, 150)
      .then((status) => {
        if ([Statuses.Complete].indexOf(Statuses[status!.data.Status as keyof typeof Statuses]) > -1) {
          finalStatus = status;
        }
        if ([Statuses.Failed].indexOf(Statuses[status!.data.Status as keyof typeof Statuses]) > -1) {
          finalStatus = status;
        }
        finalStatus = status;
      })
      .catch((error) => {
        throw new Error(error.message);
      });
  } catch (error) {
    if (error instanceof Error) {
      // await axios.post('/api/ahhg/OwsDibs/Log', {
      //   Error: `${error.message}\n${error.stack}`,
      //   Place: 'confirmBooking',
      //   HasError: true,
      // });
      return { data: { Status: 'Failed', ErrorMessage: `${error.message} // ${error.stack}`, Url: '', Guid: '' } };
    }
  }
  return finalStatus ?? { data: { Status: 'Failed', ErrorMessage: 'Unknown error', Url: '', Guid: '' } };
};

export type CancelBookingObject = {
  guestNum: string;
  language: string;
  hostelCode: string;
  Lastname: string;
};

export const cancelBooking = async (cancelObj: CancelBookingObject) => {
  const jsonBookNum = JSON.stringify(cancelObj);
  const response = await axios.post(`/api/ahhg/suite8/cancel`, jsonBookNum, {
    headers: {
      // 'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    },
  });
  return response.data;
};

//not needed after KHS changes to cancel endpoint
type CancelBookingMail = {
  firstname: string;
  lastname: string;
  arrivalDate: string;
  departureDate: string;
  UserId: string;
  Email: string;
  locale: string;
};

export const sendCancelBookingMail = async (cancelBooking: CancelBookingMail) => {
  const jsonCancelBooking = JSON.stringify(cancelBooking);
  const response = await axios.post(
    `${process.env.PUBLIC_URL}/${cancelBooking.locale}/api/ahhg/changebooking/SendCancelbookingEmail`,
    jsonCancelBooking,
    {
      headers: {
        // 'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      },
    }
  );
  return response.data;
};

export interface CompanyRatesText {
  id: string;
  url: string;
  name: string;
  displayName: string;
  fields: Fields;
}

export interface Fields {
  ratecode: Ratecode;
  rateDescription: RateDescription;
  rateDescriptionPublic: RateDescription;
}

export interface Ratecode {
  value: string;
}

export interface RateDescription {
  value: string;
}

export interface ICreditCard {
  ImageUrl: string;
  ImageAltText: string;
  Name: string;
  GuaranteeAccepted: boolean;
}

export const fetchCreditCards = async (): Promise<ICreditCard[]> => {
  const response = await axios.post(`/api/ahhg/netaxept/cclist`, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return response.data;
};
