import { withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { Container, Stack } from '@chakra-ui/react';
import { useState } from 'react';
import { Checkbox, Select, FormControl, FormLabel, FormErrorMessage, Input, Radio, RadioGroup } from '@chakra-ui/react';
import { ComponentProps } from '../../lib/component-props';

const Forms: React.FC = (props: ComponentProps): JSX.Element => {
  const [radioValue, setRadioValue] = useState('1');

  return (
    <Container className={props.params.GridParameters}>
      <Stack py={16} gap={8}>
        <FormControl variant="material" id="firstMiddle">
          <Input placeholder=" " />
          <FormLabel>First name and middle name</FormLabel>
        </FormControl>

        <FormControl variant="material" id="firstMidFilled">
          <Input placeholder=" " value="Christian Smedegaard" />
          <FormLabel>First name and middle name</FormLabel>
        </FormControl>

        <FormControl variant="material" id="firstMiddleReq" isRequired>
          <Input placeholder=" " />
          <FormLabel>First name and middle name</FormLabel>
        </FormControl>

        <FormControl variant="material" id="firstMidInvalid" isRequired isInvalid>
          <Input placeholder=" " type="email" value="Christian Smedegaard" />
          <FormLabel>Email address</FormLabel>
          <FormErrorMessage>Please fill out your email</FormErrorMessage>
        </FormControl>

        <Select placeholder="Number of persons">
          <option value="option1">1</option>
          <option value="option2">2</option>
          <option value="option3">3</option>
          <option value="option4">4</option>
          <option value="option5">5 or more</option>
        </Select>

        <Stack spacing={5} direction="row">
          <Checkbox>Not chosen</Checkbox>
          <Checkbox defaultChecked>Make this a private room</Checkbox>
          <Checkbox isDisabled>Make it nice</Checkbox>
        </Stack>

        <RadioGroup onChange={setRadioValue} value={radioValue}>
          <Stack direction="row">
            <Radio value="1">Mixed</Radio>
            <Radio value="2">Gents</Radio>
            <Radio value="3">Ladies</Radio>
          </Stack>
        </RadioGroup>
      </Stack>
    </Container>
  );
};

export default withDatasourceCheck()(Forms);
