import { Field, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { Box, Flex, FormControl, FormLabel, Input, InputGroup, useMultiStyleConfig } from '@chakra-ui/react';
import { ComponentProps } from 'lib/component-props';
import { Search } from 'tabler-icons-react';
import { BaseSyntheticEvent, useState } from 'react';
// import fuzzysort from 'fuzzysort';
import { FaqItemType } from '../../../Component-models/FaqItems';
import FaqItem from './FaqItem';
import { IDroptreeColor } from 'src/Component-models/data-items/IDroptreeColor';

type FaqProps = ComponentProps & {
  fields: {
    searchPlaceholder: Field<string>;
    faqItems: FaqItemType[];
    textColor: IDroptreeColor;
  };
};

const HostelFaq = ({ fields, ...props }: FaqProps): JSX.Element => {
  const styles = useMultiStyleConfig('Faq', { props });
  const [openFaq, setOpenFaq] = useState<number | null>();
  const notEmptyFaqItems = fields?.faqItems?.filter((item) => item.fields.Headline.value !== '');
  const [searchFaq, setSearchFaq] = useState(notEmptyFaqItems);
  const textColor = fields.textColor ? fields.textColor.fields?.colorcode?.value : 'darkGrey.700';

  const search = (query: BaseSyntheticEvent) => {
    if (!query.target.value) {
      setSearchFaq(notEmptyFaqItems);
      return;
    }

    const lowerQuery = query.target.value.toLowerCase();
    const matchedFAQs = fields.faqItems.filter(
      (site) =>
        site.fields.Headline.value.toLowerCase().includes(lowerQuery) ||
        site.fields.Description.value.toLowerCase().includes(lowerQuery)
    );

    // const res = fuzzysort.go(query.target.value, fields.faqItems, {
    //   keys: ['fields.Headline.value', 'fields.Description.value'],
    // });
    // setSearchFaq(res.map((r) => r.obj));
    setSearchFaq(matchedFAQs);
  };

  return (
    <Box __css={{ ...styles.container }} className={`ahhg-faq ${props.params.GridParameters}`}>
      <Box __css={{ ...styles.search }} borderColor={textColor}>
        <FormControl variant="material">
          <InputGroup color={textColor}>
            <Flex alignItems={'center'} paddingLeft={'1rem'}>
              <Search />
              <Input placeholder=" " onChange={search} type="text" />
              <FormLabel>{fields.searchPlaceholder.value}</FormLabel>
            </Flex>
          </InputGroup>
        </FormControl>
      </Box>
      {/* nullcheck searchFaq */}
      {/* If value show helloworld */}
      {searchFaq && searchFaq.length > 0 ? (
        searchFaq.map((faqItem, index) => (
          <FaqItem
            key={faqItem.id}
            headline={faqItem.fields.Headline.value}
            description={faqItem.fields.Description.value}
            onClick={() => setOpenFaq(index === openFaq ? null : index)}
            isOpen={index === openFaq}
            textColor={textColor}
          />
        ))
      ) : (
        <div></div>
      )}
    </Box>
  );
};

export default withDatasourceCheck()<FaqProps>(HostelFaq);
