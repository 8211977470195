import { Box, Button, useMultiStyleConfig } from '@chakra-ui/react';
import { withDatasourceCheck, Field, Text, LinkFieldValue } from '@sitecore-jss/sitecore-jss-nextjs';
import Link from 'next/link';
import { IDroptreeColor } from '../../Component-models/data-items/IDroptreeColor';
import { IButtonSize } from '../../Component-models/IButtonSize';
import { ComponentProps } from '../../lib/component-props';

type CTAButtonProps = ComponentProps & {
  fields: {
    link: Field<LinkFieldValue>;
    state: Field<boolean>;
    color: IDroptreeColor;
    backgroundColor: IDroptreeColor;
    text: Field<string>;
    size: IButtonSize;
  };
};

const CTAButton: React.FC = (props: CTAButtonProps): JSX.Element => {
  const { state, color, backgroundColor, text, size } = props.fields;
  const styles = useMultiStyleConfig('CTAButton', {});
  const variant = state?.value ? 'solid' : 'outline';
  const rteLeftMargin = props.params.Styles !== undefined && props.params.Styles.includes('rte-left-margin');
  const topMargin = props.params.Styles !== undefined && props.params.Styles.includes('top-margin');
  const buttonSize = size?.fields.text.value ?? 'small';

  return (
    <>
      <Link
        href={props.fields.link.value.href ? props.fields.link?.value.href : '#'}
        target={props.fields.link.value.target ? props.fields.link?.value.target : ''}
        passHref
      >
        {/*<a target={props.fields.link.value.target ? props.fields.link?.value.target : ''}>*/}
        <Box style={{ display: 'inline-block', maxWidth: 'fit-content' }} className="ahhg-cta-button">
          <Button
            __css={{
              ...styles[variant],
              ...styles[buttonSize],
              backgroundColor: backgroundColor?.fields.colorcode.value ?? styles[variant].backgroundColor,
              height: 'fit-content',
              marginBlock: { base: '1rem', md: '1.5rem' },
            }}
            //if rteLeftMargin is true and buttonSize is large, then set width to 339px for base and 355px for lg
            sx={rteLeftMargin && buttonSize === 'large' ? { width: { base: '339px', lg: '355px' } } : {}}
            className={`ahhg-ctabutton ${rteLeftMargin ? 'rte-left-margin' : ''} ${topMargin ? 'top-margin' : ''}`}
            borderColor={!state.value ? color?.fields.colorcode.value : 'black'}
          >
            <Box __css={{ overflow: 'hidden', position: 'relative' }}>
              <Box
                className="text"
                __css={{ ...styles.text, color: color?.fields.colorcode.value ?? styles.text.color }}
              >
                <Text field={text} />
              </Box>
              <Box
                className="revert-text"
                __css={{ ...styles.revertText, color: color?.fields.colorcode.value ?? styles.text.color }}
              >
                <Text field={text} />
              </Box>
            </Box>
          </Button>
        </Box>
        {/*</a>*/}
      </Link>
    </>
  );
};

export default withDatasourceCheck()(CTAButton);
