import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { Box, Button, Text, Link, Slide, useMultiStyleConfig, Flex, chakra } from '@chakra-ui/react';
import { Link as SitecoreLink, RichText } from '@sitecore-jss/sitecore-jss-nextjs';
import { Search } from 'tabler-icons-react';
import { Fields, getLinkField, getLinkTitle } from './HostelMenu';
import { LinkArrowRightIcon } from 'assets/Icons/ui/LinkArrowRightIcon';
import { ArrowLeftIcon } from 'assets/Icons/ui/ArrowLeftIcon';

interface HostelSubMenuProps {
  selectedLinkId: string | null;
  setSelectedLinkId: Dispatch<SetStateAction<string | null>>;
  link: Fields | null;
  search: string;
  handleFocusSearch: () => void;
  handleClearSearch: () => void;
  backgroundcolor?: string;
  textcolor?: string;
}

const HostelSubMenu = (props: HostelSubMenuProps): JSX.Element => {
  const [selectedChildrenLinkId, setSelectedChildrenLinkId] = useState<string | null>(null);
  const [parentLinkId, setParentLinkId] = useState<string | null>(null);
  const [opacity, setOpacity] = useState<number>(1);

  const {
    link,
    selectedLinkId,
    setSelectedLinkId,
    search,
    handleClearSearch,
    handleFocusSearch,
    backgroundcolor,
    textcolor,
  } = props;

  const childrenLinks = useMemo(() => {
    if (!link?.Children) return [];
    if (!search) return link.Children;
    if (selectedChildrenLinkId) return link.Children;

    const filtered = link.Children.filter((link) => getLinkTitle(link).toLowerCase().includes(search.toLowerCase()));

    return filtered;
  }, [search, selectedChildrenLinkId, link?.Children]);

  const selectedChildrenLink = useMemo(() => {
    if (!selectedChildrenLinkId) return null;
    if (selectedChildrenLinkId && link) {
      const childrenLink = link.Children.find((link) => selectedChildrenLinkId === link.Id);

      if (childrenLink) return childrenLink;
    }

    return null;
  }, [selectedChildrenLinkId, link]);

  const handleParentMenu = () => {
    setSelectedLinkId(selectedChildrenLinkId === null ? null : parentLinkId);
    setSelectedChildrenLinkId(null);
  };

  const styles = useMultiStyleConfig('Menu', { props });
  const SearchIcon = chakra(Search);
  const linkTitle = link ? getLinkTitle(link) : '';
  return (
    <Slide
      direction="right"
      in={selectedLinkId === link?.Id}
      className="ahhg-hostel-sub-menu"
      style={{ transitionDuration: selectedLinkId === null ? '200ms' : '0ms' }}
    >
      <Box
        __css={{
          ...styles.level2Container,
          backgroundColor: backgroundcolor ?? styles.level2Container.backgroundColor,
        }}
      >
        <Box __css={styles.level2HeaderContainer} className="level2-header-container" opacity={opacity}>
          <Box __css={styles.level2SearchContainer} className="level2-search-container">
            <Box __css={styles.arrowBack}>
              <chakra.div
                sx={{
                  transform: 'rotate(180deg)',
                  paddingBottom: '0.2rem',
                  marginLeft: '-0.5rem',
                  marginRight: { base: '10px', lg: 'unset' },
                }}
                className="level2-arrowLeft"
              >
                <ArrowLeftIcon
                  cursor="pointer"
                  width={8}
                  height={4}
                  onClick={() => {
                    handleParentMenu();
                    handleClearSearch();
                    setOpacity(1);
                  }}
                />
              </chakra.div>
              <Text marginLeft="1rem">{linkTitle}</Text>
            </Box>
            <SearchIcon
              size={32}
              strokeWidth={1}
              color={textcolor ?? 'black'}
              onClick={handleFocusSearch}
              cursor="pointer"
            />
          </Box>
          <Box
            __css={{
              ...styles.headerBorder,
              borderColor: textcolor ?? styles.headerBorder.borderColor,
              backgroundColor: 'transparent',
            }}
          />
        </Box>
        <Box __css={styles.level2BodyContainer} className="level2-body-container">
          {link ? (
            <Link as={SitecoreLink} field={getLinkField(link)} href={link.Href} sx={styles.level2LinkContainer}>
              <Box __css={styles.level2MainLink} className="level2-main-link">
                <Text fontFamily="body" fontSize="20px" fontWeight="400">
                  {/* if link.NavigationAltTitle is empty use NavigationTitle */}
                  {link && link.NavigationAltTitle
                    ? link.NavigationAltTitle
                    : link.NavigationTitle
                    ? link.NavigationTitle.value
                    : ''}
                </Text>
                <Box
                  __css={{
                    ...styles.linkArrow,
                    borderColor: textcolor ?? styles.linkArrow.borderColor,
                  }}
                >
                  <LinkArrowRightIcon height={'30px'} width={'30px'} />
                </Box>
                <Box
                  __css={{
                    ...styles.level2MainLinkDivider,
                    backgroundColor: textcolor ?? 'rgba(0, 0, 0, 0.2)',
                  }}
                />
              </Box>
            </Link>
          ) : null}
          {childrenLinks.map((link: Fields & { PageIcon?: string }) => {
            return (
              <div key={link.Id}>
                <Flex dir="row" align={'center'}>
                  {link.Children?.length > 0 ? (
                    <>
                      {link.PageIcon && (
                        <Box __css={{ ...styles.svgIcon, stroke: textcolor ?? 'black' }}>
                          <RichText field={{ value: link.PageIcon }} />
                        </Box>
                      )}
                      <Button
                        key={link.Id}
                        __css={styles.level2LinkContainer}
                        onClick={() => {
                          setOpacity(0);
                          setParentLinkId(selectedLinkId);
                          setSelectedChildrenLinkId(link.Id);
                          setTimeout(() => {
                            handleClearSearch();
                          }, 200);
                        }}
                        cursor="pointer"
                      >
                        <Box __css={{ ...styles.level2TextAndIcon, justifyContent: 'space-between' }}>
                          <Text
                            fontFamily="body"
                            fontSize="20px"
                            fontWeight="400"
                            minHeight="60px"
                            display="flex"
                            alignItems="center"
                          >
                            {getLinkTitle(link)}
                          </Text>
                          <Box
                            __css={{
                              ...styles.linkArrow,
                              borderColor: textcolor ?? styles.linkArrow.borderColor,
                            }}
                          >
                            <LinkArrowRightIcon height={'30px'} width={'30px'} />
                          </Box>
                        </Box>
                      </Button>
                    </>
                  ) : (
                    <>
                      {link.PageIcon && (
                        <Box __css={{ ...styles.svgIcon, stroke: textcolor ?? 'black' }}>
                          <RichText field={{ value: link.PageIcon }} />
                        </Box>
                      )}
                      <Link
                        key={link.Id}
                        as={SitecoreLink}
                        field={getLinkField(link)}
                        href={link.Href}
                        sx={styles.level2LinkContainer}
                      >
                        <Box __css={{ ...styles.level2TextAndIcon, justifyContent: 'space-between' }}>
                          <Text
                            fontFamily="body"
                            fontSize="20px"
                            fontWeight="400"
                            minHeight="60px"
                            display="flex"
                            alignItems="center"
                          >
                            {getLinkTitle(link)}
                          </Text>
                          <Box
                            __css={{
                              ...styles.linkArrow,
                              borderColor: textcolor ?? styles.linkArrow.borderColor,
                            }}
                          >
                            <LinkArrowRightIcon height={'30px'} width={'30px'} />
                          </Box>
                        </Box>
                      </Link>
                    </>
                  )}
                </Flex>
              </div>
            );
          })}
        </Box>
        {childrenLinks.length > 0 ? (
          <HostelSubMenu
            backgroundcolor={backgroundcolor}
            textcolor={textcolor}
            selectedLinkId={selectedChildrenLinkId}
            setSelectedLinkId={setSelectedChildrenLinkId}
            search={search}
            handleFocusSearch={handleFocusSearch}
            handleClearSearch={handleClearSearch}
            link={selectedChildrenLink}
          />
        ) : null}
      </Box>
    </Slide>
  );
};

export default HostelSubMenu;
