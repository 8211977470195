import { Box, LinkProps, useMultiStyleConfig, Text } from '@chakra-ui/react';
import Link from 'next/link';

type CLinkProps = {
  backgroundColor?: string;
  color?: string;
  text: string;
  variant?: 'solid' | 'outline';
  size?: 'category' | 'large' | 'small';
  target?: string;
} & LinkProps;

// There is an identical module component
// This one is for other components to use

const CLink = (props: CLinkProps): JSX.Element => {
  const { backgroundColor, color, text, variant = 'solid', size = 'small', target } = props;
  const styles = useMultiStyleConfig('CTAButton', {});

  return (
    <Link target={target} href={props.href ? props.href : '#'}>
      <Box
        __css={{
          ...styles[variant],
          ...styles[size],
          backgroundColor: backgroundColor ?? styles[variant].backgroundColor,
          borderColor: color ?? styles.text.color,
          width: '100%',
        }}
      >
        <Box __css={{ overflow: 'hidden', position: 'relative' }}>
          <Box className="text" __css={{ ...styles.text, color: color ?? styles.text.color }}>
            <Text>{text}</Text>
          </Box>
          <Box className="revert-text" __css={{ ...styles.revertText, color: color ?? styles.text.color }}>
            <Text>{text}</Text>
          </Box>
        </Box>
      </Box>
    </Link>
  );
};

export default CLink;
