'use client';
import { orginalDigizuiteImage } from 'lib/helpers';
import { ComponentProps } from 'lib/component-props';
import { withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import classes from 'components/booking/BookingStickyMenu/BookingStickyMenu.module.scss';
import { useI18n } from 'next-localization';
import { Button } from '@chakra-ui/react';
import { Search } from 'tabler-icons-react';
import { useGeneralStore, useBookingMenuStore } from 'src/store';
import Modal from 'components/Modal';
import { useState } from 'react';
// import { CalendarIcon } from 'assets/Icons/CalendarIcon';

type MewsBookingStarterProps = ComponentProps & {
  fields: {
    IconColor: { value: string };
    BookingText: string;
    Icon: orginalDigizuiteImage;
  };
};

const MewsBookingStarter: React.FC = (props: MewsBookingStarterProps): JSX.Element => {
  // console.log('MewsBookingStarter', JSON.stringify(props, null, 2));
  const { t } = useI18n();
  const { setModalOpen } = useBookingMenuStore((state) => state);
  const { isDesktop, maintenanceActive } = useGeneralStore((state) => state);
  const [openMaintenanceModal, setOpenMaintenanceModal] = useState(false);

  const iconSrc = props?.fields?.Icon?.value?.src;
  const iconColor = props?.fields?.IconColor?.value;

  const openMenu = async () => {
    if (maintenanceActive) {
      toggleMaintenanceModal(true);
      return;
    } else {
      return;
    }
  };

  const toggleMaintenanceModal = (status: boolean) => {
    setOpenMaintenanceModal(status);
    setModalOpen(status);
  };

  return (
    <>
      {process.env.NEXT_PUBLIC_MEWS_ENABLED === 'true' && (
        <div
          id="stickyMenu"
          className={`${classes.Container} ${classes.ContainerDesktop} ${!isDesktop && classes.ContainerHidden}`}
        >
          {/* <h1>Make mews booking starter here! {JSON.stringify(props, null, 2)}</h1> */}

          <Button
            className={`${!maintenanceActive && 'distributor-open'} ${classes.DatePickerToggler}`}
            variant={'bookingStarter'}
            onClick={() => openMenu()}
            rightIcon={
              <div className={classes.SearchIconContainer}>
                <section>
                  {<p>{t('booking_sticky_menu_get_prices')}</p>}
                  <Search className={classes.SearchIcon} size={22} />
                </section>
              </div>
            }
          >
            <div className={classes.DatesContainerDesktop}>
              {/* <CalendarIcon /> */}
              {/* <img src={iconSrc} alt={props?.fields?.Icon?.value?.alt} /> */}
              <span
                id="iconContainer"
                style={
                  iconSrc && iconSrc.trim() !== ''
                    ? {
                        backgroundColor: iconColor,
                        WebkitMask: `url(${iconSrc}) no-repeat center`,
                        mask: `url(${iconSrc}) no-repeat center`,
                      }
                    : undefined
                }
              ></span>
              <div>
                {<p className={classes.bookingStickyMenuFirstText}>{t('booking_sticky_menu_first_text')}</p>}
                {<p className={classes.bookingStickyMenuSecondText}>{t('booking_sticky_menu_second_text')}</p>}
              </div>
            </div>
          </Button>
        </div>
      )}

      <Modal
        isOpen={openMaintenanceModal}
        onClose={() => toggleMaintenanceModal(false)}
        message={t('booking_maintenance_message')}
        title={t('booking_maintenance_tagline')}
        hideCloseButton={true}
      />
    </>
  );
};
export default withDatasourceCheck()<MewsBookingStarterProps>(MewsBookingStarter);
