import { Box, Spinner } from '@chakra-ui/react';
import classes from './PaymentButton.module.scss';

interface IProps {
  buttonText: string;
  cartItemsLength: number;
  paymentLoading: boolean;
}

const PaymentButton: React.FC<IProps> = ({ buttonText, cartItemsLength, paymentLoading }) => {
  return (
    // <Button> does not work with the form attribute so we cant use __css like in CTAButton
    <button form="contactForm" disabled={paymentLoading || cartItemsLength <= 0} className={classes.paymentButton}>
      <Box __css={{ overflow: 'hidden', position: 'relative' }}>
        <Box className={classes.text}>
          {buttonText} {<Spinner visibility={paymentLoading ? 'visible' : 'hidden'} />}
        </Box>
        <Box className={classes.revertText}>
          {buttonText} {<Spinner visibility={paymentLoading ? 'visible' : 'hidden'} />}
        </Box>
      </Box>
    </button>
  );
};

export default PaymentButton;
