import { Box, useMultiStyleConfig } from '@chakra-ui/react';
import { Text, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { SliderTwoCard } from '../../../Component-models/data-items/SliderTwoCard';
import { ComponentProps } from '../../../lib/component-props';
import Link from 'next/link';
import VideoComponent from 'components/Video/VideoComponent';

import imageSizeData from 'public/image-sizes.json';
import { GetMetaData, generateBreakpointValues } from 'lib/helpers';

type Slider2ItemProps = ComponentProps & SliderTwoCard;

const Slider2Item = (props: Slider2ItemProps): JSX.Element => {
  const { title, tag, image, link, color, videoURL } = props.fields;
  const styles = useMultiStyleConfig('Slider2Item', {});

  // if link.value.href is empty, the link will be disabled
  if (!link.value.href) {
    link.value.href = '#';
  }

  const _image = GetMetaData(image);
  const slider2BP = generateBreakpointValues(imageSizeData.slider2card);

  return (
    <Box __css={styles.container}>
      <Link href={link.value.href} target={link.value.target} className="ahhg-slider2-link">
        <Box>
          <Box __css={styles.imageContainer}>
            <VideoComponent
              videoPoster={_image?.Src ?? ''}
              videoSrc={videoURL?.value ? videoURL.value : ''}
              isHero={false}
              customSizesAndRenderingIds={slider2BP}
            />
          </Box>
          <Box __css={styles.textContainer}>
            <Box textStyle="p" __css={{ ...styles.tag, color: color?.fields.colorcode.value ?? styles.tag.color }}>
              <Text tag="p" field={tag} />
            </Box>
            <Box textStyle="h2" __css={{ ...styles.title, color: color?.fields.colorcode.value ?? styles.title.color }}>
              <Text tag="h3" field={title} />
            </Box>
          </Box>
        </Box>
      </Link>
    </Box>
  );
};

export default withDatasourceCheck()(Slider2Item);
