import React from 'react';
import { Field, RichText, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import { Box, useStyleConfig } from '@chakra-ui/react';
import { IGridParameters } from '../../../Component-models/base-items/IGridParameters';
import { IDroptreeColor } from '../../../Component-models/data-items/IDroptreeColor';
import { transformCurrency } from 'lib/helpers';
import { useI18n } from 'next-localization';
import { useCurrencyStore, useGeneralStore } from 'src/store';

type TextParagraphProps = ComponentProps & {
  fields: {
    /**
     * RTE text field data.
     */
    text: Field<string>;
    textColor?: IDroptreeColor;
  };
  params: IGridParameters & {
    // /sitecore/content/Hostels/nex/Presentation/Styles/RTE/Left-margin
    Styles: string;
  };
};

const TextParagraph: React.FC<TextParagraphProps> = (props: TextParagraphProps) => {
  const styles = useStyleConfig('TextParagraph', {});
  const { locale } = useI18n();
  const { currentCurrency } = useCurrencyStore((state) => state);
  const { loaded } = useGeneralStore((state) => state);

  const rteLeftMargin = props.params.Styles !== undefined && props.params.Styles.includes('rte-left-margin');
  const topMargin = props.params.Styles !== undefined && props.params.Styles.includes('top-margin');

  const textContent = props.fields.text.value;
  const updatedTextContent = transformCurrency(textContent, currentCurrency, locale());

  return loaded ? (
    <Box
      className={`ahhg-text-paragraph ${props.params.GridParameters} ${rteLeftMargin ? 'rte-left-margin' : ''} 
      ${topMargin ? 'top-margin' : ''}`}
      __css={styles}
      color={props.fields?.textColor?.fields?.colorcode?.value}
    >
      {props.fields && props.fields.text && <RichText field={{ value: updatedTextContent }} className="sc-rich-text" />}
    </Box>
  ) : (
    <></>
  );
};

export default withDatasourceCheck()<TextParagraphProps>(TextParagraph);
