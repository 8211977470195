import { Icon, IconProps } from '@chakra-ui/react';
import { ReactElement } from 'react';

export const PoolIcon = (props: IconProps): ReactElement => {
  return (
    <Icon {...props} viewBox="0 0 35 35">
      <path
        d="M1 30C2.6449 30 4.11308 29.2795 5.12585 28.141C6.13862 29.2795 7.6068 30 9.2517 30C10.8898 30 12.3648 29.2795 13.3775 28.141C14.3903 29.2795 15.8585 30 17.5034 30C19.1415 30 20.6097 29.2795 21.6225 28.141C22.6352 29.2795 24.1102 30 25.7483 30C27.3932 30 28.8614 29.2795 29.8742 28.141C30.8869 29.2795 32.3619 30 34 30"
        fill="transparent"
        stroke="#212121"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.9707 24.0157C6.9155 25.0794 8.28172 25.749 9.81787 25.749C11.3438 25.749 12.7202 25.0794 13.6616 24.0157C14.6064 25.0794 15.9727 25.749 17.5054 25.749C19.0314 25.749 20.401 25.0794 21.3458 24.0157C22.2906 25.0794 23.6636 25.749 25.1895 25.749C26.7223 25.749 28.0919 25.0794 29.0367 24.0157"
        fill="transparent"
        stroke="#212121"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.4711 21.3002V7.70941C11.4711 6.45194 10.4515 5.43237 9.19403 5.43237C7.93656 5.43237 6.91699 6.45194 6.91699 7.70941V10.017"
        fill="transparent"
        stroke="#212121"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.8314 21.3002V7.70941C23.8314 6.45194 22.8118 5.43237 21.5544 5.43237C20.2969 5.43237 19.2773 6.45194 19.2773 7.70941V9.64319"
        fill="transparent"
        stroke="#212121"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.4707 12.5729H23.8313"
        fill="transparent"
        stroke="#212121"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.4707 19.5154H23.8313"
        fill="transparent"
        stroke="#212121"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.4707 14.4593H23.8313"
        fill="transparent"
        stroke="#212121"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.4707 17.4679H23.8313"
        fill="transparent"
        stroke="#212121"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};
