import { Box, Link, useMultiStyleConfig } from '@chakra-ui/react';
import {
  withDatasourceCheck,
  LinkField,
  Link as SitecoreLink,
  Field,
  useSitecoreContext,
} from '@sitecore-jss/sitecore-jss-nextjs';
import Image from 'next/image';
import { ComponentProps } from 'lib/component-props';
import PriceTag, { PriceTagsProps } from '../Currency/PriceTag';
import VideoComponent from '../Video/VideoComponent';
// import { DamImageField } from 'lib/damTypes';

import imageSizeData from 'public/image-sizes.json';
import { GetImageUrlFromObject, GetMetaData, generateBreakpointValues, orginalDigizuiteImage } from 'lib/helpers';

type SingleImageProps = ComponentProps &
  PriceTagsProps & {
    fields: {
      image: orginalDigizuiteImage;
      sticker?: orginalDigizuiteImage;
      stickerLink?: LinkField;
      videoURL: Field<string>;
    };
  };
// HR-39 SingleImage
const SingleImage = (props: SingleImageProps): JSX.Element => {
  const { image, sticker, stickerLink, videoURL } = props.fields;
  const _image = GetMetaData(image);
  const _sticker = GetMetaData(sticker);

  const { sitecoreContext } = useSitecoreContext();
  const isEditing = sitecoreContext && sitecoreContext.pageEditing;
  const isEditNoImage = isEditing && !_image?.Src;
  const styles = useMultiStyleConfig('SingleImage', {});
  const gridParams = props.params?.GridParameters ? props.params.GridParameters : 'col-xl-12 col-md-12 col-sm-12';

  const generatedbreakpoitns = generateBreakpointValues(imageSizeData.singleimage);

  return (
    <Box
      __css={{ ...styles.container, height: isEditNoImage ? '400px' : undefined }}
      className={`ahhg-single-image ${gridParams}`}
    >
      <Box __css={{ ...styles.image }}>
        <VideoComponent
          videoPoster={_image?.Src ?? ''}
          videoSrc={videoURL?.value ? videoURL.value : ''}
          isHero={false}
          customSizesAndRenderingIds={generatedbreakpoitns}
        />
      </Box>

      {props.fields?.usePricetag && props.fields.usePricetag.value ? (
        <Box __css={styles.priceTag}>
          <PriceTag {...props} />
        </Box>
      ) : _sticker.Src ? (
        <Box __css={styles.sticker}>
          {stickerLink?.value?.href ? (
            <Link as={SitecoreLink} field={stickerLink} href={stickerLink.value.href} target={stickerLink.value.target}>
              <Image
                src={GetImageUrlFromObject(sticker)}
                fill={true}
                unoptimized={true}
                alt={_sticker.Alt}
                title={_sticker.Title}
              />
            </Link>
          ) : (
            <Image
              src={GetImageUrlFromObject(sticker)}
              fill={true}
              unoptimized={true}
              alt={_sticker.Alt}
              title={_sticker.Title}
            />
          )}
        </Box>
      ) : null}
    </Box>
  );
};

export default withDatasourceCheck()<SingleImageProps>(SingleImage);
