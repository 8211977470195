import React, { ReactElement } from 'react';
import { useI18n } from 'next-localization';
import { useCurrencyStore } from '../../../store';

interface CurrencyValueProps {
  value: number;
}

const CurrencyValue: React.FC<CurrencyValueProps> = ({ value }): ReactElement => {
  const { currentCurrency } = useCurrencyStore((state) => state);
  const { locale } = useI18n();
  const currencyValue = value / (currentCurrency.Rate / 100);
  const digits = currentCurrency.Postfix == '' ? 2 : 0;
  const formatter = new Intl.NumberFormat(locale(), { maximumFractionDigits: digits });
  const displayValue = formatter.format(currencyValue) + currentCurrency.Postfix;
  return <span>{displayValue}</span>;
};

export default CurrencyValue;
