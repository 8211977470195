import { Icon, IconProps } from '@chakra-ui/react';
import { ReactElement } from 'react';

export const ArrowLeftIcon = (props: IconProps): ReactElement => {
  return (
    <Icon {...props} viewBox="0 0 11 8" width={`${props.width ?? '6'}`} height={`${props.height ?? '3'}`}>
      <line x1="10.3125" y1="4.00039" x2="-2.10361e-08" y2="4.00039" stroke="currentColor" strokeWidth="0.7" />
      <path d="M11 4C9.10152 4 7.5625 2.433 7.5625 0.5" stroke="currentColor" strokeWidth="0.7" fill="transparent" />
      <path d="M7.5625 7.5C7.5625 5.567 9.10152 4 11 4" stroke="currentColor" strokeWidth="0.7" fill="transparent" />
    </Icon>
  );
};
