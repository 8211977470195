import React, { ReactElement } from 'react';
import { PricingStructure, PackageBox, CancelData } from '../../../../store/booking-store-modules';
import DormBoxes from '../DormBoxes/DormBoxes';
import classes from '../../ParentBox.module.scss';
import FlexibilityBox from '../FlexibilityBox/FlexibilityBox';
import PrivateBoxes from '../PrivateBox/PrivateBoxes';
import { Heading } from '@chakra-ui/react';
import ContactFormBox from '../ContactFormBox/ContactFormBox';
import ExtraBoxes from '../ExtraBox/ExtraBoxes';
import CompanyRatesBox from '../CompanyRatesBox/CompanyRatesBox';
import CancelBox from '../CancelBox/CancelBox';
import ChangeBox from '../ChangeBox/ChangeBox';

type IProps =
  | {
      roomBox: true;
      ParentData: PricingStructure[keyof PricingStructure];
      title: string;
      roomBoxType: string;
    }
  | {
      roomBox: false;
      ParentData?: never;
      title: string;
      roomBoxType?: string;
    }
  | { roomBox: false; ParentData: CancelData; title: string; roomBoxType: string }
  | { extraBox: boolean; ParentData: PackageBox[]; title: string; roomBoxType?: string }
  | {
      title: string;
      roomBoxType: string;
      roomBox?: boolean;
      extraBox?: boolean;
      ParentData?: PricingStructure[keyof PricingStructure] | PackageBox[] | CancelData;
    };

const ParentBox: React.FC<IProps> = ({ ParentData, title, roomBoxType }): ReactElement => {
  let renderBox: ReactElement;

  switch (roomBoxType) {
    case 'CompanyRates':
      renderBox = <CompanyRatesBox />;
      break;
    case 'Dorms':
      renderBox = <DormBoxes DormData={ParentData as PricingStructure['Dorms']} />;
      break;
    case 'Rooms':
    case 'Offers':
      renderBox = <PrivateBoxes PrivateData={ParentData as PricingStructure['Rooms']} />;
      break;
    case 'Flexibility':
      renderBox = <FlexibilityBox />;
      break;
    case 'Cancel':
      renderBox = <CancelBox CancelData={ParentData as CancelData} />;
      break;
    case 'Change':
      renderBox = <ChangeBox CancelData={ParentData as CancelData} />;
      break;
    case 'Extra':
      renderBox = <ExtraBoxes ExtraData={ParentData as PackageBox[]} />;
      break;
    case 'Contact':
      renderBox = <ContactFormBox />;
      break;
    default:
      renderBox = <></>;
      break;
  }

  return (
    <div className={classes.renderBoxContainer}>
      <Heading p={'16px 5px 12px'} size={'md'}>
        {title}
      </Heading>
      <div className={classes.renderBox}>{renderBox}</div>
    </div>
  );
};
export default ParentBox;
