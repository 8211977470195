import { OverlayViewF } from '@react-google-maps/api';
import {
  Box,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  PopoverCloseButton,
  Link,
  Icon,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import { NextImage, Text, Link as SitecoreLink } from '@sitecore-jss/sitecore-jss-nextjs';
import { ArrowDownCircle } from 'tabler-icons-react';
import { POIType } from './GoogleMaps';

const POILocation = ({ fields }: POIType): JSX.Element => {
  const styles = useMultiStyleConfig('Maps', {});
  const position = { lat: fields.Latitude?.value, lng: fields.Longtitude?.value };
  return (
    <OverlayViewF position={position} mapPaneName="overlayMouseTarget">
      <Popover placement="top">
        <PopoverTrigger>
          <Box cursor="pointer">
            <Box __css={styles.POIMarker} />
            <Box __css={styles.markerText}>
              <Text field={fields.PlaceName} />
            </Box>
          </Box>
        </PopoverTrigger>
        <PopoverContent shadow="xl" borderRadius="20px" margin="0 1rem" bg="white" width="17rem">
          <PopoverArrow backgroundColor="white" />
          <PopoverBody padding="0.5rem" position="relative">
            <PopoverCloseButton __css={styles.closeButton} />
            <Box __css={styles.POIImage}>
              {fields.Image.value.src && (
                <NextImage
                  field={fields.Image}
                  fill={true}
                  placeholder={'empty'}
                  unoptimized={true}
                  style={{ objectFit: 'cover', objectPosition: 'center' }}
                  imageParams={{ cw: 255, ch: 100, cx: 0.5, cy: 0.5 }}
                />
              )}
            </Box>
            <Box __css={styles.POITitle}>
              <Text field={fields.Headline} />
            </Box>
            <Box __css={styles.POIDescription}>
              <Text field={fields.Description} />
            </Box>
            {fields.Link?.value?.value?.href ? (
              <Link as={SitecoreLink} field={fields.Link.value}>
                <Box __css={styles.POILink}>
                  <Icon as={ArrowDownCircle} boxSize="1.5rem" transform="rotate(-90deg)" />
                  <Box __css={styles.POILinkDescription}>{fields.Link.value.value.title}</Box>
                </Box>
              </Link>
            ) : null}
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </OverlayViewF>
  );
};

export default POILocation;
