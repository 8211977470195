import { Box, Link, useMultiStyleConfig, useBreakpointValue } from '@chakra-ui/react';
import {
  withDatasourceCheck,
  LinkField,
  NextImage,
  Link as SitecoreLink,
  Field,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';

import VideoComponent from 'components/Video/VideoComponent';
import { IGridParameters } from 'src/Component-models/base-items/IGridParameters';
import { useGeneralStore } from 'src/store';

import imageSizeData from 'public/image-sizes.json';
import { GetImageUrlFromString, GetMetaData, generateBreakpointValues, orginalDigizuiteImage } from 'lib/helpers';

type VideoProps = ComponentProps & {
  fields: {
    fallbackImage: orginalDigizuiteImage;
    sticker?: orginalDigizuiteImage;
    stickerLink?: LinkField;
    videoURL: Field<string>;
  };
  params: IGridParameters;
};
// HR-39 SingleImage
const Video = (props: VideoProps): JSX.Element => {
  const { loaded } = useGeneralStore((state) => state);

  const { fallbackImage, sticker, stickerLink, videoURL } = props.fields;
  const gridParams = props.params?.GridParameters ? props.params.GridParameters : 'col-xl-12 col-md-12 col-sm-12';
  const styles = useMultiStyleConfig('Video', {});

  // imageSizeData name must be tolower. MUST match with SITECORE TEMPLATE.NAME
  const _videoBP = generateBreakpointValues(imageSizeData.singleimage);
  const videoBP = useBreakpointValue(_videoBP, { fallback: 'md' });
  const _fallbackImage = GetMetaData(fallbackImage);
  const _sticker = GetMetaData(sticker);

  return loaded ? (
    <Box __css={styles.container} className={`ahhg-video ${gridParams}`} height={videoBP?.ch} width={videoBP?.cw}>
      <VideoComponent
        videoPoster={_fallbackImage?.Src && videoBP ? GetImageUrlFromString(_fallbackImage.Src, videoBP) : ''}
        videoSrc={videoURL?.value || ''}
      />
      {_sticker?.Src ? (
        <Box __css={styles.sticker} className="ahhg-video-sticker">
          {stickerLink?.value?.href ? (
            <Link as={SitecoreLink} field={stickerLink} href={stickerLink.value.href} target={stickerLink.value.target}>
              <NextImage field={_sticker} fill={true} unoptimized={true} />
            </Link>
          ) : (
            <NextImage field={_sticker} fill={true} unoptimized={true} />
          )}
        </Box>
      ) : null}
    </Box>
  ) : (
    <></>
  );
};

export default withDatasourceCheck()<VideoProps>(Video);
