import { ChangeEvent, useState } from 'react';
import {
  Box,
  Checkbox,
  FormControl,
  FormLabel,
  Input,
  Radio,
  RadioGroup,
  Stack,
  Textarea,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import { Text, RichText, Field, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import DatePicker from 'react-datepicker';
import { ComponentProps } from 'lib/component-props';
import Modal from '../Modal';
import CButton from '../buttons/CButton';
import { useI18n } from 'next-localization';
import axios from 'axios';
import { addDays, isBefore, isSameDay } from 'date-fns';
import { useGeneralStore } from 'src/store';

type GroupReservationFormProps = ComponentProps & {
  fields: {
    contactInfoLabel: Field<string>;
    additionalInfoLabel: Field<string>;
    firstNameLabel: Field<string>;
    surnameLabel: Field<string>;
    companyNameLabel: Field<string>;
    phoneNumberLabel: Field<string>;
    emailLabel: Field<string>;
    emailReplyLabel: Field<string>;
    arrivalDateLabel: Field<string>;
    departureDateLabel: Field<string>;
    numberOfPersonsLabel: Field<string>;
    maxNumberOfPersons: Field<number>;
    purposeOfStayLabel: Field<string>;
    sharedRoomLabel: Field<string>;
    privateRoomLabel: Field<string>;
    under18Label: Field<string>;
    dataPolicyLabel: Field<string>;
    submitLabel: Field<string>;
    yesLabel: Field<string>;
    noLabel: Field<string>;
    hostelEmail: Field<string>;
    SuccessMessage: Field<string>;
    ErrorMessage: Field<string>;
    NumberOfPersonsError: Field<string>;
  };
};

// type TGroupReservationForm = {
//   firstName: string;
//   surname: string;
//   companyName?: string;
//   phoneNumber: string;
//   email: string;
//   arrivalDate: string;
//   departureDate: string;
//   numberOfPersons: string;
//   purposeOfStay: string;
//   sharedRoom: 'Yes' | 'No';
//   privateRoom: 'Yes' | 'No';
//   under18: 'Yes' | 'No';
//   hostelEmail: string;
// };

type GroupReservationResponse = {
  Status: string;
  Error: string;
  StatusCode: number;
};

const GroupReservationForm = (props: GroupReservationFormProps): JSX.Element => {
  const { locale } = useI18n();
  const dateLocale = locale() as 'en' | 'da';

  const { isDesktop } = useGeneralStore((state) => state);

  const [firstName, setFirstName] = useState('');
  const [surname, setSurname] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [arrivalDate, setArrivalDate] = useState<Date | null>(new Date());
  const [departureDate, setDepartureDate] = useState<Date | null>(addDays(new Date(), 1));
  const [numberOfPersons, setNumberOfPersons] = useState(0);
  const [purposeOfStay, setPurposeOfStay] = useState('');

  type RadioValue = 'Yes' | 'No' | '';
  const [sharedRoom, setSharedRoom] = useState<RadioValue>('');
  const [privateRoom, setPrivateRoom] = useState<RadioValue>('');
  const [under18, setUnder18] = useState<RadioValue>('');

  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const {
    contactInfoLabel,
    additionalInfoLabel,
    firstNameLabel,
    surnameLabel,
    companyNameLabel,
    phoneNumberLabel,
    emailLabel,
    emailReplyLabel,
    arrivalDateLabel,
    departureDateLabel,
    numberOfPersonsLabel,
    purposeOfStayLabel,
    sharedRoomLabel,
    privateRoomLabel,
    under18Label,
    dataPolicyLabel,
    submitLabel,
    yesLabel,
    noLabel,
  } = props.fields;

  const styles = useMultiStyleConfig('GroupReservationForm', {});

  //add later other fields
  const validateForm = (): boolean => {
    numberOfPersons < 20 && setErrorMessage(props.fields.NumberOfPersonsError.value);
    return formSubmitted && hasAcceptedTerms && numberOfPersons >= 20;
  };

  const onDatepickerFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target.readOnly = true;
    document.body.classList.add('overflow-hidden');
  };

  const onDatepickerBlur = () => {
    document.body.classList.remove('overflow-hidden');
  };

  const onArrivalChange = (date: Date | null) => {
    if (date) {
      setArrivalDate(date);

      if (departureDate && arrivalDate && (isBefore(departureDate, date) || isSameDay(departureDate, date))) {
        setDepartureDate(addDays(date, 1));
      }
    }
  };

  const onDepartureChange = (date: Date | null) => {
    if (date) {
      setDepartureDate(date);

      if (departureDate && arrivalDate && (isBefore(date, departureDate) || isSameDay(date, departureDate))) {
        setArrivalDate(addDays(date, -1));
      }
    }
  };

  const requiredPolicyLabel = {
    value: `${dataPolicyLabel.value} <span style="color:#E53E3E" >*</span>`,
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setFormSubmitted(true);
    if (validateForm()) {
      try {
        const response = await axios.post<GroupReservationResponse>(`/api/hostel/HostelForms`, {
          firstName,
          surname,
          companyName,
          phoneNumber,
          email,
          arrivalDate,
          departureDate,
          numberOfPersons,
          purposeOfStay,
          sharedRoom,
          privateRoom,
          under18,
          hostelEmail: props.fields.hostelEmail.value,
        });
        if (response.data.StatusCode === 200) {
          setSuccessMessage(props.fields.SuccessMessage.value);
        } else {
          setErrorMessage(props.fields.ErrorMessage.value);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit} style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
      <Box __css={styles.container}>
        <Box __css={styles.section}>
          <Box __css={styles.sectionTitle}>
            <Box __css={styles.divider} />
            <Box textStyle="h2" __css={{ paddingLeft: '1rem', fontFamily: 'body' }}>
              <Text field={contactInfoLabel} />
            </Box>
          </Box>
          <Box __css={{ flexGrow: 1 }}>
            <Box __css={styles.namesContainer}>
              <FormControl variant="material" id="firstName" isRequired>
                <Input
                  placeholder=" "
                  value={firstName}
                  maxLength={50}
                  onChange={(e) => setFirstName(e.target.value)}
                  sx={!firstName ? styles.inputNotValid : undefined}
                />
                <FormLabel>
                  <Text field={firstNameLabel} />
                </FormLabel>
              </FormControl>
              <FormControl variant="material" id="surname" sx={styles.inputMargin} isRequired>
                <Input
                  placeholder=" "
                  value={surname}
                  maxLength={50}
                  onChange={(e) => setSurname(e.target.value)}
                  sx={!surname ? styles.inputNotValid : undefined}
                />
                <FormLabel>
                  <Text field={surnameLabel} />
                </FormLabel>
              </FormControl>
            </Box>
            <FormControl variant="material" id="companyName" sx={{ marginTop: { base: '0.75rem', lg: '1.5rem' } }}>
              <Input placeholder=" " value={companyName} onChange={(e) => setCompanyName(e.target.value)} />
              <FormLabel>
                <Text field={companyNameLabel} />
              </FormLabel>
            </FormControl>
            <Box __css={styles.contactsContainer}>
              <FormControl variant="material" id="phoneNumber" isRequired>
                <Input
                  placeholder=" "
                  type="text"
                  value={phoneNumber}
                  maxLength={50}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  sx={!phoneNumber ? styles.inputNotValid : undefined}
                />
                <FormLabel>
                  <Text field={phoneNumberLabel} />
                </FormLabel>
              </FormControl>
              <FormControl variant="material" id="email" sx={styles.inputMargin} isRequired>
                <Input
                  placeholder=" "
                  type="email"
                  value={email}
                  maxLength={50}
                  onChange={(e) => setEmail(e.target.value)}
                  sx={!email ? styles.inputNotValid : undefined}
                />
                <FormLabel>
                  <Text field={emailLabel} />
                </FormLabel>
                <Text field={emailReplyLabel} />
              </FormControl>
            </Box>
            <Box __css={styles.datesContainer}>
              <Box __css={{ ...styles.namesContainer, width: { base: '100%', xl: '50%' } }}>
                <FormControl variant="material" id="arrivalDate" isRequired sx={styles.dateControl}>
                  <DatePicker
                    withPortal={!isDesktop}
                    selected={arrivalDate}
                    onChange={onArrivalChange}
                    className="datepickerGroupReservation"
                    calendarClassName="datepickerGroupReservationContainer"
                    dateFormat={'EE d MMMM'}
                    locale={dateLocale}
                    minDate={new Date()}
                    onFocus={onDatepickerFocus}
                    onBlur={onDatepickerBlur}
                  />
                  <label>
                    {arrivalDateLabel.value} <span style={{ color: '#E53E3E' }}>*</span>
                  </label>
                </FormControl>
                <FormControl
                  variant="material"
                  id="departureDate"
                  sx={{ ...styles.dateControl, ...styles.inputMargin }}
                  isRequired
                >
                  <DatePicker
                    selected={departureDate}
                    withPortal={!isDesktop}
                    calendarClassName="datepickerGroupReservationContainer"
                    onChange={onDepartureChange}
                    className="datepickerGroupReservation"
                    dateFormat={'EE d MMMM'}
                    locale={dateLocale}
                    minDate={addDays(new Date(), 1)}
                    onFocus={onDatepickerFocus}
                    onBlur={onDatepickerBlur}
                  />
                  <label>
                    {departureDateLabel.value} <span style={{ color: '#E53E3E' }}>*</span>
                  </label>
                </FormControl>
              </Box>
              <FormControl variant="material" id="numberOfPersons" sx={styles.selectInput} isRequired>
                <Input
                  placeholder=" "
                  value={numberOfPersons}
                  type="number"
                  onChange={(e) => setNumberOfPersons(parseInt(e.target.value))}
                  sx={numberOfPersons < 20 ? styles.inputNotValid : undefined}
                />
                <FormLabel>
                  <Text field={numberOfPersonsLabel} />
                </FormLabel>
              </FormControl>
              {/* SELECT USES MAX NUMBERS OF PERSONS FROM SC */}
              {/* <FormControl variant="material" id="numberOfPersons" sx={styles.selectInput} isRequired>
                {/* <Select value={numberOfPersons} onChange={(e) => setNumberOfPersons(e.target.value)}> */}
              {/* generate options from number maxNumberOfPersons*/}
              {/* {[...Array(maxNumberOfPersons.value).keys()].map((i) => (
                    <option key={i} value={i + 1}>
                      {i + 1}
                    </option>
                  ))}
                </Select> */}
              {/* <FormLabel>
                  <Text field={numberOfPersonsLabel} />
                </FormLabel>
              </FormControl> */}
            </Box>
          </Box>
        </Box>
        <Box __css={{ ...styles.section, marginTop: '3rem' }}>
          <Box __css={styles.sectionTitle}>
            <Box __css={styles.divider} />
            <Box textStyle="h2" __css={{ paddingLeft: '1rem', fontFamily: 'body' }}>
              <Text field={additionalInfoLabel} />
            </Box>
          </Box>
          <Box __css={{ flexGrow: 1 }}>
            <Box __css={styles.extraInfoContainer}>
              <FormControl
                variant="material"
                id="purposeOfStay"
                sx={{ width: { base: '100%', xl: '50%' }, textarea: { paddingTop: '1.5rem' } }}
              >
                <Textarea placeholder=" " value={purposeOfStay} onChange={(e) => setPurposeOfStay(e.target.value)} />
                <FormLabel>
                  <Text field={purposeOfStayLabel} />
                </FormLabel>
              </FormControl>
              <Box __css={{ width: { base: '100%', xl: '50%' }, marginLeft: { base: 0, xl: '1.5rem' } }}>
                <FormControl id="sharedRoom" sx={{ ...styles.radioInput, marginTop: { base: '1.5rem', xl: 0 } }}>
                  <FormLabel>
                    <Text field={sharedRoomLabel} />
                  </FormLabel>
                  <RadioGroup value={sharedRoom} onChange={(value: 'Yes' | 'No') => setSharedRoom(value)}>
                    <Stack direction="row">
                      <Radio value="Yes">{yesLabel.value}</Radio>
                      <Radio value="No">{noLabel.value}</Radio>
                    </Stack>
                  </RadioGroup>
                </FormControl>
                <FormControl id="privateRoom" sx={styles.radioInput}>
                  <FormLabel>
                    <Text field={privateRoomLabel} />
                  </FormLabel>
                  <RadioGroup value={privateRoom} onChange={(value: 'Yes' | 'No') => setPrivateRoom(value)}>
                    <Stack direction="row">
                      <Radio value="Yes">{yesLabel.value}</Radio>
                      <Radio value="No">{noLabel.value}</Radio>
                    </Stack>
                  </RadioGroup>
                </FormControl>
                <FormControl
                  id="under18"
                  sx={
                    !under18 && formSubmitted
                      ? { ...styles.radioInput, ...styles.under18Required }
                      : { ...styles.radioInput }
                  }
                >
                  <FormLabel className="under18">
                    <Text field={under18Label} />
                  </FormLabel>
                  <RadioGroup value={under18} onChange={(value: 'Yes' | 'No') => setUnder18(value)}>
                    <Stack direction="row">
                      <Radio value="Yes">{yesLabel.value}</Radio>
                      <Radio value="No">{noLabel.value}</Radio>
                    </Stack>
                  </RadioGroup>
                </FormControl>
                <FormControl
                  sx={
                    !hasAcceptedTerms && formSubmitted
                      ? { ...styles.policyContainer, ...styles.policyNotChecked }
                      : { ...styles.policyContainer }
                  }
                  onInvalid={(e: ChangeEvent<HTMLInputElement>) => {
                    e.target.setCustomValidity('Please confirm that you are familiar with our personal data policy');
                  }}
                  aria-errormessage="Please confirm that you are familiar with our personal data policy"
                >
                  <FormLabel>
                    <Box>
                      <RichText field={requiredPolicyLabel} className="sc-rich-text" />
                    </Box>
                  </FormLabel>
                  <Checkbox
                    marginLeft="1rem"
                    isChecked={hasAcceptedTerms}
                    onChange={(e) => setHasAcceptedTerms(e.target.checked)}
                  />
                </FormControl>
                <CButton
                  type="submit"
                  // onSubmit={handleSubmit}
                  _disabled={{ opacity: 0.5, cursor: 'not-allowed' }}
                  text={submitLabel.value}
                  marginTop="2rem"
                  backgroundColor={styles.button.backgroundColor as string}
                  color={styles.button.color as string}
                  style={{ borderRadius: '0px 50px 50px 50px' }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Modal isOpen={!!successMessage} onClose={() => setSuccessMessage('')} title="Success" message={successMessage} />
      <Modal isOpen={!!errorMessage} onClose={() => setErrorMessage('')} title="Error" message={errorMessage} />
    </form>
  );
};

export default withDatasourceCheck()(GroupReservationForm);
