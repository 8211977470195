import React from 'react';
import { ComponentProps } from 'lib/component-props';
import { Box, useStyleConfig } from '@chakra-ui/react';
import { Field, RichText, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { IDroptreeColor } from '../../../Component-models/data-items/IDroptreeColor';
import { useCurrencyStore, useGeneralStore } from 'src/store';
import { useI18n } from 'next-localization';
import { transformCurrency } from 'lib/helpers';

type CenterTextProps = ComponentProps & {
  fields: {
    /**
     * RTE text field data.
     */
    textRte: Field<string>;
    /**
     * Text color in hex, or from theme config.
     */
    textColor?: IDroptreeColor;
    /**
     * Text aligned center on mobile (<md).
     */
    centerMobile: Field<boolean>;
    /**
     * Center text on desktop (>=md)
     */
    centerDesktop: Field<boolean>;
  };
};

const CenterText: React.FC<CenterTextProps> = (props: CenterTextProps) => {
  const styles = useStyleConfig('CenterText', {});
  const { locale } = useI18n();
  const { currentCurrency } = useCurrencyStore((state) => state);
  const { loaded } = useGeneralStore((state) => state);

  const topMargin = props.params.Styles !== undefined && props.params.Styles.includes('top-margin');

  const textContent = props.fields.textRte.value;
  const updatedTextContent = transformCurrency(textContent, currentCurrency, locale());

  return loaded ? (
    <Box
      className={`ahhg-center-text ${props.params.GridParameters} ${topMargin ? 'top-margin' : ''}`}
      __css={styles}
      color={props.fields.textColor?.fields?.colorcode?.value}
      textAlign={{
        base: props.fields.centerMobile.value ? 'center' : 'inherit',
        md: props.fields.centerDesktop.value ? 'center' : 'inherit',
      }}
    >
      <RichText className="sc-rich-text" field={{ value: updatedTextContent }} />
      {/* <pre>{JSON.stringify(props.fields, null, 2)}</pre> */}
    </Box>
  ) : (
    <></>
  );
};

export default withDatasourceCheck()<CenterTextProps>(CenterText);
