import { Box, useMultiStyleConfig } from '@chakra-ui/react';
import { withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { IDroptreeColor } from '../../../Component-models/data-items/IDroptreeColor';

import { ComponentProps } from 'lib/component-props';
import NavigationCardItem, { NavigationCardItemProps } from './NavigationCardItem';

type NavigationCardListProps = ComponentProps & {
  fields: {
    color: IDroptreeColor;
    children: Array<NavigationCardItemProps>;
  };
  params: {
    GridParameters: string;
  };
};

const NavigationCardList = (props: NavigationCardListProps): JSX.Element => {
  let children;
  let color: IDroptreeColor;
  if (props.fields) {
    children = props.fields.children;
    color = props.fields.color;
  }
  const styles = useMultiStyleConfig('NavigationCardList', { props });

  return (
    <Box className={`ahhg-navigation-card-list ${props.params.GridParameters}`} __css={styles.container}>
      {children ? (
        children.map((card) => (
          <NavigationCardItem
            id={card.id}
            key={card.id}
            params={props.params}
            rendering={props.rendering}
            fields={{ ...card.fields, color }}
          />
        ))
      ) : (
        <div>Navigation has no children.</div>
      )}
    </Box>
  );
};

export default withDatasourceCheck()<NavigationCardListProps>(NavigationCardList);
