import { ComponentProps } from 'lib/component-props';

type HtmlContentModel = ComponentProps & {
  fields: { htmlContent: { value: string } };
};

const HtmlContainer = (props: HtmlContentModel) => {
  return <div dangerouslySetInnerHTML={{ __html: props.fields.htmlContent.value }} />;
};

export default HtmlContainer;
