import React, { useState, useEffect, memo } from 'react';
import classes from '../../ParentBox.module.scss';
import customClasses from './ContactFormBox.module.scss';
import {
  useCartStore,
  useCompanyStore,
  useContactFormStore,
  useBookingMenuStore,
  useGeneralStore,
  useCustomerStore,
} from '../../../../store';
import { useForm } from 'react-hook-form';
import { FormErrorMessage, FormLabel, FormControl, Input, Flex, Select } from '@chakra-ui/react';
import { useI18n } from 'next-localization';
import { ContactInfo, submitPayment } from 'lib/booking-helpers';
import enLocale from 'i18n-iso-countries/langs/en.json';
import daLocale from 'i18n-iso-countries/langs/da.json';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { useGenerateDataLayerFromCartItems } from '../../../../lib/customHooks/hooks';
import { fireEcomDataLayerEvent } from 'lib/helpers';
import { format } from 'date-fns';

const ContactFormBox = memo(function ContactFormBox() {
  const { t, locale } = useI18n();
  const { companyDetails, loggedIn } = useCompanyStore((state) => state);
  const dataLayerObjects = useGenerateDataLayerFromCartItems();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<ContactInfo>({
    mode: 'all',
    defaultValues: {
      agentReference: '',
      address: loggedIn ? companyDetails.Street1 : '',
      city: loggedIn ? companyDetails.City : '',
      country: loggedIn ? companyDetails.CountryIso2 : '',
      email: loggedIn ? companyDetails.Email : '',
      firstName: '',
      lastName: '',
      language: locale(),
      zipCode: loggedIn ? companyDetails.ZipCode : '',
      telephone: loggedIn ? companyDetails.Telephone : '',
    },
  });

  const { cartItems, cartFetchingDates } = useCartStore((state) => state);
  const { setRoomPickerOpen, setErrorStepOpen } = useBookingMenuStore((state) => state);
  const { agreeTerms, setAgreeError, setPaymentLoading, newsletterChecked } = useContactFormStore((state) => state);
  const { loaded, isDesktop } = useGeneralStore((state) => state);

  const [countryChosen, setCountryChosen] = useState<boolean>(false);
  const [countryArr, setCountryArr] = useState<{ label: string; value: string }[]>([]);
  const setCustomerData = useCustomerStore((state) => state.setCustomerData);

  useEffect(() => {
    loadCountryOptions();
  }, []);

  const loadCountryOptions = async () => {
    const countries = await import('i18n-iso-countries');
    countries.registerLocale(enLocale);
    countries.registerLocale(daLocale);
    const countryObj = countries.getNames(locale(), { select: 'official' });
    const countryArr = Object.entries(countryObj).map(([key, value]) => {
      return {
        label: value,
        value: key,
      };
    });
    //sort countryArr alphabetically by label
    countryArr.sort((a, b) => (a.label > b.label ? 1 : -1));
    setCountryArr(countryArr);
  };

  const { sitecoreContext } = useSitecoreContext();

  //if error, put an isError store to true
  async function onSubmit(values: ContactInfo) {
    if (!agreeTerms) {
      setAgreeError(true);
      return;
    }
    try {
      setPaymentLoading(true);
      const reqObject = {
        contactInfo: values,
        cartItems,
        arrivalDate: format(new Date(cartFetchingDates.checkIn!), 'yyyy-MM-dd'),
        departureDate: format(new Date(cartFetchingDates.checkOut!), 'yyyy-MM-dd'),
        profileId: parseInt(companyDetails.ProfileId),
        hostelCode: sitecoreContext.site!.name!,
        language: locale(),
        newsletterChecked,
        url: process.env.PUBLIC_URL,
      };

      fireEcomDataLayerEvent('begin_checkout', dataLayerObjects);
      // fireCustomDataLayerEvent('customer_data', { customer_email: values.email, customer_phone: values.telephone });
      // ONG-4259 - Set customer data in store, to fire after payment is successful
      setCustomerData(values.email, values.telephone, values.country);

      await submitPayment(reqObject);
    } catch (e) {
      setRoomPickerOpen(false);
      setErrorStepOpen(true);
    } finally {
      setPaymentLoading(false);
    }
  }

  return (
    <div className={classes.roomBoxContainer}>
      <form id="contactForm" name="contactForm" onSubmit={handleSubmit(onSubmit)}>
        {/* <button form="companyLogin">submit</button> */}
        <Flex gap={'5px'} direction={isDesktop ? 'row' : 'column'}>
          <FormControl isInvalid={!!errors.firstName} variant="floating" flexShrink={2}>
            <Input
              id="firstName"
              placeholder={t('booking_label_first_name')}
              maxLength={35}
              {...register('firstName', {
                required: t('booking_errors_required'),
                minLength: { value: 2, message: t('booking_errors_2chars_length') },
                maxLength: { value: 50, message: t('booking_errors_max_length') },
                pattern: {
                  value: /^[^\@!";#$%()*/:';<=>?~^;\[\]¨_`´\}\{\\¡¿£€§½±©|]*$/,
                  message: t('booking_errors_special_characters'),
                },
              })}
            />
            <FormLabel>{t('booking_label_first_name')}</FormLabel>
            <FormErrorMessage>{errors.firstName && errors.firstName.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!errors.lastName} variant="floating" flexShrink={3}>
            <Input
              id="lastName"
              placeholder={t('booking_label_last_name')}
              maxLength={35}
              {...register('lastName', {
                required: t('booking_errors_required'),
                // required: { value: true, message: 'REQUIRED' },
                // validate: value => value === '21' || 'error message 21',
                minLength: { value: 2, message: t('booking_errors_2chars_length') },
                maxLength: { value: 50, message: t('booking_errors_max_length') },
                pattern: {
                  value: /^[^\@!";#$%()*/:';<=>?~^;\[\]¨_`´\}\{\\¡¿£€§½±©|]*$/,
                  message: t('booking_errors_special_characters'),
                },
              })}
            />
            <FormLabel>{t('booking_label_last_name')}</FormLabel>
            <FormErrorMessage>{errors.lastName && errors.lastName.message}</FormErrorMessage>
          </FormControl>
        </Flex>
        {loaded && loggedIn && (
          <Flex gap={'5px'} direction={isDesktop ? 'row' : 'column'}>
            <FormControl variant="floating" flexShrink={2} className={customClasses.companyName}>
              <Input id="companyName" placeholder={companyDetails.GuestName} tabIndex={-1} />
              <FormLabel>{companyDetails.GuestName}</FormLabel>
            </FormControl>
            <FormControl isInvalid={!!errors.agentReference} variant="floating" flexShrink={3}>
              <Input
                id="agentReference"
                placeholder={t('booking_label_agent_reference')}
                maxLength={35}
                {...register('agentReference', {
                  pattern: {
                    value: /^[^\@!";#$%()*/:';<=>?~^;\[\]¨_`´\}\{\\¡¿£€§½±©|]*$/,
                    message: t('booking_errors_special_characters'),
                  },
                })}
              />
              <FormLabel>{t('booking_label_agent_reference')}</FormLabel>
              <FormErrorMessage>{errors.agentReference && errors.agentReference.message}</FormErrorMessage>
            </FormControl>
          </Flex>
        )}
        <FormControl isInvalid={!!errors.address} variant="floating">
          <Input
            id="address"
            defaultValue={loggedIn ? companyDetails.Street1 : ''}
            maxLength={35}
            placeholder={t('booking_label_address')}
            {...register('address', {
              required: t('booking_errors_required'),
              maxLength: { value: 50, message: t('booking_errors_max_length') },
              minLength: { value: 2, message: t('booking_errors_2chars_length') },
            })}
          />
          <FormLabel>{t('booking_label_address')}</FormLabel>
          <FormErrorMessage>{errors.address && errors.address.message}</FormErrorMessage>
        </FormControl>
        <Flex gap={'5px'} direction={isDesktop ? 'row' : 'column'}>
          <FormControl isInvalid={!!errors.zipCode} variant="floating">
            <Input
              id="zipCode"
              defaultValue={loggedIn ? companyDetails.ZipCode : ''}
              placeholder={t('booking_label_post_code')}
              maxLength={35}
              {...register('zipCode', {
                required: t('booking_errors_required'),
                maxLength: { value: 50, message: t('booking_errors_max_length') },
                pattern: {
                  value: /^[a-zA-Z0-9_.-]*$/,
                  message: 'No special characters allowed',
                },
              })}
            />
            <FormLabel>{t('booking_label_post_code')}</FormLabel>
            <FormErrorMessage>{errors.zipCode && errors.zipCode.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!errors.city} variant="floating">
            <Input
              id="city"
              defaultValue={loggedIn ? companyDetails.City : ''}
              placeholder={t('booking_label_city')}
              maxLength={35}
              {...register('city', {
                required: t('booking_errors_required'),
                maxLength: { value: 50, message: t('booking_errors_max_length') },
              })}
            />
            <FormLabel>{t('booking_label_city')}</FormLabel>
            <FormErrorMessage>{errors.city && errors.city.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!errors.country} variant="floating">
            <div className={customClasses.selectWrapper}>
              <Select
                // onChange={(e) => selectCountryHandler(e.target.value)}
                className={`${classes.selectText} ${customClasses.selectText}`}
                {...register('country', {
                  required: t('booking_errors_required'),
                  onChange: (e) => setCountryChosen(!!e.target.value),
                })}
              >
                <option value="">{''}</option>
                {!!countryArr?.length &&
                  countryArr.map(({ label, value }) => (
                    <option key={value} value={value}>
                      {label}
                    </option>
                  ))}
              </Select>
            </div>
            <FormLabel className={`${countryChosen && 'countryLabel'}`}>{t('booking_label_country')}</FormLabel>
            <FormErrorMessage>{errors.country && errors.country.message}</FormErrorMessage>
          </FormControl>
        </Flex>

        <FormControl isInvalid={!!errors.email} variant="floating">
          <Input
            id="email"
            type="email"
            defaultValue={loggedIn ? companyDetails.Email : ''}
            maxLength={35}
            placeholder={t('booking_label_email')}
            {...register('email', {
              required: t('booking_errors_required'),
              pattern: {
                value: /^[\w-+\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                message: t('booking_errors_email'),
              },
              maxLength: { value: 50, message: t('booking_errors_max_length') },
            })}
          />
          <FormLabel>{t('booking_label_email')}</FormLabel>
          <FormErrorMessage>{errors.email && errors.email.message}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!errors.telephone} variant="floating">
          <Input
            id="telephone"
            defaultValue={loggedIn ? companyDetails.Telephone : ''}
            type="text"
            inputMode="tel"
            placeholder={t('booking_label_phone')}
            maxLength={35}
            {...register('telephone', {
              required: t('booking_errors_required'),
              minLength: { value: 8, message: t('booking_errors_8chars_length') },
              maxLength: { value: 50, message: t('booking_errors_max_length') },
              // pattern: {
              //   value: /\d+/,
              //   message: 'Numbers only error message',
              // },
            })}
          />
          <FormLabel>{t('booking_label_phone')}</FormLabel>
          <FormErrorMessage>{errors.telephone && errors.telephone.message}</FormErrorMessage>
        </FormControl>
      </form>
    </div>
  );
});
export default ContactFormBox;
