'use client';
import React, { ReactElement, useEffect, useState, useRef, useMemo } from 'react';
import { Search, Briefcase } from 'tabler-icons-react';
import { Button } from '@chakra-ui/react';
import classes from './BookingStickyMenu.module.scss';
import {
  useBookingMenuStore,
  useCartStore,
  useDormStore,
  useCompanyStore,
  useMenuStore,
  useBookingMenuDatesStore,
  useSitecoreFieldsStore,
  useChangeBookingStore,
  useGeneralStore,
} from '../../../store';
import BookingStickyMenuDekstop from './BoookingStickyMenuDesktop';
import { addDays, differenceInDays, format } from 'date-fns';
import { useI18n } from 'next-localization';
import { CompanyRatesText, fetchAvailability } from 'lib/booking-helpers';
import BookingStickyMenuModal from './BookingStickyMenuModal/BookingStickyMenuModal';
import { Field, withDatasourceCheck, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import { Dorm, sitecoreFields } from 'src/store/booking-store-modules';
import { dataLayerHostelNames, dataLayerItemNames, dataLayerObject, fireEcomDataLayerEvent } from 'lib/helpers';
import { shortISODateFormat } from 'lib/booking-box-helpers';
import { useRouter } from 'next/router';
import Modal from 'components/Modal';
import { isServer } from '@sitecore-jss/sitecore-jss/utils';

type BookProps = ComponentProps & {
  fields: {
    'Newsletter show': Field<boolean>;
    'Newsletter terms': Field<string>;
    'Payment terms': Field<string>;
    'Thank you page': Field<string>;
    'Error page': Field<string>;
    'Newsletter checked': Field<boolean>;
    BlockOrder: Field<string>;
    DisableMessageDormsPrivate: Field<boolean>;
    DisableMessagePrivate: Field<boolean>;
    children: CompanyRatesText[];
  };
};

const BookingStickyMenu: React.FC = (props: BookProps): ReactElement => {
  const { t, locale } = useI18n();
  const { sitecoreContext } = useSitecoreContext();
  const router = useRouter();

  const {
    modalOpen,
    setModalOpen,
    datePickerOpen,
    setDatePickerOpen,
    roomPickerOpen,
    setRoomPickerOpen,
    errorStepOpen,
    setErrorStepOpen,
    setLoading,
  } = useBookingMenuStore((state) => state);
  const { menuFetchingDates, setMenuFetchingDates, flex } = useBookingMenuDatesStore((state) => state);
  const { cartItems } = useCartStore((state) => state);
  const { setSitecoreFields } = useSitecoreFieldsStore((state) => state);
  const { setDormsData, setDormRooms } = useDormStore((state) => state);
  const { companyDetails, firstAvailableRateCode, setFirstAvailableRateCode } = useCompanyStore((state) => state);
  const { isDesktop, maintenanceActive } = useGeneralStore((state) => state);
  const { setDatePickerChangeActive } = useChangeBookingStore((state) => state);
  const [cartVisible, setCartVisible] = useState(false);
  const [openMaintenanceModal, setOpenMaintenanceModal] = useState(false);

  const today = useMemo(() => {
    return new Date();
  }, []);

  const [doQuickSearch, setDoQuickSearch] = useState(false);

  const getCalendarDates = (): [Date | null, Date | null] => {
    if (isServer()) {
      return [today, addDays(today, 1)];
    }
    // ONG-3887 STE/NEX - Etablere link med parametre til BE
    // ONG-3887 function to handle google links with quick search
    const GoogleLinkHandler = () => {
      const regex = /(\d{2}-\d{2}-\d{4})_(\d+)_/;
      const match = RegExp(regex).exec(router.asPath);
      if (match) {
        const checkIn = match[1].split('-').reverse().join('-');
        const numberOfDays = parseInt(match[2]);
        const checkOut = format(addDays(new Date(checkIn), numberOfDays), 'yyyy-MM-dd');
        return { checkIn, checkOut };
      }

      return null;
    };
    //  ONG-3887 function to handle internal links with quick search
    const InternalLinkHandler = () => {
      const params = new URLSearchParams(router.asPath.toLowerCase().split('?')[1]);
      const checkIn = params.get('checkin');
      const checkOut = params.get('checkout');
      return { checkIn, checkOut };
    };
    // is google og interal route.query
    const isClient = typeof window !== 'undefined';

    let urlPath = '';
    if (isClient) {
      urlPath = router.asPath;
    }

    const isGoogle = urlPath.includes('localuniversal');
    const isInternal = urlPath.toLowerCase().includes('checkin') && urlPath.includes('checkout');
    let dates: { checkIn: string | null; checkOut: string | null } = { checkIn: null, checkOut: null };
    if (isGoogle) {
      dates = GoogleLinkHandler() ?? dates;
      setDoQuickSearch(true);
    } else if (isInternal) {
      dates = InternalLinkHandler() || dates;
      setDoQuickSearch(true);
    } else {
      return [today, addDays(today, 1)];
    }

    if (dates.checkIn && dates.checkOut) {
      const checkInDate = new Date(dates.checkIn);
      const checkOutDate = new Date(dates.checkOut);
      const updatedMenuFetchingDates = {
        checkIn: checkInDate,
        checkOut: checkOutDate,
      };
      setMenuFetchingDates(updatedMenuFetchingDates);

      // remove query params from url
      router.replace(router.pathname, undefined, { shallow: true });

      return [checkInDate, checkOutDate];
    }

    return [today, addDays(today, 1)];
  };

  const [calendarDates, setCalendarDates] = useState<[Date | null, Date | null]>(() => getCalendarDates());

  const cartButton = useRef<HTMLAnchorElement>(null);

  //TODO: move to helper
  const generateViewItemList = (
    availability: Dorm,
    menuFetchingDates: { checkIn: Date | null; checkOut: Date | null }
  ) => {
    const dataLayerObjects: Array<dataLayerObject> = [];
    const numberOfNights = differenceInDays(
      new Date(menuFetchingDates.checkOut!),
      new Date(menuFetchingDates.checkIn!)
    );
    if (availability) {
      const DormRooms = flex ? availability.Flex : availability.Restricted;
      Object.values(DormRooms['Dorms']['B4']).forEach((item) => {
        dataLayerObjects.push({
          item_id: item[0].UniqueQualifier,
          item_name:
            dataLayerItemNames[item[0].UniqueQualifier as keyof typeof dataLayerItemNames] ??
            t(`booking_${item[0].UniqueQualifier}_headline`),
          item_variant: item[0].RateCode,
          item_brand: sitecoreContext?.site?.name
            ? dataLayerHostelNames[sitecoreContext.site.name as keyof typeof dataLayerHostelNames]
            : '',
          item_category: shortISODateFormat(menuFetchingDates.checkIn!) ?? '',
          item_category2: 0,
          item_category3: numberOfNights,
          item_category4: 0,
          item_category5: item[0].RoomType,
          item_list_id: 'STEP-1',
          item_list_name: 'Product list',
          affiliation: locale(),
          currency: 'DKK',
          price: item[0].Price,
          quantity: 1,
        });
      });

      Object.values(DormRooms['Dorms']['B6']).forEach((item) => {
        dataLayerObjects.push({
          item_id: item[0].UniqueQualifier,
          item_name:
            dataLayerItemNames[item[0].UniqueQualifier as keyof typeof dataLayerItemNames] ??
            t(`booking_${item[0].UniqueQualifier}_headline`),
          item_variant: item[0].RateCode,
          item_brand: sitecoreContext?.site?.name
            ? dataLayerHostelNames[sitecoreContext.site.name as keyof typeof dataLayerHostelNames]
            : '',
          item_category: shortISODateFormat(menuFetchingDates.checkIn!) ?? '',
          item_category2: 0,
          item_category3: numberOfNights,
          item_category4: 0,
          item_category5: item[0].RoomType,
          item_list_id: 'STEP-1',
          item_list_name: 'Product list',
          affiliation: locale(),
          currency: 'DKK',
          price: item[0].Price,
          quantity: 1,
        });
      });

      Object.values(DormRooms['Rooms']).forEach((item) => {
        dataLayerObjects.push({
          item_id: item[0].UniqueQualifier,
          item_name:
            dataLayerItemNames[item[0].UniqueQualifier as keyof typeof dataLayerItemNames] ??
            t(`booking_${item[0].UniqueQualifier}_headline`),
          item_variant: item[0].RateCode,
          item_brand: sitecoreContext?.site?.name
            ? dataLayerHostelNames[sitecoreContext.site.name as keyof typeof dataLayerHostelNames]
            : '',
          item_category: shortISODateFormat(menuFetchingDates.checkIn!) ?? '',
          item_category2: item[0].NumberOfPersons,
          item_category3: numberOfNights,
          item_category4: item[0].NumberOfPersons,
          item_category5: item[0].RoomType,
          item_list_id: 'STEP-1',
          item_list_name: 'Product list',
          affiliation: locale(),
          currency: 'DKK',
          price: item[0].Price,
          quantity: 1,
        });
      });

      if (DormRooms['Offers']) {
        Object.values(DormRooms['Offers']).forEach((item) => {
          dataLayerObjects.push({
            item_id: item[0].UniqueQualifier,
            item_name:
              dataLayerItemNames[item[0].UniqueQualifier as keyof typeof dataLayerItemNames] ??
              t(`booking_${item[0].UniqueQualifier}_headline`),
            item_variant: item[0].RateCode,
            item_brand: sitecoreContext?.site?.name
              ? dataLayerHostelNames[sitecoreContext.site.name as keyof typeof dataLayerHostelNames]
              : '',
            item_category: shortISODateFormat(menuFetchingDates.checkIn!) ?? '',
            item_category2: item[0].NumberOfPersons,
            item_category3: numberOfNights,
            item_category4: item[0].NumberOfPersons,
            item_category5: item[0].RoomType,
            item_list_id: 'STEP-1',
            item_list_name: 'Product list',
            affiliation: locale(),
            currency: 'DKK',
            price: item[0].Price,
            quantity: 1,
          });
        });
      }
    }

    return dataLayerObjects;
  };

  const getUpdatedCalendarDates = (calendarDates: [Date | null, Date | null]): [Date | null, Date | null] => {
    const [arrival] = calendarDates;
    let departure = calendarDates[1];
    if (!departure && arrival) {
      //calendarDates[1] ( DepartureDate) is missing, set it to addDay(1) of arrival
      departure = addDays(arrival, 1);
    }
    return [arrival, departure];
  };

  const fetchAvailableRooms = async () => {
    // https://youtrack.arp-hansen.dk/issue/ONG-4264 in case of no checkout date, set it to the next day. update state and use new variable
    const updatedCalendarDates = getUpdatedCalendarDates(calendarDates);
    setCalendarDates(updatedCalendarDates);

    if (updatedCalendarDates[0] && updatedCalendarDates[1]) {
      const arrDate = format(new Date(updatedCalendarDates[0]), 'yyyy-MM-dd');
      const depDate = format(new Date(updatedCalendarDates[1]), 'yyyy-MM-dd');
      setLoading(true);
      setRoomPickerOpen(true);
      try {
        const availability = await fetchAvailability(
          arrDate,
          depDate,
          sitecoreContext.site!.name!,
          locale(),
          parseInt(companyDetails.ProfileId)
        );
        setDormsData(availability);
        flex ? setDormRooms(availability.Flex) : setDormRooms(availability.Restricted);
        const generatedViewItemList = generateViewItemList(availability, menuFetchingDates);
        fireEcomDataLayerEvent('view_item_list', generatedViewItemList);
        setFirstAvailableRateCode(availability.Corporate.FirstAvailableRatecode);
        setMenuFetchingDates({ checkIn: updatedCalendarDates[0], checkOut: updatedCalendarDates[1] });
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setRoomPickerOpen(false);
        setErrorStepOpen(true);
      }
    }
  };

  useEffect(() => {
    setDatePickerChangeActive(false);
  }, [setDatePickerChangeActive]);

  useEffect(() => {
    cartItems.length > 0 ? setCartVisible(true) : setCartVisible(false);

    //find object where fields.ratecode === 'rate_1'
    const companyRates = props.fields.children.find((item) => item.fields.ratecode.value === firstAvailableRateCode);

    const newSiteCoreFields: typeof sitecoreFields = {
      newsletterShow: props.fields['Newsletter show']?.value,
      newsletterTerms: props.fields['Newsletter terms']?.value,
      paymentTerms: props.fields['Payment terms']?.value,
      thankYouPage: props.fields['Thank you page']?.value,
      newsletterChecked: props.fields['Newsletter checked']?.value,
      errorPage: props.fields['Error page']?.value,
      companyRates: companyRates ? companyRates.fields.rateDescription.value : '',
      companyRatesPublic: companyRates ? companyRates.fields.rateDescriptionPublic.value : '',
      DisableMessageDormsPrivate: props.fields['DisableMessageDormsPrivate']?.value ?? true,
      DisableMessagePrivate: props.fields['DisableMessagePrivate']?.value ?? true,
      BlockOrder: props.fields['BlockOrder']?.value ?? '',
    };
    setSitecoreFields(newSiteCoreFields);
  }, [props.fields, firstAvailableRateCode, setSitecoreFields, cartItems]);

  const openMenu = async () => {
    if (maintenanceActive) {
      toggleMaintenanceModal(true);
      return;
    }
    //if DatePicker is open, open RoomPicker
    if (datePickerOpen && !isDesktop) {
      if (menuFetchingDates.checkOut) {
        setDatePickerOpen(false);
        await fetchAvailableRooms();
        return;
      }
    }
    //else open DatePicker
    setModalOpen(true);
    setRoomPickerOpen(false);
    setDatePickerOpen(true);
  };

  const mewsHandleOpen = async () => {
    if (maintenanceActive) {
      toggleMaintenanceModal(true);
      return;
    } else {
      return;
    }
  };

  const openCartHandler = async () => {
    if (cartItems.length && !roomPickerOpen && !datePickerOpen && !errorStepOpen) {
      setModalOpen(true);
      await fetchAvailableRooms();

      setTimeout(() => {
        cartButton.current?.click();
      }, 50);
    }
  };

  const toggleMaintenanceModal = (status: boolean) => {
    setOpenMaintenanceModal(status);
    setModalOpen(status);
  };

  const openRoomPickerHandler = async () => {
    if (maintenanceActive) {
      toggleMaintenanceModal(true);
      return;
    }

    if (menuFetchingDates.checkIn && menuFetchingDates.checkOut) {
      datePickerOpen && setDatePickerOpen(false);
      setModalOpen(true);
      await fetchAvailableRooms();
    }
  };

  const { setMenuIsOpen } = useMenuStore();

  const hasRun = useRef(false);
  useEffect(() => {
    (async () => {
      if (doQuickSearch && !hasRun.current) {
        hasRun.current = true;
        setDoQuickSearch(false);
        await openRoomPickerHandler();
      }
    })();
  }, [doQuickSearch]);

  const isEditing = sitecoreContext && sitecoreContext.pageEditing;
  if (isEditing) {
    return <></>;
  }
  return (
    <>
      <BookingStickyMenuModal
        openMenu={openMenu}
        today={today}
        checkInDate={calendarDates[0]}
        checkOutDate={calendarDates[1]}
        setCalendarDates={setCalendarDates}
      />
      {isDesktop && (
        <BookingStickyMenuDekstop
          openMenu={openMenu}
          openCartHandler={openCartHandler}
          openRoomPickerHandler={openRoomPickerHandler}
          cartVisible={cartVisible}
          modalOpen={modalOpen}
          datePickerOpen={datePickerOpen}
          checkInDate={calendarDates[0]}
          checkOutDate={calendarDates[1]}
        />
      )}
      <div
        id="stickyMenu"
        className={`${classes.Container} ${modalOpen && classes.ContainerHidden} ${
          cartVisible && classes.cartVisible
        } ${process.env.NEXT_PUBLIC_MEWS_ENABLED === 'true' && classes.ContainerHidden} ${
          isDesktop && classes.ContainerHidden
        }`}
      >
        <div className={classes.MenuToggle} onClick={() => setMenuIsOpen(true)}>
          <span></span>
          <span></span>
          Menu
        </div>
        <Button
          className={classes.DatePickerToggler}
          onClick={() => openMenu()}
          rightIcon={
            <div className={classes.SearchIconContainer}>
              <Search className={classes.SearchIcon} size={22} />
            </div>
          }
        >
          {t('booking_sticky_menu_get_prices')}
        </Button>
        {/* <Button
          onClick={() => openMenu()}
          rightIcon={
            <div className={classes.SearchIconContainer}>
              <Search className={classes.SearchIcon} size={22} />
            </div>
          }
        >
          ##{t('booking_sticky_menu_get_prices')}##
        </Button> */}
        <a
          href="#cartContainer"
          ref={cartButton}
          onClick={openCartHandler}
          style={{ visibility: cartVisible && !datePickerOpen ? 'visible' : 'hidden' }}
        >
          <Button height="55px" className={classes.CartToggler}>
            <Briefcase size={22} color="white" />
          </Button>
        </a>
      </div>

      {/* mews sticky menu */}
      <div
        id="stickyMenu"
        className={`${classes.Container} ${modalOpen && classes.ContainerHidden} ${
          cartVisible && classes.cartVisible
        } ${process.env.NEXT_PUBLIC_MEWS_ENABLED === 'false' && classes.ContainerHidden} ${
          isDesktop && classes.ContainerHidden
        }`}
      >
        <div className={classes.MenuToggle} onClick={() => setMenuIsOpen(true)}>
          <span></span>
          <span></span>
          Menu
        </div>
        <Button
          className={`${!maintenanceActive && 'distributor-open'} ${classes.DatePickerToggler} ${
            datePickerOpen && classes.DatePickerTogglerOpen
          }`}
          onClick={() => mewsHandleOpen()}
          variant={'bookingStarter'}
          rightIcon={
            <div className={classes.SearchIconContainer}>
              <section>
                <Search className={classes.SearchIcon} size={22} />
              </section>
            </div>
          }
        >
          <p>{t('booking_sticky_menu_get_prices')}</p>
        </Button>
        <a
          href="#cartContainer"
          ref={cartButton}
          onClick={openCartHandler}
          style={{ visibility: cartVisible && !datePickerOpen ? 'visible' : 'hidden' }}
        >
          <Button height="55px" className={classes.CartToggler}>
            <Briefcase size={22} color="white" />
          </Button>
        </a>
      </div>

      <Modal
        isOpen={openMaintenanceModal}
        onClose={() => toggleMaintenanceModal(false)}
        message={t('booking_maintenance_message')}
        title={t('booking_maintenance_tagline')}
        hideCloseButton={true}
      />
    </>
  );
};
export default withDatasourceCheck()<BookProps>(BookingStickyMenu);
