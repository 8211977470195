import React from 'react';
import {
  Image as JssImage,
  Link as JssLink,
  ImageField,
  Field,
  LinkField,
  Text,
  useSitecoreContext,
} from '@sitecore-jss/sitecore-jss-nextjs';

interface Fields {
  Image: ImageField;
  ImageCaption: Field<string>;
  TargetUrl: LinkField;
}

type ImageProps = {
  params: { [key: string]: string };
  fields: Fields;
};

const ImageDefault = (props: ImageProps): JSX.Element => (
  <div className={`component image ${props.params.styles}`.trimEnd()}>
    <div className="component-content">
      <span className="is-empty-hint">Image</span>
    </div>
  </div>
);

const Image = (props: ImageProps): JSX.Element => {
  const { sitecoreContext } = useSitecoreContext();

  if (props.fields) {
    return (
      <div className={`component image ${props.params.styles}`}>
        <div className="component-content">
          {sitecoreContext.pageState === 'edit' ? (
            <JssImage field={props.fields.Image} />
          ) : (
            <JssLink field={props.fields.TargetUrl}>
              <JssImage field={props.fields.Image} />
            </JssLink>
          )}
          <Text tag="span" className="image-caption field-imagecaption" field={props.fields.ImageCaption} />
        </div>
      </div>
    );
  }

  return <ImageDefault {...props} />;
};

export default Image;
