import { Box, SystemStyleObject } from '@chakra-ui/react';

interface Props {
  size?: number;
  strokeWidth?: number;
  color?: string;
  style?: SystemStyleObject;
}

const ArrowLeft = (props: Props): JSX.Element => {
  const { size = 31, strokeWidth = 2, color = 'black', style } = props;
  return (
    <Box __css={style}>
      <svg width={size} height={size} viewBox="0 0 31 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line x1="28" y1="10.4178" x2="6.11959e-08" y2="10.4178" stroke={color} strokeWidth={strokeWidth} />
        <path d="M21 20.418C21 14.8951 25.4772 10.418 31 10.418" stroke={color} strokeWidth={strokeWidth} />
        <path d="M31 10.418C25.4772 10.418 21 5.94082 21 0.417969" stroke={color} strokeWidth={strokeWidth} />
      </svg>
    </Box>
  );
};

export default ArrowLeft;
