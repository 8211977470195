export const CalendarIcon: React.FC = () => {
  return (
    <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 7.2999H20M5.65 0.649902L5.65 4.3499M16.65 0.649902V4.3499M1 4.9999C1 3.34305 2.34315 1.9999 4 1.9999H18C19.6569 1.9999 21 3.34305 21 4.9999V15.9999C21 17.6568 19.6569 18.9999 18 18.9999H4C2.34315 18.9999 1 17.6568 1 15.9999V4.9999Z"
        stroke="white"
        strokeWidth="1.3"
        strokeLinecap="round"
      />
    </svg>
  );
};
