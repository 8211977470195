import React, { ReactElement, useCallback, useEffect, useMemo } from 'react';
import { X } from 'tabler-icons-react';
import classes from '../BookingStickyMenuSteps.module.scss';
import customClasses from './BookingStickyMenuDatePicker.module.scss';
import { useBookingMenuStore, useBookingMenuDatesStore, useGeneralStore } from '../../../../store';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useI18n } from 'next-localization';
import { addDays, isBefore, subDays } from 'date-fns';
import { enGB, da } from 'date-fns/locale';
import { shortDateFormat } from 'lib/booking-box-helpers';
import { ArrowRightIcon } from 'assets/Icons/ui/ArrowRightIcon';
import DatePickerWeekDays from './datepickerWeekdays';
import { GetStaticComponentProps } from '@sitecore-jss/sitecore-jss-nextjs';

registerLocale('da', da);
registerLocale('en', enGB);

interface IProps {
  openMenu: () => void;
  today: Date;
  checkInDate: Date | null;
  checkOutDate: Date | null;
  setCalendarDates: React.Dispatch<React.SetStateAction<[Date | null, Date | null]>>;
}

const BookingStickyMenuDatePicker: React.FC<IProps> = ({
  openMenu,
  today,
  checkInDate,
  checkOutDate,
  setCalendarDates,
}): ReactElement => {
  const { t, locale } = useI18n();
  const dateLocale = locale() as 'en' | 'da';
  const maxDaysInCalendar = parseInt(t('booking_max_days_in_calendar'));

  const { datePickerOpen, setDatePickerOpen, setModalOpen } = useBookingMenuStore((state) => state);
  const { menuFetchingDates, setMenuFetchingDates, setOldFetchingDates, oldFetchingDates } = useBookingMenuDatesStore(
    (state) => state
  );
  const { isDesktop, loaded } = useGeneralStore((state) => state);

  const closeDatePickerAndModal = () => {
    setModalOpen(false);
    setDatePickerOpen(false);
  };

  const hideModal = (event: React.MouseEvent<HTMLDivElement, MouseEvent> & { target: { id: string } }) => {
    event.stopPropagation();
    if (event.target.id === 'datePickerStep' && isDesktop) {
      closeDatePickerAndModal();
      !menuFetchingDates.checkOut && setMenuFetchingDates(oldFetchingDates);
    }
  };

  const maxDate = useMemo(() => addDays(new Date(), maxDaysInCalendar), [maxDaysInCalendar]);

  const yesterday = useMemo(() => {
    return subDays(new Date(), 1);
  }, []);
  //on mobile, selectedDay will be the day before today, so that all days are visible
  //if selected is new Date(), reactDatePicker does not add the react-datepicker__day--keyboard-selected class, so no styling is applied
  const selectedDate = useMemo(() => {
    return isDesktop ? checkInDate : yesterday;
  }, [yesterday, isDesktop, checkInDate]);

  useEffect(() => {
    //if dates dont exist in cache or lower than today, set them as today and tomorrow
    (!menuFetchingDates.checkIn || isBefore(new Date(menuFetchingDates.checkIn), new Date())) &&
      setMenuFetchingDates({ checkIn: new Date(), checkOut: addDays(new Date(), 1) });
    (!oldFetchingDates.checkIn || isBefore(new Date(oldFetchingDates.checkIn), new Date())) &&
      setOldFetchingDates({ checkIn: new Date(), checkOut: addDays(new Date(), 1) });
  }, []);

  useEffect(() => {
    const startDate = document.getElementsByClassName('react-datepicker__day--range-start')[0];
    let timeout: ReturnType<typeof setTimeout>;
    if (startDate) {
      //scroll to start date after 50ms
      timeout = setTimeout(() => {
        startDate.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 50);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [datePickerOpen]);

  const onChange = useCallback(
    (dates: [Date | null, Date | null]) => {
      const [start, end] = dates;

      //if start is before previous start, set start as new start and end as null
      if (start && isBefore(start, checkInDate!)) {
        setCalendarDates([start, null]);
        return;
      }

      const newFetchingDates = {
        checkIn: start,
        checkOut: end ?? null,
      };
      //check if newfetchingdates.checkout and newfetchingdates.checkin are on the same day
      if (shortDateFormat(newFetchingDates.checkOut!, 'en') === shortDateFormat(newFetchingDates.checkIn!, 'en')) {
        newFetchingDates.checkOut = null;
      }

      setCalendarDates([start, end]);
    },
    [checkInDate]
  );

  return loaded ? (
    <div
      id="datePickerStep"
      onClick={hideModal}
      className={`${customClasses.BookingStickyMenuDatePickerStep} ${classes.BookingStickyMenuStep} ${
        datePickerOpen ? classes.BookingStickyMenuStep_Active : classes.BookingStickyMenuStep_NotActive
      }`}
    >
      <div className={`${customClasses.DatePickerHeader} ${classes.Header}`}>
        <div>{t('booking_datepicker_headline')}</div>
        <X onClick={closeDatePickerAndModal} />
      </div>

      <div className={customClasses.DatePickerContainer}>
        <DatePickerWeekDays locale={dateLocale} />
        {datePickerOpen && (
          <DatePicker
            selected={selectedDate}
            onChange={onChange}
            locale={dateLocale}
            startDate={checkInDate}
            endDate={checkOutDate}
            selectsRange
            monthsShown={isDesktop ? 2 : 12}
            // monthsShown={datePickerOpen && isDesktop ? 2 : datePickerOpen ? maxDaysInCalendar / 29 : 0}
            minDate={today}
            maxDate={maxDate}
            calendarStartDay={1}
            useWeekdaysShort={true}
            fixedHeight={true}
            focusSelectedMonth={false}
            inline
          />
        )}

        <div className={`${classes.Footer} ${customClasses.Footer}`}>
          <div className={customClasses.Arrival}>
            <p className={customClasses.ArrivalLabel}>{t('bookbox_arrival')}</p>
            <p>{shortDateFormat(checkInDate!, dateLocale)}</p>
          </div>
          <ArrowRightIcon mt={'12px'} />
          <div className={customClasses.Departure}>
            <p className={customClasses.DepartureLabel}>{t('bookbox_departure')}</p>

            <p>{checkOutDate ? shortDateFormat(checkOutDate, dateLocale) : ''}</p>
          </div>
          <button id="startSearch" className={classes.Complete} onClick={openMenu}>
            {t('bookbox_accept')}
          </button>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export const getStaticProps: GetStaticComponentProps = async () => {
  return {};
};

export default BookingStickyMenuDatePicker;
