import { withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { Flex } from '@chakra-ui/react';
import {
  BarsIcon,
  BikeRentalIcon,
  CinemaIcon,
  EateryIcon,
  FoodBeverageIcon,
  GamesIcon,
  GymIcon,
  LaundryIcon,
  ParkingIcon,
  PoolIcon,
  RooftopIcon,
  TvRoomIcon,
  WifiIcon,
  YogaIcon,
} from 'assets//Icons/facilities';

const Footer: React.FC = (): JSX.Element => {
  return (
    <Flex columnGap={2}>
      <BarsIcon boxSize={8} />
      <BikeRentalIcon boxSize={8} />
      <CinemaIcon boxSize={8} />
      <EateryIcon boxSize={8} />
      <FoodBeverageIcon boxSize={8} />
      <GamesIcon boxSize={8} />
      <GymIcon boxSize={8} />
      <LaundryIcon boxSize={8} />
      <ParkingIcon boxSize={8} />
      <PoolIcon boxSize={8} />
      <RooftopIcon boxSize={8} />
      <TvRoomIcon boxSize={8} />
      <WifiIcon boxSize={8} />
      <YogaIcon boxSize={8} />
    </Flex>
  );
};

export default withDatasourceCheck()(Footer);
