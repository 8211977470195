import React, { ReactElement } from 'react';
import { X } from 'tabler-icons-react';
import { useBookingMenuStore } from '../../../../store';
import classes from '../BookingStickyMenuSteps.module.scss';
import { useI18n } from 'next-localization';

const BookingStickyMenuError: React.FC = (): ReactElement => {
  const { t } = useI18n();

  const { errorStepOpen, setErrorStepOpen, setModalOpen } = useBookingMenuStore((state) => state);

  const closeErrorAndModalHandler = () => {
    setModalOpen(false);
    setErrorStepOpen(false);
  };

  return (
    <div
      className={`${classes.BookingStickyMenuStep} ${
        errorStepOpen ? classes.BookingStickyMenuStep_Active : classes.BookingStickyMenuStep_NotActive
      }`}
    >
      <div className={classes.Header}>
        <div>Error</div>
        <X onClick={closeErrorAndModalHandler} />
      </div>
      <div className={classes.Container}>
        <div className={classes.Content}>
          <h2>{t('booking_error_tagline')}</h2>
        </div>
      </div>
      <div className={classes.Footer}>
        <div></div>
        <button id="startSearch" className={classes.Complete} onClick={closeErrorAndModalHandler}>
          Return
        </button>
      </div>
    </div>
  );
};
export default BookingStickyMenuError;
