import Script from 'next/script';

interface HardcodedDataProps {
  site?: 'nex' | 'ste';
  lang?: 'da' | 'en';
}

function HardcodedData({ site, lang }: HardcodedDataProps) {
  type ScriptsMap = Record<'nex' | 'ste', Record<'da' | 'en', string[]>>;

  const scriptsMap: ScriptsMap = {
    nex: {
      da: [
        `window.dataLayer = window.dataLayer || [];
        function gtag() {
          dataLayer.push(arguments);
        }
        gtag('consent', 'default', {
          ad_storage: 'denied',
          analytics_storage: 'denied',
          wait_for_update: 500,
        });
        gtag('set', 'ads_data_redaction', true);`,
        `dataLayer=(typeof dataLayer!=="undefined")?dataLayer:[];
        dataLayer.push({
          'MESID': 'G-LL9RYK3ZVR',
          'MESIDS': 'G-VVW82HGJXW',
          'AdwordsConversionID': '327671632',
          'AdwordsConversionLabel': 'ELpwCLO80IMYENC-n5wB'
        });`,
        `
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-M7G87HQ');
        `,
      ],
      en: [
        `window.dataLayer = window.dataLayer || [];
        function gtag() {
          dataLayer.push(arguments);
        }
        gtag('consent', 'default', {
          ad_storage: 'denied',
          analytics_storage: 'denied',
          wait_for_update: 500,
        });
        gtag('set', 'ads_data_redaction', true);`,
        `dataLayer=(typeof dataLayer!=="undefined")?dataLayer:[];
        dataLayer.push({
          'MESID': 'G-LL9RYK3ZVR',
          'MESIDS': 'G-VVW82HGJXW',
          'AdwordsConversionID': '327671632',
          'AdwordsConversionLabel': 'ELpwCLO80IMYENC-n5wB'
        });`,
        `
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-M7G87HQ');
        `,
      ],
    },
    ste: {
      da: [
        `window.dataLayer = window.dataLayer || [];
        function gtag() {
          dataLayer.push(arguments);
        }
        gtag('consent', 'default', {
          ad_storage: 'denied',
          analytics_storage: 'denied',
          wait_for_update: 500,
        });
        gtag('set', 'ads_data_redaction', true);`,
        `dataLayer=(typeof dataLayer!=="undefined")?dataLayer:[];
        dataLayer.push({
          'MESID': 'G-28V10Y9VJN',
          'MESIDS': 'G-8FFD096BJJ',
          'AdwordsConversionID': '864256363',
          'AdwordsConversionLabel': 'UfLECK3PyIQYEOuCjpwD'
        });`,
        `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-M7G87HQ');`,
      ],
      en: [
        `window.dataLayer = window.dataLayer || [];
        function gtag() {
          dataLayer.push(arguments);
        }
        gtag('consent', 'default', {
          ad_storage: 'denied',
          analytics_storage: 'denied',
          wait_for_update: 500,
        });
        gtag('set', 'ads_data_redaction', true);`,
        `dataLayer=(typeof dataLayer!=="undefined")?dataLayer:[];
        dataLayer.push({
          'MESID': 'G-28V10Y9VJN',
          'MESIDS': 'G-8FFD096BJJ',
          'AdwordsConversionID': '864256363',
          'AdwordsConversionLabel': 'UfLECK3PyIQYEOuCjpwD'
        });`,
        `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-M7G87HQ');`,
      ],
    },
  };

  // note several is is dubliated in both sites
  const scripts = site && lang ? scriptsMap[site][lang] : [];

  //  {49DB7F33-8B99-44E4-AE06-5BECA3914208}  NEX Schema
  const nexSchemaEN = {
    '@context': 'http://schema.org',
    '@type': 'Hostel',
    name: 'Next House Copenhagen',
    url: 'https://www.nexthousecopenhagen.com/',
    logo: 'https://www.nexthousecopenhagen.com/-/media/Assets/Logoer/SVG-graa-med-AHHG/Next-House-Copenhagen-logo.svg',
    image: 'https://www.nexthousecopenhagen.com/-/media/NextHouse/5-Diverse/Next-House-Copenhagen-facade-1.jpg',
    description: 'A lively and buzzy space to hang out',
    sameAs: ['https://www.facebook.com/nexthousecopenhagen', 'https://www.instagram.com/nexthousecopenhagen/'],
    telephone: '+4533301100',
    email: 'reservations@nexthousecopenhagen.com',
    checkinTime: 'T15:00+0100',
    checkoutTime: 'T11:00+0100',
    openingHours: 'Mo-Su',
    hasMap: 'https://www.google.com/maps?cid=3275811142233151268',
    parentOrganization: 'Arp-Hansen Hotel Group',
    brand: { '@type': 'Brand', name: 'Next House Copenhagen' },
    address: {
      '@type': 'PostalAddress',
      streetAddress: 'Bernstorffsgade 27',
      addressLocality: 'København V',
      postalCode: '1577',
      addressCountry: 'DK',
    },
    currenciesAccepted: ['EUR', 'DKK'],
    paymentAccepted: 'Cash, Credit Card',
    geo: { '@type': 'GeoCoordinates', latitude: '55.6693925', longitude: '12.5711936' },
    availableLanguage: ['da', 'en'],
    priceRange: 'Prices from 145 DKK (Price guarantee)',
    petsAllowed: 'False',
    smokingAllowed: 'False',
    makesOffer: {
      '@type': 'Offer',
      priceSpecification: { '@type': 'UnitPriceSpecification', priceCurrency: 'DKK' },
      acceptedPaymentMethod: [
        { '@type': 'PaymentMethod', url: 'http://purl.org/goodrelations/v1#Cash' },
        { '@type': 'PaymentMethod', url: 'http://purl.org/goodrelations/v1#JCB' },
        { '@type': 'PaymentMethod', url: 'http://purl.org/goodrelations/v1#MasterCard' },
        { '@type': 'PaymentMethod', url: 'http://purl.org/goodrelations/v1#VISA' },
      ],
    },
    amenityFeature: [
      'Free Wi-Fi',
      'Air condition',
      'Laundry room',
      'Breakfast',
      'Restaurant',
      'Fitness center',
      'Bar',
      'Non-smoking',
      'Lift',
      '24-hour reception',
      'Room service',
      'Bike rental',
    ],
  };
  const nexSchemaDA = {
    '@context': 'http://schema.org',
    '@type': 'Hostel',
    name: 'Next House Copenhagen',
    url: 'https://www.nexthousecopenhagen.com/da',
    logo: 'https://www.nexthousecopenhagen.com/-/media/Assets/Logoer/SVG-graa-med-AHHG/Next-House-Copenhagen-logo.svg',
    image: 'https://www.nexthousecopenhagen.com/-/media/NextHouse/5-Diverse/Next-House-Copenhagen-facade-1.jpg',
    description: 'Luksus hostel sprudlende af liv og god energi',
    sameAs: ['https://www.facebook.com/nexthousecopenhagen', 'https://www.instagram.com/nexthousecopenhagen/'],
    telephone: '+4533301100',
    email: 'reservations@nexthousecopenhagen.com',
    checkinTime: 'T15:00+0100',
    checkoutTime: 'T11:00+0100',
    openingHours: 'Mo-Su',
    hasMap: 'https://www.google.com/maps?cid=3275811142233151268',
    parentOrganization: 'Arp-Hansen Hotel Group',
    brand: { '@type': 'Brand', name: 'Next House Copenhagen' },
    address: {
      '@type': 'PostalAddress',
      streetAddress: 'Bernstorffsgade 27',
      addressLocality: 'København V',
      postalCode: '1577',
      addressCountry: 'DK',
    },
    currenciesAccepted: ['EUR', 'DKK'],
    paymentAccepted: 'Cash, Credit Card',
    geo: { '@type': 'GeoCoordinates', latitude: '55.6693925', longitude: '12.5711936' },
    availableLanguage: ['da', 'en'],
    priceRange: 'Priser fra 145 DKK (Prisgaranti)',
    petsAllowed: 'False',
    smokingAllowed: 'False',
    makesOffer: {
      '@type': 'Offer',
      priceSpecification: { '@type': 'UnitPriceSpecification', priceCurrency: 'DKK' },
      acceptedPaymentMethod: [
        { '@type': 'PaymentMethod', url: 'http://purl.org/goodrelations/v1#Cash' },
        { '@type': 'PaymentMethod', url: 'http://purl.org/goodrelations/v1#JCB' },
        { '@type': 'PaymentMethod', url: 'http://purl.org/goodrelations/v1#MasterCard' },
        { '@type': 'PaymentMethod', url: 'http://purl.org/goodrelations/v1#VISA' },
      ],
    },
    amenityFeature: [
      'Gratis Wi-Fi',
      'Aircondition',
      'Vaskerum',
      'Morgenmad',
      'Restaurant',
      'Fitnesscenter',
      'Bar',
      'Røgfrit',
      'Elevator',
      '24-timers reception',
      'Cykeludlejning',
    ],
  };

  const steSchemaDA = {
    '@context': 'http://schema.org',
    '@type': 'Hostel',
    name: 'Steel House Copenhagen',
    url: 'https://www.steelhousecopenhagen.com/da',
    logo: 'https://www.steelhousecopenhagen.com/-/media/steelhouse/logo/170x170/170x170-steel-house-copenhagen-logo-negativ.png',
    image:
      'https://www.steelhousecopenhagen.com/-/media/Steelhouse/Facade-og-lobby/Steel-House-Copenhagen-facade-7.jpg',
    description: 'Luksus hostel i København',
    sameAs: ['https://www.facebook.com/steelhousecopenhagen/', 'https://www.instagram.com/steel_house_copenhagen/'],
    telephone: '+4533177110',
    email: 'reservations@steelhousecopenhagen.com',
    checkinTime: 'T15:00+0100',
    checkoutTime: 'T11:00+0100',
    openingHours: 'Mo-Su',
    hasMap: 'https://www.google.com/maps?cid=7483648550187784352',
    parentOrganization: 'Arp-Hansen Hotel Group',
    brand: { '@type': 'Brand', name: 'Steel House Copenhagen' },
    address: {
      '@type': 'PostalAddress',
      streetAddress: 'Herholdtsgade 6',
      addressLocality: 'København V',
      postalCode: '1605',
      addressCountry: 'DK',
    },
    currenciesAccepted: ['EUR', 'DKK'],
    paymentAccepted: 'Cash, Credit Card',
    geo: { '@type': 'GeoCoordinates', latitude: '55.6763281', longitude: '12.559754' },
    availableLanguage: ['da', 'en'],
    priceRange: 'Priser fra 145 DKK (Prisgaranti)',
    petsAllowed: 'False',
    smokingAllowed: 'False',
    makesOffer: {
      '@type': 'Offer',
      priceSpecification: { '@type': 'UnitPriceSpecification', priceCurrency: 'DKK' },
      acceptedPaymentMethod: [
        { '@type': 'PaymentMethod', url: 'http://purl.org/goodrelations/v1#Cash' },
        { '@type': 'PaymentMethod', url: 'http://purl.org/goodrelations/v1#JCB' },
        { '@type': 'PaymentMethod', url: 'http://purl.org/goodrelations/v1#MasterCard' },
        { '@type': 'PaymentMethod', url: 'http://purl.org/goodrelations/v1#VISA' },
      ],
    },
    amenityFeature: [
      'Gratis Wi-Fi',
      'Aircondition',
      'Vaskerum',
      'Morgenmad',
      'Pool',
      'Fitnesscenter',
      'Bar',
      'Røgfrit',
      'Elevator',
      '24-timers reception',
      'Cykeludlejning',
    ],
  };

  const steSchemaEN = {
    '@context': 'http://schema.org',
    '@type': 'Hostel',
    name: 'Steel House Copenhagen',
    url: 'https://www.steelhousecopenhagen.com/',
    logo: 'https://www.steelhousecopenhagen.com/-/media/steelhouse/logo/170x170/170x170-steel-house-copenhagen-logo-negativ.png',
    image:
      'https://www.steelhousecopenhagen.com/-/media/Steelhouse/Facade-og-lobby/Steel-House-Copenhagen-facade-7.jpg',
    description: 'Luxury hostel in Copenhagen',
    sameAs: ['https://www.facebook.com/steelhousecopenhagen/', 'https://www.instagram.com/steel_house_copenhagen/'],
    telephone: '+4533177110',
    email: 'reservations@steelhousecopenhagen.com',
    checkinTime: 'T15:00+0100',
    checkoutTime: 'T11:00+0100',
    openingHours: 'Mo-Su',
    hasMap: 'https://www.google.com/maps?cid=7483648550187784352',
    parentOrganization: 'Arp-Hansen Hotel Group',
    brand: { '@type': 'Brand', name: 'Steel House Copenhagen' },
    address: {
      '@type': 'PostalAddress',
      streetAddress: 'Herholdtsgade 6',
      addressLocality: 'København V',
      postalCode: '1605',
      addressCountry: 'DK',
    },
    currenciesAccepted: ['EUR', 'DKK'],
    paymentAccepted: 'Cash, Credit Card',
    geo: { '@type': 'GeoCoordinates', latitude: '55.6763281', longitude: '12.559754' },
    availableLanguage: ['da', 'en'],
    priceRange: 'Prices from 145 DKK (Price guarantee)',
    petsAllowed: 'False',
    smokingAllowed: 'False',
    makesOffer: {
      '@type': 'Offer',
      priceSpecification: { '@type': 'UnitPriceSpecification', priceCurrency: 'DKK' },
      acceptedPaymentMethod: [
        { '@type': 'PaymentMethod', url: 'http://purl.org/goodrelations/v1#Cash' },
        { '@type': 'PaymentMethod', url: 'http://purl.org/goodrelations/v1#JCB' },
        { '@type': 'PaymentMethod', url: 'http://purl.org/goodrelations/v1#MasterCard' },
        { '@type': 'PaymentMethod', url: 'http://purl.org/goodrelations/v1#VISA' },
      ],
    },
    amenityFeature: [
      'Free Wi-Fi',
      'Air condition',
      'Laundry room',
      'Breakfast',
      'Pool',
      'Fitness center',
      'Bar',
      'Non-smoking',
      'Lift',
      '24-hour reception',
      'Room service',
      'Bike rental',
    ],
  };

  const selectedNexSchema = lang === 'en' ? nexSchemaEN : nexSchemaDA;
  const selectedSteSchema = lang === 'en' ? steSchemaEN : steSchemaDA;
  const selectedSchema = site === 'nex' ? selectedNexSchema : selectedSteSchema;
  return (
    <>
      {site === 'nex' && (
        <>
          {lang === 'da' && (
            <Script
              key="cookie-popup"
              src="https://policy.app.cookieinformation.com/uc.js"
              data-culture="DA"
              strategy="beforeInteractive"
            />
          )}
          {lang === 'en' && (
            <Script
              key="cookie-popup"
              src="https://policy.app.cookieinformation.com/uc.js"
              data-culture="EN"
              strategy="beforeInteractive"
            />
          )}
          <meta name="google-site-verification" content="c6RDiLBWwr7Xtu-iFd3YwOvmh7Xuxm_REGReEolVQE8" />
          <meta name="facebook-domain-verification" content="mtm7hg0vzkf8r7mrurbghxyyojy94y" />
        </>
      )}
      {site === 'ste' && (
        <>
          {lang === 'da' && (
            <Script
              key="cookie-popup"
              src="https://policy.app.cookieinformation.com/uc.js"
              data-culture="DA"
              strategy="beforeInteractive"
            />
          )}
          {lang === 'en' && (
            <Script
              key="cookie-popup"
              src="https://policy.app.cookieinformation.com/uc.js"
              data-culture="EN"
              strategy="beforeInteractive"
            />
          )}
          {site == 'ste' ? (
            <meta name="google-site-verification" content="C5frvqxYZKSnSLmntgru0OwvSQ9l8S9AJ6T48WnctBI" />
          ) : (
            <meta name="google-site-verification" content="c6RDiLBWwr7Xtu-iFd3YwOvmh7Xuxm_REGReEolVQE8" />
          )}
        </>
      )}

      {/* all scripts handled here */}
      {scripts.map((script, index) => (
        <script key={`script-${index}`} dangerouslySetInnerHTML={{ __html: script }} />
      ))}

      {/* NEX schema */}
      <Script
        id="app-ld-json"
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(selectedSchema, null, '\t'),
        }}
      />
    </>
  );
}

export default HardcodedData;
