import React, { ReactElement, useEffect, useState } from 'react';
import classes from './BookingChangeTrigger.module.scss';
import { useBookingMenuStore, useBookingMenuDatesStore, useChangeBookingStore } from '../../../../store';
import { useI18n } from 'next-localization';
import { ArrowRightIcon } from 'assets/Icons/ui/ArrowRightIcon';
import BrandButton from 'components/booking/BrandButton/BrandButton';
import { longDayDateFormat } from 'lib/booking-box-helpers';

interface IProps {
  changeBooking?: boolean;
}

type IFormattedDates = {
  checkIn: string;
  checkOut: string;
};

const BookingChangeTrigger: React.FC<IProps> = ({ changeBooking }): ReactElement => {
  const { t, locale } = useI18n();
  const { setRoomPickerOpen, setDatePickerOpen, setChangeStepOpen } = useBookingMenuStore((state) => state);
  const { menuFetchingDates } = useBookingMenuDatesStore((state) => state);
  const { setDatePickerChangeActive, changeReservationDates } = useChangeBookingStore((state) => state);
  const [menuDates, setMenuDates] = useState<IFormattedDates>();
  const [changeDates, setChangeDates] = useState<IFormattedDates>();

  useEffect(() => {
    if (menuFetchingDates.checkIn && menuFetchingDates.checkOut) {
      setMenuDates({
        checkIn: longDayDateFormat(menuFetchingDates.checkIn, locale()),
        checkOut: longDayDateFormat(menuFetchingDates.checkOut, locale()),
      });
    }
    if (changeReservationDates.checkIn && changeReservationDates.checkOut && changeBooking) {
      setChangeDates({
        checkIn: longDayDateFormat(changeReservationDates.checkIn, locale()),
        checkOut: longDayDateFormat(changeReservationDates.checkOut, locale()),
      });
    }
  }, [
    menuFetchingDates.checkIn,
    menuFetchingDates.checkOut,
    locale,
    changeBooking,
    changeReservationDates.checkIn,
    changeReservationDates.checkOut,
  ]);

  const openMenu = () => {
    if (changeBooking) {
      setDatePickerChangeActive(true);
      setChangeStepOpen(false);
      setDatePickerOpen(true);
      return;
    }
    setRoomPickerOpen(false);
    setDatePickerOpen(true);
  };

  return (
    <div className={`${classes.Container} ${changeBooking && classes.changeContainer}`} onClick={openMenu}>
      <div className={classes.Arrival}>
        <p className={classes.ArrivalLabel}>{t('bookbox_arrival')}</p>
        <p>{changeBooking ? changeDates?.checkIn : menuDates?.checkIn}</p>
      </div>
      <ArrowRightIcon />
      <div className={classes.Departure}>
        <p className={classes.DepartureLabel}>{t('bookbox_departure')}</p>
        <p>{changeBooking ? changeDates?.checkOut : menuDates?.checkOut}</p>
      </div>
      <BrandButton buttonText={t('booking_change_button_label')} />
    </div>
  );
};
export default BookingChangeTrigger;
