import { Box, useMultiStyleConfig, Link } from '@chakra-ui/react';
import { Field, Text, withDatasourceCheck, ImageField } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import { IDroptreeColor } from '../Component-models/data-items/IDroptreeColor';
import { ElfsightWidget } from 'react-elfsight-widget';
import { useI18n } from 'next-localization';
import { useGeneralStore } from 'src/store';
import { useEffect, useRef, useState } from 'react';
import useOnScreen from 'components/Util-Components/useOnScreen';

type FeedProps = ComponentProps & {
  fields: {
    instagramTag: Field<string>;
    headline: Field<string>;
    sticker: Field<ImageField>;
    noOfImagesDesktop: Field<number>; // Default 6
    noOfImagesMobile: Field<number>; // Default 5
    textcolor: IDroptreeColor;
  };
};

const InstagramSocialWall: React.FC = (props: FeedProps) => {
  const styles = useMultiStyleConfig('InstagramSocialWall', {});
  const { loaded } = useGeneralStore((state) => state);

  const containerRef = useRef<HTMLDivElement>(null);
  const scrolledIntoView = useOnScreen(containerRef);

  const [hasScrolledInto, setHasScrolledInto] = useState(false);
  useEffect(() => {
    if (hasScrolledInto) return;
    setHasScrolledInto(scrolledIntoView);
  }, [scrolledIntoView, hasScrolledInto]);

  const { t } = useI18n();
  return (
    <Box
      className="ahhg-instagram-social-wall"
      __css={{
        ...styles.container,
        color: props.fields?.textcolor?.fields?.colorcode?.value ?? styles.container.color,
      }}
      ref={containerRef}
    >
      <Box>
        <Link
          href={`https://www.instagram.com/${t('instagram_link')}`}
          isExternal
          sx={{ _hover: { textDecoration: 'none' } }}
        >
          <Box textStyle="p">
            <Text field={props.fields.instagramTag} />
          </Box>
          <Box __css={styles.heading}>
            <Text tag="h2" field={props.fields.headline} />
          </Box>
        </Link>
      </Box>
      {loaded && hasScrolledInto ? (
        <Box __css={styles.elfsightContainer}>
          <ElfsightWidget widgetId={`${process.env.NEXT_PUBLIC_ELFSIGHT_ID}`} lazy modern />
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default withDatasourceCheck()<FeedProps>(InstagramSocialWall);
