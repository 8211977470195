import { Button, Flex, FormControl, FormLabel, Heading, Input, Text } from '@chakra-ui/react';
import { generateGroupLink, validateBookNums } from 'lib/helpers';
import { useRef, useState } from 'react';

const LinkGenerator = (): JSX.Element => {
  const reservationNumberRef = useRef<HTMLInputElement>(null);
  const generatedLinkRef = useRef<HTMLInputElement>(null);

  const [errorText, setErrorText] = useState<string>('');
  const [successText, setSuccessText] = useState<string>('');
  const [generatedLink, setGeneratedLink] = useState<string>('');

  const handleGenerateLink = () => {
    setGeneratedLink('');
    setErrorText('');
    if (!reservationNumberRef.current || reservationNumberRef.current.value === '') {
      setErrorText('Du skal indtaste et reservationsnummer');
      return;
    }
    const bookNums = reservationNumberRef.current?.value.split(',');

    const validatedBookNumsObj = validateBookNums(bookNums);
    if (validatedBookNumsObj.error) {
      setErrorText(validatedBookNumsObj.error);
      return;
    }
    if (!validatedBookNumsObj.bookNumsObj) {
      setErrorText('BookNumsObj is undefined');
      return;
    }
    const bookNumsObj = validatedBookNumsObj.bookNumsObj;

    const response = generateGroupLink(bookNumsObj);
    response
      .then((data) => {
        if (data.Data.Status === 'fail') {
          setErrorText(data.Data.Data);
          return;
        }
        setGeneratedLink(data.Data.Data);
      })
      .catch((error) => {
        setErrorText(error.message);
      });
  };

  const handleCopyLink = () => {
    if (generatedLinkRef.current && generatedLinkRef.current.value !== '') {
      navigator.clipboard.writeText(generatedLinkRef.current.value);
      setSuccessText('Linket er kopieret til udklipsholderen');
    }
  };

  return (
    <Flex
      backgroundColor="darkgrey"
      borderRadius={'1rem'}
      gap={'1rem'}
      flexDirection="column"
      padding={'2rem'}
      w={{ base: '100', lg: '50%' }}
      justifyContent="center"
      textAlign={'center'}
    >
      <Heading size={'md'} my="1rem">
        Linkgenerator for grupper
      </Heading>
      {errorText && <Text color="red">{errorText}</Text>}
      {successText && <Text color="green">{successText}</Text>}
      <Flex gap={'1rem'} flexDirection="column">
        <FormControl id="reservationNumber">
          <FormLabel>
            <Text>Liste af reservationsnumre der skal &quot;linkes&quot;</Text>
          </FormLabel>
          <Input
            placeholder="Komma sepereret liste af numre"
            type="text"
            name="reservationNumber"
            ref={reservationNumberRef}
          />
        </FormControl>
        <Button onClick={handleGenerateLink} w="fit-content">
          Generer link
        </Button>
        <FormControl id="gruppeLink">
          <FormLabel>
            <Text>Gruppe link</Text>
          </FormLabel>
          <Input
            placeholder="Gruppe link"
            type="text"
            name="gruppeLink"
            ref={generatedLinkRef}
            value={generatedLink}
            readOnly
          />
        </FormControl>
        <Button onClick={handleCopyLink} w="fit-content">
          Kopier link
        </Button>
      </Flex>
    </Flex>
  );
};

export default LinkGenerator;
