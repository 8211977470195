import React, { ReactElement, useEffect, useState } from 'react';
import { PackageBox } from '../../../../store/booking-store-modules';
import { useCartStore } from '../../../../store';
import ExtraPackage1 from './ExtraPackage1';
import ExtraPackage2 from './ExtraPackage2';

interface IProps {
  ExtraData: PackageBox[];
}

const ExtraBoxes: React.FC<IProps> = ({ ExtraData }): ReactElement => {
  const [extraPackage2Data, setExtraPackage2Data] = useState<{ adult: PackageBox; child: PackageBox | null }>(
    {} as { adult: PackageBox; child: PackageBox }
  );

  const { addExtraPackage1Details } = useCartStore((state) => state);

  useEffect(() => {
    Object.values(ExtraData).forEach((ExtraDetails) => {
      if (ExtraDetails.PackageCode !== 'BUAD' && ExtraDetails.PackageCode !== 'BUCH') {
        addExtraPackage1Details(ExtraDetails);
      }
      if (ExtraDetails.PackageCode === 'BUAD') {
        setExtraPackage2Data((prevState) => ({
          ...prevState,
          ['adult']: ExtraDetails,
          ['child']: null,
        }));
      }
      if (ExtraDetails.PackageCode === 'BUCH') {
        setExtraPackage2Data((prevState) => ({
          ...prevState,
          ['child']: ExtraDetails,
        }));
      }
    });
  }, [ExtraData]);

  return (
    <>
      {ExtraData &&
        Object.values(ExtraData).map((ExtraDetails, index) => {
          if (
            !ExtraDetails.PackagePriceChildCategory &&
            ExtraDetails.PackageCode !== 'BUAD' &&
            ExtraDetails.PackageCode !== 'BUCH'
            // ExtraDetails.PackageCode === 'WEB'
          ) {
            return <ExtraPackage1 key={ExtraDetails.PackageCode + index} ExtraPackage1Data={ExtraDetails} />;
          }
          if (ExtraDetails.PackageCode === 'BUAD' && extraPackage2Data['child']) {
            return <ExtraPackage2 key={ExtraDetails.PackageCode + index} ExtraPackage2Data={extraPackage2Data} />;
          }
          return;
        })}
    </>
  );
};
export default ExtraBoxes;
