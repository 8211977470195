import { checkDormBoxSoldOut } from 'lib/booking-box-helpers';
import React, { ReactElement, useEffect } from 'react';
import { PricingStructure, RoomBox } from '../../../../store/booking-store-modules';
import { useDormStore } from '../../../../store/index';
import DormBox from './DormBox';

interface IProps {
  DormData: PricingStructure['Dorms'];
}

const DormBoxes: React.FC<IProps> = ({ DormData }): ReactElement => {
  const { setSoldoutDorms } = useDormStore((state) => state);

  const lowestB4Price = getLowestPricedRoom(DormData.B4);
  const lowestB6Price = getLowestPricedRoom(DormData.B6);
  const newKeys = Object.keys(DormData);

  //if lowestB4Price is higher than lowestB6Price, then we want to display B6 first
  if (lowestB4Price > lowestB6Price) {
    newKeys.reverse();
  }

  useEffect(() => {
    const soldOutDorms = checkDormBoxSoldOut(DormData);
    setSoldoutDorms(soldOutDorms);
  }, [DormData]);

  //LOOP OVER BOTH B4 and B6
  return (
    <>
      {DormData
        ? newKeys.map((dorm, index) => {
            return <DormBox key={dorm + index} DormData={DormData[dorm as keyof PricingStructure['Dorms']]} />;
          })
        : null}
    </>
  );
};
export default DormBoxes;

function getLowestPricedRoom(DormData: { [key: string]: RoomBox[] }) {
  const dorm = Object.values(DormData);
  const lowestPrice = dorm.reduce((prev, curr) =>
    prev[0].Price < curr[0].Price && prev[0].Price !== 0 ? prev : curr
  )[0];
  return lowestPrice.Price;
}
