import { ComponentProps } from 'lib/component-props';
import { IGridParameters } from '../../Component-models/base-items/IGridParameters';
import { Box } from '@chakra-ui/react';
import { Placeholder, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';

type HostelGroupingContainerProps = ComponentProps & {
  params: IGridParameters;
};

const HostelGroupingContainer: React.FC<HostelGroupingContainerProps> = (props: HostelGroupingContainerProps) => {
  const { sitecoreContext } = useSitecoreContext();
  const isEditing = sitecoreContext && sitecoreContext.pageEditing;

  return (
    <Box className={props.params.GridParameters}>
      {isEditing ? (
        <div className="hostelGroupingContainer">
          <Placeholder name={'hostelcontainer'} rendering={props.rendering} />
        </div>
      ) : (
        // this should have proper styling if any needed
        <div className="hostelGroupingContainer">
          <Placeholder name={'hostelcontainer'} rendering={props.rendering} />
        </div>
      )}
    </Box>
  );
};

export default HostelGroupingContainer;
