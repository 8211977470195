import { Icon, IconProps } from '@chakra-ui/react';
import { ReactElement } from 'react';

export const GymIcon = (props: IconProps): ReactElement => {
  return (
    <Icon {...props} viewBox="0 0 35 35">
      <path
        d="M29.3056 19.776L32.4961 17.9353C33.4778 17.3217 34.0505 16.1355 34.0505 14.3766C34.0505 10.8588 31.5553 6.56388 28.5284 4.76408C26.8513 3.78237 25.3378 3.78237 24.3561 4.55956C24.3152 4.60046 21.411 6.23664 21.411 6.23664M22.7594 16.9534C23.5775 17.9351 24.4774 18.7532 25.4591 19.3259C28.5269 21.1257 30.9812 19.694 30.9812 16.1353C30.9812 12.6176 28.486 8.3226 25.4591 6.5228C22.3912 4.76391 19.8961 6.19557 19.8961 9.75426C19.8961 10.8587 20.1415 12.0449 20.5505 13.1902M14.3381 21.4937L26.4459 14.5399C26.4868 14.5399 26.4868 14.499 26.5277 14.499C26.7322 14.3354 26.8549 14.09 26.8549 13.6809C26.8549 12.8628 26.2822 11.8402 25.546 11.4312C25.1369 11.1858 24.7688 11.1858 24.5234 11.4312L12.5384 18.3031M10.3631 30.6558L13.5536 28.8151C14.5353 28.2015 15.108 27.0153 15.108 25.2564C15.108 21.7386 12.6128 17.4436 9.58587 15.6438C7.90879 14.6621 6.39533 14.6621 5.37272 15.4393C5.33181 15.4802 2.42759 17.1164 2.42759 17.1164M6.5221 17.4448C3.45427 15.6859 1 17.1176 1 20.6354C1 24.1531 3.49517 28.4481 6.5221 30.2479C9.58993 32.0068 12.0442 30.5751 12.0442 27.0574C12.0442 23.4987 9.58993 19.2037 6.5221 17.4448Z"
        stroke="#212121"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="transparent"
      />
    </Icon>
  );
};
