import { Icon, IconProps } from '@chakra-ui/react';
import { ReactElement } from 'react';

export const PaypalIcon = (props: IconProps): ReactElement => {
  return (
    <Icon {...props} viewBox="0 0 31 21">
      <rect
        x="0.754167"
        y="0.629167"
        width="28.9292"
        height="19.7417"
        rx="2.51667"
        fill="white"
        stroke="#D9D9D9"
        strokeWidth="1.25833"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.78751 13.3358H7.42634C7.3332 13.3358 7.25398 13.4035 7.23945 13.4954L6.68893 16.9858C6.67799 17.0547 6.73132 17.1168 6.80119 17.1168H7.45102C7.54416 17.1168 7.62338 17.0491 7.63791 16.957L7.78639 16.0156C7.80071 15.9234 7.88013 15.8558 7.97308 15.8558H8.40398C9.30062 15.8558 9.81807 15.4219 9.95328 14.562C10.0142 14.1859 9.95586 13.8903 9.77967 13.6833C9.58624 13.456 9.24306 13.3358 8.78751 13.3358ZM8.94454 14.6106C8.87012 15.099 8.49693 15.099 8.13609 15.099H7.93069L8.07478 14.1868C8.08334 14.1317 8.13111 14.0911 8.18684 14.0911H8.28098C8.52678 14.0911 8.75868 14.0911 8.87851 14.2312C8.94989 14.3148 8.97181 14.439 8.94454 14.6106ZM12.8564 14.5949H12.2045C12.149 14.5949 12.101 14.6355 12.0924 14.6907L12.0636 14.873L12.018 14.807C11.8769 14.6021 11.5623 14.5337 11.2482 14.5337C10.5278 14.5337 9.91267 15.0792 9.79284 15.8445C9.73057 16.2262 9.81909 16.5912 10.0356 16.8458C10.2343 17.0798 10.5185 17.1774 10.8567 17.1774C11.437 17.1774 11.7589 16.8042 11.7589 16.8042L11.7298 16.9853C11.7189 17.0546 11.7722 17.1167 11.8416 17.1167H12.4288C12.5222 17.1167 12.6009 17.049 12.6157 16.9569L12.968 14.7259C12.9791 14.6573 12.926 14.5949 12.8564 14.5949ZM11.9477 15.8636C11.8848 16.236 11.5893 16.4859 11.2124 16.4859C11.0231 16.4859 10.8718 16.4252 10.7747 16.3102C10.6783 16.196 10.6417 16.0333 10.6724 15.8523C10.7311 15.483 11.0317 15.2249 11.4028 15.2249C11.5879 15.2249 11.7384 15.2864 11.8375 15.4024C11.9368 15.5197 11.9762 15.6832 11.9477 15.8636ZM15.6729 14.5948H16.3279C16.4196 14.5948 16.4732 14.6977 16.421 14.773L14.2425 17.9176C14.2072 17.9686 14.1491 17.9989 14.087 17.9989H13.4328C13.3406 17.9989 13.2869 17.8952 13.3402 17.8197L14.0185 16.8622L13.2971 14.7449C13.2721 14.6713 13.3265 14.5948 13.4048 14.5948H14.0484C14.132 14.5948 14.2058 14.6497 14.2299 14.7298L14.6128 16.0086L15.5163 14.6778C15.5516 14.6259 15.6104 14.5948 15.6729 14.5948Z"
        fill="#253B80"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.7748 16.9859L23.3334 13.4316C23.3419 13.3764 23.3897 13.3358 23.4453 13.3356H24.0742C24.1436 13.3356 24.197 13.3979 24.186 13.4668L23.6351 16.957C23.6207 17.0491 23.5415 17.1168 23.4482 17.1168H22.8866C22.8171 17.1168 22.7638 17.0548 22.7748 16.9859ZM18.4965 13.3358H17.1351C17.0422 13.3358 16.963 13.4035 16.9484 13.4954L16.3979 16.9859C16.387 17.0548 16.4403 17.1168 16.5098 17.1168H17.2084C17.2733 17.1168 17.3288 17.0695 17.339 17.005L17.4952 16.0156C17.5095 15.9234 17.5889 15.8558 17.6819 15.8558H18.1126C19.0094 15.8558 19.5267 15.4219 19.662 14.5621C19.7231 14.186 19.6644 13.8904 19.4883 13.6833C19.295 13.4561 18.9521 13.3358 18.4965 13.3358ZM18.6536 14.6106C18.5793 15.0991 18.2061 15.0991 17.8451 15.0991H17.6399L17.7842 14.1869C17.7928 14.1318 17.8401 14.0912 17.896 14.0912H17.9901C18.2358 14.0912 18.4679 14.0912 18.5876 14.2313C18.6591 14.3149 18.6808 14.4391 18.6536 14.6106ZM22.565 14.595H21.9137C21.8577 14.595 21.8101 14.6356 21.8017 14.6908L21.7729 14.8731L21.7271 14.807C21.586 14.6022 21.2716 14.5337 20.9575 14.5337C20.2372 14.5337 19.6222 15.0793 19.5023 15.8446C19.4403 16.2263 19.5284 16.5913 19.7449 16.8459C19.944 17.0799 20.2278 17.1774 20.566 17.1774C21.1463 17.1774 21.4681 16.8042 21.4681 16.8042L21.4391 16.9854C21.4282 17.0547 21.4815 17.1167 21.5514 17.1167H22.1383C22.2312 17.1167 22.3104 17.049 22.325 16.9569L22.6775 14.726C22.6883 14.6573 22.6349 14.595 22.565 14.595ZM21.6565 15.8637C21.594 16.236 21.298 16.486 20.921 16.486C20.7321 16.486 20.5805 16.4253 20.4833 16.3103C20.387 16.1961 20.3508 16.0334 20.3811 15.8523C20.4402 15.4831 20.7403 15.225 21.1115 15.225C21.2966 15.225 21.447 15.2864 21.5462 15.4025C21.6459 15.5197 21.6853 15.6833 21.6565 15.8637Z"
        fill="#179BD7"
      />
      <path
        d="M13.8242 12.0871L13.9916 11.0242L13.6188 11.0156H11.8389L13.0758 3.17224C13.0796 3.14856 13.0921 3.12648 13.1104 3.1108C13.1286 3.09513 13.152 3.08649 13.1763 3.08649H16.1776C17.174 3.08649 17.8616 3.29383 18.2206 3.70306C18.3889 3.89504 18.4961 4.09566 18.5479 4.31644C18.6023 4.5481 18.6033 4.82487 18.5501 5.16243L18.5463 5.18707V5.40336L18.7146 5.49872C18.8564 5.57391 18.9689 5.65998 19.0553 5.75853C19.1994 5.92267 19.2925 6.13129 19.3318 6.37862C19.3725 6.633 19.359 6.93569 19.2925 7.27837C19.2157 7.67258 19.0915 8.0159 18.9239 8.29683C18.7696 8.55568 18.5732 8.77033 18.3399 8.93677C18.1172 9.09482 17.8526 9.21484 17.5535 9.29165C17.2635 9.36709 16.933 9.40522 16.5705 9.40522H16.3369C16.1699 9.40522 16.0077 9.46536 15.8804 9.57322C15.7527 9.68328 15.6682 9.8336 15.6423 9.9981L15.6247 10.0938L15.329 11.9671L15.3156 12.0359C15.3121 12.0578 15.306 12.0686 15.2971 12.076C15.289 12.0826 15.2775 12.0871 15.2663 12.0871H13.8242Z"
        fill="#253B80"
      />
      <path
        d="M18.875 5.21216C18.8661 5.26943 18.8559 5.32798 18.8443 5.38813C18.4485 7.42025 17.0944 8.12226 15.365 8.12226H14.4844C14.273 8.12226 14.0947 8.27583 14.0618 8.48445L13.6109 11.3437L13.4832 12.1541C13.4618 12.2911 13.5674 12.4146 13.7056 12.4146H15.2674C15.4524 12.4146 15.6094 12.2802 15.6385 12.0979L15.6539 12.0185L15.948 10.1524L15.9669 10.0501C15.9956 9.86708 16.153 9.73269 16.338 9.73269H16.5715C18.0847 9.73269 19.2692 9.11835 19.6154 7.34058C19.7601 6.59793 19.6852 5.97784 19.3025 5.54172C19.1867 5.41021 19.043 5.3011 18.875 5.21216Z"
        fill="#179BD7"
      />
      <path
        d="M18.4608 5.04715C18.4003 5.02955 18.3379 5.01355 18.2739 4.99916C18.2096 4.98508 18.1436 4.9726 18.0758 4.96173C17.8384 4.92333 17.5783 4.90509 17.2996 4.90509H14.9472C14.8893 4.90509 14.8343 4.91821 14.785 4.94189C14.6765 4.99404 14.5959 5.09675 14.5764 5.2225L14.0759 8.39209L14.0615 8.48456C14.0945 8.27594 14.2727 8.12235 14.4842 8.12235H15.3647C17.0942 8.12235 18.4483 7.42003 18.8441 5.38824C18.8559 5.32808 18.8658 5.26953 18.8748 5.21226C18.7747 5.15914 18.6662 5.11371 18.5494 5.07499C18.5206 5.06539 18.4909 5.05612 18.4608 5.04715Z"
        fill="#222D65"
      />
      <path
        d="M14.5761 5.2224C14.5956 5.09665 14.6763 4.99394 14.7847 4.9421C14.8344 4.91842 14.8891 4.90531 14.9469 4.90531H17.2994C17.5781 4.90531 17.8382 4.92354 18.0756 4.96194C18.1435 4.97282 18.2094 4.9853 18.2737 4.99937C18.3377 5.01378 18.4001 5.02978 18.4606 5.04737C18.4906 5.05633 18.5204 5.06561 18.5495 5.07489C18.6662 5.1136 18.7747 5.15936 18.8749 5.21216C18.9927 4.46119 18.8739 3.94989 18.4679 3.48689C18.0203 2.97719 17.2124 2.75897 16.1786 2.75897H13.1772C12.966 2.75897 12.786 2.91255 12.7533 3.12149L11.5032 11.0455C11.4785 11.2022 11.5995 11.3437 11.7575 11.3437H13.6105L14.0757 8.39199L14.5761 5.2224Z"
        fill="#253B80"
      />
    </Icon>
  );
};
