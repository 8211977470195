import { Icon } from '@chakra-ui/react';
import { BarsIcon } from './BarsIcon';
import { BikeRentalIcon } from './BikeRentalIcon';
import { CinemaIcon } from './CinemaIcon';
import { EateryIcon } from './EateryIcon';
import { FoodBeverageIcon } from './FoodBeverageIcon';
import { GamesIcon } from './GamesIcon';
import { GymIcon } from './GymIcon';
import { LaundryIcon } from './LaundryIcon';
import { ParkingIcon } from './ParkingIcon';
import { PoolIcon } from './PoolIcon';
import { RooftopIcon } from './RooftopIcon';
import { TvRoomIcon } from './TvRoomIcon';
import { WifiIcon } from './WifiIcon';
import { YogaIcon } from './YogaIcon';

const Facilityicons = new Map<string, typeof Icon>([
  ['bars', BarsIcon],
  ['bikerental', BikeRentalIcon],
  ['cinema', CinemaIcon],
  ['eatery', EateryIcon],
  ['foodbeverage', FoodBeverageIcon],
  ['games', GamesIcon],
  ['gym', GymIcon],
  ['laundry', LaundryIcon],
  ['parking', ParkingIcon],
  ['pool', PoolIcon],
  ['rooftop', RooftopIcon],
  ['tvroom', TvRoomIcon],
  ['wifi', WifiIcon],
  ['yoga', YogaIcon],
]);

export default Facilityicons;

export * from './BarsIcon';
export * from './BikeRentalIcon';
export * from './CinemaIcon';
export * from './EateryIcon';
export * from './FoodBeverageIcon';
export * from './GamesIcon';
export * from './GymIcon';
export * from './LaundryIcon';
export * from './ParkingIcon';
export * from './PoolIcon';
export * from './RooftopIcon';
export * from './TvRoomIcon';
export * from './WifiIcon';
export * from './YogaIcon';
