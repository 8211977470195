import { Icon, IconProps } from '@chakra-ui/react';
import { ReactElement } from 'react';

export const WifiIcon = (props: IconProps): ReactElement => {
  return (
    <Icon {...props} viewBox="0 0 35 35">
      <path
        d="M13.9754 26.9625L13.9755 26.9607C14.0594 25.1844 15.5879 23.8028 17.3966 23.8917C19.2061 23.9806 20.5894 25.4992 20.5005 27.2751C20.4115 29.0545 18.8863 30.4347 17.0793 30.3459C15.2697 30.257 13.8865 28.7384 13.9754 26.9625Z"
        fill="transparent"
        stroke="#212121"
        strokeWidth="1.3"
      />
      <path
        d="M26.4049 18.5038L26.4049 18.5038L26.413 18.5119C26.4739 18.5723 26.5204 18.6185 26.56 18.6619L27.4508 19.6624L27.4507 19.6625L27.4584 19.6708C27.7804 20.0201 27.9383 20.462 27.9124 20.9287C27.886 21.4039 27.6809 21.8369 27.3306 22.1497L27.3282 22.1518C26.5991 22.8095 25.465 22.7525 24.807 22.0357L24.807 22.0356L24.8011 22.0294L23.8755 21.0474C20.7156 17.6005 15.3151 17.3483 11.8312 20.4752L11.8307 20.4756L10.4137 21.75L10.4128 21.7508C9.67898 22.4131 8.53382 22.3581 7.863 21.6238L7.8617 21.6224C7.19518 20.8971 7.24734 19.7715 7.97936 19.1109L7.9798 19.1105L8.79486 18.3736L8.7955 18.373L9.38951 17.8373C9.38964 17.8372 9.38977 17.8371 9.38989 17.837C14.2003 13.513 21.5606 13.6978 26.1507 18.252L26.151 18.2523C26.1811 18.2821 26.2079 18.3062 26.224 18.3206L26.2245 18.321L26.2419 18.3367C26.243 18.3377 26.2438 18.3384 26.2444 18.339L26.4049 18.5038Z"
        fill="transparent"
        stroke="#212121"
        strokeWidth="1.3"
      />
      <path
        d="M32.6781 13.337L32.6891 13.3491L32.7008 13.3607L32.7018 13.3617L33.4652 14.1938C33.4653 14.1939 33.4653 14.194 33.4654 14.194C34.0449 14.8263 34.0017 15.8178 33.3529 16.412C32.6986 16.9998 31.691 16.9494 31.1072 16.3127C31.1071 16.3126 31.1071 16.3126 31.1071 16.3126L30.006 15.1094L29.9997 15.1025L29.9932 15.0958C23.5501 8.44782 12.927 7.97012 5.90819 14.0467C5.867 14.0786 5.83743 14.1087 5.81961 14.1283L4.31153 15.4837L4.31097 15.4842C3.6629 16.068 2.65986 16.0178 2.06406 15.3691C1.47169 14.7183 1.52276 13.7353 2.16664 13.1594L2.16665 13.1594L2.16883 13.1574L3.70249 11.7731L3.70946 11.7684C3.7161 11.764 3.72532 11.7578 3.73559 11.7506C3.75652 11.736 3.78444 11.7156 3.81526 11.6894L3.81528 11.6894L3.81977 11.6855C11.9184 4.67836 24.0961 5.0504 31.7438 12.3887L31.7788 12.4222L31.8183 12.4502C31.9133 12.5174 31.9948 12.5873 32.064 12.6635L32.0648 12.6644L32.6781 13.337Z"
        fill="transparent"
        stroke="#212121"
        strokeWidth="1.3"
      />
    </Icon>
  );
};
