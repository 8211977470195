import React, { ReactElement, useEffect, useState } from 'react';
import { X } from 'tabler-icons-react';
import { useBookingMenuStore, useChangeBookingStore } from '../../../../store';
import classes from '../BookingStickyMenuSteps.module.scss';
import customClasses from './BookingStickyMenuChange.module.scss';
import { useI18n } from 'next-localization';
import ParentBox from 'components/booking/RoomPickerBoxes/ParentBox/ParentBox';
import { Heading } from '@chakra-ui/react';
import { CancelData, CancelExtraPackage1, CancelExtraPackage2 } from 'src/store/booking-store-modules';
import { shortDateFormat } from 'lib/booking-box-helpers';
import { subDays } from 'date-fns';

interface IFormattedDates {
  checkIn: string;
  checkOut: string;
  cancelDate: string;
}

const BookingStickyMenuChange: React.FC = (): ReactElement => {
  const { t, locale } = useI18n();
  const { changeStepOpen, setChangeStepOpen, setModalOpen } = useBookingMenuStore((state) => state);
  const { changeReservationDetails, setChangeReservationDates } = useChangeBookingStore((state) => state);

  const [formattedDates, setFormattedDates] = useState<IFormattedDates>();
  const [cancelData, setCancelData] = useState<CancelData>();

  const closeChangeAndModalHandler = () => {
    setModalOpen(false);
    setChangeStepOpen(false);
  };

  useEffect(() => {
    if (changeReservationDetails) {
      setChangeReservationDates({
        checkIn: new Date(changeReservationDetails.guestArrival),
        checkOut: new Date(changeReservationDetails.guestDeparture),
      });
      const formattedCheckin = shortDateFormat(changeReservationDetails.guestArrival, locale());
      const formattedCheckout = shortDateFormat(changeReservationDetails.guestDeparture, locale());
      const formattedCancelDate = shortDateFormat(
        subDays(new Date(changeReservationDetails.cancelDeadline), 1),
        locale()
      );
      setFormattedDates({
        checkIn: formattedCheckin,
        checkOut: formattedCheckout,
        cancelDate: formattedCancelDate,
      });

      let extraPackage2Price = 0;
      const extraPackages1: CancelExtraPackage1[] = [];

      changeReservationDetails.packages.forEach((packageItem) => {
        if (packageItem.code === 'BUAD' || packageItem.code === 'BUCH') {
          extraPackage2Price += packageItem.price;
        } else {
          extraPackages1.push({
            code: packageItem.code,
            name: packageItem.name,
            price: packageItem.price,
          });
        }
      });

      const extraPackage2: CancelExtraPackage2 = {
        numAdults: parseInt(changeReservationDetails.noOfAdults),
        // numChildren: parseInt(changeReservationDetails.noOfChildren),
        numChildren: parseInt(changeReservationDetails.noOfChildren),
        price: extraPackage2Price,
      };

      const cancelDetails: CancelData = {
        guestNum: changeReservationDetails.guestNum,
        roomType: changeReservationDetails.roomType,
        numberOfGuests: parseInt(changeReservationDetails.noOfAdults) + parseInt(changeReservationDetails.noOfChildren),
        dates: {
          checkIn: changeReservationDetails.guestArrival,
          checkOut: changeReservationDetails.guestDeparture,
        },
        roomPrice: changeReservationDetails.roomPrice,
        totalPrice: changeReservationDetails.totalAmount,
        extraPackages1: extraPackages1,
        extraPackage2: extraPackage2,
        firstName: changeReservationDetails.guestFirstname,
        lastName: changeReservationDetails.guestName,
        email: changeReservationDetails.email,
      };
      setCancelData(cancelDetails);
    }
  }, [changeReservationDetails]);

  return (
    <div
      className={`${classes.BookingStickyMenuStep} ${customClasses.BookingStickyMenuRoomChangeStep} ${
        changeStepOpen ? classes.BookingStickyMenuStep_Active : classes.BookingStickyMenuStep_NotActive
      }`}
    >
      <div className={classes.Header}>
        <div>{t('booking_menu_change_headline')}</div>
        <X onClick={closeChangeAndModalHandler} />
      </div>
      {changeReservationDetails && (
        <div className={`${classes.Container} ${customClasses.Container}`}>
          <div className={customClasses.reservationInfo}>
            <Heading p={'5px'} size={'md'}>
              {t('booking_change_content_headline')}
            </Heading>
            <p>
              {t('booking_change_content_text')} {formattedDates?.cancelDate} 23:59
            </p>
            <p>
              {t('booking_change_reservation_number')} : {changeReservationDetails.guestNum}
            </p>
          </div>
          <ParentBox
            roomBox={false}
            title={`${t('booking_cancel_box_headline')} - ${changeReservationDetails.guestNum}`}
            roomBoxType="Cancel"
            key={'cancelBox'}
            ParentData={cancelData}
          />
          {/* <ParentBox
            roomBox={false}
            title={`${t('booking_change_box_headline')} - ${changeReservationDetails.guestNum}`}
            roomBoxType="Change"
            key={'changeBox'}
            ParentData={cancelData}
          /> */}
        </div>
      )}
    </div>
  );
};
export default BookingStickyMenuChange;
