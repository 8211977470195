import { Box, Link, useMultiStyleConfig } from '@chakra-ui/react';
import { Field, Text, withDatasourceCheck, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { IDroptreeColor } from '../../../Component-models/data-items/IDroptreeColor';

import { ComponentProps } from 'lib/component-props';
import { CardType } from '../../../Component-models/CardTypeModel';
import ProductCardItem from './ProductCardItem';

type ProductCardListProps = ComponentProps & {
  fields: {
    GlobalCardTextColor: IDroptreeColor;
    showAnchorsOnMobile: Field<boolean>;
    children: Array<CardType>;
  };
};

const ProductCardList = (props: ProductCardListProps) => {
  const { GlobalCardTextColor, showAnchorsOnMobile, children } = props.fields;
  const styles = useMultiStyleConfig('ProductCardList', {});
  const { sitecoreContext } = useSitecoreContext();
  const isEditing = sitecoreContext && sitecoreContext.pageEditing;

  //css scrollBehavior does not work, use this instead
  const smoothScroll = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    const target = e.target as HTMLAnchorElement;
    const href = target.getAttribute('href');
    if (!href) return;
    const navElement = document.getElementById(href);
    const offsetTop = navElement?.offsetTop;

    scroll({
      top: offsetTop,
      behavior: 'smooth',
    });
  };

  return (
    <Box className={`ahhg-product-card-list ${props.params.GridParameters}`} __css={styles.container}>
      {/* if isEditing and children is empty show text */}
      {isEditing && !children ? (
        <Text
          __css={styles.anchorItem}
          field={{
            value: 'Add a Product Card Item to this Product Card List',
          }}
        />
      ) : null}
      <Box
        __css={{
          ...styles.anchorsContainer,
          display: { base: showAnchorsOnMobile?.value ? 'flex' : 'none', lg: 'none' },
        }}
      >
        {children &&
          children.map((child, index) => (
            <Box
              key={index + child.fields.headline.value}
              __css={{
                ...styles.anchorItem,
                color: GlobalCardTextColor?.fields.colorcode.value ?? styles.anchorItem.color,
                borderColor: GlobalCardTextColor?.fields.colorcode.value ?? styles.anchorItem.color,
              }}
            >
              <Link flexGrow={1} display="flex" href={`${child.fields.headline.value}`} onClick={smoothScroll}>
                <Text field={child.fields.headline} />
              </Link>
            </Box>
          ))}
      </Box>
      <Box __css={styles.cardContainer}>
        {children &&
          children.map((child, index) => (
            <ProductCardItem
              key={index + child.fields.headline.value}
              fields={{ ...child.fields, GlobalCardTextColor }}
              params={props.params}
              rendering={props.rendering}
            />
          ))}
      </Box>
    </Box>
  );
};

export default withDatasourceCheck()<ProductCardListProps>(ProductCardList);
