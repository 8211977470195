import {
  Box,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
  useMultiStyleConfig,
  Button,
  useBreakpointValue,
  Flex,
  Grid,
  GridItem,
} from '@chakra-ui/react';
import { NextImage } from '@sitecore-jss/sitecore-jss-nextjs';
import Image from 'next/image';
import { DigizuiteImage, GetImageUrlFromString, generateBreakpointValues } from 'lib/helpers';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { X } from 'tabler-icons-react';
import NaturalHeightImage from './NaturalHeightImage';
import imageSizeData from 'public/image-sizes.json';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  imageIndex: number;
  setImageIndex: Dispatch<SetStateAction<number>>;
  gallery: DigizuiteImage[];
  modalBgColor?: string;
}

const ProductCardImageGallery = (props: Props): JSX.Element => {
  const { isOpen, onClose, imageIndex, setImageIndex, gallery, modalBgColor } = props;

  const styles = useMultiStyleConfig('ProductCardGallery', { props });

  // const [selectedImageModal, setSelectedImageModal] = useState(0);
  const [screenWidth, setScreenWidth] = useState(0);
  const [screenOrientation, setScreenOrientation] = useState('');

  useEffect(() => {
    function handleResize() {
      setScreenOrientation(window.innerWidth > window.innerHeight ? 'landscape' : 'portrait');
      setScreenWidth(window.outerWidth);
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const stickyMenu = document.getElementById('stickyMenu');
    if (stickyMenu) {
      setTimeout(() => {
        /*eslint-disable */
        if (isOpen) {
          document.body.style.overflow = 'hidden';
          stickyMenu.style.display = 'none';
        } else {
          document.body.style.overflow = '';
          stickyMenu.style.display = 'flex';
        }
        return () => {
          document.body.style.overflow = '';
          stickyMenu.style.display = 'flex';
        };
        /*eslint-enable */
      }, 200);
    }
  }, [isOpen]);

  const handleNext = () => {
    if (imageIndex + 1 === gallery.length) return setImageIndex(0);

    setImageIndex((prev) => prev + 1);
  };

  const handlePrevious = () => {
    if (imageIndex === 0) return setImageIndex(gallery.length - 1);

    setImageIndex(imageIndex - 1);
  };

  const caouselModalLandskabe = generateBreakpointValues(imageSizeData.productcarouselmodal);

  const caouselImageBP = generateBreakpointValues(imageSizeData.imagegallerydesktopparams);
  const caouselModalBigimage = useBreakpointValue(caouselImageBP, { fallback: 'lg' });
  const _ModalSmallBP = generateBreakpointValues(imageSizeData.imagegallerytmodalthump);
  const ModalThumpnail = useBreakpointValue(_ModalSmallBP, { fallback: 'lg' });

  return (
    <>
      {isOpen && screenWidth < 1024 && screenOrientation === 'portrait' && (
        <Flex
          className="ahhg-product-gallery--modal-mobile-portrait"
          bg={modalBgColor ?? 'white'}
          justify="center"
          top="0"
          align="center"
          position="fixed"
          w="100%"
          h="100%"
          zIndex="999"
          direction="column"
          overflowY="scroll"
          mt="0 !important"
          p="12px 12px"
          left="0"
        >
          <Grid height={'100%'} width={'100%'}>
            {gallery.map((item, index) => (
              <GridItem className="ahhg-productcardgallery--portrait" key={index} width="100%">
                <NaturalHeightImage item={item} customSizeAndRenderingIds={caouselModalLandskabe} />
                <Text fontFamily="Inter" mx="10px" mb="24px">
                  {item.Alt}
                </Text>
              </GridItem>
            ))}
          </Grid>
          <Flex position="fixed" bottom="1.25rem" w="100%" justify="center">
            <Box
              borderRadius="50%"
              background="rgba(0,0,0)"
              cursor="pointer"
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="55px"
              height="55px"
              zIndex="20"
              mx="1rem"
              onClick={onClose}
            >
              <X size={30} strokeWidth={2} color={'white'} />
            </Box>
          </Flex>
        </Flex>
      )}
      {/* ONG-4227 changed tablet view */}
      {isOpen && screenWidth < 1024 && screenOrientation === 'landscape' && (
        <Flex
          className="ahhg-product-gallery--modal-mobile-landscape"
          bg={modalBgColor ?? 'white'}
          justify="center"
          top="0"
          align="center"
          position="fixed"
          w="100%"
          h="100%"
          zIndex="999"
          direction="column"
          overflowY="scroll"
          mt="0 !important"
          p="12px 12px"
          left="0"
        >
          <Grid height={'100%'} width={'100%'}>
            {gallery.map((item, index) => (
              <GridItem className="ahhg-productcardgallery--landscape" key={index} width="100%">
                <NaturalHeightImage item={item} customSizeAndRenderingIds={caouselModalLandskabe} />
                {item && (
                  <Text fontFamily="Inter" mx="10px" mb="24px">
                    {item.Alt}
                  </Text>
                )}
              </GridItem>
            ))}
          </Grid>
          <Flex position="fixed" bottom="1.25rem" w="100%" justify="center">
            <Box
              borderRadius="50%"
              background="rgba(0,0,0)"
              cursor="pointer"
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="55px"
              height="55px"
              zIndex="20"
              mx="1rem"
              onClick={onClose}
            >
              <X size={30} strokeWidth={2} color={'white'} />
            </Box>
          </Flex>
        </Flex>
      )}
      {isOpen && screenWidth > 1024 && (
        <Modal isOpen={isOpen} onClose={onClose} size="full" scrollBehavior="inside">
          <ModalOverlay />
          <ModalContent backgroundColor="transparent" padding="1rem" onClick={onClose}>
            <Box __css={styles.container} onClick={(e) => e.stopPropagation()}>
              <Box __css={{ ...styles.exitButtonContainer }} onClick={onClose}>
                <X size={30} strokeWidth={2} color={'white'} />
              </Box>
              <Box __css={styles.counter}>
                <Text>
                  <strong>{imageIndex + 1}</strong> / {gallery.length}
                </Text>
              </Box>
              <Box __css={styles.contentContainer}>
                <Box __css={styles.mainCarousel}>
                  <Carousel
                    showArrows
                    showIndicators={false}
                    showStatus={false}
                    width="100%"
                    infiniteLoop
                    selectedItem={imageIndex}
                    renderArrowNext={() => (
                      <Box __css={{ ...styles.arrowNextModalBigContainer }} onClick={handleNext}>
                        <svg width="31" height="21" viewBox="0 0 31 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <line x1="28" y1="10.4178" x2="6.11959e-08" y2="10.4178" stroke="white" strokeWidth="1.4" />
                          <path d="M21 20.418C21 14.8951 25.4772 10.418 31 10.418" stroke="white" strokeWidth="1.4" />
                          <path d="M31 10.418C25.4772 10.418 21 5.94082 21 0.417969" stroke="white" strokeWidth="1.4" />
                        </svg>
                      </Box>
                    )}
                    renderArrowPrev={() => (
                      <Box __css={{ ...styles.arrowPrevModalBigContainer }} onClick={handlePrevious}>
                        <svg width="31" height="21" viewBox="0 0 31 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <line x1="3" y1="10.4182" x2="31" y2="10.4182" stroke="white" strokeWidth="1.4" />
                          <path d="M10 0.417966C10 5.94082 5.52285 10.418 0 10.418" stroke="white" strokeWidth="1.4" />
                          <path
                            d="M-2.38419e-06 10.418C5.52285 10.418 10 14.8951 10 20.418"
                            stroke="white"
                            strokeWidth="1.4"
                          />
                        </svg>
                      </Box>
                    )}
                  >
                    {gallery.map((image, index) => (
                      <Box
                        className="ahhg-productcardgallery--modal-bigimage"
                        width="100%"
                        height="100%"
                        key={index}
                        __css={styles.modalBigImageContainer}
                      >
                        <Image
                          src={GetImageUrlFromString(image.Src, caouselModalBigimage)}
                          fill={true}
                          style={{ objectFit: 'cover' }}
                          placeholder="empty"
                          unoptimized={true}
                          loading="lazy"
                          alt={image.Alt ?? ''}
                          title={image.Title ?? ''}
                        />
                      </Box>
                    ))}
                  </Carousel>
                </Box>
                <Box __css={styles.verticalCarousel}>
                  <Carousel
                    showIndicators={false}
                    showStatus={false}
                    axis="vertical"
                    selectedItem={imageIndex}
                    centerMode
                    onClickItem={(index) => setImageIndex(index)}
                    renderArrowNext={() => (
                      <Button __css={styles.arrowNextVertical} onClick={handleNext}>
                        <svg width="12" height="18" viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <line
                            x1="6.00002"
                            y1="15.8184"
                            x2="6.00002"
                            y2="0.54563"
                            stroke="white"
                            strokeWidth="0.763636"
                          />
                          <path d="M0.545453 12C3.55792 12 6 14.4421 6 17.4545" stroke="white" strokeWidth="0.763636" />
                          <path d="M6 17.4545C6 14.4421 8.44208 12 11.4545 12" stroke="white" strokeWidth="0.763636" />
                        </svg>{' '}
                      </Button>
                    )}
                    renderArrowPrev={() => (
                      <Button __css={styles.arrowPrevVertical} onClick={handlePrevious}>
                        <svg width="12" height="18" viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <line
                            x1="5.99998"
                            y1="2.18164"
                            x2="5.99998"
                            y2="17.4544"
                            stroke="white"
                            strokeWidth="0.763636"
                          />
                          <path d="M11.4545 6C8.44208 6 6 3.55792 6 0.545454" stroke="white" strokeWidth="0.763636" />
                          <path d="M6 0.545453C6 3.55792 3.55792 6 0.545454 6" stroke="white" strokeWidth="0.763636" />
                        </svg>
                      </Button>
                    )}
                  >
                    {gallery.map((image, index) => (
                      <Box
                        __css={styles.verticalCarouselImage}
                        className="verticalCarouselImage ahhg-modal-thumpnail"
                        key={index}
                        //add custom border for nex and ste
                        sx={imageIndex === index ? styles.verticalCarouselImageActive : undefined}
                        marginBottom="1rem"
                        borderRadius={'10px'}
                      >
                        <NextImage
                          key={index}
                          field={{
                            src: GetImageUrlFromString(image.Src, ModalThumpnail),
                            alt: image.Alt,
                            title: image.Title,
                          }}
                          fill={true}
                          style={{ objectFit: 'cover' }}
                          placeholder="empty"
                          unoptimized={true}
                        />
                      </Box>
                    ))}
                  </Carousel>
                </Box>
              </Box>
            </Box>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default ProductCardImageGallery;
