import React, { useEffect } from 'react';
import Marquee from 'react-fast-marquee';
import { Box, useMultiStyleConfig } from '@chakra-ui/react';
import { Field, Text, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';

import { ComponentProps } from 'lib/component-props';
import { IDroptreeColor } from '../../../Component-models/data-items/IDroptreeColor';

type TickerProps = ComponentProps & {
  fields: {
    /**
     * Text to display in ticker.
     */
    text: Field<string>;
    /**
     * Text color.
     */
    color?: IDroptreeColor;
    /**
     * Set text to uppercase.
     */
    isUppercase?: Field<boolean>;
    /**
     * Speed calculated as pixels/second (default 60 px/sec).
     */
    speed?: Field<number>;
    /**
     * Number of seconds before starting the ticker (default 1, to wait for webfonts).
     */
    delay?: Field<number>;

    /**
     * Number of times the ticker loops (0 = infinite).
     */
    loop?: Field<number>;
    /**
     * Loop direction.
     */
    direction?: Field<boolean>;
  };
};

/**
 * Simple ticker / marquee component.
 * @see https://github.com/justin-chu/react-fast-marquee
 * @param props TickerProps
 * @returns Ticker
 */
const Ticker: React.FC<TickerProps> = (props: TickerProps) => {
  const { direction, speed, delay, loop, color, isUppercase, text } = props.fields;

  const isDirectionLeft = direction?.value ?? true;
  const topMargin = props.params.Styles !== undefined && props.params.Styles.includes('top-margin');

  const styles = useMultiStyleConfig('Ticker', {});

  const [fadeColor, setFadeColor] = React.useState<number[]>([]);

  useEffect(() => {
    //get closest parent of "ahhg-ticker" with class "hostel-container"
    const hostelContainer = document.querySelector('.ahhg-ticker')?.closest('.hostel-container');
    //get background color of hostel-container
    if (hostelContainer) {
      //returns rgb(xxx, xxx, xxx)
      const hostelContainerBgColor = window.getComputedStyle(hostelContainer).backgroundColor;
      //convert rgb(xxx, xxx, xxx) to [xxx, xxx, xxx]
      const hostelContainerBgColorArray = hostelContainerBgColor
        .substring(4, hostelContainerBgColor.length - 1)
        .split(', ')
        .map((item) => parseInt(item));
      setFadeColor(hostelContainerBgColorArray);
    }
  }, []);

  return (
    <Box
      className={`ahhg-ticker ${props.params.GridParameters ?? ''} ${topMargin ? 'top-margin' : ''}`}
      __css={styles.container}
    >
      <Marquee
        gradient={fadeColor.length > 0 ? true : false}
        gradientColor={fadeColor as [number, number, number]}
        speed={speed?.value ?? 60}
        delay={delay?.value ?? 1}
        loop={loop?.value ?? 0}
        direction={isDirectionLeft ? 'left' : 'right'}
        style={{ overflowY: 'hidden' }}
      >
        <Box
          __css={{
            paddingRight: { base: '14px', lg: '3.5rem' },
            color: color?.fields.colorcode.value ?? 'black',
            textTransform: isUppercase?.value ? 'uppercase' : 'initial',
            fontFamily: 'heading',
            fontSize: { base: isUppercase?.value ? '60px' : '70px', md: '120px', lg: '200px' },
            lineHeight: { base: '100px', lg: '325px' },
            marginBottom: { base: '0px', lg: '-30px' },
          }}
        >
          <Text field={{ value: text.value + '  //' }} />
        </Box>
      </Marquee>
    </Box>
  );
};

export default withDatasourceCheck()<TickerProps>(Ticker);
