import React, { ReactElement, useEffect, useState } from 'react';
import classes from '../../ParentBox.module.scss';
import customClasses from './ExtraBox.module.scss';
import { Checkbox } from '@chakra-ui/react';
import { useCartStore, useCurrencyStore } from '../../../../store';
import { PackageBox } from '../../../../store/booking-store-modules';
import { useI18n } from 'next-localization';
import CurrencySymbol from 'components/Currency/CurrencyFormatter/CurrencySymbol';
import CurrencyValue from 'components/Currency/CurrencyFormatter/CurrencyValue';
import { fireEcomDataLayerEvent, transformCurrency } from 'lib/helpers';
import { useGenerateDataLayerObject } from 'lib/customHooks/hooks';

type IProps = {
  ExtraPackage1Data: PackageBox;
};

const ExtraPackage1: React.FC<IProps> = ({ ExtraPackage1Data }): ReactElement => {
  const { t, locale } = useI18n();
  const { setExtraPackageActive, clearExtraPackage, cartItems, setExtraPackage1DetailsActive, package1DetailsList } =
    useCartStore((state) => state);
  const { currentCurrency } = useCurrencyStore((state) => state);

  const packageDescription = t(`booking_extra_package_1_${ExtraPackage1Data.PackageCode}_description`);
  const updatedPackageDescription = transformCurrency(packageDescription, currentCurrency, locale());

  const { PackageCode, PackagePrice } = ExtraPackage1Data;

  const dataLayerObj = useGenerateDataLayerObject('extraPackage', PackageCode, PackagePrice);

  const [extraPackage1Details, setExtraPackage1Details] = useState<PackageBox>({} as PackageBox);
  useEffect(() => {
    if (ExtraPackage1Data) {
      //find the package in package1DetailsList where packageCode === ExtraPackage1Data.PackageCode
      const packageDetails = package1DetailsList.find(
        (packageDetail) => packageDetail.PackageCode === ExtraPackage1Data.PackageCode
      );

      packageDetails && setExtraPackage1Details(packageDetails);
    }
  }, [ExtraPackage1Data, package1DetailsList]);

  const addExtraHandler = () => {
    if (cartItems.length >= 1) {
      if (extraPackage1Details.Active) {
        clearExtraPackage(extraPackage1Details.PackageCode);
        setExtraPackage1DetailsActive(extraPackage1Details.PackageCode, false);
        dataLayerObj && fireEcomDataLayerEvent('remove_from_cart', [dataLayerObj]);
      } else {
        dataLayerObj && fireEcomDataLayerEvent('add_to_cart', [dataLayerObj]);
        //sets extra package on cartItems
        setExtraPackageActive(extraPackage1Details);
        //sets extra package on package1DetailsList
        setExtraPackage1DetailsActive(extraPackage1Details.PackageCode, true);
      }
    }
  };

  return (
    <div className={classes.roomBoxContainer}>
      <div className={`${customClasses.ExtraBox}`}>
        <div className={`${classes.FormControl} ${customClasses.ExtraBoxFormControl}`}>
          <Checkbox
            variant="custom-control"
            value="restricted"
            colorScheme="darkGrey"
            name="flexibleBooking"
            size="lg"
            isChecked={extraPackage1Details.Active === true}
            onChange={addExtraHandler}
          >
            {t(`booking_extra_package_1_${PackageCode}_label`)}
            <p>{updatedPackageDescription}</p>
          </Checkbox>
        </div>
      </div>
      <div className={classes.roomPrice}>
        <p>{t('booking_extra_per_person_per_night_label')}</p>
        <h3>
          <CurrencySymbol></CurrencySymbol>
          <CurrencyValue value={PackagePrice}></CurrencyValue>
        </h3>
      </div>
    </div>
  );
};
export default ExtraPackage1;
