import React from 'react';
import { Button as CButton } from '@chakra-ui/react';

interface ButtonProps {
  /**
   * Button variants with subtle differences?
   */
  variant?: 'solid' | 'outline';
  /**
   * How large should the button be?
   */
  size?: 'xs' | 'sm' | 'lg' | 'wide';
  /**
   * Button label
   */
  label: string;
  /**
   * Optional click handler
   */
  onClick?: () => void;

  /**
   * Optional styling
   */

  styling?: Record<string, unknown>;
}

/**
 * Primary UI component for user interaction
 */
const Button: React.FC<ButtonProps> = ({ variant = 'solid', size = 'sm', label, ...props }: ButtonProps) => {
  return (
    <CButton type="button" size={size} variant={variant} {...props} sx={props.styling}>
      <span>{label}</span>
    </CButton>
  );
};

export default Button;
