import React, { ReactElement, useEffect, useState } from 'react';
import { X } from 'tabler-icons-react';
import {
  useBookingMenuStore,
  useDormStore,
  useCartStore,
  useCompanyStore,
  useBookingMenuDatesStore,
  useSitecoreFieldsStore,
  useGeneralStore,
} from '../../../../store';
import ParentBox from '../../RoomPickerBoxes/ParentBox/ParentBox';
import classes from '../BookingStickyMenuSteps.module.scss';
import customClasses from './BookingStickyMenuRoomPicker.module.scss';
import Cart from '../../Cart/Cart';
import { Slide, Spinner } from '@chakra-ui/react';
import { useI18n } from 'next-localization';
import BookingChangeTrigger from '../BookingChangeTrigger/BookingChangeTrigger';
import { generateRoomPickerOrder } from 'lib/booking-box-helpers';

const BookingStickyMenuRoomPicker: React.FC = (): ReactElement => {
  const { t } = useI18n();
  const currentTheme = process.env.NEXT_PUBLIC_HOSTEL_THEME;

  const { DormRooms, DormsData } = useDormStore((state) => state);
  const { companyActive, loggedIn } = useCompanyStore((state) => state);
  const { loading, roomPickerOpen, setRoomPickerOpen, setModalOpen } = useBookingMenuStore((state) => state);
  const { menuFetchingDates } = useBookingMenuDatesStore((state) => state);
  const { cartItems, setCartFetchingDates, setExtraPackage2Checked } = useCartStore((state) => state);
  const { sitecoreFields } = useSitecoreFieldsStore((state) => state);
  const { isDesktop } = useGeneralStore((state) => state);

  const [displayCart, setDisplayCart] = useState(false);
  const [displayCompany, setDisplayCompany] = useState(false);

  const closeRoomPickerAndModalHandler = () => {
    setModalOpen(false);
    setRoomPickerOpen(false);
  };

  const checkIfAllOffersSoldOut = () => {
    let allSoldOut = true;
    Object.values(DormRooms!['Offers']).forEach((offer) => {
      if (offer[0].Availability > 0) {
        allSoldOut = false;
      }
    });
    return allSoldOut;
  };

  const roomPickerContent: ReactElement[] = [];

  const roomPickerOrder = generateRoomPickerOrder(sitecoreFields.BlockOrder);

  if (roomPickerContent && DormsData) {
    roomPickerOrder.forEach((item) => {
      if (item.key === 'dorms1') {
        roomPickerContent.push(
          <ParentBox
            roomBox={true}
            roomBoxType={item.roomBoxType}
            title={t(item.title)}
            key={item.roomBoxType}
            extraBox={item.extraBox}
            ParentData={DormRooms?.Dorms}
          />
        );
        return;
      }
      if (item.key === 'rooms2') {
        roomPickerContent.push(
          <ParentBox
            roomBox={true}
            roomBoxType={item.roomBoxType}
            title={t(item.title)}
            key={item.roomBoxType}
            extraBox={item.extraBox}
            ParentData={DormRooms?.Rooms}
          />
        );
        return;
      }
      if (item.key === 'offers3') {
        if (checkIfAllOffersSoldOut()) return;
        roomPickerContent.push(
          <ParentBox
            roomBox={true}
            roomBoxType={item.roomBoxType}
            title={t(item.title)}
            key={item.roomBoxType}
            extraBox={item.extraBox}
            ParentData={DormRooms?.Offers}
          />
        );
        return;
      }
      if (item.key === 'flexibilityBox') {
        if (!companyActive && displayCompany) {
          roomPickerContent.push(
            <ParentBox
              roomBox={item.roomBox}
              roomBoxType={item.roomBoxType}
              title={t(item.title)}
              key={item.roomBoxType}
              extraBox={item.extraBox}
            />
          );
        }
        return;
      }
      if (item.key === 'extraBox' && DormsData.Packages.length === 0) return;
      roomPickerContent.push(
        <ParentBox
          roomBox={item.roomBox}
          roomBoxType={item.roomBoxType}
          title={t(item.title)}
          key={item.roomBoxType}
          extraBox={item.extraBox}
          ParentData={item.key === 'extraBox' ? DormsData.Packages : undefined}
        />
      );
    });
  }

  useEffect(() => {
    useCartStore.persist.hasHydrated() && setDisplayCart(true);
    useCompanyStore.persist.hasHydrated() && setDisplayCompany(true);

    if (cartItems.length === 0) {
      setCartFetchingDates(menuFetchingDates);
      setExtraPackage2Checked(false);
    }
  }, [menuFetchingDates]);

  const roomPickerContainer = (
    <div
      className={`${classes.BookingStickyMenuStep} ${customClasses.BookingStickyMenuRoomPickerStep} ${
        roomPickerOpen ? classes.BookingStickyMenuStep_Active : classes.BookingStickyMenuStep_NotActive
      } ${loading && customClasses.BookingStickyMenuRoomPickerStep_Loading}`}
    >
      <div className={classes.Header}>
        <div>Booking</div>
        <X onClick={closeRoomPickerAndModalHandler} />
      </div>
      {!loading && <BookingChangeTrigger />}

      <div className={`${classes.Container} ${customClasses.RoomPickerContainer}`}>
        {loading ? (
          <Spinner size={'xl'} color={currentTheme === 'nexthouse' ? 'green.200' : 'yellow.500'} />
        ) : (
          <>
            <div className={customClasses.RoomBoxContent}>
              {displayCompany && loggedIn && (
                <ParentBox
                  roomBox={false}
                  title={t('booking_company_headline')}
                  roomBoxType="CompanyRates"
                  key={'companyRatesBox'}
                />
              )}
              {roomPickerContent}
            </div>
            <div className={customClasses.CartContent}>{displayCart && <Cart />}</div>
          </>
        )}
      </div>
    </div>
  );

  //use ChakraSlideHide class instead of ternary, because ternary gives slow animation on datepickerOpen on desktop
  return isDesktop ? (
    <Slide
      direction="left"
      in={roomPickerOpen}
      className={`${customClasses.ChakraSlide} ${roomPickerOpen ? '' : customClasses.ChakraSlideHide}`}
    >
      {roomPickerContainer}
    </Slide>
  ) : (
    roomPickerContainer
  );
};
export default BookingStickyMenuRoomPicker;
