// Path: src\HeadMeta.tsx

import { Field, RouteData, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { HeadGlobalTracking } from './Component-models/GlobalTrackingQL';
import { HeadPageonlyTracking } from './Component-models/TrackingScripts';
import { useRouter } from 'next/router';
import Head from 'next/head';
import parse from 'html-react-parser';
import React, { useMemo } from 'react';
import { IbaseImage } from './Component-models/base-items/IbaseImage';
import { GlobalMetaQL } from './Component-models/GlobalMetaQL';
import { LanguageUrls } from './Component-models/LanguageUrlsModel';
import Script from 'next/script';
import { assignScripts } from 'lib/helpers';

interface RouteFields {
  [key: string]: unknown;
  HeadPageonlyTracking: HeadPageonlyTracking[];
  pageTitle: Field;
  description: Field;
  keywords: Field;
  canonical: Field;
  noIndex: Field<boolean>;
  noFollow: Field<boolean>;
  //socialMedia fields
  ogtitle: Field;
  ogdescription: Field;
  ogimage: IbaseImage;
  ogsite_name: Field;
  fbpages: Field;
  twittercard: Field;
  twittertitle: Field;
  twitterdescription: Field;
  twitterimage: IbaseImage;
}

// Component which recieves fields and publicUrl from Layout.tsx
// globalscript, <--- add this to props // prettier
const HeadMeta = ({
  route,
  publicUrl,
  globalMeta,
  langUrls,
  globalscript,
}: {
  route: RouteData;
  publicUrl: string;
  globalscript: HeadGlobalTracking;
  globalMeta: GlobalMetaQL;
  langUrls: LanguageUrls;
}): JSX.Element => {
  const router = useRouter();
  const { sitecoreContext } = useSitecoreContext();

  const fields = route?.fields as RouteFields;
  // destruct globalMeta

  const allScripts = useMemo(() => {
    return assignScripts(globalscript.jsonValue, fields.HeadPageonlyTracking);
  }, [fields.HeadPageonlyTracking, globalscript.jsonValue]);

  if (!globalMeta || !globalMeta.item) {
    // Handle the case where globalMeta or globalMeta.item is undefined
    return <></>;
  }

  const { fbpages, ogimage, ogsitename, twitterimage } = globalMeta.item;

  const altCanonicalLink = publicUrl + (router.locale == 'da' ? '/da' : '') + router.asPath;
  const canonicalLink = fields?.canonical?.value?.toString() ? fields.canonical?.value.toString() : altCanonicalLink;
  const sanitizedCanonicalLink = canonicalLink.split('?')[0];
  const envPublicUrl = process.env.PUBLIC_URL;

  const isEditing = sitecoreContext && sitecoreContext.pageEditing;
  // const ogLocaleAlternate = the language from langUrls which is note router.locale
  const ogLocaleAlternate = Object.entries(langUrls).filter(([language]) => language !== router.locale);

  // https://youtrack.arp-hansen.dk/issue/ONG-3960/Hostels-Scripts-Script-listen-i-Sitecore-skal-virke-igen
  // for now moved to : src\components\HardcodedData.tsx

  if (isEditing) {
    return <></>;
  } else {
    return (
      <>
        <Head>
          {/* https://youtrack.arp-hansen.dk/issue/HR-306/Sideskabelon */}
          {/* https://confluence.arp-hansen.dk/display/AR/Meta-tags */}

          <title>{fields?.pageTitle?.value?.toString() || 'Page'}</title>
          <meta name="description" content={fields?.description?.value.toString()} />
          {fields?.keywords?.value?.toString() && <meta name="keywords" content={fields?.keywords?.value.toString()} />}
          {/* if link ends in /, remove it */}
          <link
            rel="canonical"
            href={
              sanitizedCanonicalLink.charAt(sanitizedCanonicalLink.length - 1) === '/'
                ? sanitizedCanonicalLink.substring(0, sanitizedCanonicalLink.length - 1)
                : sanitizedCanonicalLink
            }
          />
          <meta
            name="robots"
            content={`${fields?.noIndex?.value ? 'noindex' : 'index'}, ${
              fields?.noFollow?.value ? 'nofollow' : 'follow'
            }`}
          />

          {/* MetaTags for Social Media - OG tags, Twitter */}
          <meta
            property="og:title"
            content={fields?.ogtitle?.value?.toString() || fields?.pageTitle?.value.toString()}
          />
          <meta
            property="og:description"
            content={fields?.ogdescription?.value?.toString() || fields?.description?.value.toString()}
          />
          {fields?.ogimage?.value?.src ? (
            <meta property="og:image" content={fields?.ogimage?.value?.src} />
          ) : (
            <meta property="og:image" content={ogimage?.jsonValue.value.src} />
          )}
          <meta property="og:url" content={altCanonicalLink.split('?')[0]} />
          {fields?.ogsite_name?.value?.toString() ? (
            <meta property="og:site_name" content={fields?.ogsite_name?.value?.toString()} />
          ) : (
            <meta property="og:site_name" content={ogsitename?.jsonValue.value} />
          )}
          <meta property="og:type" content="website" />

          {/* https://youtrack.arp-hansen.dk/issue/ONG-3815/Alle-sites-Sideskabelon-Meta-data-Tilfojelse-til-OG-tags */}
          {/* comment change da, da_DK and en, en_GB */}

          <meta property="og:locale" content={router.locale === 'da' ? 'da_DK' : 'en_GB'} />
          {
            //check if ogLocaleAlternate is not empty
            ogLocaleAlternate?.[0]?.[0] ? (
              <meta property="og:locale:alternate" content={ogLocaleAlternate[0][0] === 'da' ? 'da_DK' : 'en_GB'} />
            ) : null
          }

          <meta name="twitter:card" content="summary_large_image" />
          {fields.twittertitle?.value?.toString() ? (
            <meta name="twitter:title" content={fields.twittertitle?.value?.toString()} />
          ) : (
            <meta name="twitter:title" content={fields?.pageTitle?.value?.toString() || 'Page'} />
          )}
          {fields.twitterdescription?.value?.toString() ? (
            <meta name="twitter:description" content={fields.twitterdescription?.value?.toString()} />
          ) : (
            <meta name="twitter:description" content={fields?.description?.value?.toString()} />
          )}
          {fields.twitterimage?.value?.src ? (
            <meta name="twitter:image" content={fields.twitterimage?.value?.src} />
          ) : (
            <meta name="twitter:image" content={twitterimage?.jsonValue.value.src} />
          )}
          {fields.fbpages?.value?.toString() || fbpages.jsonValue.value.toString() ? (
            <meta
              property="fb:pages"
              content={fields.fbpages?.value?.toString() || fbpages.jsonValue.value.toString()}
            />
          ) : null}

          {/* Script and HTML inserted by E-COM from Sitecore */}
          {allScripts.metaScripts.map((script, index) => (
            <React.Fragment key={index}>{parse(script)}</React.Fragment>
          ))}
          {allScripts.schemaScripts.map((script, index) => (
            <React.Fragment key={index}>{parse(script)}</React.Fragment>
          ))}

          {allScripts.vwoScripts.map((vwoScript) => (
            <script key={vwoScript.id} id={vwoScript.id} data-nscript="afterInteractive">
              {parse(vwoScript.script)}
            </script>
          ))}

          {/* https://youtrack.arp-hansen.dk/issue/ONG-3945/Hostels-relalternate-mangler */}

          {langUrls &&
            Object.entries(langUrls).map(([language, url]) => (
              <link
                key={`${language}-${url}`}
                rel="alternate"
                hrefLang={language}
                href={`${envPublicUrl}${language === 'da' ? '/da' : ''}${url}`}
              />
            ))}

          {/* FavIcons */}
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href={`${envPublicUrl}/assets/${sitecoreContext.site?.name}/apple-touch-icon.png`}
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href={`${envPublicUrl}/assets/${sitecoreContext.site?.name}/favicon-32x32.png`}
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href={`${envPublicUrl}/assets/${sitecoreContext.site?.name}/favicon-16x16.png`}
          />
          <link rel="manifest" href={`${envPublicUrl}/assets/${sitecoreContext.site?.name}/site.webmanifest`} />
          <link
            rel="mask-icon"
            href={`${envPublicUrl}/assets/${sitecoreContext.site?.name}/safari-pinned-tab.svg`}
            color="#d2ddbb"
          />
          <meta name="msapplication-TileColor" content="#d2ddbb" />
          {/* theme color now dynamic in header */}
          {/* <meta name="theme-color" content="#d2ddbb"></meta> */}
        </Head>
        {allScripts.codeScripts.map((script, index) => (
          <Script key={index} id={`${index}`} strategy="afterInteractive">
            {parse(script)}
          </Script>
        ))}
        {allScripts.srcScripts.map((script, index) => (
          <Script key={index} src={script.src} strategy="afterInteractive" />
        ))}
      </>
    );
  }
};

export default HeadMeta;
