import { Icon, IconProps } from '@chakra-ui/react';
import { ReactElement } from 'react';

export const LaundryIcon = (props: IconProps): ReactElement => {
  return (
    <Icon {...props} viewBox="0 0 35 35">
      <path
        d="M30.6827 34H4.33091C3.61341 34 3.02637 33.45 3.02637 32.7778V6.87891H31.9872V32.7778C31.9872 33.45 31.4002 34 30.6827 34ZM4.07 7.85668V32.7778C4.07 32.9122 4.18741 33.0222 4.33091 33.0222H30.6827C30.8262 33.0222 30.9436 32.9122 30.9436 32.7778V7.85668H4.07Z"
        fill="#212121"
      />
      <path
        d="M32 7.84444H3L3.19568 3.54222C3.26091 2.12444 4.50023 1 6.02654 1H28.9735C30.4998 1 31.7521 2.12444 31.8174 3.54222L32 7.84444ZM4.08277 6.86667H30.9172L30.7737 3.59111C30.7346 2.68667 29.9388 1.97778 28.9735 1.97778H6.02654C5.06118 1.97778 4.27845 2.68667 4.23932 3.59111L4.08277 6.86667Z"
        fill="#212121"
      />
      <path
        d="M7.98309 5.40001C8.55947 5.40001 9.02672 4.96225 9.02672 4.42224C9.02672 3.88222 8.55947 3.44446 7.98309 3.44446C7.4067 3.44446 6.93945 3.88222 6.93945 4.42224C6.93945 4.96225 7.4067 5.40001 7.98309 5.40001Z"
        fill="#212121"
      />
      <path
        d="M11.5055 5.40001C12.0819 5.40001 12.5492 4.96225 12.5492 4.42224C12.5492 3.88222 12.0819 3.44446 11.5055 3.44446C10.9292 3.44446 10.4619 3.88222 10.4619 4.42224C10.4619 4.96225 10.9292 5.40001 11.5055 5.40001Z"
        fill="#212121"
      />
      <path
        d="M25.986 5.40001C26.5624 5.40001 27.0297 4.96225 27.0297 4.42224C27.0297 3.88222 26.5624 3.44446 25.986 3.44446C25.4096 3.44446 24.9424 3.88222 24.9424 4.42224C24.9424 4.96225 25.4096 5.40001 25.986 5.40001Z"
        fill="#212121"
      />
      <path
        d="M17.5063 28.9889C12.0403 28.9889 7.5918 24.8211 7.5918 19.7C7.5918 14.5789 12.0403 10.4111 17.5063 10.4111C22.9724 10.4111 27.4209 14.5789 27.4209 19.7C27.4209 24.8211 22.9724 28.9889 17.5063 28.9889ZM17.5063 11.3889C12.6143 11.3889 8.63543 15.1167 8.63543 19.7C8.63543 24.2834 12.6143 28.0111 17.5063 28.0111C22.3984 28.0111 26.3772 24.2834 26.3772 19.7C26.3772 15.1167 22.3984 11.3889 17.5063 11.3889Z"
        fill="#212121"
      />
      <path
        d="M17.572 26.7889C15.7978 26.7889 14.0628 26.1901 12.7191 25.1145C11.6233 24.2345 11.3493 22.7923 12.0668 21.6556C12.3799 21.1667 12.5495 20.5189 12.5495 19.8223C12.5495 19.1256 12.3799 18.4656 12.0538 17.9767C11.3232 16.84 11.545 15.3856 12.5756 14.5178C14.0106 13.3078 15.8761 12.6845 17.8068 12.7334C21.7204 12.8556 24.9035 15.8134 25.0601 19.4678C25.1384 21.3989 24.3948 23.2323 22.9728 24.6256C21.5509 26.0189 19.6332 26.7889 17.572 26.7889ZM17.572 13.7112C15.9805 13.7112 14.4541 14.2489 13.267 15.2512C12.6017 15.8012 12.4712 16.7423 12.9409 17.4634C13.3583 18.1112 13.5801 18.9423 13.5801 19.8223C13.5801 20.6901 13.3583 21.5212 12.9409 22.1567C12.4973 22.8656 12.68 23.8067 13.3714 24.3689C14.5324 25.2978 16.0196 25.8112 17.559 25.8112C19.3331 25.8112 20.9899 25.1512 22.2162 23.9534C23.4424 22.7556 24.0817 21.1789 24.0164 19.5167C23.886 16.3634 21.1465 13.8212 17.7807 13.7112C17.7025 13.7112 17.6372 13.7112 17.572 13.7112Z"
        fill="#212121"
      />
    </Icon>
  );
};
