import { Flex, useMultiStyleConfig, useTheme } from '@chakra-ui/react';
import React, { ReactElement, PropsWithChildren } from 'react';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';

interface IProps {
  backgroundColor: string;
  reverseColumn: number | string | undefined;
  isFluidContainer: boolean;
  noSpacingTop: boolean;
  noSpacingBottom: boolean;
  maxDesktopWidth: boolean;
}

const HostelContainerWrapper: React.FC<PropsWithChildren<IProps>> = ({
  backgroundColor,
  reverseColumn,
  isFluidContainer,
  noSpacingTop,
  noSpacingBottom,
  maxDesktopWidth,
  children,
}): ReactElement => {
  const styles = useMultiStyleConfig('HostelContainer', {});
  const theme = useTheme();
  const { sitecoreContext } = useSitecoreContext();
  const isEditing = sitecoreContext && sitecoreContext.pageEditing;

  // use regex to get the part before and after '.' from backgroundColor
  const bgColor = theme['colors'][backgroundColor.split('.')[0]][backgroundColor.split('.')[1]];

  return isEditing ? (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        width: maxDesktopWidth ? '900px' : '100%',
        margin: '0 auto',
        backgroundColor: bgColor,
      }}
    >
      {children}
    </div>
  ) : (
    <Flex __css={{ ...styles.container }} className="hostel-container" bgColor={backgroundColor}>
      <Flex
        flexDirection={reverseColumn === '1' ? { base: 'column-reverse', lg: 'row' } : { base: 'column', lg: 'row' }}
        maxWidth={{ base: '100%', md: isFluidContainer ? '100%' : '1440px' }}
        paddingTop={noSpacingTop ? 0 : { base: '32px', md: '72px' }}
        paddingBottom={noSpacingBottom ? 0 : { base: '32px', md: '72px' }}
        className="hostel-row row gx-0 gy-4"
        __css={{ ...styles.row }}
        sx={{ width: maxDesktopWidth ? '900px' : '100%' }}
      >
        {children}
      </Flex>
    </Flex>
  );
};
export default HostelContainerWrapper;
