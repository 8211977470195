import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { X } from 'tabler-icons-react';
import {
  useBookingMenuStore,
  useCartStore,
  useGeneralStore,
  useSitecoreFieldsStore,
  useCustomerStore,
} from '../../../../store';
import classes from '../BookingStickyMenuSteps.module.scss';
import customClasses from './BookingStickyMenuPayment.module.scss';
import { useI18n } from 'next-localization';
import { Spinner } from '@chakra-ui/react';
import { confirmPayment } from 'lib/booking-helpers';
import { useRouter } from 'next/router';
import { RichText } from '@sitecore-jss/sitecore-jss-nextjs';
import { useGenerateDataLayerFromCartItems } from 'lib/customHooks/hooks';
import { fireCustomDataLayerEvent, fireEcomDataLayerEvent } from 'lib/helpers';
import { differenceInDays } from 'date-fns';
import countries from 'i18n-iso-countries';
import enLocale from 'i18n-iso-countries/langs/en.json';
import CountryData from 'country-data';

const BookingStickyMenuPayment: React.FC = ({}): ReactElement => {
  const { t, locale } = useI18n();
  countries.registerLocale(enLocale);
  const router = useRouter();
  const { paymentStepOpen, setPaymentStepOpen, setModalOpen } = useBookingMenuStore((state) => state);
  const {
    cartItems,
    setCartItems,
    setCartItemIds,
    cartFetchingDates,
    setExtraPackage2Checked,
    setAllExtraPackage1DetailsNotActive,
  } = useCartStore((state) => state);
  const { isDesktop } = useGeneralStore((state) => state);
  const { sitecoreFields } = useSitecoreFieldsStore((state) => state);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  let initialStartTime = 0;
  const dataLayerObjects = useGenerateDataLayerFromCartItems();
  const customerData = useCustomerStore((state) => state);

  const getConfirmPayment = useCallback(async () => {
    initialStartTime = new Date().getMilliseconds();
    const queryParams = router.asPath.slice(2).split('&');
    const transactionId = queryParams[0].split('=')[1];
    const responseCode = queryParams[1].split('=')[1];
    const response = await confirmPayment(transactionId, responseCode);
    router.replace('/', undefined, { shallow: true });
    return response.data;
  }, []);

  const [responseData, setResponseData] = useState<string[]>([]); // [arrivalDate, departureDate, reservationNumber]
  const [newThankYouPage, setNewThankYouPage] = useState('');
  const replaceThankYouPage = (
    originalString: string,
    arrivalDate: string,
    departureDate: string,
    reservationNumber: string
  ) => {
    let newThankYou = originalString;
    const displayArrivalDate = arrivalDate.split('-').reverse().join('-');
    const displayDepartureDate = departureDate.split('-').reverse().join('-');
    newThankYou = newThankYou.replace(/##ARRIVALDATE##/g, displayArrivalDate);
    newThankYou = newThankYou.replace(/##DEPARTUREDATE##/g, displayDepartureDate);
    newThankYou = newThankYou.replace(/##BOOKNUM##/g, reservationNumber);
    return newThankYou;
  };

  useEffect(() => {
    if (
      (initialStartTime === 0 || new Date().getMilliseconds() - initialStartTime > 3000) &&
      router.asPath.includes('?transactionId') &&
      router.asPath.includes('&responseCode')
    ) {
      getConfirmPayment().then((response) => {
        response.Data && setResponseData(response.Data.split('|'));
        setLoading(false);

        if (response.Status != 'Complete') {
          setError(true);
          return;
        }

        const numberOfNights = differenceInDays(
          new Date(cartFetchingDates.checkOut!),
          new Date(cartFetchingDates.checkIn!)
        );
        let total = 0;
        cartItems.forEach((cartItem) => {
          cartItem.packages.map((cartPackage) => {
            total += cartPackage.PackagePrice * cartPackage.Count! * numberOfNights;
          });
          if (cartItem.RoomType.includes('B-')) {
            total += cartItem.Price * cartItem.numberOfGuests;
            return;
          }
          total += cartItem.Price;
        });
        const transactionData = {
          transaction_id: response.Data ? response.Data.split('|')[2] : 'unknown',
          affiliation: locale(),
          value: total,
          tax: 0,
          shipping: 0,
          currency: 'DKK',
          coupon: '',
        };
        fireEcomDataLayerEvent('purchase', dataLayerObjects, transactionData);

        //ONG-4259 - Add customer data to dataLayer
        //ONG-4259 05-04-2024 - updated code for getting country code and added a package country-data for handling country code
        const customerCountry = countries.getName(customerData.country, 'en');
        const countryCodeName = countries.getAlpha2Code(customerCountry, 'en');
        const countryCodeISO = JSON.stringify(CountryData.countries[countryCodeName]?.countryCallingCodes);
        // Check if countryCodeISO has a value
        let customerPhone = customerData.phone;
        if (countryCodeISO) {
          const updatedCountryCodeISO = countryCodeISO.replace(/\[|\]|"/g, '');
          customerPhone = `${updatedCountryCodeISO}${customerData.phone}`;
        }

        fireCustomDataLayerEvent('customer_data', {
          customer_email: customerData.email,
          customer_phone: customerPhone,
        });

        //clear all carts and all extra packages
        setCartItems([]);
        setCartItemIds([]);
        setExtraPackage2Checked(false);
        setAllExtraPackage1DetailsNotActive();
        useCartStore.persist.clearStorage();
      });
    }
  }, [getConfirmPayment, initialStartTime, router.asPath]);

  useEffect(() => {
    if (responseData.length > 0) {
      setNewThankYouPage(
        replaceThankYouPage(sitecoreFields.thankYouPage, responseData[0], responseData[1], responseData[2])
      );
    }
  }, [responseData, sitecoreFields.thankYouPage]);

  const closePaymentAndModalHandler = () => {
    setModalOpen(false);
    setPaymentStepOpen(false);
  };

  return (
    <div
      className={`${classes.BookingStickyMenuStep} ${
        paymentStepOpen ? classes.BookingStickyMenuStep_Active : classes.BookingStickyMenuStep_NotActive
      }`}
    >
      <div className={`${classes.Header} ${loading && classes.LoadingHeader}`}>
        <div>{t('booking_payment_headline')}</div>
        <X onClick={closePaymentAndModalHandler} />
      </div>
      <div className={`${classes.Container} ${loading ? customClasses.LoadingContainer : customClasses.Container}`}>
        {loading ? (
          <>
            <h2>{t('booking_payment_processing')}</h2>
            <Spinner />
          </>
        ) : (
          <div className={customClasses.contentWrapper}>
            {<RichText field={{ value: error ? sitecoreFields.errorPage : newThankYouPage }} />}
            {isDesktop && (
              <button className={customClasses.Complete} onClick={closePaymentAndModalHandler}>
                {t('booking_payment_close')}
              </button>
            )}
          </div>
        )}
      </div>
      <div className={`${classes.Footer} ${customClasses.customFooter}`}>
        <div></div>
        <button className={`${classes.Complete} ${customClasses.Complete}`} onClick={closePaymentAndModalHandler}>
          {t('booking_payment_close')}
        </button>
      </div>
    </div>
  );
};
export default BookingStickyMenuPayment;
