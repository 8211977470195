import { Text, Field, RichText, ImageField, NextImage } from '@sitecore-jss/sitecore-jss-nextjs';

type FirstComponentProps = {
  fields: {
    Title: Field<string>;
    Body: Field<string>;
    Image: ImageField;
  };
};

const FirstComponent = (props: FirstComponentProps): JSX.Element => (
  <div>
    <Text field={props.fields.Title} />
    <RichText field={props.fields.Body} />
    <NextImage field={props.fields.Image} height="105" width="200" sizes="50vw" priority={true} unoptimized={true} />
  </div>
);

export default FirstComponent;
