import React, { ReactElement, useEffect, useState } from 'react';
import {
  useCartStore,
  useBookingMenuDatesStore,
  useCompanyStore,
  useDormStore,
  useSitecoreFieldsStore,
} from '../../../../store';
import { CartItem, Reservations, RoomBox } from '../../../../store/booking-store-modules';
import classes from '../../ParentBox.module.scss';
import { useI18n } from 'next-localization';
import BrandButton from 'components/booking/BrandButton/BrandButton';
import CurrencySymbol from 'components/Currency/CurrencyFormatter/CurrencySymbol';
import CurrencyValue from 'components/Currency/CurrencyFormatter/CurrencyValue';
import { findBookedAvailability, generateOptions, getAlertMessage, shortISODateFormat } from 'lib/booking-box-helpers';
import Modal from 'components/Modal';
import { dataLayerObject, dataLayerHostelNames, fireEcomDataLayerEvent, dataLayerItemNames } from 'lib/helpers';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { differenceInDays } from 'date-fns';
import PrivateChildModal from './PrivateChildModal';
import {
  useGenerateDataLayerWhenExtraPackage1AlreadyActive,
  useGenerateDataLayerObjectExtra2,
} from 'lib/customHooks/hooks';

interface IProps {
  PrivateBoxData: RoomBox;
}

const PrivateBox: React.FC<IProps> = ({ PrivateBoxData }): ReactElement => {
  const { t, locale } = useI18n();
  const {
    addCartItem,
    identifier,
    cartItems,
    cartFetchingDates,
    setCartFetchingDates,
    companyRatesActive,
    setCompanyRatesActive,
    extraPackage2Checked,
    extraPackage2Details,
    package1DetailsList,
  } = useCartStore((state) => state);
  const { menuFetchingDates, flex, setIsFlexDisabled, setIsRestrictedDisabled } = useBookingMenuDatesStore(
    (state) => state
  );
  const { companyActive } = useCompanyStore((state) => state);
  const { DormsData } = useDormStore((state) => state);
  const { sitecoreContext } = useSitecoreContext();
  const { sitecoreFields } = useSitecoreFieldsStore((state) => state);

  const { Availability, Price, RoomType, NumberOfPersons, RateCode, UniqueQualifier } = PrivateBoxData;

  const dataLayerArrAdd = useGenerateDataLayerObjectExtra2(
    extraPackage2Details['adult'].PackagePrice,
    extraPackage2Details['child'].PackagePrice,
    'add',
    NumberOfPersons
  );
  const dataLayerExtraPackage1AlreadyActive = useGenerateDataLayerWhenExtraPackage1AlreadyActive(NumberOfPersons);

  const sitecoreAvailability = parseInt(t(`booking_${UniqueQualifier}_availability`));

  const initialAvailabilityLimit =
    sitecoreAvailability > 0 && sitecoreAvailability < Availability ? sitecoreAvailability : Availability;
  const [finalAvailability, setFinalAvailability] = useState(initialAvailabilityLimit);

  const [numberOfRooms, setNumberOfRooms] = useState(0);
  const [openRoomModal, setOpenRoomModal] = useState(false);
  const [alertError, setAlertError] = useState('');
  const [openRoomChildModal, setOpenRoomChildModal] = useState(false);
  const [roomChildReservations, setRoomChildReservations] = useState<Reservations>();

  useEffect(() => {
    if (Availability > 0) {
      const bookedGuests = findBookedAvailability(cartItems, RoomType);
      setFinalAvailability(initialAvailabilityLimit - bookedGuests);
      return;
    }
    setFinalAvailability(0);
  }, [cartItems, Availability, RoomType, initialAvailabilityLimit]);

  const checkActivePackages = () => {
    const packages = [];
    if (extraPackage2Checked) {
      packages.push({ ...extraPackage2Details['adult'], Count: NumberOfPersons });
    }
    //check if package1DetailsList is not empty and if Active is true add to packages
    if (package1DetailsList.length > 0) {
      package1DetailsList.forEach((package1) => {
        if (package1.Active) {
          packages.push({ ...package1, Count: NumberOfPersons });
        }
      });
    }
    return packages;
  };

  const handleAddReservation = () => {
    if (
      (menuFetchingDates.checkIn !== cartFetchingDates.checkIn ||
        menuFetchingDates.checkOut !== cartFetchingDates.checkOut) &&
      cartItems.length > 0
    ) {
      setAlertError('datesNotEqual');
      setOpenRoomModal(true);
      return;
    }

    //check if corresponding uniqueQualifier exists in DormsData Restrictive / Flex as well
    //check if availability is higher in corresponding room
    let notCorresponding = false;
    if (DormsData) {
      const isFlex = flex ? 'Restricted' : 'Flex';
      const category = UniqueQualifier.includes('DBL') ? 'Rooms' : 'Offers';
      const correspondingRoom = DormsData[isFlex][category][UniqueQualifier];
      if (!correspondingRoom || correspondingRoom[0].Availability < Availability) {
        flex ? setIsRestrictedDisabled(true) : setIsFlexDisabled(true);
        notCorresponding = true;
      }
    }

    cartItems.length === 0 && setCartFetchingDates(menuFetchingDates);

    cartItems.length === 0 && companyActive
      ? setCompanyRatesActive(true)
      : cartItems.length === 0 && setCompanyRatesActive(false);
    if (
      (cartItems.length > 0 && !companyActive && companyRatesActive) ||
      (cartItems.length > 0 && companyActive && !companyRatesActive)
    ) {
      setOpenRoomModal(true);
      setAlertError('companyRates');
      return;
    }

    if (!sitecoreFields.DisableMessagePrivate) {
      const reservations = {
        ...PrivateBoxData,
        numberOfGuests: NumberOfPersons,
        category: UniqueQualifier.includes('DBL') ? 'Rooms' : 'Offers',
        notCorresponding,
        packages: checkActivePackages(),
      };
      setAlertError('childNotAllowed');
      setRoomChildReservations(reservations);
      setOpenRoomChildModal(true);
      return;
    }

    for (let index = 0; index < numberOfRooms; index++) {
      const reservation: CartItem = {
        ...PrivateBoxData,
        numberOfGuests: NumberOfPersons,
        category: 'Rooms',
        identifier: identifier + index,
        notCorresponding,
        //Offers og Rooms bruger uniqueQualier som RoomType
        packages: checkActivePackages(),
      };
      addCartItem(reservation);
      //we fire dataLayers for extras when they are already active and a new cartItem gets added
      dataLayerArrAdd && extraPackage2Checked && fireEcomDataLayerEvent('add_to_cart', dataLayerArrAdd);
      dataLayerExtraPackage1AlreadyActive.length > 0 &&
        fireEcomDataLayerEvent('add_to_cart', dataLayerExtraPackage1AlreadyActive);
    }
    const dataLayerObj: dataLayerObject = {
      item_id: PrivateBoxData.RoomType,
      item_name:
        dataLayerItemNames[UniqueQualifier as keyof typeof dataLayerItemNames] ??
        t(`booking_${UniqueQualifier}_headline`),
      item_variant: PrivateBoxData.RateCode,
      item_brand: sitecoreContext?.site?.name
        ? dataLayerHostelNames[sitecoreContext.site.name as keyof typeof dataLayerHostelNames]
        : '',
      item_category: shortISODateFormat(menuFetchingDates.checkIn!) ?? '',
      item_category2: NumberOfPersons,
      item_category3: differenceInDays(new Date(menuFetchingDates.checkOut!), new Date(menuFetchingDates.checkIn!)),
      item_category4: NumberOfPersons,
      item_category5: PrivateBoxData.RoomType,
      item_list_id: 'STEP-1',
      item_list_name: 'Product list',
      affiliation: locale(),
      currency: 'DKK',
      price: PrivateBoxData.Price,
      quantity: numberOfRooms,
    };

    fireEcomDataLayerEvent('add_to_cart', [dataLayerObj]);
  };

  const changeRoomDetails = (event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    const { name } = event.target;
    if (name == 'numberOfRooms') {
      setNumberOfRooms(parseInt(event.target.value));
    }
  };
  return (
    <div className={`${classes.roomBoxContainer} ${Availability <= 0 && classes.SoldOut}`} key={UniqueQualifier}>
      <div className={classes.roomType}>
        <h3>{t(`booking_${UniqueQualifier}_headline`)}</h3>
        <p className={classes.RoomDescription}>{t(`booking_${UniqueQualifier}_description`)}</p>
      </div>
      <div className={classes.roomDetails}>
        <div className={classes.numberOfPersons}>
          <div className={classes.selectWrapper}>
            <select
              onChange={changeRoomDetails}
              disabled={Availability <= 0}
              className={classes.selectText}
              name="numberOfRooms"
            >
              <option defaultValue={0} value={0}>
                {t('booking_label_zero_option')}
              </option>
              {generateOptions(finalAvailability)}
            </select>
            <span className={classes.selectLabel}>{t('booking_number_of_rooms')}</span>
          </div>
          {numberOfRooms > 0 && finalAvailability > 0 && (
            <BrandButton clickHandler={handleAddReservation} dataQa={RoomType + ',' + RateCode} />
          )}
        </div>
        <div className={classes.roomPrice}>
          <p className={classes.roomLabel}>{t('booking_label_per_room')}</p>
          <h3>
            {Availability <= 0 ? (
              <>{t('booking_label_soldout')}</>
            ) : (
              <>
                <CurrencySymbol></CurrencySymbol>
                <CurrencyValue value={Price}></CurrencyValue>
              </>
            )}
          </h3>
        </div>
      </div>
      <Modal
        isOpen={openRoomModal}
        message={t(getAlertMessage(alertError).message)}
        title={t(getAlertMessage(alertError).title)}
        onClose={() => setOpenRoomModal(false)}
      />
      <PrivateChildModal
        isOpen={openRoomChildModal}
        onClose={() => setOpenRoomChildModal(false)}
        message={t(getAlertMessage(alertError).message)}
        title={t(getAlertMessage(alertError).title)}
        reservations={roomChildReservations}
        numberOfRooms={numberOfRooms}
        pizzaDataLayer={dataLayerArrAdd}
        extraPackage1DataLayer={dataLayerExtraPackage1AlreadyActive}
      />
    </div>
  );
};
export default PrivateBox;
