import { Icon, IconProps } from '@chakra-ui/react';
import { ReactElement } from 'react';

export const TvRoomIcon = (props: IconProps): ReactElement => {
  return (
    <Icon {...props} viewBox="0 0 35 35">
      <path
        d="M32.3499 20.9595C32.0749 20.9595 31.8915 20.7746 31.8915 20.4971V2.92486H3.19987V20.4971C3.19987 20.7746 3.01654 20.9595 2.74154 20.9595C2.46654 20.9595 2.2832 20.7746 2.2832 20.4971V2.46243C2.2832 2.18497 2.5582 2 2.74154 2H32.2582C32.5332 2 32.7165 2.18497 32.7165 2.46243V20.4971C32.8082 20.7746 32.5332 20.9595 32.3499 20.9595Z"
        fill="#212121"
      />
      <path
        d="M16.3087 24.7515C16.0337 24.7515 15.8504 24.5665 15.8504 24.2891V22.5318C15.8504 21.8844 15.3921 21.3295 14.9337 21.3295H11.3587C10.8087 21.3295 10.4421 21.8844 10.4421 22.5318V24.2891C10.4421 24.5665 10.2587 24.7515 9.98372 24.7515C9.70872 24.7515 9.52539 24.5665 9.52539 24.2891V22.5318C9.52539 21.3295 10.3504 20.4047 11.3587 20.4047H14.9337C15.9421 20.4047 16.7671 21.3295 16.7671 22.5318V24.2891C16.7671 24.5665 16.5837 24.7515 16.3087 24.7515Z"
        fill="#212121"
      />
      <path
        d="M24.9249 24.7515C24.6499 24.7515 24.4666 24.5665 24.4666 24.2891V22.5318C24.4666 21.8844 24.0083 21.3295 23.5499 21.3295H19.9749C19.4249 21.3295 19.0583 21.8844 19.0583 22.5318V24.2891C19.0583 24.5665 18.8749 24.7515 18.5999 24.7515C18.3249 24.7515 18.1416 24.5665 18.1416 24.2891V22.5318C18.1416 21.3295 18.9666 20.4047 19.9749 20.4047H23.5499C24.5583 20.4047 25.3833 21.3295 25.3833 22.5318V24.2891C25.3833 24.5665 25.1999 24.7515 24.9249 24.7515Z"
        fill="#212121"
      />
      <path
        d="M33.5421 26.3237C33.2671 26.3237 33.0838 26.1388 33.0838 25.8613V22.5318C33.0838 21.8844 32.6255 21.3295 32.1671 21.3295H28.5921C28.0421 21.3295 27.6755 21.8844 27.6755 22.5318V24.2891C27.6755 24.5665 27.4921 24.7515 27.2171 24.7515C26.9421 24.7515 26.7588 24.5665 26.7588 24.2891V22.5318C26.7588 21.3295 27.5838 20.4047 28.5921 20.4047H32.1671C33.1755 20.4047 34.0005 21.3295 34.0005 22.5318V25.8613C34.0005 26.1388 33.8171 26.3237 33.5421 26.3237Z"
        fill="#212121"
      />
      <path
        d="M1.55013 26.4161C1.27513 26.4161 1.0918 26.2312 1.0918 25.9537V22.6242C1.0918 21.4219 1.9168 20.4971 2.92513 20.4971H6.50013C7.50846 20.4971 8.33346 21.4219 8.33346 22.6242V24.3815C8.33346 24.6589 8.15013 24.8439 7.87513 24.8439C7.60013 24.8439 7.4168 24.5664 7.4168 24.289V22.5318C7.4168 21.8844 6.95846 21.3294 6.50013 21.3294H2.92513C2.37513 21.3294 2.00846 21.8844 2.00846 22.5318V25.8612C2.00846 26.1387 1.73346 26.4161 1.55013 26.4161Z"
        fill="#212121"
      />
      <path
        d="M12.0919 28.5434C11.8169 28.5434 11.6336 28.3584 11.6336 28.0809V26.3237C11.6336 25.6763 11.1753 25.1214 10.7169 25.1214H7.14193C6.59193 25.1214 6.22526 25.6763 6.22526 26.3237V28.0809C6.22526 28.3584 6.04193 28.5434 5.76693 28.5434C5.49193 28.5434 5.30859 28.3584 5.30859 28.0809V26.3237C5.30859 25.1214 6.13359 24.1965 7.14193 24.1965H10.7169C11.7253 24.1965 12.5503 25.1214 12.5503 26.3237V28.0809C12.5503 28.2659 12.3669 28.5434 12.0919 28.5434Z"
        fill="#212121"
      />
      <path
        d="M20.7081 28.5434C20.4331 28.5434 20.2498 28.3584 20.2498 28.0809V26.3237C20.2498 25.6763 19.7915 25.1214 19.3331 25.1214H15.7581C15.2081 25.1214 14.8415 25.6763 14.8415 26.3237V28.0809C14.8415 28.3584 14.6581 28.5434 14.3831 28.5434C14.1081 28.5434 13.9248 28.3584 13.9248 28.0809V26.3237C13.9248 25.1214 14.7498 24.1965 15.7581 24.1965H19.3331C20.3415 24.1965 21.1665 25.1214 21.1665 26.3237V28.0809C21.1665 28.2659 20.9831 28.5434 20.7081 28.5434Z"
        fill="#212121"
      />
      <path
        d="M29.3253 28.5434C29.0503 28.5434 28.867 28.3584 28.867 28.0809V26.3237C28.867 25.6763 28.4087 25.1214 27.9503 25.1214H24.3753C23.8253 25.1214 23.4587 25.6763 23.4587 26.3237V28.0809C23.4587 28.3584 23.2753 28.5434 23.0003 28.5434C22.7253 28.5434 22.542 28.3584 22.542 28.0809V26.3237C22.542 25.1214 23.367 24.1965 24.3753 24.1965H27.9503C28.9587 24.1965 29.7837 25.1214 29.7837 26.3237V28.0809C29.7837 28.2659 29.6003 28.5434 29.3253 28.5434Z"
        fill="#212121"
      />
      <path
        d="M12.0919 28.5434C11.8169 28.5434 11.6336 28.3584 11.6336 28.0809V26.3237C11.6336 25.6763 11.1753 25.1214 10.7169 25.1214H7.14193C6.59193 25.1214 6.22526 25.6763 6.22526 26.3237V28.0809C6.22526 28.3584 6.04193 28.5434 5.76693 28.5434C5.49193 28.5434 5.30859 28.3584 5.30859 28.0809V26.3237C5.30859 25.1214 6.13359 24.1965 7.14193 24.1965H10.7169C11.7253 24.1965 12.5503 25.1214 12.5503 26.3237V28.0809C12.5503 28.2659 12.3669 28.5434 12.0919 28.5434Z"
        fill="#212121"
      />
      <path
        d="M20.7081 28.5434C20.4331 28.5434 20.2498 28.3584 20.2498 28.0809V26.3237C20.2498 25.6763 19.7915 25.1214 19.3331 25.1214H15.7581C15.2081 25.1214 14.8415 25.6763 14.8415 26.3237V28.0809C14.8415 28.3584 14.6581 28.5434 14.3831 28.5434C14.1081 28.5434 13.9248 28.3584 13.9248 28.0809V26.3237C13.9248 25.1214 14.7498 24.1965 15.7581 24.1965H19.3331C20.3415 24.1965 21.1665 25.1214 21.1665 26.3237V28.0809C21.1665 28.2659 20.9831 28.5434 20.7081 28.5434Z"
        fill="#212121"
      />
      <path
        d="M29.3253 28.5434C29.0503 28.5434 28.867 28.3584 28.867 28.0809V26.3237C28.867 25.6763 28.4087 25.1214 27.9503 25.1214H24.3753C23.8253 25.1214 23.4587 25.6763 23.4587 26.3237V28.0809C23.4587 28.3584 23.2753 28.5434 23.0003 28.5434C22.7253 28.5434 22.542 28.3584 22.542 28.0809V26.3237C22.542 25.1214 23.367 24.1965 24.3753 24.1965H27.9503C28.9587 24.1965 29.7837 25.1214 29.7837 26.3237V28.0809C29.7837 28.2659 29.6003 28.5434 29.3253 28.5434Z"
        fill="#212121"
      />
      <path
        d="M16.3087 33.7225C16.0337 33.7225 15.8504 33.5376 15.8504 33.2601V29.9306C15.8504 29.2832 15.3921 28.7283 14.9337 28.7283H11.3587C10.8087 28.7283 10.4421 29.2832 10.4421 29.9306V33.2601C10.4421 33.5376 10.2587 33.7225 9.98372 33.7225C9.70872 33.7225 9.52539 33.5376 9.52539 33.2601V29.9306C9.52539 28.7283 10.3504 27.8035 11.3587 27.8035H14.9337C15.9421 27.8035 16.7671 28.7283 16.7671 29.9306V33.2601C16.7671 33.5376 16.4921 33.7225 16.3087 33.7225Z"
        fill="#212121"
      />
      <path
        d="M24.9249 33.7225C24.6499 33.7225 24.4666 33.5376 24.4666 33.2601V29.9306C24.4666 29.2832 24.0083 28.7283 23.5499 28.7283H19.9749C19.4249 28.7283 19.0583 29.2832 19.0583 29.9306V33.2601C19.0583 33.5376 18.8749 33.7225 18.5999 33.7225C18.3249 33.7225 18.1416 33.5376 18.1416 33.2601V29.9306C18.1416 28.7283 18.9666 27.8035 19.9749 27.8035H23.5499C24.5583 27.8035 25.3833 28.7283 25.3833 29.9306V33.2601C25.3833 33.5376 25.1083 33.7225 24.9249 33.7225Z"
        fill="#212121"
      />
      <path
        d="M33.542 33.7225C33.267 33.7225 33.0837 33.5376 33.0837 33.2601V29.9306C33.0837 29.2832 32.6253 28.7283 32.167 28.7283H28.592C28.042 28.7283 27.6753 29.2832 27.6753 29.9306V33.2601C27.6753 33.5376 27.492 33.7225 27.217 33.7225C26.942 33.7225 26.667 33.5376 26.667 33.2601V29.9306C26.667 28.7283 27.492 27.8035 28.5003 27.8035H32.0753C33.0837 27.8035 33.9087 28.7283 33.9087 29.9306V33.2601C34.0003 33.5376 33.7253 33.7225 33.542 33.7225Z"
        fill="#212121"
      />
      <path
        d="M1.45833 34C1.18333 34 1 33.815 1 33.5376V29.9306C1 28.7283 1.825 27.8035 2.83333 27.8035H6.40833C7.41667 27.8035 8.24167 28.7283 8.24167 29.9306V33.5376C8.24167 33.815 8.05833 34 7.78333 34C7.50833 34 7.325 33.815 7.325 33.5376V29.9306C7.325 29.2832 6.86667 28.7283 6.40833 28.7283H2.83333C2.28333 28.7283 1.91667 29.2832 1.91667 29.9306V33.5376C1.91667 33.815 1.73333 34 1.45833 34Z"
        fill="#212121"
      />
      <path
        d="M33.2671 34.0001H1.73372C1.45872 34.0001 1.27539 33.8151 1.27539 33.5376C1.27539 33.2602 1.45872 33.0752 1.73372 33.0752H33.2671C33.5421 33.0752 33.7254 33.2602 33.7254 33.5376C33.7254 33.8151 33.5421 34.0001 33.2671 34.0001Z"
        fill="#212121"
      />
      <path
        d="M17.4996 17.7226C16.9496 17.7226 16.3996 17.6301 15.8496 17.5376C14.1996 17.0752 12.9163 16.0578 12.0913 14.5781C11.2663 13.0983 11.0829 11.4336 11.4496 9.86131C11.9079 8.19657 12.9163 6.90177 14.3829 6.0694C17.3163 4.31217 21.1663 5.32951 22.9079 8.38154C23.7329 9.86131 23.9163 11.526 23.5496 13.0983C23.1829 14.6706 22.0829 16.0578 20.6163 16.8902C19.6996 17.4451 18.5996 17.7226 17.4996 17.7226ZM14.8413 6.80928C13.6496 7.54917 12.7329 8.65899 12.3663 10.0463C11.9996 11.4336 12.1829 12.8208 12.9163 14.1156C13.6496 15.318 14.7496 16.2428 16.1246 16.6128C17.4996 16.9827 18.8746 16.7977 20.1579 16.0578C21.3496 15.318 22.2663 14.2081 22.6329 12.8208C22.9996 11.4336 22.8163 10.0463 22.0829 8.75148C20.6163 6.25437 17.4079 5.32951 14.8413 6.80928Z"
        fill="#212121"
      />
      <path
        d="M15.6665 15.133C15.5748 15.133 15.4831 15.133 15.4831 15.0405C15.2998 14.948 15.2998 14.763 15.2998 14.6705V8.5665C15.2998 8.38153 15.3915 8.28905 15.4831 8.19656C15.6665 8.10408 15.7581 8.10408 15.9415 8.19656L21.0748 11.2486C21.2581 11.3411 21.2581 11.526 21.2581 11.6185C21.2581 11.711 21.1665 11.896 21.0748 11.9885L15.9415 15.0405C15.7581 15.0405 15.6665 15.133 15.6665 15.133ZM16.1248 9.39887V13.8382L19.8831 11.6185L16.1248 9.39887Z"
        fill="#212121"
      />
    </Icon>
  );
};
