import React, { ReactElement } from 'react';
import styles from './BookingStickyMenuModal.module.scss';
import BookingStickyMenuDatePicker from '../BookingStickyMenuDatePicker/BookingStickyMenuDatePicker';
import BookingStickyMenuRoomPicker from '../BookingStickyMenuRoomPicker/BookingStickyMenuRoomPicker';
import { useBookingMenuStore } from '../../../../store';
import BookingStickyMenuError from '../BookingStickyMenuError/BookingStickyMenuError';
import BookingStickyMenuPayment from '../BookingStickyMenuPayment/BookingStickyMenuPayment';
import BookingStickyMenuChange from '../BookingStickyMenuChange/BookingStickyMenuChange';

interface IProps {
  openMenu: () => void;
  today: Date;
  checkInDate: Date | null;
  checkOutDate: Date | null;
  setCalendarDates: React.Dispatch<React.SetStateAction<[Date | null, Date | null]>>;
}

const BookingStickyMenuModal: React.FC<IProps> = ({
  openMenu,
  today,
  checkInDate,
  checkOutDate,
  setCalendarDates,
}): ReactElement => {
  const { modalOpen } = useBookingMenuStore((state) => state);

  return (
    <div
      id="BookingModal"
      className={`${styles.BookingStickyMenuModal} ${modalOpen && styles.BookingStickyMenuModal_Active}`}
    >
      <BookingStickyMenuDatePicker
        openMenu={openMenu}
        today={today}
        checkInDate={checkInDate}
        checkOutDate={checkOutDate}
        setCalendarDates={setCalendarDates}
      />
      <BookingStickyMenuRoomPicker />
      <BookingStickyMenuError />
      <BookingStickyMenuChange />
      <BookingStickyMenuPayment />
    </div>
  );
};
export default BookingStickyMenuModal;
