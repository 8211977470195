import { Box, useMultiStyleConfig } from '@chakra-ui/react';
import CurrencySymbol from './CurrencyFormatter/CurrencySymbol';
import CurrencyValue from './CurrencyFormatter/CurrencyValue';
import { ComponentProps } from 'lib/component-props';
import { Field, Text, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { IDroptreeColor } from '../../Component-models/data-items/IDroptreeColor';
// import { colors } from 'themes/nexthouse/colors';
import { useTheme } from '@chakra-ui/react';
import { useGeneralStore } from 'src/store';

export type PriceTagsProps = ComponentProps & {
  fields: {
    dkkPrice: Field<number>;
    textFontsize?: Field<string>;
    priceNumberFontsize?: Field<string>;
    color?: IDroptreeColor;
    backgroundColor?: IDroptreeColor;
    pricesFrom: Field<string>;
    usePricetag: Field<boolean>;
  };
};

type ColorMap = {
  [key: string]: string | { [key: string]: string } | undefined;
  transparent?: string;
};

const PriceTag = (props: PriceTagsProps): JSX.Element => {
  const { loaded } = useGeneralStore((state) => state);
  const styles = useMultiStyleConfig('PriceTag', {});
  const { colors } = useTheme();

  const getColorByStringNumber = (input: string): string | undefined => {
    const [colorString, colorNumber] = input.split('.');
    const colorMap: ColorMap = colors;

    if (colorString === 'transparent') {
      return undefined;
    }
    return (colorMap[colorString] as { [key: string]: string })[colorNumber];
  };

  const _color = getColorByStringNumber(props.fields.color?.fields.colorcode.value ?? 'gray.500');
  const _backgroundColor = getColorByStringNumber(props.fields.backgroundColor?.fields.colorcode.value ?? 'gray.500');

  return loaded ? (
    <>
      <Box __css={{ ...styles.container, color: _color ?? styles.container.color }}>
        <Box __css={styles.content}>
          <Box __css={styles.textLabel}>
            <Text field={props.fields.pricesFrom} editable={true}></Text>
          </Box>
          <Box __css={{ display: 'flex', alignItems: 'baseline' }}>
            <Box __css={styles.currencyLabel}>
              <CurrencySymbol />
            </Box>
            <Box __css={styles.priceLabel}>
              <CurrencyValue value={props.fields.dkkPrice.value} />
            </Box>
          </Box>
        </Box>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          style={{ flexGrow: 1 }}
          viewBox="0 0 140 100"
          fill={_backgroundColor ?? '#EECECE'}
        >
          <path d="M140 50.0088L124.612 57.1101L134.66 69.4577L117.193 70.076L120.74 84.8967L104.169 80.0212L101.968 95.0539L87.8439 86.0979L80.8735 100L69.9898 88.1116L59.1265 100L52.1561 86.0979L38.0317 95.0539L35.8102 80.0212L19.2401 84.8967L22.8068 70.076L5.33993 69.4577L15.388 57.1101L0 50.0088L15.388 42.89L5.33993 30.56L22.8068 29.924L19.2401 15.1033L35.8102 19.9965L38.0317 4.94612L52.1561 13.9021L59.1265 0L69.9898 11.8884L80.8735 0L87.8439 13.9021L101.968 4.94612L104.169 19.9965L120.74 15.1033L117.193 29.924L134.66 30.56L124.612 42.89L140 50.0088Z" />
        </svg>
      </Box>
      {/* <pre>{JSON.stringify(props, null, 2)}</pre> */}
    </>
  ) : (
    <> </>
  );
};

export default withDatasourceCheck()(PriceTag);
