import { Icon, IconProps } from '@chakra-ui/react';
import { ReactElement } from 'react';

export const MastercardIcon = (props: IconProps): ReactElement => {
  return (
    <Icon {...props} viewBox="0 0 31 21">
      <rect
        x="1.37917"
        y="0.629167"
        width="28.9292"
        height="19.7417"
        rx="2.51667"
        fill="white"
        stroke="#D9D9D9"
        strokeWidth="1.25833"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.4037 16.9026C24.0467 16.9026 26.9999 13.9981 26.9999 10.4151C26.9999 6.83217 24.0467 3.92761 20.4037 3.92761C18.771 3.92761 17.2768 4.511 16.125 5.4774C14.9731 4.51101 13.479 3.92762 11.8463 3.92762C8.20326 3.92762 5.25 6.83217 5.25 10.4151C5.25 13.9981 8.20326 16.9026 11.8463 16.9026C13.479 16.9026 14.9731 16.3192 16.125 15.3529C17.2768 16.3192 18.771 16.9026 20.4037 16.9026Z"
        fill="#ED0006"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.125 15.3529C17.5433 14.1629 18.4426 12.3923 18.4426 10.4151C18.4426 8.43793 17.5433 6.66732 16.125 5.47739C17.2768 4.511 18.771 3.92761 20.4037 3.92761C24.0467 3.92761 27 6.83217 27 10.4151C27 13.9981 24.0467 16.9026 20.4037 16.9026C18.771 16.9026 17.2768 16.3192 16.125 15.3529Z"
        fill="#F9A000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.1252 15.3529C17.5435 14.163 18.4429 12.3924 18.4429 10.4152C18.4429 8.43802 17.5435 6.66741 16.1252 5.47748C14.7069 6.66741 13.8076 8.43802 13.8076 10.4152C13.8076 12.3924 14.7069 14.163 16.1252 15.3529Z"
        fill="#FF5E00"
      />
    </Icon>
  );
};
