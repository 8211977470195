import { Icon, IconProps } from '@chakra-ui/react';
import { ReactElement } from 'react';

export const AmexIcon = (props: IconProps): ReactElement => {
  return (
    <Icon {...props} viewBox="0 0 31 21">
      <rect
        x="1.00417"
        y="0.629167"
        width="28.9292"
        height="19.7417"
        rx="2.51667"
        fill="#1F72CD"
        stroke="#D9D9D9"
        strokeWidth="1.25833"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.8144 7.37671L3 13.881H6.36923L6.78692 12.8439H7.74166L8.15934 13.881H11.8679V13.0895L12.1984 13.881H14.1167L14.4472 13.0727V13.881H22.16L23.0979 12.8709L23.976 13.881L27.9375 13.8893L25.1142 10.647L27.9375 7.37671H24.0375L23.1245 8.36816L22.274 7.37671H13.8835L13.163 9.05552L12.4256 7.37671H9.06338V8.14129L8.68936 7.37671H5.8144ZM17.3321 8.30036H21.7612L23.1158 9.8285L24.5141 8.30036H25.8688L23.8105 10.6461L25.8688 12.9649H24.4527L23.098 11.419L21.6926 12.9649H17.3321V8.30036ZM18.4258 10.1187V9.26674V9.26592H21.1894L22.3952 10.6285L21.1359 11.9985H18.4258V11.0684H20.842V10.1187H18.4258ZM6.4665 8.30036H8.10882L9.97561 12.7109V8.30036H11.7747L13.2166 11.4627L14.5454 8.30036H16.3356V12.9676H15.2463L15.2374 9.31038L13.6494 12.9676H12.675L11.0781 9.31038V12.9676H8.83733L8.41251 11.9213H6.11739L5.69345 12.9667H4.49285L6.4665 8.30036ZM6.50907 10.954L7.26522 9.09005L8.02049 10.954H6.50907Z"
        fill="white"
      />
    </Icon>
  );
};
