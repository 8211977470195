import { OverlayViewF } from '@react-google-maps/api';
import { Box, useMultiStyleConfig } from '@chakra-ui/react';
import { Text, Field } from '@sitecore-jss/sitecore-jss-nextjs';

interface LatLng {
  lat: number;
  lng: number;
}
interface Props {
  position: LatLng;
  title: Field<string>;
}

const HostelLocation = ({ position, title }: Props): JSX.Element => {
  const styles = useMultiStyleConfig('Maps', {});

  return (
    <OverlayViewF position={position} mapPaneName="overlayMouseTarget">
      <>
        <Box __css={styles.hostelMarker} />
        <Box __css={styles.markerText}>
          <Text field={title} />
        </Box>
      </>
    </OverlayViewF>
  );
};

export default HostelLocation;
