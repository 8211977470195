import { Box, useMultiStyleConfig, Text, GridItem, Flex, Grid, Fade, useBreakpointValue } from '@chakra-ui/react';
import { LinkField, withDatasourceCheck, useSitecoreContext, NextImage } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import {
  GetImageUrlFromString,
  GetMetaData,
  generateBreakpointValues,
  orginalBaseAsset,
  orginalDigizuiteImage,
} from 'lib/helpers';
import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { useGeneralStore } from 'src/store';
import { X } from 'tabler-icons-react';
import { IGridParameters } from '../../Component-models/base-items/IGridParameters';
import { IDroptreeColor } from '../../Component-models/data-items/IDroptreeColor';
import PriceTag, { PriceTagsProps } from '../Currency/PriceTag';
import NaturalHeightImage from './ProductCardList/NaturalHeightImage';
import Image from 'next/image';

// import { updateRenderingIds } from 'src/utils';
import imageSizeData from 'public/image-sizes.json';

type ImageGalleryProps = ComponentProps &
  PriceTagsProps & {
    fields: {
      // children: I_GalleryImage[];
      sticker: orginalDigizuiteImage;
      stickerlink: LinkField | undefined;
      modalBgColor: IDroptreeColor;
      //DamImageField fixes no src key on item
      ImageGallery: {
        value: orginalBaseAsset[];
      };
      children: orginalDigizuiteImage[];
    };
    params: IGridParameters;
  };

const ImageGallery: React.FC = (props: ImageGalleryProps): JSX.Element => {
  const styles = useMultiStyleConfig('ImageGallery', {});
  const [selectedImage, setSelectedImage] = useState(0);
  const [selectedImageModal, setSelectedImageModal] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [hover, setHover] = useState(false);
  const [screenWidth, setScreenWidth] = useState(0);
  const { sitecoreContext } = useSitecoreContext();
  const isEditing = sitecoreContext && sitecoreContext.pageEditing;

  //fixes prop src does not match server
  const { loaded } = useGeneralStore((state) => state);

  const [screenOrientation, setScreenOrientation] = useState('');

  const gridParams = props.params?.GridParameters ? props.params.GridParameters : 'col-xl-12 col-md-12 col-sm-12';
  const modalBgColor = props.fields?.modalBgColor?.fields.colorcode?.value ?? 'rgba(0, 0, 0, 0.7)';

  useEffect(() => {
    function handleResize() {
      setScreenOrientation(window.innerWidth > window.innerHeight ? 'landscape' : 'portrait');
      setScreenWidth(window.outerWidth);
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, [screenOrientation]);

  // ONG-4285 created a new useEffect to handle the z-index of the modal instead of the sticky menu display
  useEffect(() => {
    const modalContainer = document.getElementById('ahhg-image-gallery--modal-desktop');
    if (modalContainer) {
      if (modalOpen) {
        modalContainer.style.zIndex = '9999';
        document.body.style.overflow = 'hidden';
      } else {
        modalContainer.style.zIndex = '';
        document.body.style.overflow = '';
      }
      return () => {
        modalContainer.style.zIndex = '';
        document.body.style.overflow = '';
      };
    }
    return;
  }, [modalOpen, screenWidth]);
  const openModal = (index: number) => {
    setModalOpen(true);
    setSelectedImageModal(index);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedImage(selectedImageModal);
  };

  const _baseImageBP = generateBreakpointValues(imageSizeData.imagegallerybaseparams);
  const baseImageParams = useBreakpointValue(_baseImageBP, { fallback: 'base' });

  const _CarouselImageBP = generateBreakpointValues(imageSizeData.imagegallerycarouselimage);
  const CarouselImageParams = useBreakpointValue(_CarouselImageBP, { fallback: 'md' });

  const _ModalSmallBP = generateBreakpointValues(imageSizeData.imagegallerytmodalthump);
  const ModalThumpnail = useBreakpointValue(_ModalSmallBP, { fallback: 'lg' });

  const _ModalDesktopBP = generateBreakpointValues(imageSizeData.imagegallerydesktopparams);
  const ModalParams = useBreakpointValue(_ModalDesktopBP, { fallback: 'lg' });

  const images = Array.isArray(props.fields?.ImageGallery.value)
    ? props.fields?.ImageGallery.value.map((item) => GetMetaData(item))
    : [];

  const childImages = Array.isArray(props.fields?.children)
    ? props.fields?.children.map((item) => GetMetaData(item?.fields.Image.value))
    : [];

  // override the alt text with the description from the datasource
  const updatedChildImages = childImages.map((image, index) => {
    const description = props.fields?.children[index]?.fields?.ImageDescription?.value || image.Alt;
    return { ...image, Alt: description };
  });

  const imagesWithMetaData = [...updatedChildImages, ...images];

  const _sticker = GetMetaData(props.fields?.sticker);
  const isEditNoImages = isEditing && imagesWithMetaData.length === 0;

  return loaded ? (
    <>
      <Box
        __css={{ ...styles.container, height: isEditNoImages ? '400px' : undefined }}
        className={`ahhg-image-gallery ${gridParams}`}
      >
        <Box
          __css={{ ...styles.defaultCarouselContainer }}
          onMouseOver={() => setHover(true)}
          onMouseOut={() => setHover(false)}
          position="relative"
        >
          {imagesWithMetaData.length > 1 && (
            <Box
              position="absolute"
              bottom="0"
              left="0"
              maxW={['220px', '340px']}
              m={{ base: '0 0 0.8rem 0.8rem', md: '0 0 1.25rem 1.25rem' }}
              p={
                imagesWithMetaData[selectedImage]?.Alt
                  ? ['6px 50px 7px 9px', '0.75rem 3rem 0.75rem 1rem']
                  : ['6px 7px 0px 7px', '4.5px 7px 0px 7px']
              }
              zIndex="10"
              bg="white"
              borderRadius={['13px', '22px']}
              display="flex"
              justifyContent="center"
              flexDirection="column"
              fontSize={['12px', '16px']}
              className="ahhg-image-gallery-label"
              onClick={() => openModal(selectedImage)}
            >
              <>
                <Box
                  color="black"
                  fontFamily="Inter"
                  fontWeight="400"
                  lineHeight={['15px', '18px']}
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                >
                  <Text fontWeight="bold" mr="0.2rem">
                    {selectedImage < 9 ? '0' : ''}
                    {selectedImage + 1}
                  </Text>
                  /
                  <Text ml="0.2rem">
                    {imagesWithMetaData.length < 10 ? '0' : ''}
                    {imagesWithMetaData.length}
                  </Text>
                </Box>
              </>
              <Text color="black" fontFamily="Inter" fontWeight="400" lineHeight="16px" mt="0.25rem">
                {imagesWithMetaData[selectedImage]?.Alt ?? ''}
              </Text>
            </Box>
          )}

          {isEditing ? (
            // if imageUrl is empty, show placeholder
            imagesWithMetaData.length > 0 ? (
              <Image
                className="media first-child"
                src={GetImageUrlFromString(imagesWithMetaData[0].Src, baseImageParams)}
                // src={imageFromBreakpoints(baseImageParams, mergedImages[0].imageUrl, true)}
                loading="lazy"
                style={{ objectFit: 'cover' }}
                unoptimized={true}
                placeholder={'empty'}
                fill={true}
                alt={imagesWithMetaData[0]?.Alt ?? ''}
                title={imagesWithMetaData[0].Title}
              />
            ) : (
              <Image
                className="media no-children"
                src={''}
                alt=""
                style={{ objectFit: 'cover' }}
                placeholder={'empty'}
                height="100"
                fill={true}
              />
            )
          ) : (
            <Carousel
              showArrows
              showIndicators={false}
              showStatus={false}
              showThumbs={false}
              swipeScrollTolerance={30}
              preventMovementUntilSwipeScrollTolerance={true}
              onClickItem={(index) => {
                openModal(index);
              }}
              onChange={(item) => {
                setSelectedImage(item);
              }}
              width="100%"
              className="carousel"
              infiniteLoop
              selectedItem={selectedImage}
              renderArrowNext={() => (
                <Flex
                  position={'absolute'}
                  top={0}
                  right={0}
                  zIndex={3}
                  height={'100%'}
                  alignItems={'center'}
                  pr={'1.25rem'}
                  visibility={{ base: 'hidden', lg: 'visible' }}
                >
                  <Fade in={hover} transition={{ enter: { duration: 0.5 }, exit: { duration: 0.5 } }}>
                    <Box
                      display={imagesWithMetaData.length > 1 ? 'flex' : 'none'}
                      __css={{ ...styles.arrowNext }}
                      onClick={() => {
                        if (selectedImage + 1 > imagesWithMetaData.length - 1) {
                          setSelectedImage(0);
                        } else {
                          setSelectedImage(selectedImage + 1);
                        }
                      }}
                    >
                      <svg width="31" height="21" viewBox="0 0 31 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <line x1="28" y1="10.4178" x2="6.11959e-08" y2="10.4178" stroke="white" strokeWidth="1.4" />
                        <path d="M21 20.418C21 14.8951 25.4772 10.418 31 10.418" stroke="white" strokeWidth="1.4" />
                        <path d="M31 10.418C25.4772 10.418 21 5.94082 21 0.417969" stroke="white" strokeWidth="1.4" />
                      </svg>
                    </Box>
                  </Fade>
                </Flex>
              )}
              renderArrowPrev={() => (
                <Flex
                  position={'absolute'}
                  zIndex={2}
                  height="100%"
                  alignItems={'center'}
                  pl={'1.25rem'}
                  visibility={{ base: 'hidden', lg: 'visible' }}
                >
                  <Fade in={hover} transition={{ enter: { duration: 0.5 }, exit: { duration: 0.5 } }}>
                    <Box
                      display={imagesWithMetaData.length > 1 ? 'flex' : 'none'}
                      __css={{ ...styles.arrowPrev }}
                      onClick={() => {
                        if (selectedImage - 1 < 0) {
                          setSelectedImage(imagesWithMetaData.length - 1);
                        } else {
                          setSelectedImage(selectedImage - 1);
                        }
                      }}
                    >
                      <svg width="31" height="21" viewBox="0 0 31 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <line x1="3" y1="10.4182" x2="31" y2="10.4182" stroke="white" strokeWidth="1.4" />
                        <path d="M10 0.417966C10 5.94082 5.52285 10.418 0 10.418" stroke="white" strokeWidth="1.4" />
                        <path
                          d="M-2.38419e-06 10.418C5.52285 10.418 10 14.8951 10 20.418"
                          stroke="white"
                          strokeWidth="1.4"
                        />
                      </svg>
                    </Box>
                  </Fade>
                </Flex>
              )}
            >
              {imagesWithMetaData?.map((item, index) => {
                return (
                  <Flex
                    key={index}
                    p="0"
                    height="100%"
                    __css={{ ...styles.imageBox }}
                    className="ahhg-image-box"
                    position={'relative'}
                  >
                    <NextImage
                      field={{
                        src: GetImageUrlFromString(item.Src, CarouselImageParams),
                        alt: item.Alt,
                        title: item.Title,
                      }}
                      // src={GetImageUrlFromString(item.Src, CarouselImageParams)}
                      loading="lazy"
                      className="media"
                      unoptimized={true}
                      style={{ objectFit: 'cover' }}
                      placeholder={'empty'}
                      //fill true adds position absolute, fixed in styling
                      fill={true}
                    />
                  </Flex>
                );
              })}
            </Carousel>
          )}
          <Box>
            {props.fields?.usePricetag && props.fields.usePricetag.value ? (
              <Box className="priceTag">
                <PriceTag {...props} />
              </Box>
            ) : props.fields?.sticker && props.fields.sticker.value?.src && props.fields?.stickerlink ? (
              <a href={props.fields?.stickerlink.value.href ?? '/'} target={props.fields?.stickerlink.value.target}>
                <Box className="sticker">
                  <Image
                    // src={props.fields?.sticker?.value?.src}
                    src={GetImageUrlFromString(_sticker.Src)}
                    fill={true}
                    style={{ objectFit: 'contain' }}
                    unoptimized={true}
                    alt={_sticker?.Alt ?? ''}
                    title={_sticker?.Title}
                  />
                </Box>
              </a>
            ) : _sticker && _sticker?.Src && props.fields?.stickerlink === undefined ? (
              <Image
                // src={props.fields?.sticker?.value?.src}
                src={GetImageUrlFromString(_sticker.Src)}
                fill={true}
                style={{ objectFit: 'contain' }}
                unoptimized={true}
                alt={_sticker?.Alt ?? ''}
                title={_sticker?.Title}
              />
            ) : null}
          </Box>
        </Box>
      </Box>
      {modalOpen && screenWidth > 1024 && (
        <Box
          __css={{ ...styles.modalContainer }}
          id="ahhg-image-gallery--modal-desktop"
          className="ahhg-image-gallery--modal-desktop"
        >
          <Box __css={{ ...styles.gridModal }}>
            <GridItem rowSpan={1} colSpan={5}>
              <Box __css={{ ...styles.modalHeader }}>
                <Box __css={{ ...styles.exitButtonContainer }} onClick={() => closeModal()}>
                  <X size={30} strokeWidth={2} color={'white'} />
                </Box>

                <Box color="white" ml="auto" fontSize="28px" display="flex" flexDirection="row">
                  <Text fontWeight="bold" mr="0.5rem">
                    {selectedImageModal < 9 ? '0' : ''}
                    {selectedImageModal + 1}
                  </Text>
                  /
                  <Text ml="0.5rem">
                    {imagesWithMetaData.length < 10 ? '0' : ''}
                    {imagesWithMetaData.length}
                  </Text>
                </Box>
              </Box>
            </GridItem>
            <GridItem rowSpan={2} colSpan={4}>
              <Box
                __css={{ ...styles.modalBigCarouselContainer }}
                sx={{
                  '.carousel-root': {
                    height: '100% !important',
                  },
                  '.carousel-slider': {
                    height: '100% !important',
                  },
                  '.slider-wrapper': {
                    height: '100% !important',
                  },
                  '.slider': {
                    height: '100% !important',
                  },
                }}
              >
                <Carousel
                  showArrows
                  showIndicators={false}
                  showStatus={false}
                  width="100%"
                  infiniteLoop
                  selectedItem={selectedImageModal}
                  showThumbs={false}
                  renderArrowNext={() => (
                    <Box
                      __css={{ ...styles.arrowNextModalBigContainer }}
                      onClick={() => {
                        if (selectedImageModal + 1 > imagesWithMetaData.length - 1) {
                          setSelectedImageModal(0);
                        } else {
                          setSelectedImageModal(selectedImageModal + 1);
                        }
                      }}
                    >
                      <svg width="31" height="21" viewBox="0 0 31 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <line x1="28" y1="10.4178" x2="6.11959e-08" y2="10.4178" stroke="white" strokeWidth="1.4" />
                        <path d="M21 20.418C21 14.8951 25.4772 10.418 31 10.418" stroke="white" strokeWidth="1.4" />
                        <path d="M31 10.418C25.4772 10.418 21 5.94082 21 0.417969" stroke="white" strokeWidth="1.4" />
                      </svg>
                    </Box>
                  )}
                  renderArrowPrev={() => (
                    <Box
                      __css={{ ...styles.arrowPrevModalBigContainer }}
                      onClick={() => {
                        if (selectedImageModal - 1 < 0) {
                          setSelectedImageModal(imagesWithMetaData.length - 1);
                        } else {
                          setSelectedImageModal(selectedImageModal - 1);
                        }
                      }}
                    >
                      <svg width="31" height="21" viewBox="0 0 31 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <line x1="3" y1="10.4182" x2="31" y2="10.4182" stroke="white" strokeWidth="1.4" />
                        <path d="M10 0.417966C10 5.94082 5.52285 10.418 0 10.418" stroke="white" strokeWidth="1.4" />
                        <path
                          d="M-2.38419e-06 10.418C5.52285 10.418 10 14.8951 10 20.418"
                          stroke="white"
                          strokeWidth="1.4"
                        />
                      </svg>
                    </Box>
                  )}
                >
                  {imagesWithMetaData.map((item, index) => (
                    <Flex
                      className="ahhg-gallery--modal-desktop"
                      display="flex"
                      key={index}
                      __css={{ ...styles.imagesModalBig }}
                    >
                      <Image
                        // src={imageFromBreakpoints(ModalDesktopParams, item.imageUrl, true)}
                        src={GetImageUrlFromString(item.Src, ModalParams)}
                        loading="lazy"
                        unoptimized={true}
                        style={{ objectFit: 'cover' }}
                        placeholder={'empty'}
                        // imageParams={ModalDesktopParams}
                        fill={true}
                        alt={item?.Alt ?? ''}
                        title={item?.Title}
                      />
                    </Flex>
                  ))}
                </Carousel>
              </Box>
            </GridItem>
            <GridItem rowSpan={2} colSpan={1} className="verticalCarousel" __css={styles.verticalCarousel}>
              <Carousel
                showIndicators={false}
                showStatus={false}
                axis="vertical"
                selectedItem={selectedImageModal}
                centerMode
                onClickItem={(index) => setSelectedImageModal(index)}
                renderArrowNext={() => (
                  <Box __css={{ ...styles.arrowNextModalPositioner }}>
                    <Box
                      __css={{ ...styles.arrowNextModalSmallContainer }}
                      onClick={() => {
                        if (selectedImageModal + 1 > imagesWithMetaData.length - 1) {
                          setSelectedImageModal(0);
                        } else {
                          setSelectedImageModal(selectedImageModal + 1);
                        }
                      }}
                    >
                      <svg width="12" height="18" viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <line
                          x1="6.00002"
                          y1="15.8184"
                          x2="6.00002"
                          y2="0.54563"
                          stroke="white"
                          strokeWidth="0.763636"
                        />
                        <path d="M0.545453 12C3.55792 12 6 14.4421 6 17.4545" stroke="white" strokeWidth="0.763636" />
                        <path d="M6 17.4545C6 14.4421 8.44208 12 11.4545 12" stroke="white" strokeWidth="0.763636" />
                      </svg>
                    </Box>
                  </Box>
                )}
                renderArrowPrev={() => (
                  <Box __css={{ ...styles.arrowPrevModalPositioner }}>
                    <Box
                      __css={{ ...styles.arrowPrevModalSmallContainer }}
                      onClick={() => {
                        if (selectedImageModal - 1 < 0) {
                          setSelectedImageModal(imagesWithMetaData.length - 1);
                        } else {
                          setSelectedImageModal(selectedImageModal - 1);
                        }
                      }}
                    >
                      <svg width="12" height="18" viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <line
                          x1="5.99998"
                          y1="2.18164"
                          x2="5.99998"
                          y2="17.4544"
                          stroke="white"
                          strokeWidth="0.763636"
                        />
                        <path d="M11.4545 6C8.44208 6 6 3.55792 6 0.545454" stroke="white" strokeWidth="0.763636" />
                        <path d="M6 0.545453C6 3.55792 3.55792 6 0.545454 6" stroke="white" strokeWidth="0.763636" />
                      </svg>
                    </Box>
                  </Box>
                )}
              >
                {imagesWithMetaData.map((item, index) => (
                  <Box
                    __css={{ ...styles.imagesModalSmall }}
                    className="ahhg-image-gallery--modal-small"
                    key={index}
                    //add custom border for nex and ste
                    sx={selectedImageModal === index ? styles.imagesModalSmallActive : undefined}
                  >
                    <NextImage
                      field={{
                        src: GetImageUrlFromString(item.Src, ModalThumpnail),
                        alt: item.Alt,
                        title: item.Title,
                      }}
                      loading="lazy"
                      unoptimized={true}
                      style={{ objectFit: 'cover' }}
                      fill={true}
                    />
                  </Box>
                ))}
              </Carousel>
            </GridItem>
          </Box>
        </Box>
      )}

      {/* ONG-4275 changed tablet view */}
      {modalOpen && screenWidth < 1024 && screenOrientation === 'landscape' && (
        <Flex
          className="ahhg-image-gallery--modal-mobile-landscape"
          bg={modalBgColor}
          justify="center"
          top="0"
          align="center"
          position="fixed"
          w="100%"
          h="100%"
          zIndex="999"
          direction="column"
          overflowY="scroll"
          mt="0 !important"
          pt=".75rem"
        >
          <Grid height={'100%'} width={'100%'}>
            {imagesWithMetaData.map((item, index) => (
              <GridItem key={index}>
                <Box width="100%">
                  <NaturalHeightImage item={item} customSizeAndRenderingIds={_ModalDesktopBP} />
                  {[index] && (
                    <Text fontFamily="Inter" mx="10px" mb="24px">
                      {imagesWithMetaData[index]?.Alt ?? ''}
                    </Text>
                  )}
                </Box>
              </GridItem>
            ))}
          </Grid>
          <Flex position="fixed" bottom="1.25rem" w="100%" justify="center">
            <Box
              borderRadius="50%"
              background="rgba(0,0,0)"
              cursor="pointer"
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="55px"
              height="55px"
              zIndex="20"
              mx="1rem"
              __css={{ ...styles.exitButtonContainer }}
              onClick={() => closeModal()}
            >
              <X size={30} strokeWidth={2} color={'white'} />
            </Box>
          </Flex>
        </Flex>
      )}

      {modalOpen && screenWidth < 1024 && screenOrientation === 'portrait' && (
        <Flex
          className="ahhg-image-gallery--modal-mobile-portrait"
          bg={modalBgColor}
          justify="center"
          top="0"
          align="center"
          position="fixed"
          w="100%"
          h="100%"
          zIndex="999"
          direction="column"
          overflowY="scroll"
          mt="0 !important"
          pt=".75rem"
        >
          <Grid height={'100%'} width={'100%'}>
            {imagesWithMetaData.map((item, index) => (
              <GridItem key={index}>
                <Box width="100%">
                  <NaturalHeightImage item={item} customSizeAndRenderingIds={_ModalDesktopBP} />
                  {imagesWithMetaData[index] && (
                    <Text fontFamily="Inter" mx="10px" mb="24px">
                      {imagesWithMetaData[index]?.Alt ?? ''}
                    </Text>
                  )}
                </Box>
              </GridItem>
            ))}
          </Grid>
          <Flex position="fixed" bottom="1.25rem" w="100%" justify="center">
            <Box
              borderRadius="50%"
              background="rgba(0,0,0)"
              cursor="pointer"
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="55px"
              height="55px"
              zIndex="20"
              mx="1rem"
              __css={{ ...styles.exitButtonContainer }}
              onClick={() => closeModal()}
            >
              <X size={30} strokeWidth={2} color={'white'} />
            </Box>
          </Flex>
        </Flex>
      )}
    </>
  ) : (
    <></>
  );
};

export default withDatasourceCheck()<ImageGalleryProps>(ImageGallery);
