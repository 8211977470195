import React, { ReactElement } from 'react';
import classes from '../../ParentBox.module.scss';
import customClasses from './ChangeBox.module.scss';
import { useI18n } from 'next-localization';
import { CancelData } from 'src/store/booking-store-modules';
// import CurrencySymbol from 'components/Currency/CurrencyFormatter/CurrencySymbol';
// import CurrencyValue from 'components/Currency/CurrencyFormatter/CurrencyValue';
import BrandButton from 'components/booking/BrandButton/BrandButton';
import BookingChangeTrigger from 'components/booking/BookingStickyMenu/BookingChangeTrigger/BookingChangeTrigger';

interface IProps {
  CancelData: CancelData;
}

const ChangeBox: React.FC<IProps> = ({ CancelData }): ReactElement => {
  const { t } = useI18n();

  const submitHandler = () => {
    console.log('submit');
  };

  return (
    <>
      {CancelData && (
        <div className={classes.roomBoxContainer}>
          <div className={`${classes.roomType} ${customClasses.cancelBox}`}>
            <h2 className={customClasses.headline}>{t(`booking_${CancelData.roomType}_headline`)}</h2>
            <BookingChangeTrigger changeBooking={true} />
            <div className={customClasses.CancelButton}>
              {/* disabled on loading */}
              <BrandButton buttonText="Cancel reservation" clickHandler={submitHandler} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default ChangeBox;
