import { Box, useDimensions, useMultiStyleConfig } from '@chakra-ui/react';
// import { Link as SitecoreLink } from '@sitecore-jss/sitecore-jss-nextjs';
// import { ArrowRightIcon } from 'assets/Icons/ArrowRightIcon';
import { ArrowRightIcon } from 'assets/Icons/ui/ArrowRightIcon';
import { useEffect, useMemo, useRef } from 'react';
// import { ComponentProps } from '../../lib/component-props';
// import { IDroptreeColor } from '../Component-models/data-items/IDroptreeColor';
import { IsHomePage } from '../../lib/SitecoreHelper';
import Link from 'next/link';
import { useI18n } from 'next-localization';
import { hexToRgbA } from 'lib/helpers';
export type BreadcrumbProps = {
  // fields: {
  //   textcolor: IDroptreeColor;
  // };
  data: BreadcrumbsType | undefined;
  whiteLogo: boolean;
  headerBgColor: string;
};

export type BreadcrumbType = {
  name: string;
  path: string;
};

export type BreadcrumbsType = {
  allpath: {
    breadcrumbs: Array<BreadcrumbType>;
  };
  removeBreadcrumb: boolean;
};

// export const getServerSideProps: GetServerSideComponentProps = async (_rendering, layoutData) => {
//   if (process.env.JSS_MODE == constants.JSS_MODE.DISCONNECTED) {
//     return null;
//   }

//   const graphQLClient = new GraphQLRequestClient(config.sitecoreApiHost + '/api/breadcrumb', {
//     apiKey: config.sitecoreApiKey,
//   });
//   /* eslint-disable  @typescript-eslint/no-explicit-any */
//   const result = await graphQLClient.request<BreadcrumbsType>(BreadcrumbQueryDocument as any, {
//     itemId: layoutData?.sitecore?.route?.itemId,
//   });
//   return result;
// };

const Breadcrumb: React.FC<BreadcrumbProps> = (props): JSX.Element => {
  const styles = useMultiStyleConfig('Breadcrumb', {});

  const headerColor = props.headerBgColor ?? '#000000';
  const fadeBackgroundColor = `linear-gradient(90deg, ${hexToRgbA(headerColor, 1)} 0%, ${hexToRgbA(
    headerColor,
    1
  )} 33%, ${hexToRgbA(headerColor, 0.83)} 75%, ${hexToRgbA(headerColor, 0)} 100%)`;

  const whiteLogo = props.whiteLogo;
  const sortedBreadcrumbs = props?.data?.allpath.breadcrumbs ?? [];
  const scrollRef = useRef<HTMLDivElement>(null);
  const scrollEndRef = useRef<HTMLDivElement>(null);
  const dimensions = useDimensions(scrollRef, true);
  const isScrollable = useMemo(() => {
    if (!dimensions || !scrollRef.current) return false;
    if (dimensions.borderBox.width < scrollRef.current?.scrollWidth) return true;

    return false;
  }, [dimensions]);

  // Scroll to the end of the breadcrumbs
  useEffect(() => {
    if (scrollRef.current && scrollEndRef.current) {
      const rect = scrollRef.current.getBoundingClientRect();
      scrollRef.current.scrollLeft = rect.right;
    }
  });

  // If an item has RemoveBreadcrumb true, show empty breadcrumb
  const { locale } = useI18n();
  if (IsHomePage() || props.data?.removeBreadcrumb) {
    return <></>;
  }

  return (
    <Box __css={{ ...styles.container }}>
      {isScrollable ? (
        <Box __css={styles.scrollFade} background={fadeBackgroundColor} className="breadcrumb-fade" />
      ) : null}
      <Box ref={scrollRef} __css={styles.breadcrumbListContainer}>
        {sortedBreadcrumbs.map((breadcrumb: BreadcrumbType, index: number) => {
          return (
            <Box __css={styles.breadcrumb} key={breadcrumb.path} color={whiteLogo ? 'white' : 'black'}>
              <Link
                href={breadcrumb.path}
                // style={styles.breadcrumbLink}
                // pointerEvents={index === sortedBreadcrumbs.length - 1 ? 'none' : 'auto'}
                locale={locale()}
              >
                {breadcrumb.name}
              </Link>
              {index !== sortedBreadcrumbs.length - 1 ? <ArrowRightIcon /> : null}
            </Box>
          );
        })}
        <Box ml="2rem" ref={scrollEndRef} />
      </Box>
      <Box __css={{ ...styles.divider, borderColor: whiteLogo ? 'white' : 'black' }} />
    </Box>
  );
};

export default Breadcrumb;
