import { Icon, useTheme } from '@chakra-ui/react';
import { ReactElement } from 'react';

export const CircleIcon = ({ checked }: { checked: boolean }): ReactElement => {
  const currentTheme = process.env.NEXT_PUBLIC_HOSTEL_THEME;
  const { colors } = useTheme();
  const nextHouseColor = colors.green[200];
  const steelHouseColor = colors.yellow[500];
  return (
    <Icon viewBox="0 0 200 200" w={'24px'} h={'23px'}>
      {checked ? (
        <path
          fill={currentTheme === 'nexthouse' ? nextHouseColor : steelHouseColor}
          d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0"
        />
      ) : null}
    </Icon>
  );
};
