import { withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { Flex } from '@chakra-ui/react';
import {
  AmexIcon,
  DinersIcon,
  DiscoverIcon,
  KlarnaIcon,
  MaestroIcon,
  MastercardIcon,
  PaypalIcon,
  VisaIcon,
} from '../../../assets/Icons/payment';

const Footer: React.FC = (): JSX.Element => {
  return (
    <Flex columnGap={2}>
      <AmexIcon boxSize={8} />
      <DinersIcon boxSize={8} />
      <DiscoverIcon boxSize={8} />
      <KlarnaIcon boxSize={8} />
      <MaestroIcon boxSize={8} />
      <MastercardIcon boxSize={8} />
      <PaypalIcon boxSize={8} />
      <VisaIcon boxSize={8} />
    </Flex>
  );
};

export default withDatasourceCheck()(Footer);
