import React from 'react';
import { ComponentProps } from 'lib/component-props';
import { Box, useMultiStyleConfig } from '@chakra-ui/react';
import { IGridParameters } from '../../Component-models/base-items/IGridParameters';

type DividerProps = ComponentProps & {
  params: IGridParameters;
};

const HostelDivider: React.FC<DividerProps> = (props: DividerProps) => {
  const styles = useMultiStyleConfig('Divider', {});
  const LineColormatches = props.params.Styles && props.params.Styles.match(/[a-zA-Z]+.[\d]{2,3}/);

  return (
    <Box
      className={props.params.GridParameters}
      __css={styles.container}
      color={LineColormatches ? LineColormatches[0] : 'black'}
    ></Box>
  );
};

export default HostelDivider;
