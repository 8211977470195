import { Box, Link, Text, useMultiStyleConfig } from '@chakra-ui/react';
import { useI18n } from 'next-localization';

import ArrowRight from '../Content-Components/icons/ArrowRight';
import { ResultForCurrentPage } from '../../Component-models/SearchModels';

const SearchResult = ({ Title, SampleText, PageLink, ...props }: ResultForCurrentPage): JSX.Element => {
  const styles = useMultiStyleConfig('SearchResult', { props });

  const { locale } = useI18n();
  return (
    <Link
      href={`${process.env.PUBLIC_URL}${locale() == 'en' ? '' : '/' + locale()}${PageLink}`}
      _hover={{ textDecoration: 'none', cursor: 'default' }}
    >
      <Box __css={{ ...styles.container }} className="ahhg-search-result">
        <Box __css={styles.title}>
          <Text fontFamily="body">{Title}</Text>
          <ArrowRight size={15} style={styles.arrow} />
        </Box>
        <Box __css={{ ...styles.excerpt }}>
          <Text textStyle="p" dangerouslySetInnerHTML={{ __html: SampleText }}></Text>
        </Box>
      </Box>
    </Link>
  );
};

export default SearchResult;
