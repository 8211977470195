import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
export const IsHomePage = (): boolean => {
  const context = useSitecoreContext();
  const site = context.sitecoreContext.site?.name;
  switch (site) {
    case 'nex':
      if (context.sitecoreContext.route?.itemId === '3dc843a0-0246-4e4b-84f5-af4b02cc64e6') {
        return true;
      }
      break;
    case 'ste':
      if (context.sitecoreContext.route?.itemId === 'eb3c132e-8884-467f-8423-c19abd4c8bc6') {
        return true;
      }
      break;
  }
  return false;
};
