import { Icon, IconProps } from '@chakra-ui/react';
import { ReactElement } from 'react';

export const MaestroIcon = (props: IconProps): ReactElement => {
  return (
    <Icon {...props} viewBox="0 0 31 21">
      <rect
        x="1.06667"
        y="0.629167"
        width="28.9292"
        height="19.7417"
        rx="2.51667"
        fill="white"
        stroke="#D9D9D9"
        strokeWidth="1.25833"
      />
      <path
        d="M17.8629 10.4278C17.8629 14.0123 14.9904 16.9181 11.4471 16.9181C7.90372 16.9181 5.03125 14.0123 5.03125 10.4278C5.03125 6.84331 7.90372 3.9375 11.4471 3.9375C14.9904 3.9375 17.8629 6.84331 17.8629 10.4278Z"
        fill="#ED0006"
      />
      <path
        d="M26.1862 10.4278C26.1862 14.0123 23.3137 16.9181 19.7703 16.9181C16.227 16.9181 13.3545 14.0123 13.3545 10.4278C13.3545 6.84331 16.227 3.9375 19.7703 3.9375C23.3137 3.9375 26.1862 6.84331 26.1862 10.4278Z"
        fill="#0099DF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6087 15.3677C16.9882 14.1773 17.8629 12.4059 17.8629 10.4278C17.8629 8.44979 16.9882 6.67842 15.6087 5.48798C14.2292 6.67842 13.3545 8.44979 13.3545 10.4278C13.3545 12.4059 14.2292 14.1773 15.6087 15.3677Z"
        fill="#6C6BBD"
      />
    </Icon>
  );
};
