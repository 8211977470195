import React from 'react';
import classes from './BrandButton.module.scss';
import { useI18n } from 'next-localization';

interface IProps {
  clickHandler?: () => void;
  buttonText?: string;
  dataQa?: string;
}

const BrandButton: React.FC<IProps> = ({ clickHandler, buttonText, dataQa }) => {
  const { t } = useI18n();
  return (
    <button onClick={clickHandler} className={classes.brandButton} data-qa={dataQa}>
      {buttonText ?? t('booking_button_add_reservation')}
    </button>
  );
};
export default BrandButton;
