import { ComponentProps } from 'lib/component-props';
import React from 'react';
import Script from 'next/script';
import { useI18n } from 'next-localization';

type HtmlContentModel = ComponentProps & {
  fields: { scriptSource: { value: string } };
};

const CookiePolicy = (props: HtmlContentModel) => {
  const { locale } = useI18n();

  return (
    <div className="sc-rich-text">
      <div id="cookie-information-declaration"></div>
      <Script
        id="CookiePolicy"
        src={props.fields.scriptSource.value}
        data-culture={locale().toUpperCase()}
        type="text/javascript"
        strategy="lazyOnload"
      ></Script>
    </div>
  );
};

export default CookiePolicy;
