import { useState } from 'react';
import { Box, useMultiStyleConfig, Flex, keyframes } from '@chakra-ui/react';
import {
  Text,
  withDatasourceCheck,
  Field,
  useSitecoreContext,
  GetStaticComponentProps,
  NextImage,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import { fadeIn } from 'assets/animations';
import { IbaseTextDouble, TextFieldsValue } from '../../Component-models/data-items/ITextDouble';
import Link from 'next/link';
import { PriceTagsProps } from '../Currency/PriceTag';
import VideoComponent from '../Video/VideoComponent';
import { useGeneralStore } from 'src/store';
import { DamImageField } from 'lib/damTypes';

import imageSizeData from 'public/image-sizes.json';
import { GetImageUrlFromString, GetMetaData, generateBreakpointValues, orginalDigizuiteImage } from 'lib/helpers';

type HeroProps = ComponentProps &
  PriceTagsProps & {
    fields: {
      children: IbaseTextDouble[];
      headingtag: Field<string>;
      subheading: Field<string>;
      subheadingtag: Field<string>;
      mainheading: Field<string>;
      mainimage: orginalDigizuiteImage;
      sticker: orginalDigizuiteImage;
      stickerlink: DamImageField;
      trumpettext: Field<string>;
      trumpetsvg: orginalDigizuiteImage;
      mobilevideo: orginalDigizuiteImage;
      biggervideo: orginalDigizuiteImage;
      videoURLDesktop: Field<string>;
      videoURLMobile: Field<string>;
    };
    params: {
      GridParameters: string;
    };
  };

const slideUp = keyframes`
  0% {
    transform: translateY(-200%)
  }

  20% {
    transform: translateY(0%)
  }

  87% {
    transform: translateY(0%)
  }

  100% {
    transform: translateY(-200%)
  }
`;

const slideDown = keyframes`
  0% {
    transform: translateY(200%)
  }

  20% {
    transform: translateY(0%)
  }

  87% {
    transform: translateY(0%)
  }

  100% {
    transform: translateY(200%)
  }
`;

const Hero: React.FC = (props: HeroProps): JSX.Element => {
  const styles = useMultiStyleConfig('Hero', {});

  const { isDesktop } = useGeneralStore((state) => state);
  const { sitecoreContext } = useSitecoreContext();
  const isEditing = sitecoreContext && sitecoreContext.pageEditing;

  // make a string array of objects with uppper and lower text
  type UpperLowerText = {
    upperText: TextFieldsValue;
    lowerText: TextFieldsValue;
  };

  let headlines: UpperLowerText[] = [];

  if (props.fields && props.fields.children) {
    headlines = props.fields.children.map((child) => {
      return {
        upperText: child.fields.uppertext,
        lowerText: child.fields.lowertext,
      };
    });

    if (isEditing) {
      // remove all but the first headline
      headlines = headlines.slice(0, 1);
    }
  }

  const [currentPhrase, setCurrentPhrase] = useState(0);
  const onAnimationIteration = () => {
    if (currentPhrase < headlines.length - 1) {
      setCurrentPhrase(currentPhrase + 1);
    } else {
      setCurrentPhrase(0);
    }
  };

  const heroBP = generateBreakpointValues(imageSizeData.hero);
  const _image = GetMetaData(props.fields?.mainimage);
  const _trumpetsvg = GetMetaData(props.fields?.trumpetsvg);
  const _sticker = GetMetaData(props.fields?.sticker);

  // First, ensure props.fields is defined
  if (props.fields) {
    // Check if props.fields.headingtag exists and then set its value to lowercase
    if (props.fields.headingtag?.value) {
      props.fields.headingtag.value = props.fields.headingtag.value.toLowerCase();
    }

    // Check if props.fields.subheadingtag exists and then set its value to lowercase
    if (props.fields.subheadingtag?.value) {
      props.fields.subheadingtag.value = props.fields.subheadingtag.value.toLowerCase();
    }
  }
  return (
    <>
      <Box className={`ahhg-hero ${props.params.GridParameters}`} __css={{ ...styles.container }}>
        <Box __css={{ ...styles.card }} className="ahhg-hero-card">
          <VideoComponent
            videoSrc={
              props.fields
                ? isDesktop
                  ? props.fields.videoURLDesktop?.value ?? ''
                  : props.fields.videoURLMobile?.value ?? ''
                : ''
            }
            videoPoster={_image?.Src ?? ''}
            className="media"
            isHero={true}
            customSizesAndRenderingIds={heroBP}
          />
          <Box __css={{ ...styles.textFields }}>
            <Flex className="trumpet" animation={`${fadeIn || ''} 1s ease-out`} __css={{}}>
              <Box className="text">
                <Text
                  tag={props?.fields?.headingtag?.value || ''}
                  field={{ value: props.fields?.trumpettext.value || '' }}
                />
              </Box>
              {_trumpetsvg.Src && (
                <NextImage
                  field={{
                    src: GetImageUrlFromString(_trumpetsvg.Src),
                    alt: _trumpetsvg.Alt,
                    title: _trumpetsvg.Title,
                  }}
                  fill={true}
                  unoptimized={true}
                />
                // <Image
                //   src={_trumpetsvg.Src || ''}
                //   height="20"
                //   width="20"
                //   className="image"
                //   alt={_trumpetsvg.Alt || ''}
                // />
              )}
            </Flex>
            {headlines.length > 1 ? (
              <h1>
                <Flex
                  h={['60px', '130px']}
                  overflow="hidden"
                  justify="center"
                  __css={{ ...styles.headline }}
                  className="headline"
                >
                  <Box
                    animation={`${slideUp} 6000ms ease-in-out infinite`}
                    transform="translateY(-200%)"
                    className="upperText"
                    onAnimationIteration={onAnimationIteration}
                  >
                    <span>{headlines[currentPhrase].upperText.value}</span>
                  </Box>
                </Flex>
                <Flex h={['60px', '130px']} overflow="hidden" justify="center" __css={{ ...styles.headline }}>
                  <Box
                    animation={`${slideDown} 6000ms ease-in-out infinite`}
                    transform="translateY(200%)"
                    className="lowerText"
                  >
                    <span> {headlines[currentPhrase].lowerText.value}</span>
                  </Box>
                </Flex>
              </h1>
            ) : (
              <Flex
                h={['120px', '260px']}
                overflow="hidden"
                justify="center"
                __css={{ ...styles.headline }}
                className="headline"
                direction="column"
              >
                {headlines.length > 0 && (
                  <h1>
                    <Box className="upperText">
                      <span>{headlines[0].upperText.value}</span>
                    </Box>
                    <Box className="lowerText">
                      <span> {headlines[0].lowerText.value}</span>
                    </Box>
                  </h1>
                )}
              </Flex>
            )}

            {/* field={props.fields?.subheading || ''} */}
            <Box className="subtitle" animation={`${fadeIn} 1s ease-out`}>
              <Text
                field={{ value: props.fields?.subheading.value || '' }}
                tag={props.fields?.subheadingtag?.value || ''}
              />
            </Box>
          </Box>

          <Box className="sticker" cursor="pointer">
            <Link
              href={props.fields?.stickerlink?.value?.href ?? ''}
              target={props.fields?.stickerlink?.value?.target as string}
            >
              {/* {_sticker.Src && <img src={_sticker?.Src} alt={_sticker.Alt} />} */}
              <NextImage
                field={{
                  src: GetImageUrlFromString(_sticker.Src),
                  alt: _sticker.Alt,
                  title: _sticker.Title,
                }}
                fill={true}
                unoptimized={true}
              />
            </Link>
          </Box>
        </Box>
      </Box>
    </>
  );
};

// This function gets called at request time on server-side.
export const getStaticProps: GetStaticComponentProps = async () => {
  return {};
};

export default withDatasourceCheck()<HeroProps>(Hero);
