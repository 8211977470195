import {
  Box,
  FormControl,
  Input,
  Modal as ChakraModal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import CButton from 'components/buttons/CButton';
import { useEffect, useRef, useState } from 'react';
import { useCompanyStore, useCartStore } from 'src/store';
import { useI18n } from 'next-localization';
import axios from 'axios';
import { CompanyLoginResponse } from 'lib/booking-helpers';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  message: string;
  buttonText?: string;
}

const CompanyLoginModal = (props: Props): JSX.Element => {
  const { t, locale } = useI18n();
  const { sitecoreContext } = useSitecoreContext();

  const { isOpen, onClose, title, message, buttonText = t('booking_alert_close_text') } = props;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const { setLoggedIn, setCompanyDetails } = useCompanyStore((state) => state);
  const { setCartItems, setCartItemIds } = useCartStore((state) => state);

  const styles = useMultiStyleConfig('Modal', { form: { width: '50%' } });

  useEffect(() => {
    if (isOpen) {
      setError('');
    }
  }, [isOpen]);

  const hostelCodeRef = useRef<HTMLInputElement>(null);
  const submitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (hostelCodeRef.current && hostelCodeRef.current.value) {
      try {
        setLoading(true);
        const response = await axios.post<CompanyLoginResponse>(`/api/ahhg/suite8/Login`, {
          ProfileId: hostelCodeRef.current.value,
          hostelCode: sitecoreContext.site?.name,
          language: locale(),
        });
        setCompanyDetails(response.data);
        setLoggedIn(true);
        setCartItems([]);
        setCartItemIds([]);
        onClose();
      } catch (error) {
        error instanceof Error && setError(t('booking_company_error'));
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <ChakraModal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent sx={styles.container}>
        <ModalCloseButton __css={styles.closeButton} />
        <Box __css={styles.title}>
          <Text>{title}</Text>
        </Box>
        <Box __css={styles.divider} />
        <Box __css={styles.message}>
          <Text whiteSpace="pre-wrap">{message}</Text>
          <Text whiteSpace="pre-wrap" color={'red'}>
            {error}
          </Text>
        </Box>
        <Box __css={styles.formContainer}>
          <form action="" onSubmit={submitHandler}>
            <FormControl>
              <Input required type={'text'} ref={hostelCodeRef} placeholder={t('booking_company_login_placeholder')} />
            </FormControl>
            <CButton
              type="submit"
              text={buttonText}
              backgroundColor={styles.button.backgroundColor as string}
              color={styles.button.color as string}
              marginTop={'1rem'}
              loading={loading}
            />
          </form>
        </Box>
      </ModalContent>
    </ChakraModal>
  );
};

export default CompanyLoginModal;
