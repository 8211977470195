import { Box, Grid, GridItem, useMultiStyleConfig } from '@chakra-ui/react';
import { withDatasourceCheck, Field, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import { useState } from 'react';
import { ITag } from '../../../Component-models/base-items/Itext';
import CLink from 'components/buttons/CLink';
import { FaqItemType } from '../../../Component-models/FaqItems';
import FaqItem from './FaqItem';

type MiniFaqProps = ComponentProps & {
  fields: {
    headline: Field<string>;
    // headlineTag: Field<string>; // Not used ??
    linkTitle: Field<{ href: string; text: string; target: string }>;
    // linkTo: Field<string>; // Not used ??
    hTag: ITag;
    faqItems: FaqItemType[];
  };
};

const MiniFaq = ({ fields, ...props }: MiniFaqProps): JSX.Element => {
  const styles = useMultiStyleConfig('MiniFaq', { props });
  const [openFaq, setOpenFaq] = useState<number | null>();
  return (
    <Box __css={{ ...styles.container }} className={`ahhg-mini-faq ${props.params?.GridParameters ?? ''}`}>
      <Grid
        templateRows="repeat(1, 1fr)"
        templateColumns="repeat(2, 1fr)"
        gap={{ base: 12, md: 10 }}
        paddingTop="1rem"
        paddingRight={{ base: '0px', lg: '12px' }}
      >
        <GridItem colSpan={{ base: 2, md: 1 }}>
          <Box textStyle={fields.hTag?.fields?.Tag?.value ?? 'h2'} maxW="50%">
            <Text tag={fields.hTag?.fields?.Tag?.value} field={fields.headline} />
          </Box>
        </GridItem>
        <GridItem colSpan={{ base: 2, md: 1 }}>
          {fields.faqItems && fields.faqItems.length > 0 ? (
            fields.faqItems.map((faqItem, index: number) => (
              <FaqItem
                key={faqItem.id}
                headline={faqItem.fields.Headline?.value}
                description={faqItem.fields.Description?.value}
                onClick={() => setOpenFaq(index === openFaq ? null : index)}
                isOpen={index === openFaq}
              />
            ))
          ) : (
            <div></div>
          )}
          {fields.linkTitle.value.href && (
            <Box __css={styles.button}>
              <CLink
                variant="outline"
                text={fields.linkTitle.value.text}
                href={fields.linkTitle.value.href}
                target={fields.linkTitle.value.target}
                color={'darkGrey.700'}
                marginTop={{ base: '1.125rem', lg: '1.5rem' }}
              />
            </Box>
          )}
        </GridItem>
      </Grid>
    </Box>
  );
};

export default withDatasourceCheck()<MiniFaqProps>(MiniFaq);
