import { Box, useMultiStyleConfig } from '@chakra-ui/react';
// Link as SitecoreLink,
import { withDatasourceCheck, Field } from '@sitecore-jss/sitecore-jss-nextjs';
import { I_GeneralLink } from '../../Component-models/base-items/IbaseLink';
import { IDroptreeColor } from '../../Component-models/data-items/IDroptreeColor';
import { ComponentProps } from '../../lib/component-props';
import CLink from '../buttons/CLink';

type LinkListProps = ComponentProps & {
  fields: {
    textcolor: IDroptreeColor;
    isOneByLine: Field<boolean>;
    children: Array<I_GeneralLink>;
  };
};

const HostelLinkList: React.FC = (props: LinkListProps): JSX.Element => {
  const { textcolor, isOneByLine, children } = props.fields;
  const styles = useMultiStyleConfig('LinkList', {});
  const rteLeftMargin = props.params.Styles !== undefined && props.params.Styles.includes('rte-left-margin');
  const topMargin = props.params.Styles !== undefined && props.params.Styles.includes('top-margin');

  return (
    <Box
      className={`ahhg-hostel-link-list ${props.params.GridParameters} ${rteLeftMargin ? 'rte-left-margin' : ''} ${
        topMargin ? 'top-margin' : ''
      }`}
      __css={{ ...styles.linkList, flexDirection: isOneByLine.value ? 'column' : undefined }}
    >
      {children.map((item) => (
        <CLink
          variant="outline"
          size="category"
          key={item.id}
          text={item.fields.Link.value.text}
          href={item.fields.Link.value.href}
          target={item.fields.Link.value.target}
          color={textcolor?.fields?.colorcode?.value ?? 'black'}
        />
      ))}

      {/* <pre>{JSON.stringify(props, null, 2)}</pre> */}
    </Box>
  );
};

export default withDatasourceCheck()(HostelLinkList);
