import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import classes from '../../ParentBox.module.scss';
import {
  useCartStore,
  useDormStore,
  useBookingMenuDatesStore,
  useCompanyStore,
  useSitecoreFieldsStore,
} from '../../../../store';
import { CartItem, RoomBox, PricingStructure } from '../../../../store/booking-store-modules';
import { Checkbox } from '@chakra-ui/react';
import { CircleIcon } from '../../../../assets/Icons/CircleIcon';
import { useI18n } from 'next-localization';
import BrandButton from 'components/booking/BrandButton/BrandButton';
import CurrencySymbol from 'components/Currency/CurrencyFormatter/CurrencySymbol';
import CurrencyValue from 'components/Currency/CurrencyFormatter/CurrencyValue';
import {
  findBookedAvailability,
  generateOptions,
  checkRoomType,
  checkLimiter,
  soldoutDorm,
  checkDormRoomDisabled,
  getAlertMessage,
} from 'lib/booking-box-helpers';
import Modal from 'components/Modal';
import DormChildModal from './DormChildModal';
import { fireEcomDataLayerEvent } from 'lib/helpers';
import {
  useGenerateDataLayerWhenExtraPackage1AlreadyActive,
  useGenerateDataLayerObject,
  useGenerateDataLayerObjectExtra2,
} from 'lib/customHooks/hooks';

interface IProps {
  DormData: PricingStructure['Dorms']['B4'] | PricingStructure['Dorms']['B6'];
}

const DormBox: React.FC<IProps> = ({ DormData }): ReactElement => {
  const {
    addCartItem,
    cartItems,
    cartItemsIds,
    updateCartItemGuests,
    identifier,
    addCartItemId,
    extraPackage2Checked,
    extraPackage2Details,
    cartFetchingDates,
    setCartFetchingDates,
    companyRatesActive,
    setCompanyRatesActive,
    package1DetailsList,
  } = useCartStore((state) => state);
  const { soldoutDorms, DormsData } = useDormStore((state) => state);
  const { companyActive } = useCompanyStore((state) => state);
  const { menuFetchingDates, flex, setIsFlexDisabled, setIsRestrictedDisabled } = useBookingMenuDatesStore(
    (state) => state
  );
  const { sitecoreFields } = useSitecoreFieldsStore((state) => state);

  const { t } = useI18n();
  const dormDetails: RoomBox[] = [];
  const [currentRoomType, setCurrentRoomType] = useState<RoomBox>();
  const [numberOfGuests, setNumberOfGuests] = useState(0);
  const [limiter, setLimiter] = useState(0);
  const [finalAvailability, setFinalAvailability] = useState<number | undefined>();
  const [openRoomModal, setOpenRoomModal] = useState(false);
  const [openRoomChildModal, setOpenRoomChildModal] = useState(false);
  const [roomChildReservation, setRoomChildReservation] = useState<CartItem>();
  const [alertError, setAlertError] = useState('');

  const dataLayerObj = useGenerateDataLayerObject(
    'dormBox',
    currentRoomType?.UniqueQualifier,
    currentRoomType?.Price,
    numberOfGuests,
    currentRoomType?.RoomType
  );

  const dataLayerArrAdd = useGenerateDataLayerObjectExtra2(
    extraPackage2Details['adult'].PackagePrice,
    extraPackage2Details['child'].PackagePrice,
    'add',
    numberOfGuests
  );
  const dataLayerExtraPackage1AlreadyActive = useGenerateDataLayerWhenExtraPackage1AlreadyActive(numberOfGuests);

  //get lowest price between all 3 room types
  useEffect(() => {
    if (DormData) {
      Object.keys(DormData).forEach((element) => {
        if (dormDetails.length < Object.keys(DormData).length) {
          if (DormData[element][0].RoomType === '4BED' || DormData[element][0].RoomType === '6BED') {
            dormDetails.unshift(DormData[element][0]);
            return;
          }
          dormDetails.push(DormData[element][0]);
        }
      });

      let lowestPriceRoom2: RoomBox | undefined;
      dormDetails.forEach((dorm) => {
        if (dorm.Price > 0) {
          if (!lowestPriceRoom2 || (dorm.Price < lowestPriceRoom2.Price && dorm.Availability > 0)) {
            lowestPriceRoom2 = dorm;
          }
        }
      });

      //if lowest price room is sold out, check if 4BED or 6BED is available, otherwise set to soldout
      lowestPriceRoom2 && lowestPriceRoom2.Availability > 0
        ? setCurrentRoomType(lowestPriceRoom2)
        : setCurrentRoomType(soldoutDorm[`${dormDetails[0]?.RoomType}-SOLDOUT` as keyof typeof soldoutDorm]);
    }
  }, [DormData]);

  useEffect(() => {
    if (currentRoomType) {
      const sitecoreAvailability = parseInt(t(`booking_${currentRoomType.RoomType}_availability`));
      const currentRoomLimiter = checkLimiter(
        currentRoomType.RoomType,
        currentRoomType.Availability,
        sitecoreAvailability
      );
      setLimiter(currentRoomLimiter);

      if (currentRoomType.Availability > 0) {
        let bookedGuests = 0;
        if (currentRoomType.RoomType.includes('B-')) {
          bookedGuests = findBookedAvailability(cartItems, currentRoomType.RoomType, cartItemsIds);
          setFinalAvailability(limiter - bookedGuests);
        } else {
          //if currentRoomType is private (4Bed, 6Bed), Select will always have 4/6 options, but availability will be based on booked guests(rooms)
          bookedGuests = cartItems.filter((item) => item.RoomType == currentRoomType.RoomType).length;
          setFinalAvailability(sitecoreAvailability - bookedGuests);
        }
      }
    }
  }, [cartItems, currentRoomType, limiter]);

  const changeChildRoom = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
      const { value } = event.target;
      setCurrentRoomType(DormData[value][0]);
    },
    [DormData]
  );

  const checkChildRoomSoldout = useCallback(
    (privateRoom: string) => {
      if (privateRoom === '4BED') {
        !soldoutDorms.includes('B-4')
          ? setCurrentRoomType(DormData['B-4'][0])
          : !soldoutDorms.includes('B-4F') && setCurrentRoomType(DormData['B-4F'][0]);
      } else {
        !soldoutDorms.includes('B-6')
          ? setCurrentRoomType(DormData['B-6'][0])
          : !soldoutDorms.includes('B-6F') && setCurrentRoomType(DormData['B-6F'][0]);
      }
    },
    [soldoutDorms, DormData]
  );
  const changePrivateRoom = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
      const { value } = event.target;
      //when going from private to non private, check if any of the others are not soldout
      if (!currentRoomType?.RoomType.includes('B-') && value === '4BED') {
        checkChildRoomSoldout('4BED');
        return;
      }
      if (!currentRoomType?.RoomType.includes('B-') && value === '6BED') {
        checkChildRoomSoldout('6BED');
        return;
      }

      setCurrentRoomType(DormData[value][0]);
      setNumberOfGuests(0);
    },
    [DormData, currentRoomType?.RoomType, checkChildRoomSoldout]
  );

  const changeGuestsNumber = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setNumberOfGuests(parseInt(event.target.value));
  };

  const checkActivePackages = () => {
    const packages = [];
    if (extraPackage2Checked) {
      packages.push({ ...extraPackage2Details['adult'], Count: numberOfGuests });
    }
    //check if package1DetailsList is not empty and if Active is true add to packages
    if (package1DetailsList.length > 0) {
      package1DetailsList.forEach((package1) => {
        if (package1.Active) {
          packages.push({ ...package1, Count: numberOfGuests });
        }
      });
    }
    return packages;
  };

  const handleAddReservation = useCallback(() => {
    if (
      (menuFetchingDates.checkIn !== cartFetchingDates.checkIn ||
        menuFetchingDates.checkOut !== cartFetchingDates.checkOut) &&
      cartItems.length > 0
    ) {
      setAlertError('datesNotEqual');
      setOpenRoomModal(true);
      return;
    }

    //check if corresponding uniqueQualifier exists in DormsData Restrictive / Flex as well
    //check if availability is higher in corresponding room
    let notCorresponding = false;

    if (DormsData && currentRoomType) {
      const isFlex = flex ? 'Restricted' : 'Flex';
      const dormType =
        currentRoomType.UniqueQualifier === 'B-4' ||
        currentRoomType.UniqueQualifier === 'B-4F' ||
        currentRoomType.UniqueQualifier === '4BED'
          ? 'B4'
          : 'B6';

      const correspondingRoom = DormsData[isFlex]['Dorms'][dormType][currentRoomType.UniqueQualifier];
      if (!correspondingRoom || correspondingRoom[0].Availability < currentRoomType.Availability) {
        flex ? setIsRestrictedDisabled(true) : setIsFlexDisabled(true);
        notCorresponding = true;
      }
    }

    cartItems.length === 0 && setCartFetchingDates(menuFetchingDates);

    cartItems.length === 0 && companyActive
      ? setCompanyRatesActive(true)
      : cartItems.length === 0 && setCompanyRatesActive(false);
    if (cartItems.length > 0 && ((!companyActive && companyRatesActive) || (companyActive && !companyRatesActive))) {
      setAlertError('companyRates');
      setOpenRoomModal(true);
      return;
    }

    if (currentRoomType && finalAvailability! > 0) {
      const reservation: CartItem = {
        ...currentRoomType,
        numberOfGuests: numberOfGuests,
        identifier: identifier,
        category: 'Dorms',
        notCorresponding,
        packages: checkActivePackages(),
      };

      //if adding a B- let roomChildModal handle it
      if (currentRoomType.RoomType.includes('B-') || !sitecoreFields.DisableMessageDormsPrivate) {
        setAlertError('childNotAllowed');
        setRoomChildReservation(reservation);
        setOpenRoomChildModal(true);
        return;
      }

      addCartItem(reservation);

      dataLayerObj && fireEcomDataLayerEvent('add_to_cart', [dataLayerObj]);
      dataLayerArrAdd && extraPackage2Checked && fireEcomDataLayerEvent('add_to_cart', dataLayerArrAdd);
      dataLayerExtraPackage1AlreadyActive.length > 0 &&
        fireEcomDataLayerEvent('add_to_cart', dataLayerExtraPackage1AlreadyActive);
    }
  }, [
    addCartItem,
    addCartItemId,
    cartItemsIds,
    currentRoomType,
    identifier,
    numberOfGuests,
    updateCartItemGuests,
    finalAvailability,
    menuFetchingDates,
    cartFetchingDates,
    cartItems,
    companyActive,
    companyRatesActive,
    checkActivePackages,
    flex,
    dataLayerObj,
  ]);

  return currentRoomType ? (
    <>
      <div
        className={`${classes.roomBoxContainer} ${currentRoomType.Availability <= 0 && classes.SoldOut}`}
        style={{ margin: '0' }}
      >
        <div className={classes.roomType}>
          <h3>{t(`booking_${currentRoomType.RoomType}_headline`)}</h3>
          <p className={classes.RoomDescription}>{t(`booking_${currentRoomType.RoomType}_description`)}</p>
          <div className={classes.FormControls}>
            <div className={`${classes.FormControl}`}>
              <Checkbox
                value={'B-4' in DormData ? 'B-4' : 'B-6'}
                colorScheme="darkGrey"
                name="roomType"
                size="lg"
                variant={'dorm-control'}
                isChecked={checkRoomType(currentRoomType.RoomType, 'B-4', 'B-6')}
                icon={<CircleIcon checked={checkRoomType(currentRoomType.RoomType, 'B-4', 'B-6')} />}
                onChange={changeChildRoom}
                disabled={
                  ('B-4' in DormData && soldoutDorms.includes('B-4')) ||
                  ('B-6' in DormData && soldoutDorms.includes('B-6'))
                }
              >
                {t('booking_dorms_radio_mixed')}
                {(checkDormRoomDisabled(DormData, 'B-4', soldoutDorms) ||
                  checkDormRoomDisabled(DormData, 'B-6', soldoutDorms)) && <p>{t('booking_label_soldout')}</p>}
              </Checkbox>
              <Checkbox
                value={'B-4F' in DormData ? 'B-4F' : 'B-6F'}
                name="roomType"
                size="lg"
                colorScheme="darkGrey"
                variant={'dorm-control'}
                isChecked={checkRoomType(currentRoomType.RoomType, 'B-4F', 'B-6F')}
                icon={<CircleIcon checked={checkRoomType(currentRoomType.RoomType, 'B-4F', 'B-6F')} />}
                onChange={changeChildRoom}
                disabled={
                  ('B-4F' in DormData && soldoutDorms.includes('B-4F')) ||
                  ('B-6F' in DormData && soldoutDorms.includes('B-6F'))
                }
              >
                {t('booking_dorms_radio_women')}
                {(checkDormRoomDisabled(DormData, 'B-4F', soldoutDorms) ||
                  checkDormRoomDisabled(DormData, 'B-6F', soldoutDorms)) && <p>{t('booking_label_soldout')}</p>}
              </Checkbox>
            </div>
            <div className={classes.FormControl}>
              <Checkbox
                size="lg"
                name="roomType"
                variant={'dorm-control-private'}
                value={'4BED' in DormData ? '4BED' : '6BED'}
                isChecked={checkRoomType(currentRoomType.RoomType, '4BED', '6BED')}
                icon={<CircleIcon checked={checkRoomType(currentRoomType.RoomType, '4BED', '6BED')} />}
                onChange={changePrivateRoom}
                disabled={
                  checkDormRoomDisabled(DormData, '4BED', soldoutDorms) ||
                  checkDormRoomDisabled(DormData, '6BED', soldoutDorms)
                }
              >
                {checkRoomType(currentRoomType.RoomType, '4BED', '6BED')
                  ? t('booking_dorms_radio_make_private_active')
                  : t('booking_dorms_radio_make_private')}
                {(checkDormRoomDisabled(DormData, '4BED', soldoutDorms) ||
                  checkDormRoomDisabled(DormData, '6BED', soldoutDorms)) && <p>{t('booking_label_soldout')}</p>}
              </Checkbox>
            </div>
          </div>
        </div>
        <div className={classes.roomDetails}>
          <div className={classes.numberOfPersons}>
            <div className={classes.selectWrapper}>
              <select
                onChange={changeGuestsNumber}
                className={classes.selectText}
                name={'numberOfGuests'}
                value={numberOfGuests}
                disabled={finalAvailability! <= 0}
              >
                <option value={0} defaultValue={0}>
                  {t('booking_label_zero_option')}
                </option>
                {generateOptions(
                  typeof finalAvailability !== 'undefined' && currentRoomType.RoomType.includes('B-')
                    ? finalAvailability
                    : limiter
                )}
              </select>
              <span className={classes.selectLabel}>{t('booking_number_of_persons')}</span>
            </div>
            {numberOfGuests > 0 && finalAvailability! > 0 && (
              <BrandButton
                clickHandler={handleAddReservation}
                dataQa={currentRoomType.RoomType + ',' + currentRoomType.RateCode}
              />
            )}
          </div>
          <div className={classes.roomPrice}>
            <p className={classes.roomLabel}>
              {checkRoomType(currentRoomType.RoomType, '4BED', '6BED')
                ? t('booking_label_per_room')
                : t('booking_label_per_person')}
            </p>
            <h3>
              {currentRoomType.Availability <= 0 ? (
                <>{t('booking_label_soldout')}</>
              ) : (
                <>
                  <CurrencySymbol></CurrencySymbol>
                  <CurrencyValue value={currentRoomType.Price}></CurrencyValue>
                </>
              )}
            </h3>
          </div>
        </div>
      </div>
      <Modal
        isOpen={openRoomModal}
        message={t(getAlertMessage(alertError).message)}
        title={t(getAlertMessage(alertError).title)}
        onClose={() => setOpenRoomModal(false)}
        errorType={alertError}
      />
      <DormChildModal
        isOpen={openRoomChildModal}
        onClose={() => setOpenRoomChildModal(false)}
        message={t(getAlertMessage(alertError).message)}
        title={t(getAlertMessage(alertError).title)}
        reservation={roomChildReservation}
        pizzaDataLayer={dataLayerArrAdd}
        extraPackage1DataLayer={dataLayerExtraPackage1AlreadyActive}
      />
    </>
  ) : (
    <></>
  );
};
export default DormBox;
