import { Icon } from '@chakra-ui/react';
import { DaIcon } from './DaIcon';
import { EnIcon } from './EnIcon';

const Flags = new Map<string, typeof Icon>([
  ['da', DaIcon],
  ['en', EnIcon],
]);

export default Flags;

export * from './DaIcon';
export * from './EnIcon';
