import { useI18n } from 'next-localization';
import React, { ReactElement, useEffect, useState } from 'react';
import { useCartStore, useContactFormStore, useSitecoreFieldsStore } from '../../../store';
import CartItem from '../CartItem/CartItem';
import { addDays, differenceInDays, format, isBefore, isSameDay } from 'date-fns';
import { enGB, da } from 'date-fns/locale';
import classes from './Cart.module.scss';
import ExtraPackage1CartItem from './ExtraPackage1CartItem/ExtraPackage1CartItem';
import ExtraPackage2CartItem from './ExtraPackage2CartItem/ExtraPackage2CartItem';
import { Checkbox } from '@chakra-ui/react';
import CurrencySymbol from 'components/Currency/CurrencyFormatter/CurrencySymbol';
import CurrencyValue from 'components/Currency/CurrencyFormatter/CurrencyValue';
import { RichText } from '@sitecore-jss/sitecore-jss-nextjs';
import PaymentButton from './PaymentButton/PaymentButton';
import CreditCards from './CreditCards/CreditCards';

const locales = {
  da,
  en: enGB,
};

export interface IProps {
  cartHeader?: string;
}

const Cart: React.FC<IProps> = (): ReactElement => {
  const {
    cartItems,
    cartFetchingDates,
    setCartFetchingDates,
    setCartItems,
    setCartItemIds,
    extraPackage2Checked,
    setExtraPackage2Checked,
    package1DetailsList,
    setAllExtraPackage1DetailsNotActive,
  } = useCartStore((state) => state);
  const {
    agreeTerms,
    setAgreeTerms,
    agreeError,
    setAgreeError,
    newsletterChecked,
    setNewsletterChecked,
    paymentLoading,
  } = useContactFormStore((state) => state);
  const { sitecoreFields } = useSitecoreFieldsStore((state) => state);

  const { t, locale } = useI18n();
  const dateLocale = locale() as 'en' | 'da';
  const currentLocale = locales[dateLocale] || locales.en;

  const [cartTotal, setCartTotal] = useState(0);
  const [extraPackage1CartItems, setExtraPackage1CartItems] = useState<ReactElement[]>([]);
  const numberOfNights = differenceInDays(new Date(cartFetchingDates.checkOut!), new Date(cartFetchingDates.checkIn!));

  const agreeHandler = () => {
    !agreeTerms ? setAgreeError(false) : setAgreeError(true);
    setAgreeTerms(!agreeTerms);
  };

  const newsletterHandler = () => {
    setNewsletterChecked(!newsletterChecked);
  };

  useEffect(() => {
    let total = 0;
    cartItems.forEach((cartItem) => {
      cartItem.packages.map((cartPackage) => {
        total += cartPackage.PackagePrice * cartPackage.Count! * numberOfNights;
      });
      if (cartItem.RoomType.includes('B-')) {
        total += cartItem.Price * cartItem.numberOfGuests;
        return;
      }
      total += cartItem.Price;
    });
    setCartTotal(total);
  }, [cartItems, extraPackage2Checked, numberOfNights]);

  useEffect(() => {
    setAgreeTerms(false);
    setAgreeError(false);
    setNewsletterChecked(sitecoreFields.newsletterChecked);
    //check if cartFetchingDates exist
    if (cartFetchingDates.checkIn && cartFetchingDates.checkOut) {
      //if dates before today and not the same day as today, clear cart and dates
      if (
        (isBefore(new Date(cartFetchingDates.checkIn), new Date()) &&
          !isSameDay(new Date(cartFetchingDates.checkIn), new Date())) ||
        (isBefore(new Date(cartFetchingDates.checkOut), new Date()) &&
          !isSameDay(new Date(cartFetchingDates.checkOut), new Date()))
      ) {
        setCartFetchingDates({ checkIn: new Date(), checkOut: addDays(new Date(), 1) });
        setCartItems([]);
        setCartItemIds([]);
        setExtraPackage2Checked(false);
        setAllExtraPackage1DetailsNotActive();
      }
    }
  }, []);

  useEffect(() => {
    setExtraPackage1CartItems([]);
    if (cartItems.length) {
      package1DetailsList.forEach((package1Details) => {
        package1Details.Active &&
          setExtraPackage1CartItems((prev) => [
            ...prev,
            <ExtraPackage1CartItem
              key={package1Details.PackageCode}
              packageCode={package1Details.PackageCode}
              packagePrice={package1Details.PackagePrice}
            />,
          ]);
      });
    }
  }, [cartItems, package1DetailsList]);

  return (
    <>
      <div className={classes.cartContainer} id="cartContainer">
        <h2 className={classes.subHeadline}>{t('cart_sub_headline')}</h2>
        <div className={classes.cartDates}>
          <div>
            <h5>{t('bookbox_arrival')}:</h5>
            <p className={currentLocale.code !== 'da' ? classes.englishDate : undefined}>
              {cartFetchingDates.checkIn
                ? format(new Date(cartFetchingDates.checkIn), 'EE d MMM', {
                    locale: currentLocale,
                  })
                : ''}
            </p>
          </div>
          <div>
            <h5>{t('bookbox_departure')}:</h5>
            <p>
              {cartFetchingDates.checkOut
                ? format(new Date(cartFetchingDates.checkOut), 'EE d MMM', {
                    locale: currentLocale,
                  })
                : ''}
            </p>
          </div>
        </div>
        {cartItems.length > 0 ? (
          cartItems.map((cartItem, index) => {
            return (
              <CartItem
                key={`${cartItem.RoomType}${index}`}
                propCartItem={cartItem}
                notCorresponding={cartItem.notCorresponding}
              />
            );
          })
        ) : (
          <div className={classes.emptyCart}>
            <h3>{t('booking_cart_empty')}</h3>
          </div>
        )}
        {cartItems.length > 0 && (
          <>
            {extraPackage1CartItems}
            <ExtraPackage2CartItem />
          </>
        )}
      </div>
      <div className={classes.cartTotal}>
        <h3>{t('cart_total')}:</h3>
        <h3>
          <CurrencySymbol />
          {<CurrencyValue value={cartTotal} />}
        </h3>
      </div>
      <div className={classes.paymentWrapper}>
        <div className={classes.paymentContainer}>
          <Checkbox
            value="agreeLabel"
            name="agreeLabel"
            size="lg"
            isChecked={agreeTerms}
            onChange={agreeHandler}
          ></Checkbox>
          <RichText
            field={{ value: sitecoreFields.paymentTerms }}
            className={`${classes.paymentLabel} ${agreeError && classes.errorLabel}`}
          />
        </div>
        <PaymentButton
          buttonText={t('cart_payment_label')}
          paymentLoading={paymentLoading}
          cartItemsLength={cartItems.length}
        />
        <CreditCards />
      </div>
      {sitecoreFields.newsletterShow && (
        <div className={classes.newsletterContainer}>
          <Checkbox
            value="newsletter"
            colorScheme="darkGrey"
            name="newsletter"
            size="lg"
            isChecked={newsletterChecked}
            onChange={newsletterHandler}
          />
          <div className={classes.newsletterLabel}>
            <h3>{t('booking_newsletter_headline')}</h3>
            <RichText field={{ value: sitecoreFields.newsletterTerms }} />
          </div>
        </div>
      )}
    </>
  );
};
export default Cart;
