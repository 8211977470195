import { Icon, IconProps } from '@chakra-ui/react';
import { ReactElement } from 'react';

export const BarsIcon = (props: IconProps): ReactElement => {
  return (
    <Icon {...props} viewBox="0 0 35 35">
      <path
        d="M13.6418 11.0523H10.6808C6.01727 11.0523 1.79785 14.1614 1.79785 16.234V17.7145C1.79785 24.1547 6.53544 34 16.6028 34C26.6702 34 32.148 24.1547 32.148 17.7145V16.234C32.148 14.9756 30.9636 13.4951 28.4468 12.5328L24.7456 13.2731C24.7456 13.2731 22.8209 11.4965 22.0807 11.3484C20.4521 11.1263 17.3431 11.0523 17.3431 11.0523H15.8626"
        stroke="#212121"
        strokeWidth="1.2"
        fill="transparent"
        strokeMiterlimit="10"
      />
      <path
        d="M29.187 16.2346C28.6688 19.0475 23.7092 21.3423 16.9729 21.3423C15.9365 21.3423 14.0859 20.602 14.0859 20.602L11.6431 23.1189C11.6431 23.1189 9.12626 20.2319 8.16393 19.7878C6.01721 18.8995 5.05489 17.567 4.75879 16.2346"
        stroke="#212121"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        fill="transparent"
      />
      <path
        d="M1.79785 0.689026L10.6808 5.87077L17.3431 18.455"
        stroke="#212121"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        fill="transparent"
      />
    </Icon>
  );
};
