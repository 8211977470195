import { Field, withDatasourceCheck, RichText, Text, Link } from '@sitecore-jss/sitecore-jss-nextjs';
import { Box, Grid, GridItem, useMultiStyleConfig, Image, Button, useBreakpointValue } from '@chakra-ui/react';
import { ComponentProps } from 'lib/component-props';
import { IDroptreeColor } from '../../Component-models/data-items/IDroptreeColor';
import { Socialicon } from '../../Component-models/data-items/socialIcon';
import { DaIcon, EnIcon } from 'assets/Icons/flags';
import { useCurrencyStore, useMenuStore } from '../../store';
import { useI18n } from 'next-localization';
import { useEffect, useState } from 'react';

type FooterProps = ComponentProps & {
  fields: {
    firstheadline: Field<string>;
    firstsecontion: Field<string>;
    secondheadline: Field<string>;
    secondsecontion: Field<string>;
    thirdheadline: Field<string>;
    thirdsecontion: Field<string>;
    fourthheadline: Field<string>;
    fourthsection: Field<string>;
    socialicons: Socialicon[];
    copyright: Field<string>;
    CurrencyEn: Field<string>;
    CurrencyDa: Field<string>;
    // INTERFACE FIELDS - reused across components
    backgroundcolor: IDroptreeColor;
    textcolor: IDroptreeColor;
    // missing current language, country selected image, copyright typo
  };
};

const Footer: React.FC = (props: FooterProps): JSX.Element => {
  const styles = useMultiStyleConfig('Footer', {});
  const colSpan = { base: 4, md: 2 };
  const { setMenuIsOpen } = useMenuStore();
  const { currentCurrency } = useCurrencyStore();
  const [displayCurrency, setDisplayCurrency] = useState(false);
  const i18n = useI18n();
  const locale = i18n.locale();

  const isWideVersion = useBreakpointValue({
    base: false,
    md: true,
  });

  useEffect(() => {
    useCurrencyStore.persist.hasHydrated() && setDisplayCurrency(true);
  }, []);

  return (
    <Box __css={styles.footerContainer} bgColor={props.fields.backgroundcolor?.fields.colorcode.value}>
      <Box __css={{ ...styles.footer }}>
        <Box __css={styles.divider} />
        <Box __css={{ paddingLeft: { base: '1.125rem', lg: '1.625rem' }, paddingRight: { base: '1.125rem', lg: '0' } }}>
          <Grid
            templateAreas={[
              '"firstSection" "secondSection" "thirdSection" "fourthSection" "fifthSection"',
              '"firstSection firstSection secondSection secondSection" "firstSection firstSection secondSection secondSection" "thirdSection thirdSection fourthSection fourthSection"',
            ]}
            templateRows={['repeat(5)', '1fr 1px auto']}
            templateColumns={['repeat(1,1fr)', 'repeat(4, 1fr)']}
            gap={{ base: 2.5, md: 10 }}
            className="grid"
          >
            <GridItem colSpan={colSpan} paddingY={2.5}>
              <Box __css={{ ...styles.item }}>
                <Box textStyle="h2">
                  <Text tag="h2" field={props.fields.firstheadline} />
                </Box>
                <Box textStyle="p" __css={{ paddingTop: '2.25rem', ...styles.footerSection }}>
                  <RichText className="text sc-rich-text" field={props.fields.firstsecontion} />
                </Box>
              </Box>
            </GridItem>
            {!isWideVersion && (
              <GridItem colSpan={4} w="100%">
                <Box __css={{ ...styles.gridDivider }} w={{ base: '100%', md: '0%' }} />
              </GridItem>
            )}
            <GridItem colSpan={colSpan} paddingY={2.5}>
              <Box __css={{ ...styles.item }}>
                <Box textStyle="h2">
                  <Text tag="h2" field={props.fields.secondheadline} />
                </Box>
                <Box textStyle="p" __css={{ paddingTop: '2.25rem', ...styles.footerSection }}>
                  <RichText className="text sc-rich-text" field={props.fields.secondsecontion} />
                </Box>
              </Box>
            </GridItem>
            <GridItem colSpan={4} w="100%">
              <Box __css={{ ...styles.gridDivider }} w="100%" />
            </GridItem>
            <GridItem colSpan={colSpan} paddingY={2.5}>
              <Box __css={{ ...styles.item }}>
                <Box textStyle="h2">
                  <Text tag="h2" field={props.fields.thirdheadline} />
                </Box>
                <Box textStyle="p" __css={{ paddingTop: '2.25rem', ...styles.footerSection }}>
                  <RichText className="text sc-rich-text" field={props.fields.thirdsecontion} />
                </Box>
              </Box>
            </GridItem>
            {!isWideVersion && (
              <GridItem colSpan={4} w="100%">
                <Box __css={{ ...styles.gridDivider }} w={{ base: '100%', md: '0%' }} />
              </GridItem>
            )}
            <GridItem colSpan={colSpan} paddingY={2.5}>
              <Box __css={{ ...styles.item }}>
                <Box textStyle="h2">
                  <Text tag="h2" field={props.fields.fourthheadline} />
                </Box>
                <Box textStyle="p" __css={{ paddingTop: '2.25rem', ...styles.footerSection }}>
                  <RichText className="text sc-rich-text" field={props.fields.fourthsection} />
                </Box>
              </Box>
            </GridItem>
          </Grid>
          <Box __css={styles.socialsCountry}>
            <Box className="container">
              <Box className="socialsContainer">
                {props.fields.socialicons.map((socialicon, index) => (
                  <Box key={index} className="imageContainer">
                    <Link
                      field={socialicon.fields.Link}
                      href={socialicon.fields.Link.value.href}
                      target={socialicon.fields.Link.value.target}
                    >
                      <Image src={socialicon.fields.Image.value?.src} width={15} height={15} />
                    </Link>
                  </Box>
                ))}
              </Box>
              <Button __css={{ ...styles.contrySelected }} onClick={() => setMenuIsOpen(true, true)}>
                <Box className="text">
                  {locale === 'en' ? `${props.fields.CurrencyEn.value}` : `${props.fields.CurrencyDa.value}`} /{' '}
                  {/* fix hidration issue */}
                  {displayCurrency ? currentCurrency.Code : ''}
                </Box>
                {locale === 'da' ? (
                  <Box __css={{ ...styles.iconContainer }}>
                    <DaIcon />
                  </Box>
                ) : locale === 'en' ? (
                  <Box __css={{ ...styles.iconContainer }}>
                    <EnIcon />
                  </Box>
                ) : null}
              </Button>
            </Box>
          </Box>
        </Box>
        <Box __css={styles.bottomDivider} />
        <Box __css={styles.copyright}>
          <RichText className="sc-rich-text" field={props.fields.copyright} />
        </Box>
      </Box>
    </Box>
  );
};

export default withDatasourceCheck()<FooterProps>(Footer);
