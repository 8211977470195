import React, { ReactElement, useState } from 'react';
import classes from '../../ParentBox.module.scss';
import customClasses from './CancelBox.module.scss';
import { useI18n } from 'next-localization';
import { CancelData } from 'src/store/booking-store-modules';
import { ArrowRight } from 'tabler-icons-react';
import CurrencySymbol from 'components/Currency/CurrencyFormatter/CurrencySymbol';
import CurrencyValue from 'components/Currency/CurrencyFormatter/CurrencyValue';
import BrandButton from 'components/booking/BrandButton/BrandButton';
import CancelBookingModal from './CancelBookingModal';
import { cancelBooking, CancelBookingObject } from 'lib/booking-helpers';
import { shortDateFormat } from 'lib/booking-box-helpers';
import { useBookingMenuStore } from 'src/store';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';

interface IProps {
  CancelData: CancelData;
}

const CancelBox: React.FC<IProps> = ({ CancelData }): ReactElement => {
  const { t, locale } = useI18n();

  const { setChangeStepOpen, setModalOpen } = useBookingMenuStore((state) => state);
  const { sitecoreContext } = useSitecoreContext();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [successText, setSuccessText] = useState('');
  const [loading, setLoading] = useState(false);
  const [cancelComplete, setCancelComplete] = useState(false);

  const submitHandler = async () => {
    setSuccessText('');
    setErrorText('');
    setCancelComplete(false);
    setLoading(true);

    const reqObject: CancelBookingObject = {
      guestNum: CancelData.guestNum,
      Lastname: CancelData.lastName,
      hostelCode: sitecoreContext.site?.name ? sitecoreContext.site.name : '',
      language: locale(),
    };

    const response = await cancelBooking(reqObject);
    if (!response.success) {
      setErrorText(t('booking_cancel_alert_error'));
      setLoading(false);
      return;
    }

    setLoading(false);
    setSuccessText(t('booking_cancel_alert_success'));
    setCancelComplete(true);

    //not needed after KHS changes to cancel endpoint
    // const cancelMailObj = {
    //   firstname: CancelData.firstName,
    //   lastname: CancelData.lastName,
    //   Email: CancelData.email,
    //   UserId: CancelData.guestNum,
    //   arrivalDate: mailDateFormat(CancelData.dates.checkIn),
    //   departureDate: mailDateFormat(CancelData.dates.checkOut),
    //   locale: locale(),
    // };
    // const mailResponse = (await sendCancelBookingMail(cancelMailObj)) as string;
    // if (mailResponse.toLowerCase() === 'cancel email sent') {
    //   setLoading(false);
    //   setSuccessText(t('booking_cancel_alert_success'));
    //   setCancelComplete(true);
    // }
  };

  const onCloseCancelModal = () => {
    setSuccessText('');
    setErrorText('');
    setIsModalOpen(false);
    if (cancelComplete) {
      setChangeStepOpen(false);
      setModalOpen(false);
    }
  };

  const openCancelModalHandler = () => {
    setIsModalOpen(true);
    setCancelComplete(false);
  };

  return (
    <>
      {CancelData && (
        <div className={classes.roomBoxContainer}>
          <div className={`${classes.roomType} ${customClasses.cancelBox}`}>
            <h2 className={customClasses.headline}>{t(`booking_${CancelData.roomType}_headline`)}</h2>
            <div className={customClasses.datesContainer}>
              <div className={customClasses.arrival}>
                <p className={customClasses.arrivalLabel}>{t('bookbox_arrival')}</p>
                <p>{shortDateFormat(CancelData.dates.checkIn, locale())}</p>
              </div>
              <ArrowRight />
              <div className={customClasses.departure}>
                <p className={customClasses.departureLabel}>{t('bookbox_departure')}</p>
                <p>{shortDateFormat(CancelData.dates.checkOut, locale())}</p>
              </div>
            </div>
            <div className={customClasses.cartItems}>
              <div className={customClasses.cartItem}>
                <p>
                  {t('booking_number_of_persons')} - {CancelData.numberOfGuests}
                </p>
                <p>
                  <CurrencySymbol /> <CurrencyValue value={CancelData.roomPrice} />
                </p>
              </div>
              {CancelData.extraPackages1 &&
                CancelData.extraPackages1.map((extraPackage) => (
                  <div className={customClasses.cartItem} key={extraPackage.code}>
                    <p>
                      {t(`booking_extra_package_1_${extraPackage.code}_label`)} - {CancelData.numberOfGuests}
                    </p>
                    <p>
                      <CurrencySymbol /> <CurrencyValue value={extraPackage.price} />
                    </p>
                  </div>
                ))}
              {/* adults and children not present on cancelData so we use price > 0 to check if it is present */}
              {CancelData.extraPackage2 && CancelData.extraPackage2?.price > 0 && (
                <div className={customClasses.cartItem}>
                  <p>
                    {t(`booking_extra_package_2_label`)} - {CancelData.extraPackage2.numAdults}{' '}
                    {t('booking_adults_label')} / {CancelData.extraPackage2.numChildren} {t('booking_children_label')}
                  </p>
                  <p>
                    <CurrencySymbol /> <CurrencyValue value={CancelData.extraPackage2.price} />
                  </p>
                </div>
              )}
              <div className={customClasses.totalPrice}>
                <h2>
                  {t('cart_total')} <CurrencySymbol /> <CurrencyValue value={CancelData.totalPrice} />
                </h2>
              </div>
            </div>
            <div className={customClasses.CancelButton}>
              {/* disable on loading */}
              <BrandButton buttonText={t('booking_cancel_alert_button')} clickHandler={openCancelModalHandler} />
            </div>
          </div>
          <CancelBookingModal
            onClose={onCloseCancelModal}
            isOpen={isModalOpen}
            title={t('booking_cancel_alert_title')}
            submitHandler={submitHandler}
            errorText={errorText}
            successText={successText}
            loading={loading}
            cancelComplete={cancelComplete}
          />
        </div>
      )}
    </>
  );
};
export default CancelBox;
