import { Icon, IconProps } from '@chakra-ui/react';
import { ReactElement } from 'react';

export const VisaIcon = (props: IconProps): ReactElement => {
  return (
    <Icon {...props} viewBox="0 0 31 21">
      <rect
        x="0.629167"
        y="0.629167"
        width="28.9292"
        height="19.7417"
        rx="2.51667"
        fill="white"
        stroke="#D9D9D9"
        strokeWidth="1.25833"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.22055 14.226H7.39177L6.02041 8.91835C5.95532 8.67419 5.81712 8.45835 5.61382 8.35662C5.10647 8.10097 4.5474 7.89751 3.93751 7.7949V7.59055H6.88353C7.29012 7.59055 7.59507 7.89751 7.64589 8.25401L8.35743 12.0826L10.1853 7.59055H11.9633L9.22055 14.226ZM12.9796 14.226H11.2525L12.6747 7.59055H14.4018L12.9796 14.226ZM16.6364 9.42873C16.6872 9.07135 16.9922 8.86701 17.3479 8.86701C17.907 8.8157 18.516 8.91832 19.0242 9.17308L19.3292 7.74444C18.8209 7.5401 18.2619 7.43748 17.7545 7.43748C16.0782 7.43748 14.8584 8.35659 14.8584 9.63219C14.8584 10.6026 15.7224 11.1121 16.3323 11.4191C16.9922 11.7252 17.2463 11.9295 17.1954 12.2356C17.1954 12.6947 16.6872 12.899 16.1799 12.899C15.57 12.899 14.9601 12.746 14.4019 12.4904L14.097 13.9199C14.7068 14.1746 15.3667 14.2773 15.9766 14.2773C17.8562 14.3277 19.0242 13.4095 19.0242 12.0312C19.0242 10.2956 16.6364 10.1939 16.6364 9.42873ZM25.0686 14.226L23.6973 7.59055H22.2243C21.9193 7.59055 21.6144 7.7949 21.5127 8.10097L18.9733 14.226H20.7513L21.1061 13.2565H23.2907L23.494 14.226H25.0686ZM22.4785 9.37744L22.9859 11.8782H21.5637L22.4785 9.37744Z"
        fill="#172B85"
      />
    </Icon>
  );
};
