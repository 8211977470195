import CurrencySymbol from 'components/Currency/CurrencyFormatter/CurrencySymbol';
import CurrencyValue from 'components/Currency/CurrencyFormatter/CurrencyValue';
import { useI18n } from 'next-localization';
import { X } from 'tabler-icons-react';
import React, { ReactElement, useEffect, useState } from 'react';
import { useCartStore, useDormStore, useCurrencyStore, useBookingMenuDatesStore } from '../../../store';
import { CartItem as ICartItem } from '../../../store/booking-store-modules';
import classes from './CartItem.module.scss';
import { fireEcomDataLayerEvent } from 'lib/helpers';
import {
  useGenerateDataLayerObject,
  useGenerateDataLayerObjectExtra2,
  useGenerateDataLayerWhenExtraPackage1AlreadyActive,
} from 'lib/customHooks/hooks';

export interface IProps {
  propCartItem: ICartItem;
  notCorresponding?: boolean;
}

const CartItem: React.FC<IProps> = ({ propCartItem, notCorresponding }): ReactElement => {
  const { t } = useI18n();

  const {
    removeCartItem,
    removeCartId,
    cartItems,
    setExtraPackage2Checked,
    setAllExtraPackage1DetailsNotActive,
    extraPackage2Details,
    extraPackage2Checked,
  } = useCartStore((state) => state);
  const { DormRooms } = useDormStore((state) => state);
  const { currentCurrency } = useCurrencyStore((state) => state);
  const { isFlexDisabled, isRestrictedDisabled, setIsRestrictedDisabled, setIsFlexDisabled } = useBookingMenuDatesStore(
    (state) => state
  );
  const dataLayerObj = useGenerateDataLayerObject(
    'cartItem',
    propCartItem.UniqueQualifier,
    propCartItem.Price,
    propCartItem.numberOfGuests,
    propCartItem.RoomType
  );

  const dataLayerRemoveExtraPackage2 = useGenerateDataLayerObjectExtra2(
    extraPackage2Details['adult'].PackagePrice,
    extraPackage2Details['child'].PackagePrice,
    'remove',
    propCartItem.numberOfGuests
  );

  const dataLayerRemoveExtraPackage1 = useGenerateDataLayerWhenExtraPackage1AlreadyActive(propCartItem.numberOfGuests);

  const [cartItemPrice, setCartItemPrice] = useState<number>();

  useEffect(() => {
    if (DormRooms && currentCurrency) {
      //B-4, B-4F, B-6 and B-6F need to be multiplied by numberOfGuests, but 4BED&6BED/offers dont
      cartItems.forEach((cartItem) => {
        if (cartItem.UniqueQualifier === propCartItem.UniqueQualifier) {
          cartItem.UniqueQualifier.includes('B-')
            ? setCartItemPrice(cartItem.Price * propCartItem.numberOfGuests)
            : setCartItemPrice(cartItem.Price);
        }
      });
    }
  }, [DormRooms, currentCurrency, cartItems, propCartItem.UniqueQualifier, propCartItem.numberOfGuests]);

  const removeCartItemHandler = () => {
    removeCartItem(propCartItem.identifier);
    //dataLayer
    dataLayerObj && fireEcomDataLayerEvent('remove_from_cart', [dataLayerObj]);
    dataLayerRemoveExtraPackage2 &&
      extraPackage2Checked &&
      fireEcomDataLayerEvent('remove_from_cart', dataLayerRemoveExtraPackage2);
    dataLayerRemoveExtraPackage1.length > 0 && fireEcomDataLayerEvent('remove_from_cart', dataLayerRemoveExtraPackage1);

    if (notCorresponding) {
      //check how many cartItems have notCorresponding = true
      const notCorrespondingItems = cartItems.filter((item) => item.notCorresponding === true).length;
      //if there are no more notCorresponding items, enable flex/restricted
      if (notCorrespondingItems === 1) {
        isFlexDisabled && setIsFlexDisabled(false);
        isRestrictedDisabled && setIsRestrictedDisabled(false);
      }
    }
    if (cartItems.length === 1) {
      setExtraPackage2Checked(false);
      setAllExtraPackage1DetailsNotActive();
    }
    if (propCartItem.UniqueQualifier.includes('B-')) {
      removeCartId(propCartItem.UniqueQualifier);
    }
  };

  let cartRoomLabel = '';
  if (propCartItem.UniqueQualifier.includes('B-')) {
    cartRoomLabel =
      propCartItem.numberOfGuests > 1
        ? `${propCartItem.numberOfGuests} ${t('booking_multiple_beds')}`
        : `${propCartItem.numberOfGuests} ${t('booking_single_bed')}`;
  } else {
    cartRoomLabel = `1 ${t('booking_room_label')}`;
  }

  return cartItemPrice ? (
    <div key={propCartItem.identifier} className={classes.cartItem}>
      <div className={classes.cartSubtitle}>
        <p>{t(`booking_${propCartItem.UniqueQualifier}_headline`)}</p>
        <div className={classes.removeIcon} onClick={removeCartItemHandler}>
          <X size={19} />
        </div>
      </div>
      <div className={classes.cartPrice}>
        <p>
          {t(`booking_${propCartItem.UniqueQualifier}_label`)} &nbsp;
          {cartRoomLabel}
        </p>
        <h3>
          {cartItemPrice <= 0 ? (
            <span className={classes.soldOut}>{t('booking_label_soldout')}</span>
          ) : (
            <>
              <CurrencySymbol />
              <CurrencyValue value={cartItemPrice} />
            </>
          )}
        </h3>
      </div>
    </div>
  ) : (
    <> </>
  );
};
export default CartItem;
