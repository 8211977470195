import React, { ReactElement, useEffect } from 'react';
import classes from '../../ParentBox.module.scss';
import customClasses from './ExtraBox.module.scss';
import { Checkbox } from '@chakra-ui/react';
import { useCartStore, useCurrencyStore } from '../../../../store';
import { PackageBox, ExtraPackage2Details } from '../../../../store/booking-store-modules';
import ExtraPackage2Select from './ExtraPackage2Select';
import { useI18n } from 'next-localization';
import CurrencySymbol from 'components/Currency/CurrencyFormatter/CurrencySymbol';
import CurrencyValue from 'components/Currency/CurrencyFormatter/CurrencyValue';
import { useGenerateDataLayerObjectExtra2 } from 'lib/customHooks/hooks';
import { fireEcomDataLayerEvent, transformCurrency } from 'lib/helpers';

type IProps = {
  ExtraPackage2Data: {
    adult: PackageBox;
    child: PackageBox | null;
  };
};

const ExtraPackage2: React.FC<IProps> = ({ ExtraPackage2Data }): ReactElement => {
  const { t, locale } = useI18n();
  const {
    cartItems,
    extraPackage2Details,
    setExtraPackage2Details,
    extraPackage2Checked,
    setExtraPackage2Checked,
    setExtraPackageActive,
    clearExtraPackage,
  } = useCartStore((state) => state);
  const { currentCurrency } = useCurrencyStore((state) => state);

  const packageDescription = t('booking_extra_package_2_description');
  const updatedPackageDescription = transformCurrency(packageDescription, currentCurrency, locale());

  let numberOfPackageOptions: Array<ReactElement> = [];

  const dataLayerArrAdd = useGenerateDataLayerObjectExtra2(
    ExtraPackage2Data.adult.PackagePrice,
    ExtraPackage2Data.child?.PackagePrice,
    'add'
  );

  const dataLayerArrRemove = useGenerateDataLayerObjectExtra2(
    ExtraPackage2Data.adult.PackagePrice,
    ExtraPackage2Data.child?.PackagePrice,
    'remove'
  );

  useEffect(() => {
    if (ExtraPackage2Data && ExtraPackage2Data.child) {
      const extraPackage2Details: ExtraPackage2Details = {
        adult: ExtraPackage2Data.adult,
        child: ExtraPackage2Data.child,
      };
      setExtraPackage2Details(extraPackage2Details);
    }
  }, [ExtraPackage2Data]);

  const extraPackage2Handler = () => {
    if (cartItems.length) {
      if (extraPackage2Checked) {
        clearExtraPackage(extraPackage2Details['adult'].PackageCode);
        clearExtraPackage(extraPackage2Details['child'].PackageCode);
        dataLayerArrRemove && fireEcomDataLayerEvent('remove_from_cart', dataLayerArrRemove);
      } else {
        //by default no children get the extra package so only set the adult package active
        setExtraPackageActive(extraPackage2Details['adult']);
        dataLayerArrAdd && fireEcomDataLayerEvent('add_to_cart', dataLayerArrAdd);
      }
      setExtraPackage2Checked(!extraPackage2Checked);
    }
  };

  return extraPackage2Details && extraPackage2Details.adult && extraPackage2Details.child ? (
    <div className={classes.roomBoxContainer}>
      <div className={`${customClasses.ExtraBox}`}>
        <div className={`${classes.FormControl} ${customClasses.ExtraBoxFormControl}`}>
          <Checkbox
            variant="custom-control"
            value="restricted"
            colorScheme="darkGrey"
            name="flexibleBooking"
            size="lg"
            isChecked={extraPackage2Checked}
            onChange={extraPackage2Handler}
            iconColor="green.200"
          >
            {t('booking_extra_package_2_label')}
            <p>{updatedPackageDescription}</p>
          </Checkbox>
        </div>
      </div>
      <div className={classes.roomPrice}>
        <p>{t('booking_per_adult_per_night')}</p>
        <h3>
          <CurrencySymbol></CurrencySymbol>
          <CurrencyValue value={extraPackage2Details.adult.PackagePrice}></CurrencyValue>
        </h3>
      </div>
      <div className={classes.roomPrice}>
        <p>{t('booking_per_child_per_night')}</p>
        <h3>
          <CurrencySymbol></CurrencySymbol>
          <CurrencyValue value={extraPackage2Details.child.PackagePrice}></CurrencyValue>
        </h3>
      </div>
      {extraPackage2Checked && (
        <div className={customClasses.buffet}>
          {t('booking_extra_package_2_select')}
          <div className={customClasses.buffetOptions}>
            {cartItems.map((cartItem) => {
              numberOfPackageOptions = [];
              for (let i = 0; i < cartItem.numberOfGuests; i++) {
                numberOfPackageOptions.push(
                  <option value={`${cartItem.numberOfGuests - i},${i}`} key={cartItem.numberOfGuests - i}>
                    {i === 0
                      ? `${cartItem.numberOfGuests} ${t('booking_adults_label')}`
                      : `${cartItem.numberOfGuests - i} ${t('booking_adults_label')}, ${i} ${t(
                          'booking_children_label'
                        )}`}
                  </option>
                );
              }
              return (
                <div key={`${cartItem.RateCode}${cartItem.identifier}`}>
                  <p>{t(`booking_${cartItem.UniqueQualifier}_headline`)}:</p>
                  <ExtraPackage2Select
                    key={`${cartItem.RateCode}${cartItem.identifier}`}
                    roomType={cartItem.RoomType}
                    numberOfPackageOptions={numberOfPackageOptions}
                    cartIdentifier={cartItem.identifier}
                    packageCode1={extraPackage2Details['adult'].PackageCode}
                    packageCode2={extraPackage2Details['child'].PackageCode}
                  />
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  ) : (
    <></>
  );
};
export default ExtraPackage2;
