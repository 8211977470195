import { ReactElement } from 'react';

export const EnIcon = (): ReactElement => {
  return (
    <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" width="30" height="30">
      <g clipPath="url(#a)">
        <path
          d="M15 30c8.284 0 15 -6.716 15 -15 0 -8.284 -6.716 -15 -15 -15C6.716 0 0 6.716 0 15c0 8.284 6.716 15 15 15Z"
          fill="#fff"
        />
        <path
          d="M3.1 5.868a14.945 14.945 0 0 0 -2.583 5.219H8.32L3.1 5.867Zm26.383 5.219a14.948 14.948 0 0 0 -2.584 -5.22l-5.22 5.22h7.804ZM0.517 18.913a14.946 14.946 0 0 0 2.584 5.22l5.219 -5.22H0.517ZM24.132 3.1A14.946 14.946 0 0 0 18.913 0.518V8.32l5.22 -5.22ZM5.867 26.9a14.948 14.948 0 0 0 5.22 2.583V21.68l-5.22 5.22ZM11.086 0.517A14.947 14.947 0 0 0 5.867 3.1l5.22 5.219V0.517Zm7.827 28.966a14.947 14.947 0 0 0 5.22 -2.584l-5.22 -5.219v7.803Zm2.767 -10.57 5.219 5.22a14.947 14.947 0 0 0 2.584 -5.22H21.68Z"
          fill="#0052B4"
        />
        <path
          d="M29.873 13.043H16.957V0.127a15.15 15.15 0 0 0 -3.914 0v12.916H0.127a15.15 15.15 0 0 0 0 3.913h12.916v12.917a15.146 15.146 0 0 0 3.913 0V16.957h12.917a15.146 15.146 0 0 0 0 -3.914Z"
          fill="#D80027"
        />
        <path
          d="m18.913 18.913 6.694 6.694c0.308 -0.308 0.601 -0.63 0.881 -0.963l-5.73 -5.73h-1.845Zm-7.826 0 -6.693 6.694c0.307 0.308 0.629 0.601 0.963 0.881l5.73 -5.73v-1.845Zm0 -7.826L4.393 4.393c-0.307 0.308 -0.601 0.63 -0.881 0.963l5.73 5.731h1.845Zm7.826 0 6.694 -6.694a15.44 15.44 0 0 0 -0.963 -0.881l-5.73 5.73v1.845Z"
          fill="#D80027"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h30v30H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
