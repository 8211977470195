import { Box, Flex } from '@chakra-ui/react';
import { fetchCreditCards, ICreditCard } from 'lib/booking-helpers';
import { NextImage } from '@sitecore-jss/sitecore-jss-nextjs';
import { useEffect, useState } from 'react';
import { useBookingMenuDatesStore, useBookingMenuStore } from 'src/store';

const CreditCards: React.FC = () => {
  const [creditCards, setCreditCards] = useState<ICreditCard[]>([]);
  const fetchCredit = async () => {
    const response = await fetchCreditCards();
    setCreditCards(response);
  };

  const { flex } = useBookingMenuDatesStore((state) => state);
  const { roomPickerOpen } = useBookingMenuStore((state) => state);

  useEffect(() => {
    creditCards.length === 0 && roomPickerOpen && fetchCredit();
  }, [roomPickerOpen, creditCards.length]);

  return (
    <Flex flexDirection={'row'} gap="0.5rem" justifyContent={'flex-start'}>
      {creditCards.length > 0 &&
        creditCards.map((creditCard) => {
          if (flex && !creditCard.GuaranteeAccepted) {
            return null;
          }
          return (
            <Box key={creditCard.Name}>
              <NextImage
                alt={creditCard.ImageAltText}
                height="22"
                width="35"
                field={{ value: { src: `${process.env.PUBLIC_URL}/${creditCard.ImageUrl}` } }}
                unoptimized={true}
              />
            </Box>
          );
        })}
    </Flex>
  );
};

export default CreditCards;
