import React from 'react';
import { Field, withDatasourceCheck, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import { Box, useMultiStyleConfig } from '@chakra-ui/react';
import { IDroptreeColor } from '../../../Component-models/data-items/IDroptreeColor';
import { ITag } from '../../../Component-models/base-items/Itext';

type HeadlineProps = ComponentProps & {
  fields: {
    smallText: Field<string>;
    bigText: Field<string>;
    hTag: ITag;
    textcolor: IDroptreeColor;
    centerOnDesktop: Field<boolean>;
    centerOnMobile: Field<boolean>;
  };
};

const Hostelheadline: React.FC<HeadlineProps> = (props: HeadlineProps) => {
  const styles = useMultiStyleConfig('Headline', {});
  const topMargin = props.params.Styles !== undefined && props.params.Styles.includes('top-margin');

  if (!props.fields) {
    return null;
  }
  const { smallText, bigText, hTag, textcolor, centerOnDesktop, centerOnMobile } = props.fields;

  return (
    <Box
      className={`ahhg-hostel-headline ${props.params.GridParameters} ${topMargin ? 'top-margin' : ''}`}
      __css={{
        ...styles.container,
        textAlign: {
          base: centerOnMobile?.value ? 'center' : 'left',
          md: centerOnDesktop?.value ? 'center' : 'left',
        },
        justifyContent: {
          base: centerOnMobile?.value ? 'center' : 'flex-start',
          md: centerOnDesktop?.value ? 'center' : 'flex-start',
        },
        color: textcolor?.fields?.colorcode?.value ?? styles.container.color,
      }}
    >
      <Box __css={{ maxWidth: 'fit-content' }}>
        <Box __css={styles.smallText}>
          <Text tag="p" field={smallText} />
        </Box>
        <Box textStyle={hTag?.fields.Tag.value} __css={styles.bigText}>
          <Text tag={hTag?.fields.Tag.value} field={bigText} />
        </Box>
      </Box>
    </Box>
  );
};

export default withDatasourceCheck()<HeadlineProps>(Hostelheadline);
