import { Icon, IconProps } from '@chakra-ui/react';
import { ReactElement } from 'react';

export const DinersIcon = (props: IconProps): ReactElement => {
  return (
    <Icon {...props} viewBox="0 0 31 21">
      <rect
        x="1.31667"
        y="0.629167"
        width="28.9292"
        height="19.7417"
        rx="2.51667"
        fill="white"
        stroke="#D9D9D9"
        strokeWidth="1.25833"
      />
      <path
        d="M17.2732 17.0624C20.7772 17.0796 23.9755 14.1367 23.9755 10.5568C23.9755 6.6422 20.7772 3.93604 17.2732 3.9375H14.2575C10.7118 3.93604 7.79297 6.64293 7.79297 10.5568C7.79297 14.1375 10.7118 17.0796 14.2575 17.0624H17.2732Z"
        fill="#0079BE"
      />
      <path
        d="M14.2721 4.47998C11.0317 4.48108 8.40635 7.1697 8.40527 10.4881C8.40635 13.8061 11.0317 16.4948 14.2721 16.4958C17.5129 16.4948 20.1393 13.8061 20.1397 10.4881C20.1393 7.1697 17.5132 4.48108 14.2721 4.47998ZM10.5535 10.4881C10.5528 9.72085 10.7803 8.97125 11.2063 8.33725C11.6322 7.70325 12.2367 7.21435 12.9409 6.93444V14.0414C12.2367 13.7617 11.632 13.2729 11.2061 12.6389C10.7802 12.0049 10.5527 11.2553 10.5535 10.4881ZM15.603 14.0428V6.93408C16.3077 7.21358 16.9127 7.70245 17.339 8.33666C17.7652 8.97087 17.9927 9.72087 17.9918 10.4885C17.9926 11.256 17.765 12.006 17.3388 12.6401C16.9126 13.2743 16.3076 13.7632 15.603 14.0428Z"
        fill="white"
      />
      <path
        d="M17.2732 17.0624C20.7772 17.0796 23.9755 14.1367 23.9755 10.5568C23.9755 6.6422 20.7772 3.93604 17.2732 3.9375H14.2575C10.7118 3.93604 7.79297 6.64293 7.79297 10.5568C7.79297 14.1375 10.7118 17.0796 14.2575 17.0624H17.2732Z"
        fill="#0079BE"
      />
      <path
        d="M14.2721 4.47998C11.0317 4.48108 8.40635 7.1697 8.40527 10.4881C8.40635 13.8061 11.0317 16.4948 14.2721 16.4958C17.5129 16.4948 20.1393 13.8061 20.1397 10.4881C20.1393 7.1697 17.5132 4.48108 14.2721 4.47998ZM10.5535 10.4881C10.5528 9.72085 10.7803 8.97125 11.2063 8.33725C11.6322 7.70325 12.2367 7.21435 12.9409 6.93444V14.0414C12.2367 13.7617 11.632 13.2729 11.2061 12.6389C10.7802 12.0049 10.5527 11.2553 10.5535 10.4881ZM15.603 14.0428V6.93408C16.3077 7.21358 16.9127 7.70245 17.339 8.33666C17.7652 8.97087 17.9927 9.72087 17.9918 10.4885C17.9926 11.256 17.765 12.006 17.3388 12.6401C16.9126 13.2743 16.3076 13.7632 15.603 14.0428Z"
        fill="white"
      />
    </Icon>
  );
};
