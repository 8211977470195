import { Icon, IconProps } from '@chakra-ui/react';
import { ReactElement } from 'react';

export const KlarnaIcon = (props: IconProps): ReactElement => {
  return (
    <Icon {...props} viewBox="0 0 31 21">
      <rect
        x="1.44167"
        y="0.629167"
        width="28.9292"
        height="19.7417"
        rx="2.51667"
        fill="#FEB4C7"
        stroke="#D9D9D9"
        strokeWidth="1.25833"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.49104 13.4572H4.2627V7.875H5.49104V13.4572ZM8.55765 7.87503H7.35595C7.35595 8.90229 6.90311 9.84515 6.11358 10.462L5.63748 10.8339L7.48219 13.4574H8.9989L7.30152 11.0434C8.10611 10.2077 8.55765 9.08353 8.55765 7.87503ZM10.5169 13.4556H9.35688V7.87603H10.5169V13.4556ZM14.0216 9.59618V9.84308C13.7086 9.62037 13.3308 9.4895 12.9233 9.4895C11.8449 9.4895 10.9707 10.4013 10.9707 11.5261C10.9707 12.6508 11.8449 13.5626 12.9233 13.5626C13.3308 13.5626 13.7086 13.4318 14.0216 13.2091V13.4558H15.1296V9.59618H14.0216ZM14.0181 11.5262C14.0181 12.0746 13.5686 12.5192 13.0141 12.5192C12.4597 12.5192 12.0102 12.0746 12.0102 11.5262C12.0102 10.9777 12.4597 10.5332 13.0141 10.5332C13.5686 10.5332 14.0181 10.9777 14.0181 11.5262ZM25.7107 9.84308V9.59618H26.8186V13.4558H25.7107V13.2091C25.3977 13.4318 25.0199 13.5626 24.6124 13.5626C23.5339 13.5626 22.6597 12.6508 22.6597 11.5261C22.6597 10.4013 23.5339 9.4895 24.6124 9.4895C25.0199 9.4895 25.3977 9.62037 25.7107 9.84308ZM24.7033 12.5192C25.2577 12.5192 25.7072 12.0746 25.7072 11.5262C25.7072 10.9777 25.2577 10.5332 24.7033 10.5332C24.1488 10.5332 23.6993 10.9777 23.6993 11.5262C23.6993 12.0746 24.1488 12.5192 24.7033 12.5192ZM20.6907 9.49241C20.2481 9.49241 19.8292 9.63573 19.5492 10.0311V9.59645H18.446V13.4558H19.5627V11.4276C19.5627 10.8407 19.9401 10.5533 20.3945 10.5533C20.8814 10.5533 21.1613 10.8567 21.1613 11.4196V13.4558H22.268V11.0014C22.268 10.1032 21.5833 9.49241 20.6907 9.49241ZM16.8437 9.5961V10.0988C17.0658 9.79725 17.4797 9.59632 17.9296 9.59632V10.7194L17.9235 10.7192L17.9165 10.7189C17.4781 10.7189 16.8463 11.0457 16.8463 11.6537V13.4557H15.7094V9.5961H16.8437ZM27.2995 12.8117C27.2995 12.4111 27.6108 12.0865 27.9947 12.0865C28.3787 12.0865 28.6901 12.4111 28.6901 12.8117C28.6901 13.2121 28.3787 13.5368 27.9947 13.5368C27.6108 13.5368 27.2995 13.2121 27.2995 12.8117Z"
        fill="#17120F"
      />
    </Icon>
  );
};
