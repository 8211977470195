import { Button, Flex, FormControl, FormLabel, Heading, Input, Text } from '@chakra-ui/react';
import { generateGuestLink } from 'lib/helpers';
import { useRef, useState } from 'react';

const LinkFetcher = (): JSX.Element => {
  const reservationNumberRef = useRef<HTMLInputElement>(null);
  const generatedLinkRef = useRef<HTMLInputElement>(null);

  const [errorText, setErrorText] = useState<string>('');
  const [successText, setSuccessText] = useState<string>('');
  const [generatedLink, setGeneratedLink] = useState<string>('');

  const handleGenerateLink = () => {
    setGeneratedLink('');
    setErrorText('');
    if (!reservationNumberRef.current || reservationNumberRef.current.value === '') {
      setErrorText('Du skal indtaste et reservationsnummer');
      return;
    }
    const resNum = reservationNumberRef.current?.value;
    //check if resNum is a number
    if (isNaN(Number(resNum))) {
      setErrorText('Du skal indtaste ET reservationsnummer');
      return;
    }

    const response = generateGuestLink(resNum);
    response
      .then((data) => {
        const urlDelimiter = data.indexOf('guid');
        const guid = data.slice(urlDelimiter + 5);
        if (!guid) {
          setErrorText('Der findes ingen reservation med det reservationsnummer');
          return;
        }

        setGeneratedLink(data);
      })
      .catch((error) => {
        setErrorText(error.message);
      });
  };

  const handleCopyLink = () => {
    if (generatedLinkRef.current && generatedLinkRef.current.value !== '') {
      navigator.clipboard.writeText(generatedLinkRef.current.value);
      setSuccessText('Linket er kopieret til udklipsholderen');
    }
  };

  return (
    <Flex
      backgroundColor="darkgrey"
      borderRadius={'1rem'}
      gap={'1rem'}
      flexDirection="column"
      padding={'2rem'}
      w={{ base: '100', lg: '50%' }}
      justifyContent="center"
      textAlign={'center'}
    >
      <Heading size={'md'} my="1rem">
        Hent link for en enkelt reservation
      </Heading>
      {errorText && <Text color="red">{errorText}</Text>}
      {successText && <Text color="green">{successText}</Text>}
      <Flex gap={'1rem'} flexDirection="column">
        <FormControl id="reservationNumber">
          <FormLabel>
            <Text>Indtast reservationsnummeret der ønskes link til</Text>
          </FormLabel>
          <Input
            placeholder="Indtast reservations nummer "
            type="text"
            name="reservationNumber"
            ref={reservationNumberRef}
          />
        </FormControl>
        <Button onClick={handleGenerateLink} w="fit-content">
          Hent gæsteregistrerings link
        </Button>
        <FormControl id="gruppeLink">
          <FormLabel>
            <Text>Gæsteregistrerings link</Text>
          </FormLabel>
          <Input type="text" name="gruppeLink" ref={generatedLinkRef} value={generatedLink} readOnly />
        </FormControl>
        <Button onClick={handleCopyLink} w="fit-content">
          Kopier link
        </Button>
      </Flex>
    </Flex>
  );
};

export default LinkFetcher;
