import { Box, chakra, Flex, useMultiStyleConfig } from '@chakra-ui/react';
import { RichText } from '@sitecore-jss/sitecore-jss-nextjs';
import { motion, AnimatePresence } from 'framer-motion';
import { transformCurrency } from 'lib/helpers';
import { useI18n } from 'next-localization';
import { MouseEventHandler } from 'react';
import { useCurrencyStore, useGeneralStore } from 'src/store';

export type FaqItemProps = {
  isOpen: boolean;
  onClick?: MouseEventHandler;
  headline: string;
  description: string;
  textColor?: string;
};

const FaqItem = ({ headline, description, isOpen, onClick, textColor }: FaqItemProps): JSX.Element => {
  const styles = useMultiStyleConfig('FaqItem', { isOpen: isOpen });

  const { currentCurrency } = useCurrencyStore((state) => state);
  const { loaded } = useGeneralStore((state) => state);
  const { locale } = useI18n();

  const parentColor = textColor || 'darkGrey.700';

  const textContent = description;
  const updatedDescription = transformCurrency(textContent, currentCurrency, locale());

  return loaded ? (
    <Box __css={{ ...styles.container }} borderColor={parentColor}>
      <Flex __css={{ ...styles.header }} onClick={onClick}>
        <chakra.h3 __css={{ ...styles.headline }} color={parentColor}>
          {headline}
        </chakra.h3>
        <Box
          __css={isOpen ? { ...styles.button, ...styles.minusIcon } : styles.button}
          bgColor={isOpen ? 'transparent' : parentColor}
          borderColor={isOpen ? parentColor : 'transparent'}
        >
          <Box
            className={`plusminus ${isOpen && 'activePlusMinus'}`}
            sx={{ _after: { backgroundColor: isOpen ? parentColor : '#fff' } }}
          ></Box>
        </Box>
      </Flex>
      {/* {isOpen && (
        <Box __css={{ ...styles.text }}>
          <RichText field={{ value: description }} className="sc-rich-text" />
        </Box>
      )} */}
      <Box __css={{ ...styles.text }} color={parentColor}>
        <AnimatePresence>
          {isOpen && (
            <motion.div
              key={headline}
              initial={{
                maxHeight: '0px',
                opacity: 0,
              }}
              animate={{
                maxHeight: '500px',
                opacity: 1,
                transition: {
                  maxHeight: { duration: 0.3, ease: 'easeInOut' },
                  opacity: { duration: 0.3, ease: 'easeInOut' },
                },
              }}
              exit={{
                maxHeight: '0px',
                opacity: 0,
                transition: {
                  maxHeight: { duration: 0.3, ease: 'easeOut' },
                  opacity: { duration: 0, ease: 'easeInOut' },
                },
              }}
            >
              <RichText field={{ value: updatedDescription }} className="sc-rich-text" />
            </motion.div>
          )}
        </AnimatePresence>
      </Box>
    </Box>
  ) : (
    <></>
  );
};

export default FaqItem;
