import { Box, Icon, Skeleton, useMultiStyleConfig } from '@chakra-ui/react';
import {
  withDatasourceCheck,
  Field,
  LinkField,
  ImageFieldValue,
  useSitecoreContext,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { GoogleMap, useLoadScript } from '@react-google-maps/api';

import { ComponentProps } from 'lib/component-props';
import { mapThemeStyles } from '../../../../themes/base/components/maps';
import HostelLocation from './HostelLocation';
import POILocation from './POILocation';
import { Map2 } from 'tabler-icons-react';
import { IGridParameters } from '../../../Component-models/base-items/IGridParameters';
import { RefObject, useEffect, useRef, useState } from 'react';
import useOnScreen from 'components/Util-Components/useOnScreen';

type MapProps = ComponentProps & {
  fields: {
    title: Field<string>;
    positionLatitude: Field<string>;
    positionLongitude: Field<string>;
    centerLatitude: Field<string>;
    centerLongitude: Field<string>;
    zoom: Field<number>;
    POIs: Array<POIType>;
  };
  params: IGridParameters;
};

export type POIType = {
  fields: {
    Latitude: Field<number>;
    // Known spell error but its using existing data so don't change
    Longtitude: Field<number>;
    Headline: Field<string>;
    PlaceName: Field<string>;
    Description: Field<string>;
    Image: Field<ImageFieldValue>;
    Link: Field<LinkField>;
  };
};

// "Map" component name not allowed, so named "Maps"
const GoogleMaps = (props: MapProps): JSX.Element => {
  const styles = useMultiStyleConfig('Maps', { props });
  const center = {
    lat: parseFloat(props.fields?.centerLatitude?.value || '0.00'),
    lng: parseFloat(props.fields?.centerLongitude?.value || '0.00'),
  };
  const position = {
    lat: parseFloat(props.fields?.positionLatitude?.value || '0.00'),
    lng: parseFloat(props.fields?.positionLongitude?.value || '0.00'),
  };

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY as string,
  });

  const mapContainerRef: RefObject<HTMLDivElement> = useRef(null);
  const mapScrolledIntoView = useOnScreen(mapContainerRef);

  const { sitecoreContext } = useSitecoreContext();
  const isEditing = sitecoreContext && sitecoreContext.pageEditing;

  const [isMapContainerRef, setIsMapContainerRef] = useState(false);
  useEffect(() => {
    if (isMapContainerRef || isEditing) return;
    setIsMapContainerRef(mapScrolledIntoView);
  }, [mapScrolledIntoView, isEditing]);

  return (
    <Box className={props.params.GridParameters} __css={styles.container} ref={mapContainerRef}>
      {isMapContainerRef && isLoaded && !isEditing ? (
        <GoogleMap
          zoom={props.fields.zoom?.value}
          center={center}
          mapContainerStyle={{ height: '100%', width: '100%' }}
          options={{
            disableDefaultUI: true,
            keyboardShortcuts: false,
            styles: mapThemeStyles,
            clickableIcons: false,
          }}
        >
          <HostelLocation position={position} title={props.fields.title} />
          {props.fields.POIs.map((POI) => (
            <POILocation key={POI.fields.PlaceName.value} fields={POI.fields} />
          ))}
        </GoogleMap>
      ) : (
        <>
          <Box>
            <Icon as={Map2} boxSize="6rem" strokeWidth={1.5} __css={styles.loadingIcon} />
          </Box>
          <Skeleton h="100%" w="100%" />
        </>
      )}
    </Box>
  );
};

export default withDatasourceCheck()<MapProps>(GoogleMaps);
